/**
 *
 * ActivitySettingsIndirect
 *
 */
import find from 'lodash/find';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  ActivitySettings,
  departmentValueGetter,
  nameValueFormatter,
  nameValueGetter,
} from 'components/ActivitySettingsDirect';
import FormulaRenderer from 'components/ActivitySettingsDirect/FormulaRenderer';
import CheckboxCellRenderer from 'components/CheckboxCellRenderer';
import { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import NameWithToolTip from 'components/NameWithToolTip';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { addPlanMHEOpenAction, deletePlanActivityLines, addPlanRoleOpenAction } from 'containers/PlanDetailPage/actions';
import {
  makeSelectOpenedMhesFromPlan,
  selectEditFromplan,
  selectPeriodIndexFromPlan,
  selectPlanDepartments,
  makeSelectOpenedRolesFromPlan
} from 'containers/PlanDetailPage/selectors';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { addPaMHEOpenAction, deletePaActivityLines, addPaROleOpenAction } from 'containers/PlanningAreaDetailPage/actions';
import {
  selectAreaDepartments,
  selectEditFromPa,
  selectOpenedMhesFromPa,
  selectPeriodIndexFromPa,
  selectOpenedRolesFromPa,
} from 'containers/PlanningAreaDetailPage/selectors';
import { PERMISSIONS } from 'utils/security';
import { numberCellFormatter } from 'utils/utils';

import DetailsButtons from '../ActivitySettingsDirect/DetailsButtons';
import DetailsButtonRoles from '../ActivitySettingsDirect/DetailsButtonRoles';
import CustomerCellRenderer from '../CustomerCellRenderer';
import DeleteCellRenderer from '../DeleteCellRenderer';
import { SimpleHeader } from '../DetailPageShared';
import { withDeleteDialog } from '../Dialog';
import indirectMessages from './messages';

function createColumnDef(self, messages, isIndirect, customerOptions) {
  const { props } = self;
  const {
    intl: { formatMessage },
  } = props;
  const editable = props.edit;
  const isForecastEditor = props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  let columnDefs = [
    {
      headerName: formatMessage(messages.departmentId),
      children: [{
        headerName: formatMessage(messages.departmentId),
        valueGetter: departmentValueGetter(self, formatMessage(indirectMessages.allDepartments)),
        width: 160,
        field: 'departmentId',
        colId: 'departmentId',
        rowGroup: true,
        hide: true,
      }],
    },
    {
      headerName: props.intl.formatMessage(messages.activity),
      width: 160,
      colId: 'customName',
      field: 'customName',
      editable: false,
      cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
      valueFormatter: nameValueFormatter,
      filterValueGetter: nameValueGetter,
      menuTabs: ['filterMenuTab'],
      rowDrag: editable ? params => !params.node.group : false,
    },
    {
      headerName: formatMessage(messages.customerId),
      minWidth: 80,
      field: 'customerName',
      colId: 'customerName',
      editable,
      menuTabs: ['filterMenuTab'],

      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: params => params.value && params.value.label,
      cellEditorParams: {
        values: customerOptions,
      },
    },
  ];
  if (isForecastEditor) {
    if (!isDayOfWeekTransformationActivity(props.formik)) {
      columnDefs = columnDefs.concat([
        {
          headerName: formatMessage(messages.isEffortForecast),
          width: 80,
          field: 'isEffortForecast',
          colId: 'isEffortForecast',
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: { editable },
          menuTabs: ['filterMenuTab'],
          sortable: false,
        },
      ]);
    }
    columnDefs = columnDefs.concat([
      {
        headerName: formatMessage(messages.omsCode),
        width: 160,
        field: 'activity.omsName',
        valueGetter: params => {
          if (params.data?.omsCode && params.data?.omsName) {
            return `${params.data?.omsCode} - ${params.data?.omsName}`
          }
          return (
            params.data &&
            params.data.activity &&
            params.data.activity.omsCode &&
            `${params.data.activity.omsCode} - ${params.data.activity.omsName}`
          )
        },
        colId: 'omsName',
        editable: false,
        menuTabs: ['filterMenuTab'],
        sortable: false,
      },
    ]);
  }
  columnDefs = columnDefs.concat([
    {
      headerName: props.intl.formatMessage(messages.formula),
      field: 'formulaText',
      colId: 'formulaText',
      editable,
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: FormulaRenderer,
      cellRendererParams: {
        commentTitle: formatMessage(messages.comment),
        editMode: editable,
        isDayOfWeekTransformationActivity: isDayOfWeekTransformationActivity(props.formik),
      },
    },
  ]);
  if (editable) {
    columnDefs.push({
      headerName: formatMessage(messages.comment),
      field: 'comment',
      colId: 'comment',
      menuTabs: ['filterMenuTab'],
      sortable: false,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 300,
      },
      editable,
    });
  }
  columnDefs = columnDefs.concat([
    {
      headerName: props.intl.formatMessage(messages.effortPerHead),
      field: 'effortPerHead',
      colId: 'effortPerHead',
      editable,
      menuTabs: ['filterMenuTab'],
      valueFormatter: numberCellFormatter,
    },
    {
      headerName: props.viewSettings.checkboxRole ? '' : props.intl.formatMessage(messages.details),
      field: 'detailsButtons',
      colId: 'detailsButtons',
      cellRendererFramework: DetailsButtons,
      sortable: false,
      suppressMenu: true,
      cellRendererParams: {
        onClickHandler: props.addMHEOpenAction,
        editable,
      },
    },
  ]);
  if(props.viewSettings.checkboxRole){
    columnDefs.push({
      headerName: formatMessage(messages.details),
      field: 'detailsButtonsRole',
      colId: 'detailsButtonsRole',
      cellRendererFramework: DetailsButtonRoles,
      sortable: false,
      suppressMenu: true,
      cellRendererParams: {
        onClickHandler: props.addRoleOpenAction,
        editable,
      },
    })
  }
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: payload => props.openDeleteDialog(props.deleteActivityLines, payload, payload.activity),
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
      headerComponentFramework: SimpleHeader,
    });
  }
  return { columnDefs };
}

// Plan
const mapPlanStateToProps = createStructuredSelector({
  periodIndex: selectPeriodIndexFromPlan,
  edit: selectEditFromplan,
  token: makeSelectToken(),
  activityIdsMheOpen: makeSelectOpenedMhesFromPlan(),
  activityIdsRoleOpen: makeSelectOpenedRolesFromPlan(),
  departments: selectPlanDepartments,
  viewSettings: selectViewModeStoredData,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteActivityLines: deletePlanActivityLines,
      addMHEOpenAction: addPlanMHEOpenAction,
      addRoleOpenAction: addPlanRoleOpenAction
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  periodIndex: selectPeriodIndexFromPa,
  edit: selectEditFromPa,
  token: makeSelectToken(),
  activityIdsMheOpen: selectOpenedMhesFromPa,
  activityIdsRoleOpen: selectOpenedRolesFromPa,
  departments: selectAreaDepartments,
  viewSettings: selectViewModeStoredData,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteActivityLines: deletePaActivityLines,
      addMHEOpenAction: addPaMHEOpenAction,
      addRoleOpenAction: addPaROleOpenAction
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export default injectIntl(withPlanConnect(withDeleteDialog(ActivitySettings(createColumnDef, true, indirectMessages))));
export const PaActivitySettingsIndirect = injectIntl(
  withPaConnect(withDeleteDialog(ActivitySettings(createColumnDef, true, indirectMessages))),
);
