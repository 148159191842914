/*
 * PlanningAreaDetailToolbar Messages
 *
 * This contains all the text for the PlanningAreaDetailToolbar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  saveTableConfig: {
    id: 'app.components.PlanningAreaDetailToolbar.saveTableConfig',
    defaultMessage: 'Save Current To User View',
  },
  back: {
    id: 'app.components.PlanningAreaDetailToolbar.back',
    defaultMessage: 'Back',
  },
  createPlan: {
    id: 'app.components.PlanningAreaDetailToolbar.createPlan',
    defaultMessage: 'Create Plan',
  },
  showPlans: {
    id: 'app.components.PlanningAreaDetailToolbar.showPlans',
    defaultMessage: 'Show Plans',
  },
  copy: {
    id: 'app.components.PlanningAreaDetailToolbar.copy',
    defaultMessage: 'Copy',
  },
  history: {
    id: 'app.components.PlanningAreaDetailToolbar.history',
    defaultMessage: 'History',
  },
  display: {
    id: 'app.components.PlanningAreaDetailToolbar.display',
    defaultMessage: 'Display',
  },
  edit: {
    id: 'app.components.PlanningAreaDetailToolbar.edit',
    defaultMessage: 'Edit',
  },
  save: {
    id: 'app.components.PlanningAreaDetailToolbar.save',
    defaultMessage: 'Save',
  },
  upload: {
    id: 'app.components.PlanningAreaDetailToolbar.upload',
    defaultMessage: 'Upload',
  },
  interfaces: {
    id: 'app.components.PlanningAreaDetailToolbar.interfaces',
    defaultMessage: 'Interfaces',
  },
  forecast: {
    id: 'app.components.PlanningAreaDetailToolbar.forecast',
    defaultMessage: 'Forecast',
  },
  interfacesTooltipText: {
    id: 'app.components.PlanningAreaDetailToolbar.interfacesTooltipText',
    defaultMessage: 'On this page you can configure connection between this plan and SmartProductivity',
  },
  forecastTooltipText: {
    id: 'app.components.PlanningAreaDetailToolbar.forecastTooltipText',
    defaultMessage:
      'Before you start using forecasting you must configure interfaces. On this page you can view forecast received from SmartVolume or create forecast on your own using SmartProductivity data.',
  },
  editLoader: {
    id: 'app.components.PlanningAreaDetailToolbar.editLoader',
    defaultMessage: 'Please wait until we fetch data',
  }
});
