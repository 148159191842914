import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from '../FormikDatePicker';

const Wrap = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 5px;
  > * {
    margin: 0 5px;
  }
  transition: ${props => props.theme.transitions.all};
  :hover {
    background-color: ${props => props.theme.color.yellow};
  }
`;

export const ItemSelectable = props => (
  <Wrap className={props.className} isSelected={props.isSelected} onClick={props.disabled ? null : props.onClick}>
    {props.isSelected && <FontAwesomeIcon icon="check-square" />}
    {!props.isSelected && <FontAwesomeIcon icon="square" />}
    {props.children}
  </Wrap>
);

export const ReduxFormCheckbox = styled(props => {
  const { meta, input, ...other } = props;
  return (
    <ItemSelectable className={props.className} isSelected={input.value} onClick={() => input.onChange(!input.value)} />
  );
})`
  width: inherit;
  :hover {
    cursor: pointer;
  }
`;

export const FormikCheckbox = styled(props => {
  const { field, form, disabled } = props;
  return (
    <ItemSelectable
      className={props.className}
      isSelected={field.value}
      onClick={() => {
        form.setFieldValue(field.name, !field.value);
      }}
      disabled={disabled}
    />
  );
})`
  width: inherit;
  :hover {
    cursor: pointer;
  }
`;

export const FormikCheckboxSunday = styled(props => {
  const { field, form, disabled } = props;
  return (
    <ItemSelectable
      className={props.className}
      isSelected={field.value}
      onClick={() => {
        if(field.name === 'includeWeekStartAsSunday' && field.value){
          form.setFieldValue('includeVolumeCategory', false);
          form.setFieldValue('includeMhe', false);
          form.setFieldValue('includeRole', false);
        }
        form.setFieldValue(field.name, !field.value);
      }}
      disabled={disabled}
    />
  );
})`
  width: inherit;
  :hover {
    cursor: pointer;
  }
`;

ReduxFormCheckbox.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
};

ItemSelectable.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ItemSelectable;
