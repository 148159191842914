/*
 * PlanDetailBasicInfo Messages
 *
 * This contains all the text for the PlanDetailBasicInfo component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.PlanDetailBasicInfo.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'app.components.PlanDetailBasicInfo.description',
    defaultMessage: 'Description',
  },
  planningArea: {
    id: 'app.components.PlanDetailBasicInfo.planningArea',
    defaultMessage: 'Planning Area',
  },
  parentPlanningArea: {
    id: 'app.components.PlanDetailBasicInfo.parentPlanningArea',
    defaultMessage: 'Parent Planning Area',
  },
  saveFromTo: {
    id: 'app.components.PlanDetailBasicInfo.save',
    defaultMessage: 'Save',
  },
  weekStartDay: {
    id: 'app.containers.PlanDetailBasicInfo.weekStartDay',
    defaultMessage: 'Week Start Day',
  },
  region: {
    id: 'app.components.PlanDetailBasicInfo.region',
    defaultMessage: 'Region',
  },
  country: {
    id: 'app.components.PlanDetailBasicInfo.country',
    defaultMessage: 'Country',
  },
  sector: {
    id: 'app.components.PlanDetailBasicInfo.sector',
    defaultMessage: 'Sector',
  },
  dayStartTime: {
    id: 'app.components.PlanDetailBasicInfo.dayStartTime',
    defaultMessage: 'Day Start Time',
  },
  masterPlans: {
    id: 'app.components.PlanDetailBasicInfo.masterPlans',
    defaultMessage: 'Master Plans',
  },
  schedules: {
    id: 'app.components.PlanDetailBasicInfo.schedules',
    defaultMessage: 'Schedules',
  }
});
