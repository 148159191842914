import React from "react";
import { WarningText } from 'components/FormikTextInput';
import { FormattedMessage } from "react-intl";


const Warnings = ({reportingRunError, smartCodeErrors = [], smartCodeErrorStatusFlag, messages, snowflakeStatus}) => {
    return(
        <>
        {
            reportingRunError && <WarningText><FormattedMessage {...messages.reportingRunningError}/></WarningText>
        }
        {
            snowflakeStatus === false && <WarningText><FormattedMessage {...messages.snowFlakeWarning}/></WarningText>
        }
        {
            smartCodeErrorStatusFlag && <WarningText><FormattedMessage {...messages.smartCodeStatusError}/></WarningText>
        }
        {
            smartCodeErrors?.map((sc)=>{
                return <WarningText>{sc}</WarningText>
            })
        }
        {
            (!reportingRunError &&  smartCodeErrors.length === 0 && !smartCodeErrorStatusFlag && snowflakeStatus === true) &&  <WarningText><FormattedMessage {...messages.noWarningsAvailable}/></WarningText>
        }
        </>
    )
}

export default Warnings;