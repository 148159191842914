/*
 *
 * PlanningAreaDetailPage reducer
 *
 */

import { fromJS } from 'immutable';
import { DEFAULT_GUI_STATE, handleCommonEventsForPlanningArea } from '../../utils/commonDetailReducer';
import {
  SUBMIT_ERROR,
  SAVE_PA,
  CLEAR_MASTER_PLAN_CONFLICT,
  SAVE_MASTER_PLAN_CONFLICT,
  PLANNINGAREA_DETAILS_LOADER,
  RESET_PLANNING_AREA,
  SAVE_ROLES,
  SAVE_PLANINGAREA_SMART_CODE_MAPPINGS,
  PLANNINGAREA_DETAILS_LOADER_FLAG,
  PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG,
  TOGGLE_VOLUME_FORMULA,
  TOGGLE_VOLUME_VALUE,
  TOGGLE_VOLUME_FORMULA_VAR,
  TOGGLE_VOLUME_VALUE_VAR,
} from './constants';

export const initialState = fromJS({
  planningArea: {},
  guiState: DEFAULT_GUI_STATE,
  activityIdsMheOpen: [],
  activityIdsShiftOpen: [],
  activityIdsRoleOpen: [],
  showValue: true,
  showFormula: true,
  showValueVar: true,
  showFormulaVar: true,
});

function planningAreaDetailPageReducer(state = initialState, action) {
  const commonResult = handleCommonEventsForPlanningArea(state, action);
  if (commonResult) {
    return commonResult;
  }
  switch (action.type) {
    case SUBMIT_ERROR:
      return state.setIn(['guiState', 'submitError'], true);
    case RESET_PLANNING_AREA:
      return state.set('planningArea',null)
    case SAVE_PA:
      return state.setIn(['guiState', 'submitError'], false);
    case SAVE_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'submitError'], false).setIn(['guiState', 'masterPlanConflict'], action.payload);
    case CLEAR_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'masterPlanConflict'], null);
    case PLANNINGAREA_DETAILS_LOADER:
      return state.set('planningAreaDetailsLoader', action.payload)
    case SAVE_ROLES:
      return state.set('planRoles', action.payload);
    case SAVE_PLANINGAREA_SMART_CODE_MAPPINGS:
      return state.set('planSmartCodeMappings', action.payload);
    case PLANNINGAREA_DETAILS_LOADER_FLAG:
      return state.set('planingAreadetailsFlag', action.payload);
    case PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG:
      return state.set('planningAreaOverviewFlag', action.payload);
    case TOGGLE_VOLUME_FORMULA:
      return state.set('showFormula', action.payload);
    case TOGGLE_VOLUME_VALUE:
      return state.set('showValue', action.payload);  
    case TOGGLE_VOLUME_FORMULA_VAR:
      return state.set('showFormulaVar', action.payload);
    case TOGGLE_VOLUME_VALUE_VAR:
      return state.set('showValueVar', action.payload);       
    default:
      return state;
  }
}

export default planningAreaDetailPageReducer;
