import React from 'react';
import PropTypes from 'prop-types';
import { DateTime, Duration } from 'luxon';

import { DAY_NAMES } from 'utils/calendar/constants';

export const formatDay = day => {
  if (day) {
    return `${day.substr(0, 1).toUpperCase()}${day.substr(1).toLowerCase()}`;
  }
  return '';
};

export function parseDates(entity, fields) {
  const result = { ...entity };
  fields.forEach(field => {
    if (result[field]) {
      result[field] = DateTime.fromISO(entity[field]);
    }
  });
  return result;
}

export function parseTime(val) {
  if (val && val.isValid) {
    return val;
  }
  if (!val) {
    return null;
  }
  const timeElements = val.match(/[\d]{2,3}/g);
  if (Array.isArray(timeElements) && timeElements.length === 4) {
    return Duration.fromObject({
      hours: Number(timeElements[0]),
      minutes: Number(timeElements[1]),
      seconds: Number(timeElements[2]),
    });
  }
  console.warn(`Cannot parse time value, expected string in form 07:00:00.000`, val);
  return undefined;
}

export function parseTimes(entity, fields) {
  const result = { ...entity };
  fields.forEach(field => {
    if (entity[field] && entity[field].match) {
      const timeElements = entity[field].match(/[\d]{2,3}/g);

      if (Array.isArray(timeElements) && timeElements.length === 4) {
        result[field] = Duration.fromObject({
          hours: Number(timeElements[0]),
          minutes: Number(timeElements[1]),
          seconds: Number(timeElements[2]),
        });
      } else {
        console.warn(`Cannot parse time value, expected string in form 07:00:00.000`, entity[field]);
      }
    }
  });
  return result;
}

export function parseDate(val) {
  if (val && val.isValid) {
    return val;
  }
  let result = DateTime.fromISO(val);
  if (!result.isValid && val !== undefined) {
    result = DateTime.fromFormat(val, 'L/d/yyyy');
  }
  if (!result.isValid && val !== undefined) {
    result = DateTime.fromFormat(val, 'L-d-yyyy');
  }
  return result;
}

export function parseDateAndFormatToLocalString(val) {
  const parsed = DateTime.fromISO(val);
  return parsed ? parsed.toLocaleString() : '';
}

export function parseDateTOJSDate(val) {
  if (!val) return null;
  const parsed = DateTime.fromISO(val);
  return (parsed && parsed.toJSDate()) || null;
}

export function parseDateTimeAndFormatToLocalString(val) {
  const parsed = DateTime.fromISO(val);
  return parsed ? parsed.toLocaleString(DateTime.DATETIME_SHORT) : '';
}

export function formatTime(val) {
  const parsed = typeof val === 'string' ? DateTime.fromISO(val) : val;
  return parsed ? parsed.toLocaleString(DateTime.TIME_SIMPLE) : '';
}

export function formatDate(val) {
  const parsed = typeof val === 'string' ? parseDate(val) : val;
  return parsed ? parsed.toLocaleString(DateTime.DATE_SHORT) : '';
}

export function formatDateTime(val) {
  const parsed = typeof val === 'string' ? parseDate(val) : val;
  return parsed ? parsed.toLocaleString(DateTime.DATETIME_SHORT) : '';
}

export const generateDays = (
  start: DateTime,
  end: DateTime,
  granularity: 'day' | 'week' = 'day',
  firstDayOfWeek = 'MONDAY',
): DateTime[] => {
  if (firstDayOfWeek && !DAY_NAMES.includes(firstDayOfWeek)) {
    throw Error(`firstDayOfWeek has invalid value '${firstDayOfWeek}', valid names: ${DAY_NAMES}`);
  }
  const dayOfWeekAsNumber = DAY_NAMES.indexOf(firstDayOfWeek);
  let startOfInterval = start.startOf(granularity);
  const endDate = end.startOf('day');
  if (granularity === 'week') {
    startOfInterval = startOfInterval.plus({ days: dayOfWeekAsNumber });
  }
  const dates = [];
  while (startOfInterval <= endDate) {
    dates.push(startOfInterval);
    startOfInterval = granularity === 'day' ? startOfInterval.plus({ days: 1 }) : startOfInterval.plus({ weeks: 1 });
    // maximum amount of dates
    if (dates.length > 1000) {
      break;
    }
  }
  return dates;
};

export const dateCellFormater = params => {
  const val = params.value;
  if (!val) return;
  return formatDate(val);
};

export function scheduleText() {
  return 'Not Scheduled';
}

export const dateScheduledCellFormater = params => {
  const val = params.value;
  if (!val) return scheduleText();
  return formatDate(val);
};

export const dateTimeCellFormatter = params => {
  const val = params.value;
  if (!val) return;
  const parsed = typeof val === 'string' ? parseDate(val) : val;
  return (
    (parsed && `${parsed.toLocaleString(DateTime.DATE_SHORT)} ${parsed.toLocaleString(DateTime.TIME_SIMPLE)}`) || null
  );
};

export const timeCellFormatter = params => {
  const val = params.value;
  if (!val) return;
  const parsed = typeof val === 'string' ? parseDate(val) : val;
  return (parsed && parsed.toLocaleString(DateTime.TIME_SIMPLE)) || null;
};

export function dateComparator(filterDate, value) {
  const cellDate = new Date(value);
  let res = 0;
  if (cellDate < filterDate) {
    res = -1;
  } else if (cellDate > filterDate) {
    res = 1;
  } else {
    res = 0;
  }
  return res;
}

export const DisplayDateTime = props =>
  props.value ? (
    <div className={props.className}>
      {formatDate(props.value)} {formatTime(props.value)}
    </div>
  ) : null;

DisplayDateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
};

export const DisplayDate = props => <>{props.value ? formatDate(props.value) : null}</>;

DisplayDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export const DisplayTime = props => <>{props.value ? formatTime(props.value) : null}</>;

DisplayTime.propTypes = {
  value: PropTypes.object,
};

/**
 * Workaround the intl library. User demands it this way. - as for now
 *
 * @returns {*|string}
 */
export function getLocaleDateString() {
  const formats = {
    'ar-SA': 'dd/MM/yy',
    'bg-BG': 'dd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'zh-TW': 'yyyy/M/d',
    'cs-CZ': 'd.M.yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-DE': 'dd.MM.yyyy',
    'el-GR': 'd/M/yyyy',
    'en-US': 'M/d/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'is-IS': 'd.M.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ko-KR': 'yyyy-MM-dd',
    'nl-NL': 'd-M-yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'pl-PL': 'yyyy-MM-dd',
    'pt-BR': 'd/M/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'hr-HR': 'd.M.yyyy',
    'sk-SK': 'd. M. yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sv-SE': 'yyyy-MM-dd',
    'th-TH': 'd/M/yyyy',
    'tr-TR': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'id-ID': 'dd/MM/yyyy',
    'uk-UA': 'dd.MM.yyyy',
    'be-BY': 'dd.MM.yyyy',
    'sl-SI': 'd.M.yyyy',
    'et-EE': 'd.MM.yyyy',
    'lv-LV': 'yyyy.MM.dd.',
    'lt-LT': 'yyyy.MM.dd',
    'fa-IR': 'MM/dd/yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'hy-AM': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'mk-MK': 'dd.MM.yyyy',
    'af-ZA': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'ky-KG': 'dd.MM.yy',
    'sw-KE': 'M/d/yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'pa-IN': 'dd-MM-yy',
    'gu-IN': 'dd-MM-yy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'kn-IN': 'dd-MM-yy',
    'mr-IN': 'dd-MM-yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'mn-MN': 'yy.MM.dd',
    'gl-ES': 'dd/MM/yy',
    'kok-IN': 'dd-MM-yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'dv-MV': 'dd/MM/yy',
    'ar-IQ': 'dd/MM/yyyy',
    'zh-CN': 'yyyy/M/d',
    'de-CH': 'dd.MM.yyyy',
    'en-GB': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'fr-BE': 'd/MM/yyyy',
    'it-CH': 'dd.MM.yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'zh-HK': 'd/M/yyyy',
    'de-AT': 'dd.MM.yyyy',
    'en-AU': 'd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'zh-SG': 'd/M/yyyy',
    'de-LU': 'dd.MM.yyyy',
    'en-CA': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'fr-CH': 'dd.MM.yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'zh-MO': 'd/M/yyyy',
    'de-LI': 'dd.MM.yyyy',
    'en-NZ': 'd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'en-IE': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'es-DO': 'dd/MM/yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'en-JM': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'en-029': 'MM/dd/yyyy',
    'es-CO': 'dd/MM/yyyy',
    'ar-SY': 'dd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'en-TT': 'dd/MM/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'en-ZW': 'M/d/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'am-ET': 'd/M/yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'mn-Mong-CN': 'yyyy/M/d',
    'gd-GB': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'prs-AF': 'dd/MM/yy',
    'bn-BD': 'dd-MM-yy',
    'wo-SN': 'dd/MM/yyyy',
    'rw-RW': 'M/d/yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'gsw-FR': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'oc-FR': 'dd/MM/yyyy',
    'mi-NZ': 'dd/MM/yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'se-SE': 'yyyy-MM-dd',
    'br-FR': 'dd/MM/yyyy',
    'smn-FI': 'd.M.yyyy',
    'moh-CA': 'M/d/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'ii-CN': 'yyyy/M/d',
    'dsb-DE': 'd. M. yyyy',
    'ig-NG': 'd/M/yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'lb-LU': 'dd/MM/yyyy',
    'ba-RU': 'dd.MM.yy',
    'nso-ZA': 'yyyy/MM/dd',
    'quz-BO': 'dd/MM/yyyy',
    'yo-NG': 'd/M/yyyy',
    'ha-Latn-NG': 'd/M/yyyy',
    'fil-PH': 'M/d/yyyy',
    'ps-AF': 'dd/MM/yy',
    'fy-NL': 'd-M-yyyy',
    'ne-NP': 'M/d/yyyy',
    'se-NO': 'dd.MM.yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'si-LK': 'yyyy-MM-dd',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'km-KH': 'yyyy-MM-dd',
    'cy-GB': 'dd/MM/yyyy',
    'bo-CN': 'yyyy/M/d',
    'sms-FI': 'd.M.yyyy',
    'as-IN': 'dd-MM-yyyy',
    'ml-IN': 'dd-MM-yy',
    'en-IN': 'dd-MM-yyyy',
    'or-IN': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'tk-TM': 'dd.MM.yy',
    'bs-Latn-BA': 'd.M.yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'se-FI': 'd.M.yyyy',
    'zu-ZA': 'yyyy/MM/dd',
    'xh-ZA': 'yyyy/MM/dd',
    'tn-ZA': 'yyyy/MM/dd',
    'hsb-DE': 'd. M. yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'sr-Latn-BA': 'd.M.yyyy',
    'smj-NO': 'dd.MM.yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'hr-BA': 'd.M.yyyy.',
    'sr-Latn-ME': 'd.M.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'en-SG': 'd/M/yyyy',
    'ug-CN': 'yyyy-M-d',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'es-US': 'M/d/yyyy',
  };
  // We do what would intl package do but in more simple manner. First try to find exact language and country name.
  let result = formats[navigator.language];
  // If we dont have it. Try to match partially.
  if (result === undefined) {
    const key = Object.keys(formats).find(item => item.includes(navigator.language));
    result = formats[key];
  }
  // It might not work in many cases so there is a fallback.
  return result || 'dd/MM/yyyy';
}
