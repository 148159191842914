import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import ActivityCheckTableControlled from 'containers/TableControlled/TableActivityCheck';
import { getToken } from 'containers/App/selectors';
import messages from './messages';
import { Loading } from "containers/ForecastDetailPage/Loading";
import './style.css';

const ActivityCheckRenderer = params => {
    const colName = params?.colDef?.colId;
    const colData = params?.data?.list?.filter((list) => list.id === colName);
    if (colData[0]?.name) {
        return <FontAwesomeIcon size="lg" icon="check" />
    } else {
        return null;
    }
}

const columnDefs = (intl, headers) => {
    const columns = [
        {
            colId: 'activity',
            field: 'activity',
            headerName: intl.formatMessage(messages.activityKey),
            headerTooltip: intl.formatMessage(messages.activityKey),
            pinned: true,
            width: 300,
        },
    ]
    headers?.forEach((header) => {
        columns.push({
            headerName: header.isPlan ? intl.formatMessage(messages.plan) : intl.formatMessage(messages.planListPlanningArea),
            headerTooltip: header.isPlan ? intl.formatMessage(messages.plan) : intl.formatMessage(messages.planListPlanningArea),
            //@ts-ignore
            children: [{
                headerName: header.name,
                headerTooltip: header.name,
                cellRendererFramework: ActivityCheckRenderer,
                width: 160,
                field: header.name,
                colId: header.id,
                suppressMenu: true,
            }],
        })
    })
    return columns;
}

const ActivityCheck = (props) => {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState([]);

    useEffect(() => {
        const fetchActivityData = async () => {
            let masterPlanUrl = `/smartCode/activityCheck/${props.id}`;
            if (props.addFlag) {
                masterPlanUrl = `/smartCode/activityCheck/${props.id}/${props.planId.value}`;
            }
            const response = await fetchData(withUrl(masterPlanUrl).andToken(token), dispatch);
            if (response.isOk) {
                setHeaders(response?.data?.headers);
                setData(response?.data?.data);
                const columnState = [];
                response?.data?.headers?.forEach((header) => {
                    columnState.push({ "colId": header.name, field: header.name, "hide": false, "aggFunc": null, "pivotIndex": null, "pinned": false, "rowGroupIndex": null })
                });
                columnState.push({ "colId": 'activity', field: 'activity', "width": '300', "hide": false, "aggFunc": null, "pivotIndex": null, "pinned": true, "rowGroupIndex": null });
                setConfig(columnState);
            }
        }
        fetchActivityData();
    }, [])
    return (
            <ActivityCheckTableControlled
                defaultConfig={config}
                showCOG={true}
                columnDefs={columnDefs(props.intl, headers)}
                rowData={data}
                domLayout="autoHeight"
                headers={headers}
            />
    )
}

export default ActivityCheck;