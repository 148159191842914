import { defineMessages } from 'react-intl';

export default defineMessages({
  activity: {
    defaultMessage: 'Activity',
    id: 'app.containers.ShiftScheduleDetailPage.activity',
  },
  lastUpdate: {
    defaultMessage: 'Last Sync With SmartShift',
    id: 'app.containers.ShiftScheduleDetailPage.lastUpdate',
  },
  smartShiftJobs: {
    defaultMessage: 'SmartShift Jobs',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftJobs',
  },
  smartShiftOnly: {
    defaultMessage: 'SmartShift only',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftOnly',
  },
  activityCustomer: {
    defaultMessage: 'Customer',
    id: 'app.containers.ShiftScheduleDetailPage.activityCustomer',
  },
  activityExistsInSmartShift: {
    defaultMessage: 'Exists in SmartShift',
    id: 'app.containers.ShiftScheduleDetailPage.activityExistsInSmartShift',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'app.containers.ShiftScheduleDetailPage.yes',
  },
  activityDepartment: {
    defaultMessage: 'Department',
    id: 'app.containers.ShiftScheduleDetailPage.activityDepartment',
  },
  activityDisabled: {
    defaultMessage: 'Activity path is disabled',
    id: 'app.containers.ShiftScheduleDetailPage.activityDisabled',
  },
  activityFacility: {
    defaultMessage: 'Facility',
    id: 'app.containers.ShiftScheduleDetailPage.activityFacility',
  },
  smartShiftFrom: {
    defaultMessage: 'SmartShift Job From',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftFrom',
  },
  activityKronosJob: {
    defaultMessage: 'SmartShift Job',
    id: 'app.containers.ShiftScheduleDetailPage.activityKronosJob',
  },
  activityName: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleDetailPage.activityName',
  },
  smartShiftJob: {
    defaultMessage: 'SmartShift Job',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftJob',
  },
  activityTableUnassign: {
    defaultMessage: 'Unassign',
    id: 'app.containers.ShiftScheduleDetailPage.activityTableUnassign',
  },
  activityPlan: {
    defaultMessage: 'Plan',
    id: 'app.containers.ShiftScheduleDetailPage.activityPlan',
  },
  smartShiftTo: {
    defaultMessage: 'SmartShift Job To',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftTo',
  },
  smartShiftJobTransferCosts: {
    defaultMessage: 'SmartShift Job Transfer Costs',
    id: 'app.containers.ShiftScheduleDetailPage.smartShiftJobTransferCosts',
  },
  activityUom: {
    defaultMessage: 'UOM',
    id: 'app.containers.ShiftScheduleDetailPage.activityUom',
  },
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.CreateShiftSchedulePage.back',
  },
  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleDetailPage.basicInfo',
  },
  confirmDirty: {
    defaultMessage: 'There are unsaved changes at this schedule. Do you want to leave the page without saving them?',
    id: 'app.containers.ShiftScheduleDetailPage.confirmDirty',
  },
  country: {
    defaultMessage: 'Country',
    id: 'app.containers.ShiftScheduleDetailPage.country',
  },
  coveragePriority: {
    defaultMessage: 'Coverage Priority',
    id: 'app.containers.ShiftScheduleDetailPage.coveragePriority',
  },
  allowLeftoverSpread: {
    defaultMessage: 'Allow Leftover Spread',
    id: 'app.containers.ShiftScheduleDetailPage.allowLeftoverSpread',
  },
  ignoreActivity: {
    defaultMessage: 'Ignore Activity',
    id: 'app.containers.ShiftScheduleDetailPage.ignoreActivity',
  },
  customers: {
    defaultMessage: 'Customers',
    id: 'app.containers.ShiftScheduleDetailPage.customers',
  },
  deactivatePlan: {
    defaultMessage: 'Deactivate',
    id: 'app.components.ShiftScheduleStaffDetailPage.deactivatePlan',
  },
  deleteParameterSetConfirmation: {
    defaultMessage: 'Are you sure? You are deleting schedule parameter set - {name}',
    id: 'app.containers.ShiftScheduleDetailPage.deleteConfirmation',
  },
  departmentDisabled: {
    defaultMessage: 'Department path is disabled',
    id: 'app.containers.ShiftScheduleDetailPage.departmentDisabled',
  },
  departmentTransferCosts: {
    defaultMessage: 'Department Transfer Costs',
    id: 'app.components.ShiftScheduleStaffDetailPage.departmentTransferCosts',
  },
  description: {
    defaultMessage: 'Description',
    id: 'app.containers.ShiftScheduleDetailPage.description',
  },
  detail: {
    defaultMessage: 'Detail',
    id: 'app.containers.ShiftScheduleDetailPage.Detail',
  },
  disableHint: {
    defaultMessage: "Type '-' to disable path",
    id: 'app.containers.ShiftScheduleDetailPage.disableHint',
  },
  display: {
    defaultMessage: 'Display',
    id: 'app.components.ShiftScheduleStaffDetailPage.display',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'app.containers.ShiftScheduleDetailToolbar.edit',
  },
  errorAssociatedPlans: {
    defaultMessage: "Facility can't be removed, because it has associated plans: {plans}",
    id: 'app.containers.ShiftScheduleDetailToolbar.errorAssociatedPlans',
  },
  errorLastFacility: {
    defaultMessage: "The last facility can't be removed",
    id: 'app.containers.ShiftScheduleDetailToolbar.errorLastFacility',
  },
  facilities: {
    defaultMessage: 'Facilities',
    id: 'app.containers.ShiftScheduleDetailPage.facilities',
  },
  facility: {
    defaultMessage: 'Facility',
    id: 'app.containers.ShiftScheduleDetailPage.facility',
  },
  facilityCode: {
    defaultMessage: 'MDM ID',
    id: 'app.containers.ShiftScheduleDetailPage.facilityCode',
  },
  facilityDisabled: {
    defaultMessage: 'Facility path is disabled',
    id: 'app.containers.ShiftScheduleDetailPage.facilityDisabled',
  },
  facilityFrom: {
    defaultMessage: 'Facility From',
    id: 'app.containers.ShiftScheduleDetailPage.facilityFrom',
  },
  facilityTo: {
    defaultMessage: 'Facility To',
    id: 'app.containers.ShiftScheduleDetailPage.facilityTo',
  },
  facilityTransferCosts: {
    defaultMessage: 'Facility Transfer Costs',
    id: 'app.components.ShiftScheduleStaffDetailPage.facilityTransferCosts',
  },
  from: {
    defaultMessage: 'From',
    id: 'app.containers.ShiftScheduleDetailPage.from',
  },
  openShiftGroups: {
    defaultMessage: 'Open Shift Groups',
    id: 'app.containers.ShiftScheduleDetailPage.groups',
  },
  header: {
    defaultMessage: 'Schedule - {name}',
    id: 'app.containers.ShiftScheduleDetailPage.header',
  },
  hideBlank: {
    defaultMessage: 'Hide Blank',
    id: 'app.containers.ShiftScheduleDetailPage.hideBlank',
  },
  interfaces: {
    defaultMessage: 'Interfaces',
    id: 'app.containers.ShiftScheduleDetailPage.interfaces',
  },
  kronosId: {
    defaultMessage: 'SmartShift ID',
    id: 'app.containers.ShiftScheduleDetailPage.kronosId',
  },
  kronosNotSet: {
    defaultMessage: 'Set SmartShift interface to allow staff synchronization',
    id: 'app.containers.ShiftScheduleDetailPage.kronosNotSet',
  },
  laborCategory: {
    defaultMessage: 'Labor Category',
    id: 'app.containers.ShiftScheduleDetailPage.laborCategory',
  },
  lastSyncResult: {
    defaultMessage: 'Last synchronization result:',
    id: 'app.containers.ShiftScheduleDetailPage.lastSyncResult',
  },
  mainFacility: {
    defaultMessage: 'Main Facility',
    id: 'app.containers.ShiftScheduleDetailPage.mainFacility',
  },
  mustBeSaved: {
    defaultMessage: 'Not saved',
    id: 'app.containers.ShiftScheduleDetailPage.mustBeSaved',
  },
  name: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleDetailPage.name',
  },
  parametersTableHeader: {
    defaultMessage: 'Parameters',
    id: 'app.containers.ShiftScheduleDetailPage.parametersTableHeader',
  },
  parametersValues: {
    defaultMessage: 'Parameters',
    id: 'app.containers.ShiftScheduleDetailPage.parametersValues',
  },
  plan: {
    defaultMessage: 'Plan',
    id: 'app.containers.ShiftScheduleDetailPage.plan',
  },
  planningArea: {
    defaultMessage: 'Planning area',
    id: 'app.containers.ShiftScheduleDetailPage.planningArea',
  },
  region: {
    defaultMessage: 'Region',
    id: 'app.containers.ShiftScheduleDetailPage.region',
  },
  required: {
    defaultMessage: 'Required',
    id: 'app.containers.ShiftScheduleDetailPage.Required',
  },
  runs: {
    defaultMessage: 'Runs',
    id: 'app.containers.ShiftScheduleDetailPage.runs',
  },
  setAsDefault: {
    defaultMessage: 'Set as Default',
    id: 'app.containers.ShiftScheduleDetailPage.setAsDefault',
  },
  sector: {
    defaultMessage: 'Sector',
    id: 'app.containers.ShiftScheduleDetailPage.sector',
  },
  setName: {
    defaultMessage: 'Parameter Set',
    id: 'app.containers.ShiftScheduleDetailPage.setName',
  },
  setNameDefault: {
    defaultMessage: 'New Parameter Set',
    id: 'app.containers.ShiftScheduleDetailPage.setNameDefault',
  },
  setNameRequired: {
    defaultMessage: 'Set Name is required',
    id: 'app.containers.ShiftScheduleRunsPage.setNameRequired',
  },
  showBlank: {
    defaultMessage: 'Show Blank',
    id: 'app.containers.ShiftScheduleDetailPage.showBlank',
  },
  staff: {
    defaultMessage: 'Staff',
    id: 'app.containers.ShiftScheduleDetailPage.staff',
  },
  status: {
    defaultMessage: 'Status',
    id: 'app.containers.ShiftScheduleDetailPage.status',
  },
  statusDisabled: {
    defaultMessage: 'Disabled',
    id: 'app.containers.ShiftScheduleDetailPage.statusDisabled',
  },
  statusEnabled: {
    defaultMessage: 'Enabled',
    id: 'app.containers.ShiftScheduleDetailPage.statusEnabled',
  },
  syncError_NOT_FOUND: {
    defaultMessage: 'No persons found for hyperfind query [{param1}]',
    id: 'app.containers.ShiftScheduleDetailPage.syncError_NOT_FOUND',
  },
  syncInProgress: {
    defaultMessage: 'Synchronization with SmartShift is in progress',
    id: 'app.containers.ShiftScheduleDetailPage.syncInProgress',
  },
  syncFailed: {
    defaultMessage: 'Synchronization with SmartShift failed',
    id: 'app.containers.ShiftScheduleDetailPage.syncFailed',
  },
  warning:{
    defaultMessage: 'Warnings',
    id: 'app.containers.ShiftScheduleDetailPage.warning',
  },
  noWarningAvailable:{
    defaultMessage: 'No warnings available to display.',
    id: 'app.containers.ShiftScheduleDetailPage.noWarningAvailable',
  },
  warningAvailable:{
    defaultMessage: 'There are warnings for this Schedule!',
    id: 'app.containers.ShiftScheduleDetailPage.warningAvailable',
  },
  syncKronos: {
    defaultMessage: 'Sync With SmartShift',
    id: 'app.containers.ShiftScheduleDetailPage.syncKronos',
  },
  timezone: {
    defaultMessage: 'Time Zone',
    id: 'app.containers.ShiftScheduleDetailPage.timezone',
  },
  to: {
    defaultMessage: 'To',
    id: 'app.containers.ShiftScheduleDetailPage.to',
  },
  total: {
    defaultMessage: 'Total',
    id: 'app.containers.ShiftScheduleDetailPage.total',
  },
  totalFrom: {
    defaultMessage: 'Total From',
    id: 'app.containers.ShiftScheduleDetailPage.totalFrom',
  },
  totalTo: {
    defaultMessage: 'Total To',
    id: 'app.containers.ShiftScheduleDetailPage.totalTo',
  },
  transferCosts: {
    defaultMessage: 'Transfer Costs',
    id: 'app.components.ShiftScheduleStaffDetailPage.transferCosts',
  },
  parameterSets: {
    defaultMessage: 'Parameter Sets',
    id: 'app.components.ShiftScheduleStaffDetailPage.parameterSets',
  },
  productivityTypeDirect: {
    defaultMessage: 'Direct',
    id: 'app.containers.ShiftScheduleDetailPage.productivityTypeDirect',
  },
  productivityTypeIndirectEffort: {
    defaultMessage: 'Indirect Effort',
    id: 'app.containers.ShiftScheduleDetailPage.productivityTypeIndirectEffort',
  },
  productivityTypeIndirectLocationUnproductive: {
    defaultMessage: 'Indirect Location Unproductive',
    id: 'app.containers.ShiftScheduleDetailPage.productivityTypeIndirectLocationUnproductive',
  },
  productivityTypeIndirectStaffUnproductive: {
    defaultMessage: 'Indirect Staff Unproductive',
    id: 'app.containers.ShiftScheduleDetailPage.productivityTypeIndirectStaffUnproductive',
  },
  transferFrom: {
    defaultMessage: 'Department From',
    id: 'app.containers.ShiftScheduleDetailPage.transferFrom',
  },
  transferTo: {
    defaultMessage: 'Department To',
    id: 'app.containers.ShiftScheduleDetailPage.transferTo',
  },
  type: {
    defaultMessage: 'Type',
    id: 'app.containers.ShiftScheduleDetailPage.type',
  },
  unproductive: {
    defaultMessage: 'Transfer Cost is Unproductive',
    id: 'app.containers.ShiftScheduleDetailPage.unproductive',
  },
  unsavedCount: {
    defaultMessage: 'There are {count} changes, please consider saving the schedule!',
    id: 'app.containers.ShiftScheduleDetailPage.unsavedCount',
  },
  upload: {
    defaultMessage: 'Upload',
    id: 'app.containers.ShiftScheduleDetailPage.upload',
  },
  uploadDisable: {
    defaultMessage: 'Plan/Staff details are not available',
    id: 'app.containers.ShiftScheduleDetailPage.uploadDisable',
  },
  validFrom: {
    defaultMessage: 'Valid from',
    id: 'app.containers.ShiftScheduleDetailPage.validFrom',
  },
  validTo: {
    defaultMessage: 'Valid to',
    id: 'app.containers.ShiftScheduleDetailPage.validTo',
  },
  values: {
    defaultMessage: 'Values',
    id: 'app.containers.ShiftScheduleDetailPage.values',
  },
  weekStartDay: {
    defaultMessage: 'Week Start Day',
    id: 'app.containers.ShiftScheduleDetailPage.weekStartDay',
  },
  workTime: {
    defaultMessage: 'FTE Work Time/Day',
    id: 'app.containers.ShiftScheduleDetailPage.workTime',
  },
  syncActivities: {
    defaultMessage: 'Check with SmartShift',
    id: 'app.containers.ShiftScheduleDetailPage.syncActivities',
  },
  activityWarning: {
    defaultMessage: 'Warning: Some activities do not exist in SmartShift',
    id: 'app.containers.ShiftScheduleDetailPage.activityWarning',
  },
  coveragePriorityLimits: {
    defaultMessage: 'Coverage Priority must be between 0 and 10',
    id: 'app.containers.ShiftScheduleDetailPage.coveragePriorityLimits',
  },
  comment: {
    defaultMessage: 'Comment',
    id: 'app.containers.ShiftScheduleDetailPage.comment',
  },
});
