import React, { useEffect, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import DateTimePickerCellEditor from 'components/DateTimePickerCellEditor';
import AgTable from 'components/Table';
import { dateComparator, dateTimeCellFormatter, parseDate } from 'utils/dateTime';
import { ROLES } from 'utils/security';
import { ApiMasterPlanToPlanDTO, ApiPlanDTO } from 'types/drep-backend.d';

import { makeSelectUser } from '../LoginPage/selectors';
import messages from './messages';
import DeleteCellRenderer from 'components/DeleteCellRenderer';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }
  .past-row {
    color: red;
  }
  .future-row {
    color: Orange;
  }
  .ag-full-width-row {
    overflow: visible;
  }
`;

const validFromToStyle = (params, admin, col) => {
  if (admin) {
    return !!params.colDef.editable;
  }
  if (!admin) {
    return params.data[col] < DateTime.now().minus({ days: 1 }) ? false : params.colDef.editable != false;
  }
};

const columnDefs = (intl: InjectedIntl, formik, editable: boolean, isAdmin: boolean, withAction: boolean) => {
  const handleFrom = params => {
    if (isAdmin && editable) {
      return !!params.colDef.editable;
    }
    if (!isAdmin && editable) {
      return params.data.validFrom < DateTime.now().minus({ days: 1 }) ? false : params.colDef.editable != false;
    }
    if (!editable) {
      return false;
    }
  };
  const handleTo = params => {
    if (isAdmin && editable) {
      return !!params.colDef.editable;
    }
    if (!isAdmin && editable) {
      return params.data.validTo < DateTime.now().minus({ days: 1 }) ? false : params.colDef.editable != false;
    }
    if (!editable) {
      return false;
    }
  };

  const PlanLink = (params) =>{
    const url = `/plan/${params?.data?.planId}`
      return (
        <a href={url} target="_blank">
        {params?.value}
      </a>
      )
  }
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.planListName),
      cellRendererFramework: PlanLink,
      sortable: true,
      resizable: true,
    },
    {
      colId: 'planningArea.name',
      field: 'planningArea.name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.planListPlanningArea),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'facility',
      field: 'facility',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      filter: 'setFilter',
      valueGetter: ({ data }) => {
        const units =
          data.planningArea && data.planningArea.facilities
            ? data.planningArea.facilities.map(fac => fac.code).join(', ')
            : [];
        return units;
      },
      headerName: intl.formatMessage(messages.planListFacility),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'managementUnit',
      field: 'managementUnit',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) => {
        const units =
          data.planningArea && data.planningArea.facilities
            ? data.planningArea.facilities
                .flatMap(fac => fac.managementUnits)
                .map(mu => mu.code)
                .join(', ')
            : [];
        return units;
      },
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListManagementUnit),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'customer',
      field: 'customer',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) =>
        data.planningArea && data.planningArea.customers
          ? data.planningArea.customers.map(c => `${c.code}: ${c.name}`).join(', ')
          : '',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListCustomer),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'validFrom',
      field: 'validFrom',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      onCellValueChanged: params => {
        formik.setFieldValue(`masterPlan.plans.${params.node.rowIndex}.validFrom`, params.newValue);
      },
      cellRendererFramework: DateTimePickerCellEditor,
      editable: editable && handleFrom,
      cellRendererParams: params => ({
        editable: validFromToStyle(params, isAdmin, 'validFrom'),
        minDate: isAdmin ? undefined : DateTime.now(),
      }),
      headerName: intl.formatMessage(messages.planListValidFrom),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'validTo',
      field: 'validTo',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      onCellValueChanged: params => {
        formik.setFieldValue(`masterPlan.plans.${params.node.rowIndex}.validTo`, params.newValue);
      },
      cellRendererFramework: DateTimePickerCellEditor,
      cellRendererParams: params => ({
        editable: validFromToStyle(params, isAdmin, 'validTo'),
        minDate: isAdmin ? undefined : DateTime.now(),
      }),
      editable: editable && handleTo,
      headerName: intl.formatMessage(messages.planListValidTo),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'audit.updated',
      field: 'audit.updated',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      headerName: intl.formatMessage(messages.planListModified),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'audit.updatedBy',
      field: 'audit.updatedBy',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) => `${data.audit?.updatedBy?.firstName || ''} ${data.audit?.updatedBy?.lastName || ''}`,
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListModifiedBy),
      sortable: true,
      resizable: true,
    },
  ];
  const actionColumn: ColDef = {
    cellRendererFramework: DeleteCellRenderer,
    cellRendererParams: {
      onDelete: data => {
        formik.setFieldValue('masterPlan.plans', formik.values.masterPlan.plans.filter(it => it.uuid !== data.uuid));
      },
      hide: row => row.uuid,
    },
    colId: 'removeButton',
    field: 'id',
    headerName: '',
    width: 50,
    pinned: true,
    suppressMenu: true,
  };
  return withAction ? [actionColumn, ...columns] : columns;
};

type Props = {
  formik: any;
  intl: InjectedIntl;
  data: (ApiMasterPlanToPlanDTO & { uuid?: string })[];
  plans: ApiPlanDTO[];
  fullPlans: ApiPlanDTO[];
  editable: boolean;
  user: any;
};

const PlansTable: React.FC<Props> = ({ intl, formik, data, plans, editable, user, fullPlans }) => {
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };
  const userData = user.toJS();
  const isAdmin = userData.roles.find(r => r.role === ROLES.ADMIN.name);

  const ROW_HEIGHT = 30;

  let withAction = false;
  const rowsData = data.map(row => {
    const plan = fullPlans?.find(p => p.id === row.planId);
    withAction = withAction || row.uuid !== undefined;
    return {
      ...row,
      validFrom: row.validFrom ? parseDate(row.validFrom) : row.validFrom,
      validTo: row.validTo ? parseDate(row.validTo) : row.validTo,
      ...plan,
    };
  });

  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl, formik, editable, isAdmin, withAction);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [gridApi, data, plans, withAction]);

  return (
    <Table
      masterDetail
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl, formik, editable, isAdmin, withAction)}
      height={80 + ROW_HEIGHT * Math.min(10, rowsData?.length || 0)}
      rowData={rowsData}
      rowHeight={ROW_HEIGHT}
      onGridReady={onGridReady}
      getRowClass={params => {
        const classes = [];
        if (params.data.validTo <= DateTime.now().minus({ days: 1 })) {
          classes.push('past-row');
        } else if (params.data.validTo <= DateTime.now().plus({ days: 40 })) {
          classes.push('future-row');
        }
        return classes.join(' ');
      }}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);
export default injectIntl(withConnect(PlansTable));
