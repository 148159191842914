import { defineMessages } from 'react-intl';

const scope = 'app.containers.PlanInterfaces';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Plan {name} - Interfaces',
  },

  paHeader: {
    id: `${scope}.paHeader`,
    defaultMessage: 'Planning Area {name} - Interfaces',
  },
  confirmDirty: {
    id: `${scope}.confirmDirty`,
    defaultMessage: 'There are unsaved changes at this plan. Do you want to leave the page without saving them?',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  kronos: {
    id: `${scope}.kronos`,
    defaultMessage: 'SmartShift',
  },
  kronosUploads: {
    id: `${scope}.kronosUploads`,
    defaultMessage: 'SmartShift Uploads',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'URL',
  },
  selectKronos: {
    id: `${scope}.selectKronos`,
    defaultMessage: 'Select SmartShift...',
  },
  planSaved: {
    id: `${scope}.planSaved`,
    defaultMessage: 'Plan saved',
  },
  paSaved: {
    id: `${scope}.paSaved`,
    defaultMessage: 'Planning Area saved',
  },
  generate: {
    id: `${scope}.generate`,
    defaultMessage: 'Generate',
  },
  setEndDate: {
    id: `${scope}.setEndDate`,
    defaultMessage: 'Set End Date',
  },
  setStartDate: {
    id: `${scope}.setStartDate`,
    defaultMessage: 'Set Start Date',
  },
  scheduleParamsValidationError: {
    id: `${scope}.scheduleParamsValidationError`,
    defaultMessage: 'Either all or none of schedule parameters must be set',
  },
  smartVolumeAssociation: {
    id: `${scope}.smartVolumeAssociation`,
    defaultMessage: 'Smart Volume',
  },
  addSmartProdAsociation: {
    id: `${scope}.addSmartVolumeAssociation`,
    defaultMessage: 'Add',
  },
  smartProdAssociationSubtitle: {
    id: `${scope}.smartProdAssociationSubtitle`,
    defaultMessage: 'SmartProd Association',
  },
  smartVolumeFacility: {
    id: `${scope}.smartVolumeFacilityField`,
    defaultMessage: 'Facility',
  },
  smartVolumeSmartProdInstance: {
    id: `${scope}.smartVolumeSmartProdInstance`,
    defaultMessage: 'SmartProd Instance',
  },
  smartVolumeWHID: {
    id: `${scope}.smartVolumeWHID`,
    defaultMessage: 'WH_ID',
  },
  smartVolumeTableCellInstance: {
    id: `${scope}.smartVolumeTableCellInstance`,
    defaultMessage: 'SmartProd Instance',
  },
  smartVolumeTableCellFacility: {
    id: `${scope}.smartVolumeTableCellFacility`,
    defaultMessage: 'Facility',
  },
  smartVolumeTableCellWHID: {
    id: `${scope}.smartVolumeTableCellWHID`,
    defaultMessage: 'WH_ID',
  },
  smartVolumeTableCellScheduleStartDate: {
    id: `${scope}.smartVolumeTableCellScheduleStartDate`,
    defaultMessage: 'Schedule Start Date',
  },
  smartVolumeTableCellScheduleTime: {
    id: `${scope}.smartVolumeTableCellScheduleTime`,
    defaultMessage: 'Schedule Time',
  },
  smartVolumeTableCellSchedulePeriodDays: {
    id: `${scope}.smartVolumeTableCellSchedulePeriodDays`,
    defaultMessage: 'Schedule Period (Days)',
  },
  smartVolumeTableCellEditScheduleButton: {
    id: `${scope}.smartVolumeTableCellEditScheduleButton`,
    defaultMessage: 'Schedule',
  },
  smartVolumeTableSourceDetail: {
    id: `${scope}.smartVolumeTableSourceDetail`,
    defaultMessage: 'Source Detail',
  },
  scheduleDialogSave: {
    id: `${scope}.scheduleDialogSave`,
    defaultMessage: 'Save',
  },
  scheduleDialogCancelLabel: {
    id: `${scope}.scheduleDialogCancelLabel`,
    defaultMessage: 'Cancel',
  },
  scheduleDialogHeaderLabel: {
    id: `${scope}.scheduleDialogHeaderLabel`,
    defaultMessage: 'SmartVolume Scheduling for {smartProdInstance}/{smartProdWarehouseId}',
  },
  scheduleDialogClearSchedule: {
    id: `${scope}.scheduleDialogClearSchedule`,
    defaultMessage: 'Clear Schedule',
  },
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'Overview',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End Date',
  },
  generated: {
    id: `${scope}.generated`,
    defaultMessage: 'Generated',
  },
  generatedBy: {
    id: `${scope}.generatedBy`,
    defaultMessage: 'Generated By',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  NOT_SENT: {
    id: `${scope}.NOT_SENT`,
    defaultMessage: 'Not Sent',
  },
  SUCCESSFUL: {
    id: `${scope}.SUCCESSFUL`,
    defaultMessage: 'Successful',
  },
  FAILED: {
    id: `${scope}.FAILED`,
    defaultMessage: 'Failed',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  sendToKronos: {
    id: `${scope}.sendToKronos`,
    defaultMessage: 'Send To SmartShift',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Send',
  },
  resend: {
    id: `${scope}.resend`,
    defaultMessage: 'Resend',
  },
  errorDetails: {
    id: `${scope}.errorDetails`,
    defaultMessage: 'Error Details',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  deleted: {
    id: `${scope}.deleted`,
    defaultMessage: 'Deleted',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  timeSpan: {
    id: `${scope}.timeSpan`,
    defaultMessage: 'Time Span',
  },
  HOUR: {
    id: `${scope}.HOUR`,
    defaultMessage: '1 H',
  },
  QUARTER_OF_HOUR: {
    id: `${scope}.QUARTER_OF_HOUR`,
    defaultMessage: '15 Min',
  },
  errorLog: {
    id: `${scope}.errorLog`,
    defaultMessage: 'Error Log',
  },
  headsOpt: {
    id: `${scope}.headsOpt`,
    defaultMessage: 'Heads (opt)',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  // SmartVolume Runs
  smartVolumeRunsSubtitle: {
    id: `${scope}.smartVolumeRunsSubtitle`,
    defaultMessage: 'SmartVolume Runs',
  },
  smartVolumeRunsRequested: {
    id: `${scope}.smartVolumeRunsRequested`,
    defaultMessage: 'Requested',
  },
  smartVolumeRunsSmartProd: {
    id: `${scope}.smartVolumeRunsSmartProd`,
    defaultMessage: 'SmartProd',
  },
  smartVolumeRunsType: {
    id: `${scope}.smartVolumeRunsType`,
    defaultMessage: 'Type',
  },
  smartVolumeRunsFinished: {
    id: `${scope}.smartVolumeRunsFinished`,
    defaultMessage: 'Finished',
  },
  smartVolumeRunsRequestedBy: {
    id: `${scope}.smartVolumeRunsRequestedBy`,
    defaultMessage: 'Requested by',
  },
  smartVolumeRunsStatus: {
    id: `${scope}.smartVolumeRunsStatus`,
    defaultMessage: 'Status',
  },
  smartVolumeRunsForecastPeriod: {
    id: `${scope}.smartVolumeRunsForecastPeriod`,
    defaultMessage: 'Forecast Period',
  },
  smartVolumeRunsStartDate: {
    id: `${scope}.smartVolumeRunsStartDate`,
    defaultMessage: 'Start Date',
  },
  smartVolumeRunsEndDate: {
    id: `${scope}.smartVolumeRunsEndDate`,
    defaultMessage: 'End Date',
  },
  smartVolumeRunsLearningDate: {
    id: `${scope}.smartVolumeRunsLearningDate`,
    defaultMessage: 'SmartVolume Learning Date',
  },
  smartVolumeRunsDownloadFile: {
    id: `${scope}.smartVolumeRunsDownloadFile`,
    defaultMessage: 'SmartVolume Response',
  },
  smartVolumeRunsMessages: {
    id: `${scope}.smartVolumeRunsMessages`,
    defaultMessage: 'SmartVolume Messages',
  },
  downloadResponseTooltip: {
    id: `${scope}.downloadResponseTooltip`,
    defaultMessage: 'Download Response',
  },
});
