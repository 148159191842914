/**
 *
 * ResultMatrixColumnOptions
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import IconButton from 'components/IconButton';
import { CheckButton } from 'components/ToggleButton';
import withSecurity, { PERMISSIONS } from 'utils/security';


import messages from './messages';

const ExportButton = IconButton;

const Wrapper = styled.div`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  > * {
    margin: 1px 2px;
    white-space: nowrap;
  }
  ${ExportButton} {
    margin-left: auto;
    font-size: 14px;
    font-weight: normal;
  }

  h6 {
    margin: 5px;
  }
`;

const Space = styled.div`
  margin-left: 40px;
`;

const ButtonGroup = styled.div`
background-color: rgba(0, 0, 0, 0.05);
padding: 5px;
display: flex;
flex-direction: column;
`

const ButtonSection = styled.div`
display: flex;
gap: 5px;
`

/* eslint-disable react/prefer-stateless-function */
function ResultMatrixColumnOptions(props) {
  const {
    onChange,
    settings,
    formikBag,
    hasPerm,
    disableHeadsOpt,
    disableShowBudget = false,
    disableShowForecast = false,
  } = props;
  const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  const islabourAvailabilityTypeDep = props && props.plan && props.plan.planningParameters && props.plan.planningParameters.labourAvailabilityType == 'DEPARTMENT';
  const hasActivityAdjustments = props && props.plan && props.plan.planningParameters && props.plan.planningParameters.hasActivityAdjustments;
  const calculateType = props && props.plan && props.plan.planningParameters && props.plan.planningParameters.calculateType;

  return (
    <Wrapper>
      <ButtonGroup>
        <h6>Values</h6>
        <ButtonSection>
          <CheckButton name="showVolume" value={settings.showVolume} onChange={onChange}>
            <FormattedMessage {...messages.volume} />
          </CheckButton>
          <CheckButton name="showEffort" value={settings.showEffort} onChange={onChange}>
            <FormattedMessage {...messages.effort} />
          </CheckButton>
          {calculateType === 'FTE' &&
            <CheckButton name="showFte" value={settings.showFte} onChange={onChange}>
              <FormattedMessage {...messages.ftes} />
            </CheckButton>
          }
          <CheckButton name="showProductivityRate" value={settings.showProductivityRate} onChange={onChange}>
            <FormattedMessage {...messages.productivityRateNew} />
          </CheckButton>
          <CheckButton name="showVariance" value={settings.showVariance} onChange={onChange}>
            <FormattedMessage {...messages.variance} />
          </CheckButton>
          {calculateType === 'Staff' &&
            <CheckButton name="showNumberOfStaff" value={settings.showNumberOfStaff} onChange={onChange}>
              <FormattedMessage {...messages.numberOfStaff} />
            </CheckButton>
          }
          {
            hasActivityAdjustments &&
            <CheckButton name="showEffortAdjustment" value={settings.showEffortAdjustment} onChange={onChange}>
              <FormattedMessage {...messages.effortAdjustment} />
            </CheckButton>
          }
        </ButtonSection>
      </ButtonGroup>
      <Space />
      {islabourAvailabilityTypeDep &&
      <ButtonGroup>
        <h6>Employees availability</h6>
        <ButtonSection>
          {islabourAvailabilityTypeDep && calculateType === 'FTE' &&
            <CheckButton name="showAvailableFTE" value={settings.showAvailableFTE} onChange={onChange}>
              <FormattedMessage {...messages.ftesAvailable} />
            </CheckButton>
          }
          {islabourAvailabilityTypeDep &&
            <CheckButton name="showAvailableHours" value={settings.showAvailableHours} onChange={onChange}>
              <FormattedMessage {...messages.effortAvailable} />
            </CheckButton>
          }
          {islabourAvailabilityTypeDep && calculateType === 'Staff' &&
            <CheckButton name="showAvailableStaff" value={settings.showAvailableStaff} onChange={onChange}>
              <FormattedMessage {...messages.staffAvailable} />
            </CheckButton>
          }
          {islabourAvailabilityTypeDep &&
            <CheckButton name="showlabourDiscrepancy" value={settings.showlabourDiscrepancy} onChange={onChange}>
              <FormattedMessage {...messages.labDiscrepancy} />
            </CheckButton>
          }
        </ButtonSection>
      </ButtonGroup>
      }
      <Space />
      <ButtonGroup>
        <h6>Productivity type</h6>
        <ButtonSection>
          <CheckButton name="showDirect" value={settings.showDirect} onChange={onChange}>
            <FormattedMessage {...messages.direct} />
          </CheckButton>
          <CheckButton name="showIndirect" value={settings.showIndirect} onChange={onChange}>
            <FormattedMessage {...messages.indirect} />
          </CheckButton>
        </ButtonSection>
      </ButtonGroup>
      <Space />
      <ButtonGroup>
        <h6>Value source</h6>
        <ButtonSection>
          <CheckButton name="showBudget" value={settings.showBudget} disabled={disableShowBudget} onChange={onChange}>
            <FormattedMessage {...messages.budget} />
          </CheckButton>
          <CheckButton name="showPlanned" value={settings.showPlanned} onChange={onChange}>
            <FormattedMessage {...messages.planned} />
          </CheckButton>
          <CheckButton name="showActuals" value={settings.showActuals} onChange={onChange}>
            <FormattedMessage {...messages.actuals} />
          </CheckButton>
          {isForecastEditor && !isDayOfWeekTransformationActivity(formikBag) && (
            <CheckButton
              name="showForecast"
              value={settings.showForecast}
              disabled={disableShowForecast}
              onChange={onChange}
            >
              <FormattedMessage {...messages.forecast} />
            </CheckButton>
          )}
        </ButtonSection>
      </ButtonGroup>
      {false && (
        <CheckButton name="showPlannedOriginal" value={settings.showPlannedOriginal} onChange={onChange}>
          <FormattedMessage {...messages.original} />
        </CheckButton>
      )}
    </Wrapper>
  );
}

export default withSecurity()(ResultMatrixColumnOptions);
