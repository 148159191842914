/**
 *
 * CdmEditableList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { makeSelectToken } from '../../containers/LoginPage/selectors';
import AutocompleteSelect from '../AutocompleteSelect';
import { EditableListDeleteIconButton, EditableListWrapper } from '../DetailPageShared';
import { AddButton } from '../IconButton';
import SimpleCodeNameCdmItem from './SimpleCodeNameCdmItem';

export const DropDownItemWrapper = styled.div`
  position: relative;
  margin: 2px;
  padding: 2px;
  padding-right: 20px;

  .react-select__menu {
    z-index: 40;
  }
`;

class CdmEditableList extends React.PureComponent {
  render() {
    const { isEdit, field } = this.props;
    const hasAddedField = form => list(form).filter(it => !it.id).length > 0;
    const list = form => get(form.values, field) || [];

    const addButton = (form, push) =>
      isEdit ? (
        <AddButton
          key={`${field}-add-button`}
          disabled={form.isSubmitting || hasAddedField(form)}
          onClick={() => {
            push(this.createNewItem());
          }}
        />
      ) : null;

    const itemsList = (form, remove) =>
      list(form).map((item, index) => {
        if (this.props.itemsFilter && !this.props.itemsFilter(item)) {
          return null;
        }
        return isEdit
          ? this.renderSingleItemEdit(item, remove, index, form, field)
          : this.renderDelegateComponent(item, `${field}.${index}`);
      });

    return (
      <FieldArray
        name={field}
        render={({ remove, push, form }) => (
          <EditableListWrapper key="EditableListWrapper">
            {[itemsList(form, remove), addButton(form, push)]}
          </EditableListWrapper>
        )}
      />
    );
  }

  renderSingleItemEdit(item, remove, index, form, field) {
    const isNewRow = item.id === undefined;

    const { validationOnItemRemoved, onItemRemoved, entity } = this.props;

    const doRemove = () => {
      if (!validationOnItemRemoved || validationOnItemRemoved(item, index)) {
        remove(index);

        if (onItemRemoved) {
          const updatedValue = [...form.values[field]];
          // Workaround: formik operation are async, value from `form.values` will contains the removed item.
          updatedValue.splice(index, 1);
          onItemRemoved(updatedValue);
        }
      }
    };

    if (isNewRow && !this.props.delegateNewItemRender) {
      return (
        <DropDownItemWrapper key="new-item-dropdown">
          <AutocompleteSelect
            key="select"
            entity={entity || field}
            getOptionLabel={option => `${option.code} : ${option.name}`}
            setValue={value => {
              form.setFieldValue(`${field}.${index}`, { ...value, direct: true });
              if (this.props.onItemAdded) {
                // Workaround: formik operation are async, value from `form.values` will not contains the added item.
                const updatedValue = form.values[field].slice(0, Math.max(form.values[field].length - 1, 0));
                updatedValue.push(value);
                this.props.onItemAdded(updatedValue);
              }
            }}
            exclude={this.props.excludeItems || form.values[field]}
            token={this.props.token}
            additionalQueryParams={this.props.additionalQueryParams || ''}
          />
          <EditableListDeleteIconButton key="delete-button" onClick={doRemove} />
        </DropDownItemWrapper>
      );
    }

    return this.renderDelegateComponent(
      item,
      `${field}.${index}`,
      <EditableListDeleteIconButton onClick={doRemove} />,
      form,
      isNewRow,
    );
  }

  createNewItem() {
    return { name: '', code: '' };
  }

  /**
   * Renders single item using provided [this.props.editComponent] component
   */
  renderDelegateComponent(item, fieldPath, additionalItem, form, isNewRow) {
    const ItemComponent = this.props.editComponent ? this.props.editComponent : SimpleCodeNameCdmItem;

    return (
      <ItemComponent
        key={item.id ? item.id : `new-item-${fieldPath}`}
        item={item}
        editable={this.props.isEdit}
        token={this.props.token}
        onChange={(value, field) => {
          form.setFieldValue(`${fieldPath}.${field}`, value);
        }}
        isNewRow={isNewRow}
      >
        {additionalItem}
      </ItemComponent>
    );
  }
}

CdmEditableList.propTypes = {
  field: PropTypes.string,
  entity: PropTypes.string,
  isEdit: PropTypes.bool,
  intl: PropTypes.object,
  token: PropTypes.string,

  excludeItems: PropTypes.array,

  /**
   * Provided function must return a boolean, can be used to hide some items
   */
  itemsFilter: PropTypes.func,

  /**
   * Callback which is invoked when a new item is added
   */
  onItemAdded: PropTypes.func,

  /**
   * Callback which is invoked when an item is removed
   */
  onItemRemoved: PropTypes.func,

  /**
   * Validation which is invoked before an item is removed. An item is not removed if it returns false value.
   */
  validationOnItemRemoved: PropTypes.func,

  /**
   * If true, rendering of newly added rows is delegated to [editComponent], otherwise it is rendered as a single dropdown
   */
  delegateNewItemRender: PropTypes.bool,

  /**
   * Additional query params which will be passed to the back-end while fetching the data
   */
  additionalQueryParams: PropTypes.string,

  editComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
});

export const EditableList = injectIntl(connect(mapStateToProps)(CdmEditableList));
