import { Component } from 'react';
import * as React from 'react';
import { Field, Form, Formik, getIn } from 'formik';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import Select from 'react-select';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import * as Yup from 'yup';

import BasePage from 'components/BasePage';
import {
  DetailPageSectionsWrapper,
  DetailToolbarButtonsGroup,
  DetailToolbarButtonsWrap,
} from 'components/DetailPageShared';
import FormikNumberInput from 'components/FormikNumberInput';
import { FormikText, InlineLabel } from 'components/FormikTextInput';
import IconButton, { AddButton, SaveButton } from 'components/IconButton';
import { FormikCheckbox } from 'components/Menu/ItemSelectable';
import NamedSection from 'components/NamedSection';
import ToggleSection from 'components/ToggleSection';
import { PATHS } from 'containers/App/constants';
import AddOpenShiftGroupModal from 'containers/ShiftScheduleActivityDetailPage/AddOpenShiftGroupModal';
import { FormikJobDetailData } from 'containers/ShiftScheduleActivityDetailPage/types';
import { convertArrayToMap } from 'containers/ShiftScheduleDetailPage/utils';
import { formatDateToApiFormat } from 'utils/api';
import { formatDate } from 'utils/dateTime';
import injectSaga from 'utils/injectSaga';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { findDiffs } from 'utils/utilsTs';
import { ApiScheduleActivityDTO, ApiSmartShiftJobScheduleDTO, ApiUpdateSchedule } from 'types/drep-backend.d';

import { loadShiftSchedule, saveShiftSchedule } from '../ShiftScheduleDetailPage/actions';
import saga from '../ShiftScheduleDetailPage/saga';
import { ApiSchedule } from '../ShiftScheduleDetailPage/types';
import messages from './messages';
import NumberRestrictionsTable from './numberRestrictionsTable';
import { selectShiftSchedule } from './selectors';
import TimeRestrictionsTable from './timeRestrictionsTable';

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 160px 1fr;
  margin: 0 0 12px;
  column-gap: 10px;
`;

const FullWidthToggleSection = styled(ToggleSection)`
  min-width: 1170px;
  flex: 1;
`;

const BasicInfoSectionWrapper = styled.div`
  min-width: 1170px;
  width: 100%;
  flex: auto;
  display: flex;
`;

const BasicInfoSection = styled(NamedSection)`
  flex: 2.5;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const PlanActivitiesSection = styled(NamedSection)`
  min-width: 300px;
  flex: 1;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const BasicInfoContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 48% 50%;
  column-gap: 10px;
`;

const OpenShiftGroupSelect = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  align-items: center;
`;

const ActivitiesTable = styled.table`
  font-size: 13px;
  width: 100%;

  td,
  th {
    padding: 5px 6px 5px 2px;
    text-align: left;
  }

  td:nth-child(0),
  th:nth-child(0),
  td:nth-child(1),
  th:nth-child(1) {
    width: 28%;
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 22%;
  }
`;

export const validationSchema = Yup.object().shape({
  numberRestrictions: Yup.array().of(Yup.object().shape({ limit: Yup.string().required('Limit is mandatory') })),
  timeRestrictions: Yup.array().of(Yup.object().shape({ limit: Yup.string().required('Limit is mandatory') })),
});

interface ShiftScheduleActivityDetailPageProps {
  shiftSchedule: ApiSchedule;
  match: any;
  history: any;
  intl: any;
  fixed: boolean;
  loadShiftSchedule(id: number): () => void;
  save(activity: any): () => void;
}

class ShiftScheduleActivityDetailPage extends Component<ShiftScheduleActivityDetailPageProps, {}> {
  public siblingSmartShiftJobSchedules: ApiSmartShiftJobScheduleDTO[];

  state = {
    addOpenShiftGroupModalOpen: false,
  };

  public componentDidMount() {
    this.props.loadShiftSchedule(this.props.match.params.scheduleId);
    this.saveSiblingSmartShiftJobSchedules();
  }

  public render() {
    if (!this.props.shiftSchedule?.id) {
      return <BasePage labelMessage={messages.smartShiftDetailHeader}>Loading SmartShiftJob Detail...</BasePage>;
    }

    const smartShiftJobSchedule: ApiSmartShiftJobScheduleDTO = this.props.shiftSchedule.smartShiftJobSchedules.find(
      js => js.id === parseInt(this.props.match.params.id, 0),
    );

    const shiftScheduleActivities: ApiScheduleActivityDTO[] = this.props.shiftSchedule.activities.filter(a =>
      smartShiftJobSchedule?.scheduleActivityIds.includes(a.id),
    );

    const doSubmit = (values, actions) => {
      const { job } = values;
      if (!smartShiftJobSchedule || !job) {
        actions.setSubmitting(false);
        return;
      }
      const timeRestrictionDiffs = findDiffs(smartShiftJobSchedule.timeRestrictions, job.timeRestrictions);
      const numberRestrictionDiffs = findDiffs(smartShiftJobSchedule.numberRestrictions, job.numberRestrictions);
      const openShiftGroupVal = isEqual(smartShiftJobSchedule.openShiftGroup, job.openShiftGroup)
        ? null
        : values.job.openShiftGroup;

      const toSave: Partial<ApiUpdateSchedule> = {
        smartShiftJobSchedules: [
          {
            id: job.id,
            smartShiftJobId: job.smartShiftJobId,
            allowLeftoverSpread: job.allowLeftoverSpread,
            scheduleActivityIds: job.scheduleActivityIds,
            coveragePriority: job.coveragePriority,
            deletedNumberRestrictions: numberRestrictionDiffs.deletedIds,
            deletedTimeRestrictions: timeRestrictionDiffs.deletedIds,
            numberRestrictions: numberRestrictionDiffs.added.concat(numberRestrictionDiffs.modified).map(r => ({
              ...r,
              limit: r.limit,
              period: r.period,
              restrictionId: r.id,
              dateFrom: formatDateToApiFormat(r.dateFrom),
              dateTo: formatDateToApiFormat(r.dateTo),
            })),
            timeRestrictions: timeRestrictionDiffs.added.concat(timeRestrictionDiffs.modified).map(r => ({
              ...r,
              limit: r.limit,
              period: r.period,
              restrictionId: r.id,
              dateFrom: formatDateToApiFormat(r.dateFrom),
              dateTo: formatDateToApiFormat(r.dateTo),
            })),
            openShiftGroup: job.openShiftGroup ? job.openShiftGroup : openShiftGroupVal,
            minimumJobSegmentLength: job.minimumJobSegmentLength,
          },
        ],
        id: this.props.shiftSchedule.id,
      };
      this.props.save({
        schedule: toSave,
        scheduleName: this.props.shiftSchedule.name,
      });
      actions.setSubmitting(false);
    };

    return (
      <BasePage labelMessage={messages.smartShiftDetailHeader}>
        <Formik
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          initialValues={{
            activities: shiftScheduleActivities,
            job: smartShiftJobSchedule,
          }}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  private renderForm = (formik: FormikJobDetailData) => (
    <Form>
      <Prompt when={formik.dirty} message={this.props.intl.formatMessage(messages.confirmDirty)} />

      {this.renderToolbar(formik)}

      <DetailPageSectionsWrapper>
        {this.renderBasicInfoSection(formik)}

        <FullWidthToggleSection message={messages.smartShiftJobTimeRestrictions} expanded>
          <TimeRestrictionsTable formik={formik} />
        </FullWidthToggleSection>
        <FullWidthToggleSection message={messages.smartShiftJobNumberRestrictions} expanded>
          <NumberRestrictionsTable formik={formik} />
        </FullWidthToggleSection>
      </DetailPageSectionsWrapper>
    </Form>
  );

  private saveSiblingSmartShiftJobSchedules() {
    if (
      this.props.shiftSchedule !== undefined &&
      this.props.shiftSchedule.activities !== undefined &&
      (this.siblingSmartShiftJobSchedules === undefined || this.siblingSmartShiftJobSchedules.length === 0)
    ) {
      this.siblingSmartShiftJobSchedules = this.props.shiftSchedule.smartShiftJobSchedules.filter(
        ssjs => ssjs.customer && ssjs.department && ssjs.facility && ssjs.id,
      );
    }
  }

  private renderToolbar = (formik: FormikJobDetailData) => {
    const { fixed } = this.props;
    this.saveSiblingSmartShiftJobSchedules();
    const currentIndex = this.siblingSmartShiftJobSchedules.findIndex(
      candidate => candidate.id === formik.values?.job?.id,
    );
    const nextIndex = currentIndex === this.siblingSmartShiftJobSchedules.length - 1 ? 0 : currentIndex + 1;
    const prevIndex = currentIndex === 0 ? this.siblingSmartShiftJobSchedules.length - 1 : currentIndex - 1;
    const goTo = path => () => this.props.history.push(path.replace(':scheduleId', this.props.shiftSchedule.id));

    const goToPrev = () =>
      this.siblingSmartShiftJobSchedules[prevIndex]?.id &&
      goTo(PATHS.smartShiftJobScheduleDetailId.replace(':id', this.siblingSmartShiftJobSchedules[prevIndex]?.id));
    const goToNext = () =>
      this.siblingSmartShiftJobSchedules[nextIndex]?.id &&
      goTo(PATHS.smartShiftJobScheduleDetailId.replace(':id', this.siblingSmartShiftJobSchedules[nextIndex]?.id));
    const goBack = () => goTo(PATHS.shiftScheduleDetailId.replace(':id', this.props.shiftSchedule.id));

    return (
      <DetailToolbarButtonsWrap fixed={fixed}>
        <IconButton id="back" onClick={goBack()} icon="arrow-circle-left" tooltip={messages.back} />
        <DetailToolbarButtonsGroup>
          <SaveButton onClick={formik.submitForm} disabled={!formik.dirty} />
          <IconButton onClick={goToPrev()} label={messages.previous} disabled={!goToPrev()} />
          <IconButton onClick={goToNext()} label={messages.next} disabled={!goToNext()} />
        </DetailToolbarButtonsGroup>
      </DetailToolbarButtonsWrap>
    );
  };

  private handleAddOpenShiftGroupOpen = () => {
    this.setState({
      addOpenShiftGroupModalOpen: true,
    });
  };

  private handleAddOpenShiftGroupClose = () => {
    this.setState({
      addOpenShiftGroupModalOpen: false,
    });
  };

  private getExistingOpenShiftGroups = (formik: FormikJobDetailData) => {
    const existingGroupNames = new Set();
    if (formik.values.job.openShiftGroup) {
      existingGroupNames.add(formik.values.job.openShiftGroup);
    }
    this.props.shiftSchedule?.smartShiftJobSchedules?.forEach(jobSchedule => {
      if (jobSchedule?.openShiftGroup) {
        existingGroupNames.add(jobSchedule?.openShiftGroup);
      }
    });
    return [...existingGroupNames].map(name => ({ value: name, label: name }));
  };

  private renderBasicInfoSection = (formik: FormikJobDetailData) => {
    if (!formik.values.job) {
      return null;
    }
    const plansById = convertArrayToMap(this.props.shiftSchedule?.plans || [], plan => plan.planId);
    const { department, facility, customer } = formik.values.job || {};
    const existingGroups = this.getExistingOpenShiftGroups(formik);
    const handleGroupChange = (newValue: any) => {
      formik.setFieldValue('job.openShiftGroup', newValue?.value);
    };
    const handleGroupAdd = (newValue: string) => {
      formik.setFieldValue('job.openShiftGroup', newValue);
    };
    return (
      <BasicInfoSectionWrapper>
        <BasicInfoSection message={messages.basicInfo}>
          <BasicInfoContent>
            <div>
              <GridRow>
                <InlineLabel {...messages.name} />
                <FormikText>{formik.values.job.activity}</FormikText>
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.facility} />
                <FormikText>{`${facility.code} - ${facility.name}`}</FormikText>
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.department} />
                <FormikText>{department}</FormikText>
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.customer} />
                <FormikText>{customer?.name}</FormikText>
              </GridRow>
            </div>
            <div>
              <GridRow>
                <InlineLabel {...messages.coveragePriority} />
                <FormikNumberInput
                  name="job.coveragePriority"
                  value={getIn(formik.values, 'job.coveragePriority')}
                  editable
                  required
                  min={0}
                  max={10}
                  componentType = "coveragePriority"
                />
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.allowLeftoverSpread} />
                <div style={{ width: '34px' }}>
                  <Field component={FormikCheckbox} name="job.allowLeftoverSpread" />
                </div>
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.minimumJobSegmentLength} />
                <div>
                  <FormikNumberInput
                    name="job.minimumJobSegmentLength"
                    value={getIn(formik.values, 'job.minimumJobSegmentLength')}
                    editable
                    required={false}
                    min={0}
                    style={{ marginRight: 10, width: 145 }}
                  />
                  <FormattedMessage {...messages.minutes} />
                </div>
              </GridRow>
              <GridRow>
                <InlineLabel {...messages.openShiftGroup} />
                <OpenShiftGroupSelect>
                  <span>
                    <Select
                      isClearable
                      placeholder={this.props.intl.formatMessage(messages.select)}
                      value={{
                        value: getIn(formik.values, 'job.openShiftGroup'),
                        label: getIn(formik.values, 'job.openShiftGroup'),
                      }}
                      options={existingGroups}
                      onChange={handleGroupChange}
                      style={{ width: 145 }}
                    />
                  </span>
                  <span>
                    <AddButton key="add-button" onClick={this.handleAddOpenShiftGroupOpen} />
                  </span>
                </OpenShiftGroupSelect>
                <AddOpenShiftGroupModal
                  open={this.state.addOpenShiftGroupModalOpen}
                  onClose={this.handleAddOpenShiftGroupClose}
                  onAddOpenShiftGroup={handleGroupAdd}
                />
              </GridRow>
            </div>
          </BasicInfoContent>
        </BasicInfoSection>
        <PlanActivitiesSection message={messages.planActivitiesInfo}>
          <ActivitiesTable>
            <thead>
              <th>&nbsp;</th>
              <th>{this.props.intl.formatMessage(messages.plan)}</th>
              <th>{this.props.intl.formatMessage(messages.validFrom)}</th>
              <th>{this.props.intl.formatMessage(messages.validTo)}</th>
            </thead>
            <tbody>
              {formik.values.activities.map(activity => {
                const activityPlan = plansById.get(activity.planId);
                const planName = activityPlan?.plan?.name || '';
                const validFrom = formatDate(activityPlan?.validFrom) || '';
                const validTo = formatDate(activityPlan?.validTo) || '';
                return (
                  <tr key={activity.id}>
                    <td>{activity.activity.name}</td>
                    <td>{planName}</td>
                    <td>{validFrom}</td>
                    <td>{validTo}</td>
                  </tr>
                );
              })}
            </tbody>
          </ActivitiesTable>
        </PlanActivitiesSection>
      </BasicInfoSectionWrapper>
    );
  };
}

const mapStateToProps = createStructuredSelector({
  shiftSchedule: selectShiftSchedule,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadShiftSchedule,
      save: saveShiftSchedule,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'shiftScheduleActivityDetailPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  withScrollWatch(100),
  // @ts-ignore
)(ShiftScheduleActivityDetailPage);
