/*
 * DeleteCellRenderer Messages
 *
 * This contains all the text for the DeleteCellRenderer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DeleteCellRenderer.header',
    defaultMessage: 'This is the DeleteCellRenderer component !',
  },
  delete: {
    id: 'app.components.DeleteCellRenderer.delete',
    defaultMessage: 'Delete row',
  },
});
