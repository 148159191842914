// @flow

/**
 *
 * ResultToolBar
 *
 */

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import IconButton, { SaveButton } from 'components/IconButton';
import ViewModePopover from 'components/ViewModePopover';
import { withScrollWatch } from 'utils/scroll';

import { PATHS } from '../../containers/App/constants';
import withSecurity, { PERMISSIONS } from '../../utils/security';
import { Title } from '../PlanningAreaDetailToolbar';
import UserViewToggleButton from '../ToggleButton/UserViewToggleButton';
import messages from './messages';

export const ButtonWrap = styled.div`
  margin: 10px 10px 5px;
  padding: 10px;
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  display: flex;
  justify-content: space-between;
  ${SaveButton} {
    display: inline-flex;
    :hover {
      box-shadow: none;
    }
  }
  ${props =>
    props.fixed &&
    css`
      top: 0px;
      z-index: ${props.theme.zIndex.overlayToolbar};
      left: 70px;
      margin: 0px;
      padding: 10px;
    `};
  :first-child {
    position: ${props => (props.fixed ? 'fixed' : 'absolute')};
    width: ${props => (props.fixed ? 'calc(100% - 75px)' : 'calc(100% - 30px)')};
  }
  :nth-child(2) {
    margin-top: 80px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  > * {
    margin-left: 10px;
  }
`;

type PropsTypes = {
  history: Object,
  fixed: boolean,
  id: number,
  editable: boolean,
  deleted: boolean,
  ppId: number,
  planEdit: boolean,
  dirty: boolean,
  title: string,
  saveData: Function,
  editModeChange: Function,
  openUploadModal: Function,
  makeDirtyHandler: Function,
  hasPerm: Function,
  editing: boolean,
};

const ResultToolBar = React.memo(function ResultToolBar(props: PropsTypes) {
  const {
    makeDirtyHandler,
    dirty = false,
    title,
    fixed,
    id,
    planEdit = false,
    saveData,
    editable = false,
    deleted = false,
    ppId,
  } = props;

  return (
    <ButtonWrap fixed={fixed}>
      {props.hasPerm(PERMISSIONS.VIEW_PLAN_DETAILS) && (
        <IconButton
          icon="arrow-circle-left"
          id="back"
          tooltip={{ ...messages.back }}
          onClick={() => props.history.push(`${PATHS.planDetailId.replace(':id', id)}?isEdit=${String(planEdit)}`)}
        />
      )}
      {fixed && <Title>{title}</Title>}

      <ButtonsGroup>
        <SaveButton disabled={!dirty} onClick={saveData} />
        {props.hasPerm(PERMISSIONS.EDIT_RESULT) &&
          (props.hasPerm(PERMISSIONS.EDIT_RESULT_IGNORE_RESTRICTIONS) || editable) &&
          (props.editing ? (
            <IconButton
              icon="eye"
              id="edit"
              tooltip={{ ...messages.display }}
              onClick={makeDirtyHandler(() => props.editModeChange(false))}
            />
          ) : (
            <IconButton
              icon="edit"
              id="edit"
              tooltip={{ ...messages.edit }}
              onClick={() => props.editModeChange(true)}
              disabled={deleted === true}
            />
          ))}
        {props.hasPerm(PERMISSIONS.EDIT_RESULT) && (
          <IconButton icon="upload" id="upload" tooltip={messages.upload} onClick={props.openUploadModal} />
        )}
        {props.hasPerm(PERMISSIONS.VIEW_RESULT) && (
          <IconButton
            id="graphs"
            icon="chart-line"
            tooltip={{ ...messages.graphs }}
            onClick={() =>
              props.history.push({
                pathname: `${PATHS.planGraphs.replace(':id', id)}`,
                search: props.history.location.search,
              })
            }
          />
        )}
        {props.hasPerm(PERMISSIONS.HISTORY_PLAN) && (
          <IconButton
            icon="history"
            id="history"
            tooltip={{ ...messages.history }}
            onClick={makeDirtyHandler(() => props.history.push(PATHS.historyPlan.replace(':id', id)))}
          />
        )}
        {props.hasPerm(PERMISSIONS.HISTORY_PLAN) && (
          <IconButton
            icon="calculator"
            id="calculationStats"
            tooltip={{ ...messages.calculationStats }}
            onClick={() =>
              props.history.push({
                pathname: `${PATHS.planComputationStatistics.replace(':id', id).replace(':paramsid', ppId)}`,
                search: props.history.location.search,
              })
            }
          />
        )}
        <ViewModePopover />
        <UserViewToggleButton />
      </ButtonsGroup>
    </ButtonWrap>
  );
});

export default withSecurity()(withScrollWatch(70)(withRouter(ResultToolBar)));
