/**
 *
 * SideBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import styled from 'styled-components';


import {PATHS} from '../../containers/App/constants';
import withSecurity, {PERMISSIONS} from '../../utils/security';
import messages from './messages';
import { ACTIVE_ENV } from '../../utils/activeEnv';

const Bar = styled.div`
  position: fixed;
  flex-direction: column;
  width: 50px;
  padding-top: 100px;
  background-color: #222222;
  height: 100vh;
`;

const ButtonWrap = styled.div`
  height: 50px;
  text-align: center;
  margin-top: 5px;
  padding-top: 8px;
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: none;
    color: ${props => (props.active ? props.theme.color.black4 : props.theme.color.white)};
  }
  color: ${props => (props.active ? props.theme.color.black4 : props.theme.color.greyBorder)};
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${props => (props.active ? props.theme.color.yellow : props.theme.color.black4)};
  background-color: ${props => (props.active ? props.theme.color.greyPageBg : props.theme.color.black4)};
`;

function isSubNavigation(props) {
  const { url } = props.match;
  if (props.path === '/') {
    return url === props.path;
  }
  return props.match.url.includes(props.path);
}

const Button = props => {
  const { className, children, history, path, ...rest } = props;
  return (
    <ButtonWrap
      {...rest}
      className={className}
      onClick={() => props.history.push(props.path)}
      active={isSubNavigation(props)}
    >
      {props.children}
    </ButtonWrap>
  );
};
Button.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
  history: PropTypes.object,
  children: PropTypes.node,
};

const ButtonText = styled.span``;

function SideBar(props) {
  const { intl } = props;
  return (
    <Bar>
      {props.hasPerm(PERMISSIONS.SIDEBAR) && (
        <>
          <Button {...props} path={PATHS.root} id="sidebar-button-home">
            <ButtonText>
              <FontAwesomeIcon icon="home" size="2x" title={intl.formatMessage(messages.home)} />
            </ButtonText>
          </Button>
          <Button {...props} path={PATHS.planningAreasList} id="sidebar-button-planningAreasList">
            <ButtonText>
              <FontAwesomeIcon icon="building" size="2x" title={intl.formatMessage(messages.planningAreas)} />
            </ButtonText>
          </Button>
          <Button {...props} path={PATHS.planList} id="sidebar-button-planList">
            <ButtonText>
              <FontAwesomeIcon icon="chart-line" size="2x" title={intl.formatMessage(messages.plans)} />
            </ButtonText>
          </Button>
          {!ACTIVE_ENV.shiftFillingDisabled && props.hasPerm(PERMISSIONS.VIEW_SCHEDULES) && (
            <Button {...props} path={PATHS.shiftScheduleList} id="sidebar-button-scheduleList">
              <ButtonText>
                <FontAwesomeIcon icon="calendar-alt" size="2x" title={intl.formatMessage(messages.shifts)} />
              </ButtonText>
            </Button>
          )}
          {props.hasPerm(PERMISSIONS.VIEW_MASTER_PLAN) && (
            <Button {...props} path={PATHS.masterPlanList} id="sidebar-button-masterPlanList">
              <ButtonText>
                <FontAwesomeIcon icon="object-group" size="2x" title={intl.formatMessage(messages.masterPlanList)} />
              </ButtonText>
            </Button>
          )}
        </>
      )}
    </Bar>
  );
}

SideBar.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
  hasPerm: PropTypes.func,
};

export default compose(injectIntl, withRouter, withSecurity())(SideBar);
