/*
 * PlanningAreaDetailPage Messages
 *
 * This contains all the text for the PlanningAreaDetailPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.PlanningAreaDetailPage.header',
    defaultMessage: 'Planning area - {name}',
  },
  headerOnly: {
    id: 'app.containers.PlanningAreaDetailPage.headerOnly',
    defaultMessage: 'Planning area - ',
  },
  newPa: {
    id: 'app.containers.PlanningAreaDetailPage.newPa',
    defaultMessage: 'New planning area',
  },
  planingAreadetailsLoader:{
    id: 'app.containers.PlanningAreaDetailPage.planingAreadetailsLoader',
    defaultMessage: 'Please wait, while we fetch Planning Area details',
  },
  basicInfo: {
    id: 'app.containers.PlanningAreaDetailPage.basicInfo',
    defaultMessage: 'Basic Information',
  },
  basicSettings: {
    id: 'app.containers.PlanningAreaDetailPage.basicSettings',
    defaultMessage: 'Basic Settings',
  },
  audit: {
    id: 'app.containers.PlanningAreaDetailPage.audit',
    defaultMessage: 'Audit',
  },
  customers: {
    id: 'app.containers.PlanningAreaDetailPage.customers',
    defaultMessage: 'Customers',
  },
  facilities: {
    id: 'app.containers.PlanningAreaDetailPage.facilities',
    defaultMessage: 'Facilities',
  },
  workZonePeriod: {
    id: 'app.containers.PlanningAreaDetailPage.workZonePeriod',
    defaultMessage: 'Work Zone Periods',
  },
  budgetVolume: {
    id: 'app.containers.PlanningAreaDetailPage.budgetVolume',
    defaultMessage: 'Baseline Volume',
  },
  effortForecast: {
    id: 'app.containers.PlanningAreaDetailPage.plannedHoursSection',
    defaultMessage: 'Planned Hours',
  },
  activitySettings: {
    id: 'app.containers.PlanningAreaDetailPage.activitySettings',
    defaultMessage: 'Activity Settings',
  },
  ttTables: {
    id: 'app.containers.PlanningAreaDetailPage.ttTables',
    defaultMessage: 'Activity Time Transformation Tables',
  },
  changeAnyway: {
    id: 'app.containers.PlanningAreaDetailPage.changeAnyway',
    defaultMessage: 'Change Anyway',
  },
  confirmDirty: {
    id: 'app.containers.PlanningAreaDetailPage.confirmDirty',
    defaultMessage: 'There are unsaved changes at planning area. Do you want to leave the page without saving them?',
  },
  workingHours: {
    id: 'app.containers.PlanningAreaDetailPage.workingHours',
    defaultMessage: 'Work Zone Periods',
  },
  labourAvailability: {
    id: 'app.containers.PlanningAreaDetailPage.labourAvailability',
    defaultMessage: 'Labour Availability',
  },
  labourAvailabilityTitle:{
    id: 'app.containers.PlanningAreaDetailPage.labourAvailabilityTitle',
    defaultMessage: 'Labour Availability',
  },
  masterPlanConflict: {
    id: 'app.containers.PlanningAreaDetailPage.masterPlanConflict',
    defaultMessage: 'Master plan conflict',
  },
  masterPlanConflictDescription: {
    id: 'app.containers.PlanningAreaDetailPage.masterPlanConflictDescription',
    defaultMessage: 'Facility already has another master plan: {names}',
  },
  mheAvailability: {
    id: 'app.containers.PlanningAreaDetailPage.mheAvailability',
    defaultMessage: 'MHE Availability',
  },
  planningAreaSaved: {
    id: 'app.containers.PlanningAreaDetailPage.planningAreaSaved',
    defaultMessage: 'Planning area {area} saved',
  },
  planningParamsUpdated: {
    id: 'app.containers.PlanningAreaDetailPage.planningParamsUpdated',
    defaultMessage: 'Planning parameters updated',
  },
  budgetVolumesDeleted: {
    id: 'app.containers.PlanningAreaDetailPage.budgetVolumesDeleted',
    defaultMessage: 'Budget volumes deleted',
  },
  activityLineDeleted: {
    id: 'app.containers.PlanningAreaDetailPage.activityLineDeleted',
    defaultMessage: 'Planning area activity line deleted',
  },
  adjustmentLineDeleted: {
    id: 'app.containers.PlanningAreaDetailPage.adjustmentLineDeleted',
    defaultMessage: 'Planning area Adjustment line deleted',
  },
  departments: {
    id: 'app.containers.PlanningAreaDetailPage.departments',
    defaultMessage: 'Departments',
  },
  volumeCategoryTimeTrans: {
    id: 'app.containers.PlanningAreaDetailPage.volumeCategoryTimeTrans',
    defaultMessage: 'Volume Category Time Transformation Tables',
  },
  shiftDefinition: {
    id: 'app.containers.PlanningAreaDetailPage.shiftDefinition',
    defaultMessage: 'Shift Definition',
  },
  shifts: {
    id: 'app.containers.PlanningAreaDetailPage.shifts',
    defaultMessage: 'Shifts',
  },
  directActivities: {
    id: 'app.containers.PlanningAreaDetailPage.ActivitySettingsDirect',
    defaultMessage: 'Direct Activities',
  },
  indirectActivities: {
    id: 'app.containers.PlanningAreaDetailPage.ActivitySettingsIndirect',
    defaultMessage: 'Indirect Activities',
  },
  productivityRate: {
    id: 'app.containers.PlanningAreaDetailPage.ProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  labourAvailabilityOverview: {
    id: 'app.containers.PlanningAreaDetailPage.labourAvailabilityOverview',
    defaultMessage: 'Labour Availability Overview',
  },
  mheAvailabilityOverview: {
    id: 'app.containers.PlanningAreaDetailPage.mheAvailabilityOverview',
    defaultMessage: 'MHE Availability Overview',
  },
  // errors
  errorSumOfRatiosMustBe100Percent: {
    id: 'app.containers.PlanningAreaDetailPage.errorSumOfRatiosMustBe100Percent',
    defaultMessage: 'Sum of ratios in Effort Forecast table must be equal to 100%',
  },
  errorNegativeProductivityRateIsNotAllowed: {
    id: 'app.containers.PlanningAreaDetailPage.errorNegativeProductivityRateIsNotAllowed',
    defaultMessage: 'Negative productivity rate is not allowed',
  },
  errorOverrideOutOfPeriod: {
    id: 'app.containers.PlanningAreaDetailPage.errorOverrideOutOfPeriod',
    defaultMessage: 'Productivity Rate out of period range',
  },
  errorNegativeEffortIsNotAllowed: {
    id: 'app.containers.PlanningAreaDetailPage.errorNegativeEffortIsNotAllowed',
    defaultMessage: 'Effort values in Effort Forecast table have to be a positive number or zero',
  },
  errorNameIsRequired: {
    id: 'app.containers.PlanningAreaDetailPage.errorNameIsRequired',
    defaultMessage: 'Name is required',
  },
  errorWZPForMHEAvailabilityIsRequired: {
    id: 'app.containers.PlanningAreaDetailPage.errorWZPForMHEAvailabilityIsRequired',
    defaultMessage: 'Work zone period for mhe availability is required',
  },
  errorMaintenanceDayForMHEIsRequired: {
    id: 'app.containers.PlanningAreaDetailPage.errorMaintenanceDayForMHEIsRequired',
    defaultMessage: 'Maintenance day for mhe availability is required',
  },
  errorStartTimeForUnproductiveActivityIsRequired: {
    id: 'app.containers.PlanningAreaDetailPage.errorStartTimeForUnproductiveActivityIsRequired',
    defaultMessage: 'Start time for unproductive activity is required',
  },
  errorEndTimeForUnproductiveActivityIsRequired: {
    id: 'app.containers.PlanningAreaDetailPage.errorEndTimeForUnproductiveActivityIsRequired',
    defaultMessage: 'End time for unproductive activity is required',
  },
  errorPeriodLengthOrDaysMustBeProvided: {
    id: 'app.containers.PlanningAreaDetailPage.errorPeriodLengthOrDaysMustBeProvided',
    defaultMessage: 'Period length or days must be provided for location unproductive activity',
  },
  errorOnlyPeriodLengthOrDaysMustBeProvided: {
    id: 'app.containers.PlanningAreaDetailPage.errorOnlyPeriodLengthOrDaysMustBeProvided',
    defaultMessage: 'Only period length or days must be provided for location unproductive activity, not both',
  },
  subTiTleError: {
    id: 'app.containers.PlanningAreaDetailPage.subTiTleError',
    defaultMessage: 'First setup Labour Categories and assign these to Shifts with Default Heads in Labour Availability Section',
  },
  mheError: {
    id: 'app.containers.PlanningAreaDetailPage.mheError',
    defaultMessage: 'First setup MHE Availability with default pieces in MHE Availability Section',
  },
  productivityError: {
    id: 'app.containers.PlanDetailPage.productivityError',
    defaultMessage: 'First setup activities in Activity Settings',
  },
  errorLastFacility: {
    id: 'app.containers.PlanningAreaDetailPage.productivityError',
    defaultMessage: "The last facility can't be removed",
  },
  activityAdjustments: {
    id: 'app.containers.PlanningAreaDetailPage.activityAdjustments',
    defaultMessage: 'Adjustments',
  },
  adjustmentOverview: {
    id: 'app.containers.PlanDetailPage.adjustmentOverview',
    defaultMessage: 'Adjustments Overview',
  },
  planningAreadetailsLoaderFlag:{
    id: 'app.containers.PlanDetailPage.planningAreadetailsLoaderFlag',
    defaultMessage: 'Please wait, while we fetch data',
  }
});
