/*
 * MasterPlanDetailToolbar Messages
 *
 * This contains all the text for the MasterPlanDetailToolbar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  saveTableConfig: {
    id: 'app.components.MasterPlanDetailToolbar.saveTableConfig',
    defaultMessage: 'Save Current To User View',
  },
  display: {
    id: 'app.components.MasterPlanDetailToolbar.display',
    defaultMessage: 'Display',
  },
  edit: {
    id: 'app.components.MasterPlanDetailToolbar.edit',
    defaultMessage: 'Edit',
  },
  back: {
    id: 'app.components.MasterPlanDetailToolbar.back',
    defaultMessage: 'Back',
  },
  save: {
    id: 'app.components.MasterPlanDetailToolbar.save',
    defaultMessage: 'Save',
  },
  paIsDeleted: {
    id: 'app.components.MasterPlanDetailToolbar.paIsDeleted',
    defaultMessage: 'Planning Area is disabled.',
  },
  disable: {
    id: 'app.components.MasterPlanDetailToolbar.disable',
    defaultMessage: 'Disable',
  },
  enable: {
    id: 'app.components.MasterPlanDetailToolbar.enable',
    defaultMessage: 'Enable',
  },
  deleteConfirmText: {
    id: 'app.containers.MasterPlanDetailToolbar.deleteConfirmText',
    defaultMessage: 'Are you sure? Disabling items: {items}',
  },
  enableConfirmText: {
    id: 'app.containers.MasterPlanDetailToolbar.enableConfirmText',
    defaultMessage: 'Are you sure? Enabling items: {items}',
  },
  enableConfirmButton: {
    id: 'app.containers.MasterPlanDetailToolbar.enableConfirmButton',
    defaultMessage: 'ENABLE',
  },
  enableTitle: {
    id: 'app.containers.MasterPlanDetailToolbar.enableTitle',
    defaultMessage: 'Enable Action',
  },
  disableConfirmButton: {
    id: 'app.containers.MasterPlanDetailToolbar.disableConfirmButton',
    defaultMessage: 'DISABLE',
  },
  disableTitle: {
    id: 'app.containers.MasterPlanDetailToolbar.disableTitle',
    defaultMessage: 'Disable Action',
  },
  activityCheck: {
    id: 'app.containers.MasterPlanDetailToolbar.activityCheck',
    defaultMessage: 'Activity Comparision',
  }
});
