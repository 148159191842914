/*
 * PlanDetailPlannedVolume Messages
 *
 * This contains all the text for the PlanDetailPlannedVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.PlanningAreaLabourAvailability.header',
    defaultMessage: 'Labour Category',
  },
});
