import { DateTime } from 'luxon';

import { formatDateToApiFormat, withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import {
  ApiActivityDTO,
  ApiForecastConversionRateTableDTO,
  ApiForecastPerOmsCodeTableDTO,
  ApiPlanOrPlanningAreaDTO,
  ApiSmartProdRunDTO, ApiSmartProdRunScheduleDTO,
} from 'types/drep-backend.d';

import { EntityEntry, ForecastConversionRateTableDTOWithId } from './types';

export const fetchSmartProdInstances = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/smartProdInstances/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};


export const fetchOMSCodes = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void, setOMSLoader?: any) => {
  if(setOMSLoader){
    setOMSLoader(true);
  }
  const response = await fetchData(withUrl(`/omses/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results
          .sort((a, b) => {
            if (a.code === b.code) {
              return a.name < b.name ? -1 : 1;
            }
            return a.code < b.code ? -1 : 1;
          })
          .map(result => ({
            value: result.id,
            label: `${result.code} - ${result.name}`,
          }))
      : [],
  );
  if(setOMSLoader){
    setOMSLoader(false);
  }
};

export const fetchWarehouses = async (token: string, dispatch, setWHIDs: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/smartProdWarehouses/?expression=`).andToken(token), dispatch);
  setWHIDs(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.smartProdWhId,
        }))
      : [],
  );
};


export const fetchActivities = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ApiActivityDTO[]) => void,
) => {
  const activitiesResponse = await fetchData(
    withUrl(`/activities/?expression=&pageSize=100000&planningParametersId=${planningParametersId}`).andToken(token),
    dispatch,
  );
  if (activitiesResponse.isOk) {
    setEntries(activitiesResponse.data.results);
  }
};

export const fetchSmartProdJobAdjustments = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setSmartProdJobAdjustments: (entries) => void,
) => {
  const activitiesResponse = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/jobCodeAdjustments`).andToken(token),
    dispatch,
  );

  if (activitiesResponse.isOk) {
    setSmartProdJobAdjustments(activitiesResponse.data);
  }
};

export const fetchSmartProdActivityAdjustments = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setSmartProdActivityAdjustments: (entries) => void,
) => {
  const activitiesResponse = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/activityForecastRecalculationAdjustments`).andToken(token),
    dispatch,
  );

  if (activitiesResponse.isOk) {
    setSmartProdActivityAdjustments(activitiesResponse.data);
  }
};

export const fetchForecastConversionRateTable = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ForecastConversionRateTableDTOWithId[]) => void,
  setInitialForecastConversionRatesLoader?:any,
) => {
  setInitialForecastConversionRatesLoader(true);
  const response = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/forecastConversionRates`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setEntries(
      response.data
        .sort((a, b) => {
          if (!a.omsId) {
            return 1;
          }
          if (!b.omsId) {
            return -1;
          }
          return 0;
        })
        .map((r, id) => ({
          ...r,
          id,
        })),
    );
  }
};

export const saveForecastConversionRateTable = (
  token: string,
  dispatch,
  planningParametersId: string,
  forecastConversionRates: ApiForecastConversionRateTableDTO[],
) =>
  fetchData(
    withUrl(`/planningParameters/${planningParametersId}/forecastConversionRates`)
      .andToken(token)
      .post(forecastConversionRates),
    dispatch,
  );

export const getForecastPerOMSCode = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ApiForecastPerOmsCodeTableDTO) => void,
  jobsIncluded: boolean,
  setForecastLoader,
) => {
  setForecastLoader(true);
  const response = await fetchData(
    withUrl(
      `/planningParameters/${planningParametersId}/forecastPerOmsCode?jobsIncluded=${jobsIncluded ? 'true' : 'false'}`,
    ).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    const sortedEntries = {
      oms: response.data.oms.map(omsData => ({
        ...omsData,
        dates: omsData.dates.sort((a, b) => (DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1)),
        activities: omsData.activities.map(activityData => ({
          ...activityData,
          dates: activityData.dates.sort((a, b) =>
            DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1,
          ),
          jobs: !activityData.jobs
            ? undefined
            : activityData.jobs.map(job => ({
                ...job,
                dates: job.dates
                  .filter(j => j.effortDate)
                  .sort((a, b) => (DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1)),
              })),
        })),
      })),
    };
    setEntries(sortedEntries);
  }
  setForecastLoader(false);
};

export const refreshJobCodes = (token: string, dispatch, planningParametersId: string) =>
  fetchData(
    withUrl(`/planningParameters/${planningParametersId}/refreshJobCodes`).andToken(token).asRawResponse().post(),
    dispatch,
  );

type RunsSetter = ((entries: ApiSmartProdRunDTO[]) => void) | ((entries: ApiSmartProdRunScheduleDTO[]) => void);

const getSmartProdRuns = url => async (
  token,
  dispatch,
  planningParametersId: string,
  setEntries: RunsSetter,
  setSmartProdRunLoader?: any,
  setTimeTransformationRuns?: any,
) => {
  setSmartProdRunLoader && setSmartProdRunLoader(true)
  const response = await fetchData(
    withUrl(`${url}?planningParametersId=${planningParametersId}`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    const responseData  = response?.data?.sort((a, b) => (DateTime.fromISO(a.createdDate) < DateTime.fromISO(b.createdDate) ? 1 : -1));
    setEntries(responseData?.filter((r)=> !r.timeTransformation));
    setTimeTransformationRuns && setTimeTransformationRuns(responseData?.filter((r)=> r.timeTransformation));
  }
  setSmartProdRunLoader && setSmartProdRunLoader(false)
};

export const getSmartProdRequestedRuns = getSmartProdRuns('/smartProdRun');

export const getSmartProdScheduledRuns = getSmartProdRuns('/smartProdRun/schedule');

export const saveSmartProdRuns = (
  token: string,
  dispatch,
  planningParametersId: string | number,
  smartProdRuns: ApiSmartProdRunDTO[],
) =>
  fetchData(
    withUrl(`/smartProdRun?planningParametersId=${planningParametersId}`)
      .andToken(token)
      .post(
        smartProdRuns.map(s => ({
          ...s,
          dataSourceDates: {
            ...s.dataSourceDates,
            startDate: formatDateToApiFormat(s.dataSourceDates.startDate),
            endDate: formatDateToApiFormat(s.dataSourceDates.endDate),
            years: s.dataSourceDates.years.map(formatDateToApiFormat),
            months: s.dataSourceDates.months.map(formatDateToApiFormat),
            weeks: s.dataSourceDates.weeks.map(formatDateToApiFormat),
            dates: s.dataSourceDates.dates.map(formatDateToApiFormat),
          },
          forecastDates: {
            ...s.forecastDates,
            startDate: formatDateToApiFormat(s.forecastDates.startDate),
            endDate: formatDateToApiFormat(s.forecastDates.endDate),
            years: s.forecastDates.years.map(formatDateToApiFormat),
            months: s.forecastDates.months.map(formatDateToApiFormat),
            weeks: s.forecastDates.weeks.map(formatDateToApiFormat),
            dates: s.forecastDates.dates.map(formatDateToApiFormat),
          },
          createdBy: undefined,
          sentBy: undefined,
          calculatedBy: undefined,
        })),
      ),
    dispatch,
  );

  export const saveTimeTransformationRuns = (
    token: string,
    dispatch,
    planningParametersId: string | number,
    smartProdRuns: ApiSmartProdRunDTO[],
  ) =>
    fetchData(
      withUrl(`/smartProdRun?planningParametersId=${planningParametersId}`)
        .andToken(token)
        .post(
          smartProdRuns.map(s => ({
            ...s,
            dataSourceDates: {
              ...s.dataSourceDates,
              startDate: formatDateToApiFormat(s.dataSourceDates.startDate),
              endDate: formatDateToApiFormat(s.dataSourceDates.endDate),
              years: s.dataSourceDates.years.map(formatDateToApiFormat),
              months: s.dataSourceDates.months.map(formatDateToApiFormat),
              weeks: s.dataSourceDates.weeks.map(formatDateToApiFormat),
              dates: s.dataSourceDates.dates.map(formatDateToApiFormat),
            },
            createdBy: undefined,
            sentBy: undefined,
            calculatedBy: undefined,
          })),
        ),
      dispatch,
    );

export const saveSmartProdScheduledRuns = (
  token: string,
  dispatch,
  planningParametersId: string,
  smartProdRuns: ApiSmartProdRunScheduleDTO[],
) =>
  fetchData(
    withUrl(`/smartProdRun/schedule?planningParametersId=${planningParametersId}`)
      .andToken(token)
      .post(
        smartProdRuns.map(s => ({
          ...s,
          createdBy: undefined,
          sentBy: undefined,
          calculatedBy: undefined,
          sourceStartDate: formatDateToApiFormat(s.sourceStartDate),
          sourceEndDate: formatDateToApiFormat(s.sourceEndDate),
        })),
      ),
    dispatch,
  );

export const smartProdRunsRequest = (token: string, dispatch, planningParametersId: number, runId: number) =>
  fetchData(
    withUrl(`/smartProdRun/${runId}/calculate?planningParametersId=${planningParametersId}`).andToken(token).post(),
    dispatch,
  );

export const smartProdRunsDeleteSchedule = (token: string, dispatch, runId: number) =>
  fetchData(
    withUrl(`/smartProdRun/schedule/${runId}`).andToken(token).delete().asRawResponse(),
    dispatch,
  );

export const smartProdRunsCopyRunSettings = (token: string, endpoint: string, dispatch, runId: number, data: ApiSmartProdRunDTO) =>
  fetchData(withUrl(`/${endpoint}/${runId}/copy`).andToken(token).post(data), dispatch);

export const fetchPlansAndPaWithSameSmartProdSource = async (
  token: string,
  dispatch,
  planningParametersId: number,
  setEntries: (entries: ApiPlanOrPlanningAreaDTO[]) => void,
) => {
  const response = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/plansAndPaWithSameSmartProdSource`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setEntries(response.data);
  }
};

export const copyMappingOfPlansAndPaWithSameSmartProdSource = async (
  token: string,
  dispatch,
  sourcePlanningParametersId: number,
  destinationPlanningParametersId: number,
  override: boolean,
) => {
  const response = await fetchData(
    withUrl(
      `/planningParameters/${destinationPlanningParametersId}/copyJobCodeMapping/${sourcePlanningParametersId}?override=${
        override ? 'true' : 'false'
      }`,
    )
      .andToken(token)
      .post()
      .asRawResponse(),
    dispatch,
  );
  return response;
};


export const fetchMasterPlanDetails = async (
  token: string,
  dispatch,
  planId: string | number,
  setMasterPlanDetails,
  entity?: any,
) => {
  const activitiesResponse = await fetchData(
    withUrl(`/masterPlan/${entity == 'planningAreas' ? 'planAreaId': 'planId'}/${planId}`).andToken(token),
    dispatch,
  );
  if (activitiesResponse.isOk) {
    setMasterPlanDetails(activitiesResponse.data);
  }
};

export const fetchSmartProdRunsDetails = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setSmartProdRunsDetails,
  setSmartProdRunsDetailLoader?: any,
) => {
  setSmartProdRunsDetailLoader(true);
  // const activitiesResponse = await fetchData(
  //   withUrl(`/smartProdSource/planningParametersId/${planningParametersId}`).andToken(token),
  //   dispatch,
  // );
  // if (activitiesResponse.isOk) {
  //   setSmartProdRunsDetails(activitiesResponse.data);
  // }
  setSmartProdRunsDetailLoader(false);
};
