/*
 * ActivitySettingsDirect Messages
 *
 * This contains all the text for the ActivitySettingsDirect component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ActivitySettingsDirect.header',
    defaultMessage: 'Direct Activities',
  },
  activity: {
    id: 'app.components.ActivitySettingsDirect.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.ActivitySettingsDirect.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  isEffortForecast: {
    id: 'app.components.ActivitySettingsDirect.effortForecast',
    defaultMessage: 'Effort Forecast',
  },
  omsCode: {
    id: 'app.components.ActivitySettingsDirect.omsCode',
    defaultMessage: 'OMS Code',
  },
  omsName: {
    id: 'app.components.ActivitySettingsDirect.omsName',
    defaultMessage: 'OMS Code',
  },
  uom: {
    id: 'app.components.ActivitySettingsDirect.uom',
    defaultMessage: 'Unit of Measure',
  },
  uomName: {
    id: 'app.components.ActivitySettingsDirect.uomName',
    defaultMessage: 'Unit of Measure',
  },
  noOfEmployees: {
    id: 'app.components.ActivitySettingsDirect.noOfEmployees',
    defaultMessage: '# of Employees',
  },
  numberOfEmployees: {
    id: 'app.components.ActivitySettingsDirect.numberOfEmployees',
    defaultMessage: '# of Employees',
  },
  productivityRate: {
    id: 'app.components.ActivitySettingsDirect.productivityRate',
    defaultMessage: 'Productivity Rate (UOM/h)',
  },
  hourlyProductivityRate: {
    id: 'app.components.ActivitySettingsDirect.hourlyProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  formula: {
    id: 'app.components.ActivitySettingsDirect.formula',
    defaultMessage: 'Formula (volume)',
  },
  formulaText: {
    id: 'app.components.ActivitySettingsDirect.formulaText',
    defaultMessage: 'Formula',
  },
  comment: {
    id: 'app.components.ActivitySettingsDirect.comment',
    defaultMessage: 'Comment',
  },
  action: {
    id: 'app.components.ActivitySettingsDirect.action',
    defaultMessage: 'Action',
  },
  details: {
    id: 'app.components.ActivitySettingsDirect.details',
    defaultMessage: 'Details',
  },
  detailsButtons: {
    id: 'app.components.ActivitySettingsDirect.detailsButtons',
    defaultMessage: 'Details',
  },
  delete: {
    id: 'app.components.ActivitySettingsDirect.delete',
    defaultMessage: 'Delete',
  },
  addMHEButtonLabel: {
    id: 'app.components.ActivitySettingsDirect.addMHEButtonLabel',
    defaultMessage: 'MHE',
  },
  addMHESelect: {
    id: 'app.components.ActivitySettingsDirect.addMHESelect',
    defaultMessage: 'Select MHE:',
  },
  addShiftSelect: {
    id: 'app.components.ActivitySettingsDirect.addShiftSelect',
    defaultMessage: 'Select Shift:',
  },
  addShiftRole: {
    id: 'app.components.ActivitySettingsDirect.addShiftRole',
    defaultMessage: 'Select Role:',
  },
  addMHEAddLine: {
    id: 'app.components.ActivitySettingsDirect.addMHEAddLine',
    defaultMessage: 'Add line',
  },
  addRoleAddLine: {
    id: 'app.components.ActivitySettingsDirect.addRoleAddLine',
    defaultMessage: 'Add Role',
  },
  addMHETimepercent: {
    id: 'app.components.ActivitySettingsDirect.addMHETimepercent',
    defaultMessage: 'Time percent',
  },
  transitionRate: {
    id: 'app.components.ActivitySettingsDirect.transitionRate',
    defaultMessage: 'Time percent',
  },
  addMHEType: {
    id: 'app.components.ActivitySettingsDirect.addMHEType',
    defaultMessage: 'MHE Type',
  },
  mheTypeName: {
    id: 'app.components.ActivitySettingsDirect.mheTypeName',
    defaultMessage: 'MHE Type',
  },
  addMHEName: {
    id: 'app.components.ActivitySettingsDirect.addMHEName',
    defaultMessage: 'Name',
  },
  name: {
    id: 'app.components.ActivitySettingsDirect.name',
    defaultMessage: 'Name',
  },
  customName: {
    id: 'app.components.ActivitySettingsDirect.customName',
    defaultMessage: 'Name',
  },
  dialogDeleteText: {
    id: 'app.components.ActivitySettingsDirect.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting activity {name} ({variableName})',
  },
  warningMessage: {
    id: 'app.components.ActivitySettingsDirect.warningMessage',
    defaultMessage: 'Warning: {warning}. The formula update will be saved anyway.',
  },
  errorMessage: {
    id: 'app.components.ActivitySettingsDirect.errorMessage',
    defaultMessage: "Parsing error: {error}. The formula update won't be saved.",
  },
  unknownVariables: {
    id: 'app.components.ActivitySettingsDirect.unknownVariables',
    defaultMessage: 'Unknown variables {variables}',
  },
  selfVariables: {
    id: 'app.components.ActivitySettingsDirect.selfVariables',
    defaultMessage: 'Formula point to the self variable {variables}',
  },
  customerId: {
    id: 'app.components.ActivitySettingsDirect.customerId',
    defaultMessage: 'Customer',
  },
  customerName: {
    id: 'app.components.ActivitySettingsDirect.customerName',
    defaultMessage: 'Customer',
  },
  activityLabel: {
    id: 'app.components.ActivitySettingsDirect.activityLabel',
    defaultMessage: 'Activity: {name}',
  },
  vcLabel: {
    id: 'app.components.ActivitySettingsDirect.vcLabel',
    defaultMessage: 'Volume Category: {name}',
  },
  all: {
    id: 'app.components.ActivitySettingsDirect.all',
    defaultMessage: 'All',
  },
  department: {
    id: 'app.components.ActivitySettingsDirect.department',
    defaultMessage: 'Department',
  },
  departmentId: {
    id: 'app.components.ActivitySettingsDirect.departmentId',
    defaultMessage: 'Department',
  },
  allDepartments: {
    id: 'app.components.ActivitySettingsDirect.allDepartments',
    defaultMessage: 'All Departments',
  },
  detailsButtonsShift: {
    id: 'app.components.ActivitySettingsDirect.detailsButtonsShift',
    defaultMessage: 'Per Shift',
  },
  addShiftButtonLabel:{
    id: 'app.components.ActivitySettingsDirect.addShiftButtonLabel',
    defaultMessage: 'PR per Shift',
  },
  addShiftName: {
    id: 'app.components.ActivitySettingsDirect.addShiftName',
    defaultMessage: 'Shift',
  },
  prDefault: {
    id: 'app.components.ActivitySettingsDirect.prDefault',
    defaultMessage: 'PR(Default)',
  },
  wzpName: {
    id: 'app.components.ActivitySettingsDirect.wzpName',
    defaultMessage: 'Shift',
  },
  addRole: {
    id: 'app.components.ActivitySettingsDirect.addRole',
    defaultMessage: 'Role',
  },
  addRoleButtonLabel: {
    id: 'app.components.ActivitySettingsDirect.addRoleButtonLabel',
    defaultMessage: 'Role',
  },
  timePercentage: {
    id: 'app.components.ActivitySettingsDirect.timePercentage',
    defaultMessage: 'Time Percentage',
  }
});
