/*
 *
 * PlanDetailPage constants
 *
 */

export const NAVIGATE_PLAN_DETAIL = 'app/PlanDetailPage/NAVIGATE_PLAN_DETAIL';
export const STORE_PLAN = 'app/PlanDetailPage/STORE_PLAN';
export const RESET_PLAN = 'app/PlanDetailPage/RESET_PLAN';
export const STORE_PLAN_GUI_STATE_ITEM = 'app/PlanDetailPage/STORE_PLAN_GUI_STATE_ITEM';
export const SAVE_BASIC_INFO = 'app/PlanDetailPage/SAVE_BASIC_INFO';
export const SAVE_BASIC_SETTINGS = 'app/PlanDetailPage/SAVE_BASIC_SETTINGS';
export const SAVE_PLANNED_VOLUME_HEADER = 'app/PlanDetailPage/SAVE_PLANNED_VOLUME_HEADER';
export const ADD_PLANNED_VOLUME_LINE = 'app/PlanDetailPage/ADD_PLANNED_VOLUME_LINE';
export const DELETE_PLANNED_VOLUME = 'app/PlanDetailPage/DELETE_PLANNED_VOLUME';
export const ADD_ACTIVITY_LINE = 'app/PlanDetailPage/ADD_ACTIVITY_LINE';
export const MODIFY_ACTIVITY_LINE = 'app/PlanDetailPage/MODIFY_ACTIVITY_LINE';
export const DELETE_ACTIVITY_LINES = 'app/PlanDetailPage/DELETE_ACTIVITY_LINES';
export const COPY_PLAN = 'app/PlanDetailPage/COPY_PLAN';
export const ADD_MHE_OPEN = 'app/PlanDetailPage/ADD_MHE_OPEN';
export const ADD_SHIFT_OPEN = 'app/PlanDetailPage/ADD_SHIFT_OPEN';
export const START_NEW = 'app/PlanDetailPage/START_NEW';
export const START_PLAN_COPY = 'app/PlanDetailPage/START_PLAN_COPY';
export const RESET_PLAN_BUDGET = 'app/PlanDetailPage/RESET_PLAN_BUDGET';
export const SAVE_PLAN = 'app/PlanDetailPage/SAVE_ALL';
export const SAVE_PLAN_DONE = 'app/PlanDetailPage/SAVE_PLAN_DONE';
export const SAVE_MASTER_PLAN_CONFLICT = 'app/PlanDetailPage/SAVE_MASTER_PLAN_CONFLICT';
export const SUBMIT_ERROR = 'app/PlanDetailPage/SUBMIT_ERROR';
export const CLEAR_MASTER_PLAN_CONFLICT = 'app/PlanDetailPage/CLEAR_MASTER_PLAN_CONFLICT';
export const CLEAR_PLAN = 'app/PlanDetailPage/CLEAR_PLAN';
export const CLEAR_PLAN_MHE = 'app/PlanDetailPage/CLEAR_PLAN_MHE';
export const ADD_WZP = 'app/PlanDetailPage/ADD_WZP';
export const DELETE_WZP = 'app/PlanDetailPage/DELETE_WZP';
export const LOAD_PLAN = 'app/PlanDetailPage/LOAD_PLAN';
export const LOAD_PLAN_DETAILS = 'app/PlanDetailPage/LOAD_PLAN_DETAILS';
export const LOAD_PLAN_OVERVIEW_DATA = 'app/PlanDetailPage/LOAD_PLAN_OVERVIEW_DATA';
export const SWITCH_TO_DISPLAY_PLAN = 'app/PlanDetailPage/SWITCH_TO_DISPLAY_PLAN';
export const TOGGLE_SECTION_PLAN = 'app/PlanDetailPage/TOGGLE_SECTION_PLAN';
export const STORE_PLAN_PERIOD_INDEX = 'app/PlanDetailPage/STORE_PLAN_PERIOD_INDEX';
export const ADD_PERIOD_PLAN = 'app/PlanDetailPage/ADD_PERIOD_PLAN';
export const DELETE_PERIOD_PLAN = 'app/PlanDetailPage/DELETE_PERIOD_PLAN';
export const COPY_PERIOD_PLAN = 'app/PlanDetailPage/COPY_PERIOD_PLAN';
export const ADD_LABOUR_AVAILABILITY_CATEGORY_LINE = 'app/PlanDetailPage/ADD_LABOUR_AVAILABILITY_CATEGORY_LINE';
export const DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE = 'app/PlanDetailPage/DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE';
export const ADD_SHIFT_SETTINGS_LINE = 'app/PlanDetailPage/ADD_SHIFT_SETTINGS_LINE';
export const DELETE_SHIFT_SETTINGS_LINE = 'app/PlanDetailPage/DELETE_SHIFT_SETTINGS_LINE';
export const PLANDETAIL_MASTERPLANS = 'app/PlanDetailPage/PLANDETAIL_MASTERPLANS';
export const PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS = 'app/PlanDetailPage/PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS';
export const SAVE_PLANDETAIL_MASTERPLANS = 'app/PlanDetailPage/SAVE_PLANDETAIL_MASTERPLANS';
export const SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS = 'app/PlanDetailPage/SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS';
export const SAVE_PLAN_SMART_CODE_MAPPINGS = 'app/PlanDetailPage/SAVE_PLAN_SMART_CODE_MAPPINGS';
export const OPEN_MASTER_PLAN_DIALOG = 'app/PlanDetailPage/OPEN_MASTER_PLAN_DIALOG';
export const CLOSE_MASTER_PLAN_DIALOG = 'app/PlanDetailPage/CLOSE_MASTER_PLAN_DIALOG';
export const STORE_PLAN_DETAILS = 'app/PlanDetailPage/STORE_PLAN_DETAILS';
export const SAVE_LABOUR_OVERVIEW = 'app/PlanDetailPage/SAVE_LABOUR_OVERVIEW';
export const SAVE_MHE_OVERVIEW = 'app/PlanDetailPage/SAVE_MHE_OVERVIEW';
export const PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA = 'app/PlanDetailPage/PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA';
export const PLAN_DETAILS_LOADER = 'app/PlanDetailPage/PLAN_DETAILS_LOADER';
export const PLAN_DETAILS_LOADER_FLAG = 'app/PlanDetailPage/PLAN_DETAILS_LOADER_FLAG';
export const PLAN_DETAILS_LOADER_OVERVIEW_FLAG = 'app/PlanDetailPage/PLAN_DETAILS_LOADER_OVERVIEW_FLAG';
export const OPEN_SCHEDULE_DIALOG = 'app/PlanDetailPage/OPEN_SCHEDULE_DIALOG';
export const CLOSE_SCHEDULE_DIALOG = 'app/PlanDetailPage/CLOSE_SCHEDULE_DIALOG';
export const PLAN_RATIO_WARNING_FLAG = 'app/PlanDetailPage/PLAN_RATIO_WARNING_FLAG';
export const ADD_ADJUSTMENT_LINE = 'app/PlanDetailPage/ADD_ADJUSTMENT_LINE';
export const DELETE_ADJUSTMENT_LINE = 'app/PlanDetailPage/DELETE_ADJUSTMENT_LINE';
export const SAVE_ADJUSTMENT_OVERVIEW = 'app/PlanDetailPage/SAVE_ADJUSTMENT_OVERVIEW';
export const PLANDETAIL_SCHEDULES = 'app/PlanDetailPage/PLANDETAIL_SCHEDULES';
export const SAVE_PLANDETAIL_SCHEDULES = 'app/PlanDetailPage/SAVE_PLANDETAIL_SCHEDULES';
export const SAVE_ROLES = 'app/PlanDetailPage/SAVE_ROLES';
export const ADD_ROLE_OPEN = 'app/PlanDetailPage/ADD_ROLE_OPEN';
export const TOGGLE_VOLUME_VALUE = 'app/PlanDetailPage/TOGGLE_VOLUME_VALUE';
export const TOGGLE_VOLUME_FORMULA = 'app/PlanDetailPage/TOGGLE_VOLUME_FORMULA';
export const TOGGLE_VOLUME_VALUE_VAR = 'app/PlanDetailPage/TOGGLE_VOLUME_VALUE_VAR';
export const TOGGLE_VOLUME_FORMULA_VAR = 'app/PlanDetailPage/TOGGLE_VOLUME_FORMULA_VAR';
