/*
 * PlanDetailPage Messages
 *
 * This contains all the text for the PlanDetailPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.PlanDetailPage.header',
    defaultMessage: 'Plan detail - {name}',
  },
  headerOnly: {
    id: 'app.containers.PlanDetailPage.headerOnly',
    defaultMessage: 'Plan detail - ',
  },
  plandetailsLoader:{
    id: 'app.containers.PlanDetailPage.plandetailsLoader',
    defaultMessage: 'Please wait, while we fetch Plan details',
  },
  plandetailsLoaderFlag:{
    id: 'app.containers.PlanDetailPage.plandetailsLoaderFlag',
    defaultMessage: 'Please wait, while we fetch data',
  },
  newPlan: {
    id: 'app.containers.PlanDetailPage.newPlan',
    defaultMessage: 'New plan',
  },
  basicInfo: {
    id: 'app.containers.PlanDetailPage.basicInfo',
    defaultMessage: 'Basic Information',
  },
  basicSettings: {
    id: 'app.containers.PlanDetailPage.basicSettings',
    defaultMessage: 'Basic Settings',
  },
  audit: {
    id: 'app.containers.PlanDetailPage.audit',
    defaultMessage: 'Audit',
  },
  customers: {
    id: 'app.containers.PlanDetailPage.customers',
    defaultMessage: 'Customers',
  },
  changeAnyway: {
    id: 'app.containers.PlanDetailPage.changeAnyway',
    defaultMessage: 'Change Anyway',
  },
  facilities: {
    id: 'app.containers.PlanDetailPage.facilities',
    defaultMessage: 'Facilities',
  },
  masterPlanConflict: {
    id: 'app.containers.PlanDetailPage.masterPlanConflict',
    defaultMessage: 'Master plan conflict',
  },
  masterPlanConflictDescription: {
    id: 'app.containers.PlanDetailPage.masterPlanConflictDescription',
    defaultMessage: 'Facility already has another master plan: {names}',
  },
  workZonePeriod: {
    id: 'app.containers.PlanDetailPage.workZonePeriod',
    defaultMessage: 'Work Zone Periods',
  },
  plannedVolume: {
    id: 'app.containers.PlanDetailPage.plannedVolume',
    defaultMessage: 'Planned Volume',
  },
  budgetVolume: {
    id: 'app.containers.PlanDetailPage.budgetVolume',
    defaultMessage: 'Baseline Volume',
  },
  activitySettings: {
    id: 'app.containers.PlanDetailPage.activitySettings',
    defaultMessage: 'Activity Settings',
  },
  ttTables: {
    id: 'app.containers.PlanDetailPage.ttTables',
    defaultMessage: 'Activity Time Transformation Tables',
  },
  budgetLastRefresh: {
    id: 'app.containers.PlanDetailPage.budgetLastRefresh',
    defaultMessage: 'Last refresh',
  },
  budgetRefreshBy: {
    id: 'app.containers.PlanDetailPage.budgetRefreshBy',
    defaultMessage: 'Refreshed by',
  },
  budgetButton: {
    id: 'app.containers.PlanDetailPage.budgetButton',
    defaultMessage: 'Refresh from Planning Area',
  },
  budgetVolumeCategory: {
    id: 'app.containers.PlanDetailPage.budgetVolumeCategory',
    defaultMessage: 'Volume Category',
  },
  budgetUnitOfMeasure: {
    id: 'app.containers.PlanDetailPage.budgetUnitOfMeasure',
    defaultMessage: 'Unit of Measure',
  },
  week: {
    id: 'app.containers.PlanDetailPage.week',
    defaultMessage: 'Week',
  },
  confirmDirty: {
    id: 'app.containers.PlanDetailPage.confirmDirty',
    defaultMessage: 'There are unsaved changes at this plan. Do you want to leave the page without saving them?',
  },
  workingHours: {
    id: 'app.containers.PlanDetailPage.workingHours',
    defaultMessage: 'Work Zone Periods',
  },
  activityAdjustments: {
    id: 'app.containers.PlanDetailPage.activityAdjustments',
    defaultMessage: 'Adjustments',
  },
  labourAvailability: {
    id: 'app.containers.PlanDetailPage.labourAvailability',
    defaultMessage: 'Labour Availability',
  },
  labourAvailabilityTitle:{
    id: 'app.containers.PlanDetailPage.labourAvailabilityTitle',
    defaultMessage: 'Labour Availability',
  },
  actualVolume: {
    id: 'app.containers.PlanDetailPage.actualVolume',
    defaultMessage: 'Actual Volume',
  },
  effortForecast: {
    id: 'app.containers.PlanDetailPage.plannedHoursSection',
    defaultMessage: 'Planned Hours ',
  },
  mheAvailability: {
    id: 'app.containers.PlanDetailPage.mheAvailability',
    defaultMessage: 'MHE Availability',
  },
  planBudgetRefresh: {
    id: 'app.containers.PlanDetailPage.planBudgetRefresh',
    defaultMessage: 'Plan budget refreshed from Planning Area',
  },
  planVolumesDeleted: {
    id: 'app.containers.PlanDetailPage.planVolumesDeleted',
    defaultMessage: 'Plan volumes deleted',
  },
  planActivityLineDeleted: {
    id: 'app.containers.PlanDetailPage.planActivityLineDeleted',
    defaultMessage: 'Plan activity line deleted',
  },
  planAdjustmentLineDeleted: {
    id: 'app.containers.PlanDetailPage.planAdjustmentLineDeleted',
    defaultMessage: 'Plan Adjustment line deleted',
  },
  planCopied: {
    id: 'app.containers.PlanDetailPage.planCopied',
    defaultMessage: 'Plan copied',
  },
  planSaved: {
    id: 'app.containers.PlanDetailPage.planSaved',
    defaultMessage: 'Plan saved',
  },
  planHasNoFacility: {
    id: 'app.containers.PlanDetailPage.planHasNoFacility',
    defaultMessage: "Can't save master plan, because it has no facility",
  },
  department: {
    id: 'app.containers.PlanDetailPage.Department',
    defaultMessage: 'Department',
  },
  volumeCategoryTimeTrans: {
    id: 'app.containers.PlanDetailPage.volumeCategoryTimeTrans',
    defaultMessage: 'Volume Category Time Transformation Tables',
  },
  shiftDefinition: {
    id: 'app.containers.PlanDetailPage.shiftDefinition',
    defaultMessage: 'Shift Definition',
  },
  shifts: {
    id: 'app.containers.PlanDetailPage.shifts',
    defaultMessage: 'Shifts',
  },
  directActivities: {
    id: 'app.containers.PlanDetailPage.ActivitySettingsDirect',
    defaultMessage: 'Direct Activities',
  },
  indirectActivities: {
    id: 'app.containers.PlanDetailPage.ActivitySettingsIndirect',
    defaultMessage: 'Indirect Activities',
  },
  productivityRate: {
    id: 'app.containers.PlanDetailPage.ProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  labourAvailabilityOverview:{
    id: 'app.containers.PlanDetailPage.labourAvailabilityOverview',
    defaultMessage: 'Labour Availability Overview',
  },
  warning: {
    id: 'app.containers.PlanDetailPage.warning',
    defaultMessage: 'Warnings',
  },
  subTiTleError: {
    id: 'app.containers.PlanDetailPage.subTiTleError',
    defaultMessage: 'First setup Labour Categories and assign these to Shifts with Default Heads in Labour Availability Section',
  },
  mheAvailabilityOverview: {
    id: 'app.containers.PlanDetailPage.mheAvailabilityOverview',
    defaultMessage: 'MHE Availability Overview',
  },
  mheError: {
    id: 'app.containers.PlanDetailPage.mheError',
    defaultMessage: 'First setup MHE Availability with default pieces in MHE Availability Section',
  },
  productivityError: {
    id: 'app.containers.PlanDetailPage.productivityError',
    defaultMessage: 'First setup activities in Activity Settings',
  },
  adjustmentOverview: {
    id: 'app.containers.PlanDetailPage.adjustmentOverview',
    defaultMessage: 'Adjustments Overview',
  },
  activitySmartCodeMapping: {
    id: 'app.containers.PlanDetailPage.activitySmartCodeMapping',
    defaultMessage: 'You changing this activity to {path} which would require to update SmartCode mapping',
  },
});
