import * as React from 'react';
import styled from 'styled-components';

import Label from 'components/Label';
import { getNestedField } from 'utils/utils';

export const FormikText = styled.div`
  align-items: center;
  align-self: center;
  width: 200px;
  display: inline-block;
`;

export const FormikCollapseText = styled.div`
  margin-left: 25px !important;
  margin-bottom: 10px;
  margin-left: 10px;
  align-items: center;
  align-self: center;
  display: inline-block;
`;

export const WarningText = styled.div`
  margin-left: 25px !important;
  margin-top: 10px;
  margin-left: 10px;
  align-items: center;
  align-self: center;
`;

export const WarningLiText = styled.div`
  margin-bottom: 10px
  align-items: center;
  align-self: center;
`;
export const WarningLiElement = styled.li`
  margin-left: 20px;
  margin-bottom: 3px
`;
export const WarningUiElement = styled.ul`
  margin-top: 8px;
`;
export const InputError = styled.div`
  color: ${props => props.theme.color.red};
  margin-top: 8px;
  margin-bottom: 2px;
`;

export const FormikTextInput = ({ field, form, editable, value, ...props }) => {
  if (!form || !field) {
    return null;
  }
  const { touched, errors } = form;
  const { name } = field;
  const error = getNestedField(touched, name) && getNestedField(errors, name) && (
    <InputError>{getNestedField(errors, name)}</InputError>
  );

  return editable !== false ? (
    <FormikText>
      <input key={field.name} type="text" {...field} valu={value || ''} {...props} />
      {error}
    </FormikText>
  ) : (
    <div>{props.value}</div>
  );
};

export const InlineLabel = styled(Label)`
  display: inline-block;
`;

export default FormikTextInput;
