/*
 * LabourAvailabilityOverview Messages
 *
 * This contains all the text for the LabourAvailabilityOverview component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  granularity: {
    id: 'app.components.LabourAvailabilityOverview.granularity',
    defaultMessage: 'Granularity',
  },
  availableHeads: {
    id: 'app.components.LabourAvailabilityOverview.availableHeads',
    defaultMessage: 'Available (Heads)',
  },
  confirm: {
    id: 'app.components.LabourAvailabilityOverview.confirm',
    defaultMessage: 'Confirm',
  },
  week: {
    id: 'app.components.LabourAvailabilityOverview.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.LabourAvailabilityOverview.day',
    defaultMessage: 'Day',
  },
  shift: {
    id: 'app.components.LabourAvailabilityOverview.shift',
    defaultMessage: 'Shift',
  },
  labourCategory: {
    id: 'app.components.LabourAvailabilityOverview.labourCategory',
    defaultMessage: 'Labour Category',
  },
  department: {
    id: 'app.components.LabourAvailabilityOverview.department',
    defaultMessage: 'Department',
  },
  shiftName:{
    id: 'app.components.LabourAvailabilityOverview.shiftName',
    defaultMessage: 'Shift',
  },
  labourCategoryName:{
    id: 'app.components.LabourAvailabilityOverview.labourCategoryName',
    defaultMessage: 'Labour Category',
  },
  departmentName:{
    id: 'app.components.LabourAvailabilityOverview.departmentName',
    defaultMessage: 'Department',
  },
  roleName: {
    id: 'app.components.LabourAvailabilityOverview.roleName',
    defaultMessage: 'Role',
  }
});
