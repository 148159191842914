import React from 'react';
import { FormattedMessage } from 'react-intl';

import UsedPlansTable from './UsedPlansTable';
import UsedPlanningAreasTable from './UsedPlanningAreasTable';
import messages from './messages';
import { ApiSmartProdSourceDTO } from 'types/drep-backend';

type Props = {
    entity: ApiSmartProdSourceDTO;
};

const UsedPlansAndPA: React.FC<Props> = ({ entity }) => (
    <div>
        <p>
            <FormattedMessage {...messages.plan} />
        </p>
        <UsedPlansTable entity={entity}
        />
        <p>
            <FormattedMessage {...messages.planningArea} />
        </p>
        <UsedPlanningAreasTable entity={entity} />
    </div>
)

export default UsedPlansAndPA;