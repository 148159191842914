/*
 * PlanResultPage Messages
 *
 * This contains all the text for the PlanResultPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.PlanResultPage.header',
    defaultMessage: 'Plan Result - {name}',
  },
  content: {
    id: 'app.containers.PlanResultPage.content',
    defaultMessage: 'Plan Result',
  },
  pageLabel: {
    id: 'app.containers.PlanResultPage.pageLabel',
    defaultMessage: 'Result Page - {name}',
  },
  exportLabel: {
    id: 'app.containers.PlanResultPage.exportLabel',
    defaultMessage: 'Result - {name} - {type}',
  },
  sheetLabel: {
    id: 'app.containers.PlanResultPage.sheetLabel',
    defaultMessage: '{name} - {type}',
  },
  basicInfo: {
    id: 'app.containers.PlanResultPage.basicInfo',
    defaultMessage: 'Plan Basic Information',
  },
  noWarningAvailable: {
    id: 'app.containers.PlanResultPage.noWarningAvailable',
    defaultMessage: 'No warnings available to display.',
  },
  mheWarning: {
    id: 'app.containers.PlanResultPage.mheWarning',
    defaultMessage: 'Warnings',
  },
  mheWarningTitle:{
    id: 'app.containers.PlanResultPage.mheWarningTitle',
    defaultMessage: 'There are warnings for this Plan!',
  },
  basicInfoPA: {
    id: 'app.containers.PlanResultPage.basicInfoPA',
    defaultMessage: 'Planing Area Basic Information',
  },
  resultMatrix: {
    id: 'app.containers.PlanResultPage.resultMatrix',
    defaultMessage: 'Result Matrix',
  },
  confirmDirty: {
    id: 'app.containers.PlanResultPage.confirmDirty',
    defaultMessage: 'You have unsaved changes. Do you want to leave anyway?',
  },
  graphTitleTrend: {
    id: 'app.containers.PlanResultPage.graphTitleTrend',
    defaultMessage: "Graphical Effort/FTE's Trend",
  },
  graphTitleActivity: {
    id: 'app.containers.PlanResultPage.graphTitleActivity',
    defaultMessage: 'Graphical Effort by activity by WZP',
  },
  effortTrend: {
    id: 'app.containers.PlanResultPage.effortTrend',
    defaultMessage: 'Effort Trend',
  },
  fteTrend: {
    id: 'app.containers.PlanResultPage.fteTrend',
    defaultMessage: "FTE's Trend",
  },
  baseline: {
    id: 'app.containers.PlanResultPage.baseline',
    defaultMessage: 'Baseline',
  },
  budget: {
    id: 'app.containers.PlanResultPage.budget',
    defaultMessage: 'Baseline',
  },
  planned: {
    id: 'app.containers.PlanResultPage.plan',
    defaultMessage: 'Planned',
  },
  original: {
    id: 'app.containers.PlanResultPage.original',
    defaultMessage: 'Original',
  },
  plan: {
    id: 'app.containers.PlanResultPage.planned',
    defaultMessage: 'Planned',
  },
  actuals: {
    id: 'app.containers.PlanResultPage.actuals',
    defaultMessage: 'Actuals',
  },
  forecast: {
    id: 'app.containers.PlanResultPage.forecast',
    defaultMessage: 'Forecast',
  },
  bar: {
    id: 'app.containers.PlanResultPage.bar',
    defaultMessage: 'Bar',
  },
  line: {
    id: 'app.containers.PlanResultPage.line',
    defaultMessage: 'Line',
  },
  sumIndirect: {
    id: 'app.containers.PlanResultPage.sumIndirect',
    defaultMessage: 'Sum Indirect',
  },
  sumDirect: {
    id: 'app.containers.PlanResultPage.sumDirect',
    defaultMessage: 'Sum Direct',
  },
  week: {
    id: 'app.containers.PlanResultPage.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.containers.PlanResultPage.day',
    defaultMessage: 'Day',
  },
  startDate: {
    id: 'app.containers.PlanResultPage.startDate',
    defaultMessage: 'Start Date',
  },
  effort: {
    id: 'app.containers.PlanResultPage.effort',
    defaultMessage: 'Effort',
  },
  e: {
    id: 'app.containers.PlanResultPage.e',
    defaultMessage: 'Effort',
  },
  fte: {
    id: 'app.containers.PlanResultPage.fte',
    defaultMessage: "FTE's",
  },
  heads: {
    id: 'app.containers.PlanResultPage.heads',
    defaultMessage: 'Heads',
  },
  headsOpt: {
    id: 'app.containers.PlanResultPage.headsOpt',
    defaultMessage: 'Heads (opt)',
  },
  sah: {
    id: 'app.containers.PlanResultPage.sah',
    defaultMessage: 'Available Hours',
  },
  saf: {
    id: 'app.containers.PlanResultPage.saf',
    defaultMessage: 'Available FTE',
  },
  sas: {
    id: 'app.containers.PlanResultPage.sas',
    defaultMessage: 'Available Staff',
  },
  diff: {
    id: 'app.containers.PlanResultPage.diff',
    defaultMessage: 'Labour Discrepancy in %',
  },
  variance: {
    id: 'app.containers.PlanResultPage.variance',
    defaultMessage: 'Variance',
  },
  effAdju: {
    id: 'app.containers.PlanResultPage.effAdju',
    defaultMessage: 'Effort with adj.',
  },
  diffAdju: {
    id: 'app.containers.PlanResultPage.diffAdju',
    defaultMessage: 'Adjustment %',
  },
  indirectEffort: {
    id: 'app.containers.PlanResultPage.indirectEffort',
    defaultMessage: 'Indirect Effort',
  },
  indirectFte: {
    id: 'app.containers.PlanResultPage.indirectFte',
    defaultMessage: "Indirect FTE's",
  },
  indirectHeads: {
    id: 'app.containers.PlanResultPage.indirectHeads',
    defaultMessage: 'Indirect Heads',
  },
  directEffort: {
    id: 'app.containers.PlanResultPage.directEffort',
    defaultMessage: 'Direct Effort',
  },
  directFte: {
    id: 'app.containers.PlanResultPage.directFte',
    defaultMessage: "Direct FTE's",
  },
  directHeads: {
    id: 'app.containers.PlanResultPage.directHeads',
    defaultMessage: 'Direct Heads',
  },
  direct_plan_effort: {
    id: 'app.containers.PlanResultPage.direct_plan_effort',
    defaultMessage: 'Direct Planned Effort',
  },
  direct_plan_fte: {
    id: 'app.containers.PlanResultPage.direct_plan_fte',
    defaultMessage: "Direct Planned FTE's",
  },
  direct_plan_heads: {
    id: 'app.containers.PlanResultPage.direct_plan_heads',
    defaultMessage: 'Direct Planned Heads',
  },
  indirect_plan_effort: {
    id: 'app.containers.PlanResultPage.indirect_plan_effort',
    defaultMessage: 'Indirect Planned Effort',
  },
  indirect_plan_fte: {
    id: 'app.containers.PlanResultPage.indirect_plan_fte',
    defaultMessage: "Indirect Planned FTE's",
  },
  indirect_plan_heads: {
    id: 'app.containers.PlanResultPage.indirect_plan_heads',
    defaultMessage: 'Indirect Planned Heads',
  },
  direct_budget_effort: {
    id: 'app.containers.PlanResultPage.direct_budget_effort',
    defaultMessage: 'Direct Baseline Effort',
  },
  direct_budget_fte: {
    id: 'app.containers.PlanResultPage.direct_budget_fte',
    defaultMessage: "Direct Baseline FTE's",
  },
  direct_budget_heads: {
    id: 'app.containers.PlanResultPage.direct_budget_heads',
    defaultMessage: 'Direct Baseline Heads',
  },
  indirect_budget_effort: {
    id: 'app.containers.PlanResultPage.indirect_budget_effort',
    defaultMessage: 'Indirect Baseline Effort',
  },
  indirect_budget_fte: {
    id: 'app.containers.PlanResultPage.indirect_budget_fte',
    defaultMessage: "Indirect Baseline FTE's",
  },
  indirect_budget_heads: {
    id: 'app.containers.PlanResultPage.indirect_budget_heads',
    defaultMessage: 'Indirect Baseline Heads',
  },
  direct_actuals_effort: {
    id: 'app.containers.PlanResultPage.direct_actuals_effort',
    defaultMessage: 'Direct Actuals Effort',
  },
  direct_actuals_fte: {
    id: 'app.containers.PlanResultPage.direct_actuals_fte',
    defaultMessage: "Direct Actuals FTE's",
  },
  direct_actuals_heads: {
    id: 'app.containers.PlanResultPage.direct_actuals_heads',
    defaultMessage: 'Direct Actuals Head',
  },
  indirect_actuals_effort: {
    id: 'app.containers.PlanResultPage.indirect_actuals_effort',
    defaultMessage: 'Indirect Actuals Effort',
  },
  indirect_actuals_fte: {
    id: 'app.containers.PlanResultPage.indirect_actuals_fte',
    defaultMessage: "Indirect Actuals FTE's",
  },
  indirect_actuals_heads: {
    id: 'app.containers.PlanResultPage.indirect_actuals_heads',
    defaultMessage: 'Indirect Actuals Heads',
  },
  direct_availability_effort: {
    id: 'app.containers.PlanResultPage.direct_availability_effort',
    defaultMessage: 'Available Effort',
  },
  direct_availability_fte: {
    id: 'app.containers.PlanResultPage.direct_availability_fte',
    defaultMessage: "Available FTE's",
  },
  direct_availability_heads: {
    id: 'app.containers.PlanResultPage.direct_availability_heads',
    defaultMessage: 'Available Heads',
  },
  direct: {
    id: 'app.containers.PlanResultPage.direct',
    defaultMessage: 'Direct',
  },
  indirect: {
    id: 'app.containers.PlanResultPage.indirect',
    defaultMessage: 'Indirect',
  },
  granularityActivity: {
    id: 'app.containers.PlanResultPage.granularityActivity',
    defaultMessage: 'Granularity',
  },

  activities: {
    id: 'app.components.PlanResultPage.activities',
    defaultMessage: 'Activities',
  },
  customer: {
    id: 'app.components.PlanResultPage.customer',
    defaultMessage: 'Customer',
  },
  all: {
    id: 'app.components.PlanResultPage.all',
    defaultMessage: 'All',
  },
  mhe: {
    id: 'app.components.PlanResultPage.mhe',
    defaultMessage: 'Material Handling Equipment',
  },
  role: {
    id: 'app.components.PlanResultPage.role',
    defaultMessage: 'Role',
  },
  activity: {
    id: 'app.components.PlanResultPage.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.PlanResultPage.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  colProductivityRate: {
    id: 'app.components.PlanResultPage.colProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  pr: {
    id: 'app.components.PlanResultPage.pr',
    defaultMessage: 'Productivity Rate',
  },
  h: {
    id: 'app.components.PlanResultPage.h',
    defaultMessage: 'h',
  },
  uom: {
    id: 'app.components.PlanResultPage.uom',
    defaultMessage: 'UoM',
  },
  uomPerHour: {
    id: 'app.components.PlanResultPage.uomPerHour',
    defaultMessage: 'UoM/h',
  },
  month: {
    id: 'app.components.PlanResultPage.month',
    defaultMessage: 'Month',
  },
  colVolume: {
    id: 'app.components.PlanResultPage.colVolume',
    defaultMessage: 'Volume',
  },
  v: {
    id: 'app.components.PlanResultPage.v',
    defaultMessage: 'Volume',
  },
  colEffort: {
    id: 'app.components.PlanResultPage.colEffort',
    defaultMessage: 'Effort h',
  },
  colFte: {
    id: 'app.components.PlanResultPage.colFte',
    defaultMessage: 'FTE',
  },
  result: {
    id: 'app.components.PlanResultPage.result',
    defaultMessage: 'Total',
  },
  sum_v: {
    id: 'app.components.PlanResultPage.sum_v',
    defaultMessage: 'Sum Volume',
  },
  sum_e: {
    id: 'app.components.PlanResultPage.sum_e',
    defaultMessage: 'Sum Effort h',
  },
  sum_fte: {
    id: 'app.components.PlanResultPage.sum_fte',
    defaultMessage: 'Sum FTE',
  },
  sum_effAdju: {
    id: 'app.components.PlanResultPage.sum_effAdju',
    defaultMessage: 'Sum Effort with adj.',
  },
  sum_diffAdju: {
    id: 'app.components.PlanResultPage.sum_diffAdju',
    defaultMessage: 'Adjustment %',
  },
  sum_heads: {
    id: 'app.components.PlanResultPage.sum_heads',
    defaultMessage: 'Sum Head',
  },
  sum: {
    id: 'app.components.PlanResultPage.sum',
    defaultMessage: 'Labour Demand Sum',
  },
  sum_h: {
    id: 'app.components.PlanResultPage.sum_h',
    defaultMessage: 'Sum hours',
  },
  youCanOverrideThisCell: {
    id: 'app.components.PlanResultPage.youCanOverrideThisCell',
    defaultMessage: 'Cell can be overridden. Click to edit.',
  },
  overrideStateCalc: {
    id: 'app.components.PlanResultPage.overrideStateCalc',
    defaultMessage: 'This cell is calculated normal way.',
  },
  overrideStateOverrideCalc: {
    id: 'app.components.PlanResultPage.overrideStateOverrideCalc',
    defaultMessage: 'This cell is calculated from a overridden value.',
  },
  overrideStateOverriden: {
    id: 'app.components.PlanResultPage.overrideStateOverriden',
    defaultMessage: 'This cell is overridden directly.',
  },
  overrideStateOverridenMhe:{
    id: 'app.components.PlanResultPage.overrideStateOverridenMhe',
    defaultMessage: 'This cell is overridden using MHE Assistant.',
  },
  overrideStateOverridenUser:{
    id: 'app.components.PlanResultPage.overrideStateOverridenUser',
    defaultMessage: 'This cell is overridden by User.',
  },
  fullValue: {
    id: 'app.components.PlanResultPage.fullValue',
    defaultMessage: 'Full Value',
  },
  justEdited: {
    id: 'app.components.PlanResultPage.justEdited',
    defaultMessage: 'Value is edited but not saved. You have save in order to make values recalculate.',
  },
  origPlanned: {
    id: 'app.components.PlanResultPage.origPlanned',
    defaultMessage: 'Planned Base',
  },
  hMonth: {
    id: 'app.components.PlanResultPage.hMonth',
    defaultMessage: 'h/Month',
  },
  hWeek: {
    id: 'app.components.PlanResultPage.hWeek',
    defaultMessage: 'h/Week',
  },
  hDay: {
    id: 'app.components.PlanResultPage.hDay',
    defaultMessage: 'h/day',
  },
  hWzp: {
    id: 'app.components.PlanResultPage.hWzp',
    defaultMessage: 'h/wzp',
  },
  hShift: {
    id: 'app.components.PlanResultPage.hShift',
    defaultMessage: 'h/shift',
  },
  hHour: {
    id: 'app.components.PlanResultPage.hHour',
    defaultMessage: 'h/h',
  },
  hMinute: {
    id: 'app.components.PlanResultPage.hMinute',
    defaultMessage: 'h/minute',
  },
  mheName: {
    id: 'app.components.PlanResultPage.mheName',
    defaultMessage: 'MHE Name',
  },
  export: {
    id: 'app.components.PlanResultPage.export',
    defaultMessage: 'Export',
  },
  mheResult: {
    id: 'app.components.PlanResultPage.mheResult',
    defaultMessage: 'Total',
  },
  activityTitle: {
    id: 'app.components.PlanResultPage.activityTitle',
    defaultMessage: 'Activity',
  },
  available: {
    id: 'app.components.PlanResultPage.available',
    defaultMessage: 'Available',
  },
  availability: {
    id: 'app.components.PlanResultPage.availability',
    defaultMessage: 'Available',
  },
  graphTitleDilo: {
    id: 'app.components.PlanResultPage.graphTitleDilo',
    defaultMessage: 'Day in the life of (DILO)',
  },
  graphHeads: {
    id: 'app.components.PlanResultPage.graphHeads',
    defaultMessage: 'Heads comparison',
  },
  overrideByVolumeTitle: {
    id: 'app.components.PlanResultPage.overrideByVolumeTitle',
    defaultMessage: 'Override By Volume Category Helper',
  },
  confirm: {
    id: 'app.components.PlanResultPage.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'app.components.PlanResultPage.cancel',
    defaultMessage: 'Cancel',
  },
  wzp: {
    id: 'app.components.PlanResultPage.wzp',
    defaultMessage: 'WZP',
  },
  shift: {
    id: 'app.components.PlanResultPage.shift',
    defaultMessage: 'Shift',
  },
  hour: {
    id: 'app.components.PlanResultPage.hour',
    defaultMessage: 'Hour',
  },
  volumeCategory: {
    id: 'app.components.PlanResultPage.volumeCategory',
    defaultMessage: 'Volume Category',
  },
  csv: {
    id: 'app.components.PlanResultPage.csv',
    defaultMessage: 'CSV',
  },
  labourDiscrepancy: {
    id: 'app.components.PlanResultPage.labourDiscrepancy',
    defaultMessage: 'Labour discrepancy',
  },
  labourAvailabilitySum: {
    id: 'app.components.PlanResultPage.labourAvailabilitySum',
    defaultMessage: 'Labour Availability Sum',
  },
  demand: {
    id: 'app.components.PlanResultPage.demand',
    defaultMessage: 'Demand',
  },
  undoChanges: {
    id: 'app.components.PlanResultPage.undoChanges',
    defaultMessage: 'Undo Changes',
  },
  department: {
    id: 'app.components.PlanResultPage.department',
    defaultMessage: 'Department',
  },
  allDepartments: {
    id: 'app.components.PlanResultPage.allDepartments',
    defaultMessage: 'All Departments',
  },
  failToSave: {
    id: 'app.components.PlanResultPage.failToSave',
    defaultMessage: 'Fail To Save',
  },
  dirtyForm: {
    id: 'app.components.PlanResultPage.dirtyForm',
    defaultMessage: 'You have unsaved date. Do you want to leave and discard them?',
  },
  changesSaved: {
    id: 'app.components.PlanResultPage.changesSaved',
    defaultMessage: 'The changes has been saved',
  },
  showLabels: {
    id: 'app.components.PlanResultPage.showLabels',
    defaultMessage: 'Show Labels',
  },
  close: {
    id: 'app.components.PlanResultPage.close',
    defaultMessage: 'Close',
  },
  noMonthlyOverides: {
    id: 'app.components.PlanResultPage.noMonthlyOverides',
    defaultMessage: "Overrides and Actuals can't be inserted at monthly granularity",
  },
  noWeeklyOverides: {
    id: 'app.components.PlanResultPage.noWeeklyOverides',
    defaultMessage:
      "Overrides and Actuals can't be inserted at weekly granularity when plan activity transformation is set to Daily",
  },
  noWeeklyOveridesPRHourly: {
    id: 'app.components.PlanResultPage.noWeeklyOveridesPRHourly',
    defaultMessage:
      "Overrides and Actuals can't be inserted at weekly granularity when Productivity rate is set to Daily.",
  },
  noHourlyOverides: {
    id: 'app.components.PlanResultPage.noHourlyOverides',
    defaultMessage:
      "Overrides and Actuals can't be inserted at hourly granularity when plan transformation type is set to Shift",
  },
  errorAssignShiftToPerformCalculation: {
    id: 'app.components.PlanResultPage.errorAssignShiftToPerformCalculation',
    defaultMessage: 'Assign a shift to perform calculation',
  },
  errorAssignWZPToPerformCalculation: {
    id: 'app.components.PlanResultPage.errorAssignWZPToPerformCalculation',
    defaultMessage: 'Assign a WZP to perform calculation',
  },
  unproductiveActivities: {
    id: 'app.components.PlanResultPage.unproductiveActivities',
    defaultMessage: 'Unproductive Activities',
  },
  nos: {
    id: 'app.components.PlanResultPage.nos',
    defaultMessage: '#Staff',
  },
  wzpAverage: {
    id: 'app.components.PlanResultPage.wzpAverage',
    defaultMessage: 'Average #Staff',
  },
});
