/* eslint-disable no-restricted-syntax,no-plusplus */

import { DateTime, Duration } from 'luxon';
import { parseTimes, parseDates } from 'utils/dateTime';
import { formulaToText } from '../formulas';
import { forceCapitalize } from '../utils';
import { PRODUCTIVITY_TYPE } from '../common/productivity/constants';

function mapUnproductiveLocation(act) {
  const result = parseDates(parseTimes(act, ['startTime', 'endTime']), ['startDate', 'endDate']);
  result.days = act.days || [];
  return result;
}

function extractActivityAndUomLookups(activityParameters, lookups) {
  const { activities, uoms } = lookups;
  if (activityParameters && activityParameters.length) {
    for (const ap of activityParameters) {
      if (ap.activity && !activities[ap.activity.id]) {
        activities[ap.activity.id] = ap.activity;
      }
      if (ap.uom && !uoms[ap.uom.id]) {
        uoms[ap.uom.id] = ap.uom;
      }
    }
  }
}

export function reorganizeActivityParameters(period, planningParameters, lookups) {
  const result = {};
  result.dayTransitions = calculateApDayTransitions(period.activityParameters, planningParameters.departments);
  result.wzpTransitions = calculateApWzpTransitions(period, planningParameters.departments);
  result.hourTransitions = calculateApHourTransitions(period, planningParameters.departments);
  result.direct = filterActivities(period.activityParameters, PRODUCTIVITY_TYPE.DIRECT);
  result.indirect = filterActivities(period.activityParameters, PRODUCTIVITY_TYPE.INDIRECT_EFFORT);
  result.unproductiveLocations = period.activityParameters
    .filter(it => it.activity.productivityType === PRODUCTIVITY_TYPE.LOCATION_UNPRODUCTIVE)
    .map(mapUnproductiveLocation);
  result.unproductiveStaff = period.activityParameters.filter(
    it => it.activity.productivityType === PRODUCTIVITY_TYPE.STAFF_UNPRODUCTIVE,
  );
  extractActivityAndUomLookups(period.activityParameters, lookups);
  return result;
}

function filterActivities(aps, productivityType) {
  const result = [];
  let i = 0;
  for (const ap of aps) {
    if (ap.activity.productivityType === productivityType) {
      const { dayTransitions, wzpTransitions, shiftActivityProductivityRateDTOs, planningParametersId, mheTransitions, roleActivities, hourTransitions, ...theRest } = ap;
      const row = { index: i++, ...theRest };
      if(shiftActivityProductivityRateDTOs){
        const shiftWithIndex = [];
        let s = 0;
        for (const shift of shiftActivityProductivityRateDTOs) {
          shiftWithIndex.push({index: s++, ...shift})
        }
        row.perShift = shiftWithIndex.map(shift =>({
          id: shift.id,
          wzpId: shift.wzpId,
          productivityRate: shift.productivityRate,
          wzpName: shift.wzpName
        }))
      }
      if(roleActivities){
        const rowWithIndex = [];
        let r = 0;
        for (const role of roleActivities) {
          rowWithIndex.push({index: r++, ...role})
        }
        row.roles = rowWithIndex.map(role =>({
          id: role.role.id,
          timePercentage: role.timePercentage * 100,
          name: role.role.name,
          roleId: role.role.id
        }))
      }
      if (mheTransitions) {
        const mheWithIndex = [];
        let j = 0;
        for (const mhe of mheTransitions) {
          mheWithIndex.push({ index: j++, ...mhe });
        }
        row.mheTransitions = mheWithIndex;
        row.mhes = mheWithIndex.map(mhe => ({
          // eslint-disable-line
          id: mhe.id,
          transitionRate: mhe.transferRate * 100,
          mheId: mhe.mheDTO.id,
          mheTypeId: mhe.mheDTO.mheTypeId,
          mheTypeName: mhe.mheDTO.mheTypeName,
          name: mhe.mheDTO.name,
          regionalConfigurationName: mhe.mheDTO.regionalConfigurationName,
          index: mhe.index,
        }));
        // row.perShift = mheWithIndex.map(mhe => ({
        //   // eslint-disable-line
        //   id: mhe.id,
        //   transitionRate: mhe.transferRate * 100,
        //   ShiftId: mhe.mheDTO.id,
        //   ShiftTypeId: mhe.mheDTO.mheTypeId,
        //   ShiftTypeName: mhe.mheDTO.mheTypeName,
        //   name: mhe.mheDTO.name,
        //   regionalConfigurationName: mhe.mheDTO.regionalConfigurationName,
        //   index: mhe.index,
        //   prDefault : ap.hourlyProductivityRate
        // }));
      }
      //row.perShift = [{name:'Warehouse', prDefault : ap.hourlyProductivityRate}]
      result.push(row);
    }
  }
  return result;
}

export function listWithIdToMap(list) {
  const result = {};
  if (list) {
    list.forEach(d => {
      result[d.id] = d;
    });
  }
  return result;
}

function calculateApDayTransitions(activityParameters, departments) {
  const departmentsById = listWithIdToMap(departments);
  const rows = [];
  for (let i = 0; i < activityParameters.length; i++) {
    const ap = activityParameters[i];
    const row = {
      index: i,
      id: ap.id,
      department: departmentsById[ap.departmentId],
      activity: ap.activity,
      variableName: ap.variableName,
      uom: ap.uom,
      customerId: ap.customerId,
    };
    if (ap.dayTransitions) {
      for (const dt of ap.dayTransitions) {
        row[dt.dayOfWeek] = Number(dt.transferRate * 100);
      }
    }
    rows.push(row);
  }
  return rows;
}

function calculateApWzpTransitions(period, departments) {
  const departmentsById = listWithIdToMap(departments);
  const { activityParameters } = period;
  const rows = [];
  const headers = [];
  let lastDay = '';
  for (let i = 0; i < activityParameters.length; i++) {
    const ap = activityParameters[i];
    const row = {
      index: i,
      id: ap.id,
      activity: ap.activity,
      variableName: ap.variableName,
      department: departmentsById[ap.departmentId],
      uom: ap.uom,
      customerId: ap.customerId,
    };
    if (ap.wzpTransitions) {
      for (const wzpt of ap.wzpTransitions) {
        const fieldName = `${wzpt.dayOfWeek}_${wzpt.wzpId}`;
        if (i === 0) {
          // build headers
          if (lastDay !== wzpt.dayOfWeek) {
            lastDay = wzpt.dayOfWeek;
            headers.push({ label: lastDay, children: [] });
          }
          const wzpHeader = { label: wzpt.wzpName, field: fieldName };
          headers[headers.length - 1].children.push(wzpHeader);
        }
        row[fieldName] = Number(wzpt.transferRate * 100);
      }
    }
    rows.push(row);
  }
  return { rowData: rows, headers };
}

const weekHeaderInRange = (startDate, endDate) => header => {
  const { field } = header;
  const [startDay, endDay] = field
    .replace('from', '')
    .split('to')
    .map(date => DateTime.fromFormat(date, 'yyyy-MM-dd'));
  return !(startDay > endDate || endDay < startDate);
};

const dayHeaderInRange = (startDate, endDate) => header => {
  const date = DateTime.fromFormat(header.field, 'yyyy-MM-dd');
  return !(date > endDate || date < startDate);
};

export function vcHeadersByRange(vcp, startDate, endDate, mode) {
  if (!startDate || !endDate || !vcp) return vcp;
  const isDayMode = mode === 'DAY';
  const headers = (isDayMode && vcp.dailyHeaders) || vcp.headers;
  const inRange = (isDayMode && dayHeaderInRange) || weekHeaderInRange;
  const key = (isDayMode && 'dailyHeaders') || 'headers';
  return { [key]: headers.filter(inRange(startDate, endDate)) };
}

export function recalculateVolumeCategoryParameters(vcp) {
  const rowData = [];
  const rowDataVar = [];
  const headers = [];
  const dailyRowData = [];
  const dailyRowDataVar = [];
  const dailyHeaders = [];
  for (let i = 0; i < vcp.length; i++) {
    const { volumeCategory, uom, formula, ...theRest } = vcp[i];
    const targetArray = volumeCategory.variable ? rowDataVar : rowData;
    const targetDailyArray = volumeCategory.variable ? dailyRowDataVar : dailyRowData;
    const row = { volumeCategory, uom, index: targetArray.length };
    // dailyHeaders.push({ field: 'default', headerName: 'Default', formula: `defaultFormula` });
    // headers.push({ field: 'default', headerName: 'Default', formula: `defaultFormula` });
    const defaultformulaArray = vcp[i].defaultFormula ? formulaToText(JSON.parse(vcp[i].defaultFormula)): vcp[i].defaultFormula;
        let defaultformulaText = '';
        defaultformulaArray && defaultformulaArray.forEach(a => {
          defaultformulaText += `${(typeof a === 'object' && a.text) || a}`;
        });
    row['defaultFormula'] = defaultformulaText;
    row['id'] = volumeCategory.id;
    if (theRest.weeklyVolumes) {
      for (const volume of theRest.weeklyVolumes) {
        const field = `from${volume.startDay}to${volume.endDay}`;
        row[field] = volume.volume;
        const formulaArray = volume.formula ? formulaToText(JSON.parse(volume.formula)): volume.formula;
        let formulaText = '';
        formulaArray && formulaArray.forEach(a => {
          formulaText += `${(typeof a === 'object' && a.text) || a}`;
        });
        row[`${field}_formula`] = volume.formula ? formulaText : volume.formula;
        row[`${field}_textF`] = volume.formula;
        if (i === 0) {
          headers.push({ field, headerName: volume.startDay, formula: `${field}_formula` });
        }
      }
    }
    const dRow = { volumeCategory, uom, index: targetDailyArray.length };
    //dRow['defaultFormula'] = formulaToText(JSON.parse(vcp[i].defaultFormula));
    const defaultdayformulaArray = vcp[i].defaultFormula ? formulaToText(JSON.parse(vcp[i].defaultFormula)): vcp[i].defaultFormula;
        let defaultdayformulaText = '';
        defaultdayformulaArray && defaultdayformulaArray.forEach(a => {
          defaultdayformulaText += `${(typeof a === 'object' && a.text) || a}`;
        });
      dRow['defaultFormula'] = defaultdayformulaText;
      dRow['id'] = volumeCategory.id;
    if (theRest.dayVolumes) {
      for (const volume of theRest.dayVolumes) {
        let val = Number(volume.volume);
        val = val % 1 !== 0 ? val.toFixed(3) : val;
        dRow[volume.day] = val;
        const formulaArray = volume.formula ? formulaToText(JSON.parse(volume.formula)): volume.formula;
        let formulaText = '';
        formulaArray && formulaArray.forEach(a => {
          formulaText += `${(typeof a === 'object' && a.text) || a}`;
        });
        dRow[`${volume.day}_formula`] = volume.formula ? formulaText : volume.formula;
        dRow[`${volume.day}_textF`] = volume.formula;
        // fetch headers from the first 'vcp' only, it is the same for all
        if (i === 0) {
          dailyHeaders.push({ field: volume.day, headerName: volume.day, formula: `${volume.day}_formula` });
        }
      }
    }
    targetArray.push(row);
    targetDailyArray.push(dRow);
  }
  return { rowData, headers, dailyRowData, dailyHeaders, rowDataVar, dailyRowDataVar };
}

export function calculateActuals(vcp) {
  const actuals = { formulas: [] };
  for (let i = 0; i < vcp.length; i++) {
    const { volumeCategory, formula, ...theRest } = vcp[i];
    const formulaText = formulaToText(JSON.parse(formula));
    actuals.formulas.push({ volumeCategory, formula, formulaText });
  }
  return actuals;
}

function calculateApHourTransitions(period, departments) {
  const departmentsById = listWithIdToMap(departments);
  const { activityParameters } = period;
  const rows = [];
  const headers = [];
  let lastDay = '';
  for (let i = 0; i < activityParameters.length; i++) {
    const ap = activityParameters[i];
    const row = {
      index: i,
      id: ap.id,
      activity: ap.activity,
      variableName: ap.variableName,
      department: departmentsById[ap.departmentId],
      uom: ap.uom,
      customerId: ap.customerId,
    };
    if (ap.hourTransitions) {
      for (const ht of ap.hourTransitions) {
        const fieldName = hourTransition => `${ht.dayOfWeek}_${hourTransition.hourOfDay}`;
        if (i === 0) {
          // build headers
          if (lastDay !== ht.dayOfWeek) {
            lastDay = ht.dayOfWeek;
            headers.push({ label: lastDay, children: [] });
          }
          const wzpHeader = { label: ht.wzpName };
          wzpHeader.children = ht.hours.map(h => ({
            label: `${h.hourOfDay}`,
            field: fieldName(h),
          }));
          headers[headers.length - 1].children.push(wzpHeader);
        }
        ht.hours.forEach(h => {
          row[fieldName(h)] = Number(h.transferRate * 100);
        });
      }
    }
    rows.push(row);
  }
  return { rowData: rows, headers };
}

export function convertActuals(apiData) {
  const headers = [];
  const rowData = [];
  for (const apiRow of apiData) {
    if (apiRow.weeklyVolumes) {
      if (headers.length === 0) {
        for (const wv of apiRow.weeklyVolumes) {
          headers.push({
            headerName: wv.startDay,
            field: `vol_${wv.startDay}`,
          });
        }
      }
      const row = {};
      for (const wv of apiRow.weeklyVolumes) {
        row[`vol_${wv.startDay}`] = wv.volume;
      }
      row.volumeCategory = apiRow.volumeCategory;
      row.uom = apiRow.uom;
      rowData.push(row);
    }
  }
  return { headers, rowData };
}

export function recalculateMheAvailabilities(period) {
  return period.mheAvailabilities.map(it => {
    const floatIT = it.maintenanceRate == "0.14" ? 14 : it.maintenanceRate == "0.28" ? 28 : it.maintenanceRate == "0.56" ? 56 : it.maintenanceRate * 100;
    const wzp = period.workZonePeriods.filter(w => w.id === it.wzpId) || [];
    return {
      ...it,
      maintenanceRate: floatIT,
      maintenanceDay: forceCapitalize(it.maintenanceDay),
      wzp: wzp.length === 1 ? wzp[0] : {},
    };
  });
}

export function recalculateLabourCategoryParameters(period) {
  return period.labourCategoryParameters.map(it => ({
    ...it,
    absenteerismRate: it.absenteerismRate * 100,
    productivity: it.productivity * 100,
    vacationRate: it.vacationRate * 100,
  }));
}

export function recalculateVolumeCategoryDayTransitions(volumeCategoryDayTransitions, volumeCategoryParameters) {
  const rows = [];
  const uomByVolCatId = {};
  if (volumeCategoryParameters) {
    volumeCategoryParameters.forEach(vcp => {
      uomByVolCatId[vcp.volumeCategory.id] = vcp.uom;
    });
  }
  for (let i = 0; i < volumeCategoryDayTransitions.length; i++) {
    const it = volumeCategoryDayTransitions[i];
    const row = {
      index: i,
      id: it.volumeCategory.id,
      volumeCategory: it.volumeCategory,
      uom: uomByVolCatId[it.volumeCategory.id],
    };
    if (it.dayTransitions) {
      for (const dt of it.dayTransitions) {
        row[dt.dayOfWeek] = Number(dt.transferRate * 100);
      }
    }
    rows.push(row);
  }
  return rows;
}

export function activitiesByPlan(plan) {
  if (!plan) return;
  const {
    planningParameters: { periods },
  } = plan;
  let result = {};
  periods.forEach(period => {
    result = period.activityParameters.reduce((current, item) => {
      current[item.activity.id] = {
        // eslint-disable-line
        id: item.activity.id,
        name: item.activity.name,
        customName: item.customName,
        variableName: item.activity.variableName,
      };
      return current;
    }, result);
  });
  return result;
}
