/**
 *
 * ListPage
 *
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import BasePage from '../../components/BasePage';
import MasterPlanColorCodeDetails from '../../components/MasterPlanColorCodeDetails';
import { DeleteDialog, formatArrayValues, withDeleteDialog } from '../../components/Dialog';
import IconButton, { DisableSelected, EnableSelected, MasterPlanReports, SmartProdRunReports } from '../../components/IconButton';
import UserViewToggleButton from '../../components/ToggleButton/UserViewToggleButton';
import withSecurity, { ROLES } from 'utils/security';
import { saveToBSAction } from '../App/actions';
import Table from '../TableControlled';
import { loadConfigFromFavAction, saveConfigToFavAction, setTableConfigAction } from '../TableControlled/actions';
import {
  changeFilterAction,
  changeViewAction,
  defaultAction,
  enableSelectedAction,
  loadListAction,
  removeSelectedAction,
  rowSelectedAction,
  masterPlanReportsRun,
  resetmasterPlanReportsRun,
  smartProdSourcesRun,
  confirmMasterPlanReportRuns,
} from './actions';
import {resetstoreShiftSchedule} from '../../containers/ShiftScheduleDetailPage/actions';
import { resetPlanDetails } from '../../containers/PlanDetailPage/actions';
import { clearPlanAction } from 'containers/MasterPlanDetailPage/actions';
import {resetPlanningArea} from 'containers/PlanningAreaDetailPage/actions';
import ButtonsWrap from './ButtonsWrap';
import { makeColumnDefs } from './colDefs';
import GridWrap from './GridWrap';
import defaultMessages from './messages';
import reducer from './reducer';
import saga from './saga';
import { makeSelectors } from './selectors';
import { DateTime } from 'luxon';
import MasterPlanMultipleReports from '../../components/MasterPlanMultipleReports';

const withConfig = config => WrappedComponent => props => <WrappedComponent config={config} {...props} />;

const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    min-width: 200px;
    text-align: center;
  }
`;

export const Loading = ({domainName, toggleFlag}) => {
  return (
    <LoadingWrap>
        <div className='loader'></div>
        {
          domainName ? <FormattedMessage {...defaultMessages[`${domainName}_${toggleFlag ? 'Enabled' : 'Disabled'}`]} /> : <FormattedMessage {...defaultMessages.defaultLoadingMessage}/>
        }
    </LoadingWrap>
  );
};

const Fill = styled.div`
  flex: 1;
`;

const LoadDeletedList = ({gridApi, domainName, messages, listAction, toggleFlag, loader}) => {
    const icon = domainName === 'planListPage' ? 'chart-line' : domainName === 'areaListPage' ? 'building' :  domainName === 'shiftScheduleListPage' ? 'calendar-alt' : domainName === 'masterPlanListPage' ? 'object-group' : 'user-clock';
    return(
      <IconButton
        id={domainName}
        icon={icon}
        message={messages[`${domainName}_${!toggleFlag ? 'Enabled' : 'Disabled'}`]}
        onClick = {()=>listAction()}
        disabled={!gridApi || loader}
      />
    )
}

const makeListPage = config => {
  const {
    domainName,
    defaultTableConfig,
    permissionAdd,
    permissionDisable,
    permissionSaveConfig,
    permissionDisableData,
    permissionViewDetail,
    pathToDetail,
    pathToCreate,
    messages,
    exportToExcel,
  } = config;

  const { makeSelectData, makeSelectSelectedRows, makeSelectDomain, selectLoaderFlag } = makeSelectors(config);
  const madeColDefs = makeColumnDefs(config);

  /* eslint-disable react/prefer-stateless-function */
  class ListPage extends React.PureComponent {
    state = { gridApi: null, toggleFlag: true };

    componentDidMount() {
      let payloadFlag = false;
      const isRowDisbaled = this.props && this.props.location && this.props.location && this.props.location.state && this.props.location.state.disabled;
      if(isRowDisbaled !== undefined){
        payloadFlag = isRowDisbaled;
      }
      this.props.defaultAction(this.props.match.params, true);
      this.props.loadListAction(payloadFlag);
      this.setState({toggleFlag: !payloadFlag})
    }

    componentWillUnmount() {
      if(domainName === 'shiftScheduleListPage'){
      this.props.resetstoreShiftSchedule('')
      }
      if(domainName === 'planListPage'){
        this.props.resetPlanDetails()
      }
      if(domainName === 'areaListPage'){
        this.props.resetPlanningArea()
      }
      if(domainName === 'masterPlanListPage'){
        this.props.clearPlanAction()
      }
      this.props.defaultAction({ reset: 'false' }, false);
    }

    onGridReady = params => {
      if (config.onGridReady) config.onGridReady(params, this.props);
      this.setState({ gridApi: params });
    };

    handleCloseModal = () => {
      this.props.resetmasterPlanReportsRun();
    };

    confirmModal = (start,end)=>{
      this.props.confirmMasterPlanReportRuns(start, end);
    };

    handleToggleListAction = () => {
      if(this.state.gridApi){
        this.state.gridApi.api.setFilterModel(null);
        this.state.gridApi.api.onFilterChanged();
      }
      this.setState(prevState=> ({
        toggleFlag: !prevState.toggleFlag
      }), ()=>this.props.loadListAction(!this.state.toggleFlag))
      
    }
    
    render() {
      const {
        intl,
        data,
        hasPerm,
        hasOneOfPerm,
        domain,
        user,
        match: {
          params: { reset },
        },
        loader,
      } = this.props;
      const { gridApi } = this.state;
      const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
      const selectedEnabled = this.props.selectedRows.find(item => !item.deleted) !== undefined;
      const selectedDisabled = this.props.selectedRows.find(item => item.deleted) !== undefined;
      const colDefs = madeColDefs(this.props);
      const isReady = data !== false && domain && domain.enabled;
      const masterPlanTableStyle =(validTo)=>{
        if(!validTo || (new Date(validTo) < new Date(DateTime.now().minus({days:1})))){
          return 'past-row';
        }else if(new Date(validTo) <= new Date(DateTime.now().plus({days:40}))){
          return 'expiry-row';
        }else {
          return 'future-row';
        }
      }
      return (
        <BasePage labelMessage={messages.header} noMaxWidth>
          <Helmet>
            <title>{intl.formatMessage(messages.header)}</title>
            <meta name="description" content={intl.formatMessage(messages.content)} />
          </Helmet>
          {hasOneOfPerm([permissionAdd, permissionSaveConfig]) && (
            <ButtonsWrap>
              {hasPerm(permissionAdd) && (
                <IconButton
                  id="list-page-add"
                  icon="plus-circle"
                  message={messages.add}
                  onClick={() => {
                    this.props.history.push(pathToCreate);
                  }}
                />
              )}
              <div>
                {exportToExcel && (
                  <IconButton
                    icon="file-export"
                    id="file-export"
                    disabled={!gridApi}
                    tooltip={defaultMessages.excelExport}
                    onClick={() => {
                      if (!gridApi) return;
                      const columnKeys = gridApi.columnApi
                        .getAllDisplayedColumns()
                        .map(a => a.colId)
                        .filter(c => c !== '0' && c !== 'action');
                      gridApi.api.exportDataAsExcel({ columnKeys });
                    }}
                  />
                )}
                <LoadDeletedList gridApi={gridApi} domainName={domainName} messages={messages} listAction={this.handleToggleListAction} toggleFlag={this.state.toggleFlag} loader={loader}/>
                {hasPerm(permissionSaveConfig) && <UserViewToggleButton />}
              </div>
            </ButtonsWrap>
          )}
         {domainName === "masterPlanListPage" && <MasterPlanColorCodeDetails/>}
          <GridWrap>
            { loader ? <Loading domainName={domainName} toggleFlag={this.state.toggleFlag}/> :
            (isReady && (
              <Table
                key={`tableKeyByReset_${reset}_${this.props.domain.version}`}
                getRowClass={params => {
                  const classes = [];
                  if (params.data.deleted) {
                    classes.push('deleted-row');
                  }
                  if(params.data.pageName === "Master Plan"){
                    classes.push(masterPlanTableStyle(params.data.validTo))
                  }
                  return classes.join(' ');
                }}
                messages={messages}
                name={domainName}
                defaultConfig={defaultTableConfig}
                getRowHeight={params => {
                  const { facilities, customers } = params.data;
                  const facilitiesLength = (facilities || []).length;
                  const customersLength = (customers || []).length;
                  const rows = facilitiesLength > customersLength ? facilitiesLength : customersLength;
                  return (rows < 3 ? 3 : rows) * 26;
                }}
                isRowSelectable={rowNode => this.props.hasPerm(permissionDisableData) || rowNode.data.editable}
                onDetail={detail =>
                  this.props.hasPerm(permissionViewDetail) &&
                  this.props.history.push(pathToDetail.replace(':id', detail.data.id))
                }
                onRowDoubleClicked={detail =>
                  this.props.hasPerm(permissionViewDetail) &&
                  this.props.history.push(pathToDetail.replace(':id', detail.data.id))
                }
                rowData={data}
                excelStyles={[
                  {
                    id: 'dateType',
                    dataType: 'dateTime',
                  },
                ]}
                columnDefs={colDefs}
                onRowSelected={this.props.rowSelectedAction}
                onGridReady={this.onGridReady}
                rowSelection="multiple"
                suppressContextMenu
              />
            ))
              }
          </GridWrap>
          {this.props.hasOneOfPerm(permissionDisable) && (
            <ButtonsWrap>
              {this.props.hasPerm(permissionDisable) && (
                <DisableSelected
                  onClick={() => {
                    this.setState(state => ({ ...state, dialog: 'disabled' }));
                    this.props.openDeleteDialog(this.props.removeSelectedAction, undefined, this.props.selectedRows);
                  }}
                  disabled={!selectedEnabled}
                />
              )}
              { 
                domainName === "masterPlanListPage" && isAdmin &&
                <MasterPlanReports
                  disabled={!selectedEnabled}
                  onClick={() => {this.props.masterPlanReportsRun(this.props.selectedRows)}}
                />
              }
              {
                domainName === "smartProdSourceListPage" &&
                <SmartProdRunReports
                  disabled={!selectedEnabled}
                  onClick={() => {this.props.smartProdSourcesRun(this.props.selectedRows)}}
                />
              }
              {this.props.hasPerm(permissionDisable) && (
                <EnableSelected
                  onClick={() => {
                    this.setState(state => ({ ...state, dialog: 'enabled' }));
                    this.props.openDeleteDialog(this.props.enableSelectedAction, undefined, this.props.selectedRows);
                  }}
                  disabled={!selectedDisabled}
                />
              )}
            </ButtonsWrap>
          )}
          <DeleteDialog
            {...this.props}
            text={this.state.dialog === 'enabled' ? messages.enableConfirmText : messages.deleteConfirmText}
            formatText={formatArrayValues}
            confirmLabel={this.state.dialog === 'enabled' ? messages.enableConfirmButton : messages.disableConfirmButton}
            title={this.state.dialog === 'enabled' ? messages.enableTitle : messages.disableTitle}
          />
          <MasterPlanMultipleReports
            modalFlag={this.props.domain.masterPlanReportRunsFlag}
            resetModal={this.handleCloseModal}
            confirmModal={this.confirmModal}
          />
        </BasePage>
      );
    }
  }

  ListPage.propTypes = {
    intl: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    selectedRows: PropTypes.array,
    changeFilterAction: PropTypes.func,
    loadConfigFromFavAction: PropTypes.func,
    saveConfigToFavAction: PropTypes.func,
    openDeleteDialog: PropTypes.func,
    rowSelectedAction: PropTypes.func,
    hasOneOfPerm: PropTypes.func,
    removeSelectedAction: PropTypes.func,
    enableSelectedAction: PropTypes.func,
    changeViewAction: PropTypes.func,
    saveToBSAction: PropTypes.func,
    defaultAction: PropTypes.func,
    hasPerm: PropTypes.func,
    setTableConfigAction: PropTypes.func,
    loadListAction: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    domain: PropTypes.object,
    config: PropTypes.object,
  };

  const mapStateToProps = createStructuredSelector({
    domain: makeSelectDomain(),
    data: makeSelectData(),
    selectedRows: makeSelectSelectedRows(),
    loader: selectLoaderFlag(),
  });

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        saveToBSAction,
        loadListAction,
        removeSelectedAction,
        defaultAction,
        rowSelectedAction,
        enableSelectedAction,
        changeFilterAction,
        changeViewAction,
        loadConfigFromFavAction,
        setTableConfigAction,
        saveConfigToFavAction,
        masterPlanReportsRun,
        smartProdSourcesRun,
        resetmasterPlanReportsRun,
        confirmMasterPlanReportRuns,
        resetstoreShiftSchedule,
        resetPlanDetails,
        clearPlanAction,
        resetPlanningArea,
      },
      dispatch,
    );
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const withReducer = injectReducer({ key: domainName, reducer: reducer(config) });
  const withSaga = injectSaga({ key: domainName, saga: saga(config) });

  return compose(
    withConfig(config),
    withReducer,
    withSaga,
    withConnect,
    injectIntl,
    withDeleteDialog,
    withSecurity(),
  )(ListPage);
};

export default makeListPage;
