import { Component } from 'react';
import * as React from 'react';
import { Field, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import FormikDatePicker from 'components/FormikDatePicker';
import { FormikText, InlineLabel } from 'components/FormikTextInput';
import IconButton from 'components/IconButton';
import ReactSelect from 'components/StyledSelect';
import { Plan } from 'containers/PlanResultPage/calculation/types';
import { ShiftSchedulePlanTabs } from 'containers/Tabs';
import { pushToFormikArray, replaceFormikArrayAtIndex } from 'utils/utils';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import { storePeriodIndexShiftScheduleAction } from './actions';
import messages from './messages';
import { selectAvailablePlans, selectShiftScheduleIndex } from './selectors';

const ButtonLimitedWidth = styled(IconButton)`
  width: 98px;
`;

const LongTextWrapper = styled(FormikText)`
  width: 220px;
`;

const Select = styled(ReactSelect)`
  max-width: 300px;
`;

const PlanSectionWrapper = styled.div`
  min-width: 750px;
  min-height: 100px;
`;

const PlanSectionInnerWrapper = styled.div`
  width: 725px;
  margin: 10px 0;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 128px 220px 128px 220px;
  margin: 0 0 12px;
  column-gap: 10px;
`;

interface PlanTabsProps {
  intl: any;
  formik: FormikProps<ApiScheduleDTO>;
  isEdit: boolean;
  selectedPlanIndex: number;
  availablePlans: Plan[];

  storePeriodIndexShiftScheduleAction(action: number): () => any;
}

class PlanTabs extends Component<PlanTabsProps, {}> {
  public shouldComponentUpdate(nextProps: Readonly<PlanTabsProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    const current = this.props;
    return (
      current.isEdit !== nextProps.isEdit ||
      current.selectedPlanIndex !== nextProps.selectedPlanIndex ||
      !isEqual(current.availablePlans, nextProps.availablePlans) ||
      !isEqual(current.formik.values.plans, nextProps.formik.values.plans)
    );
  }

  public render() {
    const { selectedPlanIndex, formik, isEdit } = this.props;

    if (formik.values.plans.length === 0 && !isEdit) {
      return null;
    }

    if (selectedPlanIndex >= formik.values.plans.length) {
      this.props.storePeriodIndexShiftScheduleAction(0);
    }

    const selectedPlan = formik.values.plans[selectedPlanIndex];
    const alreadyAssignedPlanIds = formik.values.plans.map(p => p.planId);

    let selectedPlanSection;

    if (selectedPlan) {
      if (selectedPlan.plan) {
        selectedPlanSection = this.renderSinglePlanTab(selectedPlan, selectedPlanIndex);
      } else {
        const options = (this.props.availablePlans || [])
          .filter(p => alreadyAssignedPlanIds.indexOf(p.id) === -1)
          .map(p => ({ value: p, label: p.name }));

        const planSelectionHandler = selected => {
          replaceFormikArrayAtIndex(
            formik,
            'plans',
            {
              plan: selected.value,
              planId: selected.value.id,
              validFrom: DateTime.local(),
              validTo: null,
            },
            selectedPlanIndex,
          );
        };
        selectedPlanSection = (
          <PlanSectionWrapper>
            <InlineLabel {...messages.name} />

            <Field
              name={`plans[${selectedPlanIndex}]`}
              component={Select}
              options={options}
              onChange={planSelectionHandler}
            />
          </PlanSectionWrapper>
        );
      }
    } else {
      selectedPlanSection = <PlanSectionWrapper />;
    }

    return (
      <ShiftSchedulePlanTabs
        tabs={formik.values.plans}
        tabHeader={this.planTabHeader}
        addTabAction={this.addNewPlanHandler(formik)}
      >
        {selectedPlanSection}
      </ShiftSchedulePlanTabs>
    );
  }

  private renderSinglePlanTab(selectedPlan, index) {
    const { plan } = selectedPlan;
    const planningArea = plan.planningArea || {};
    const country = planningArea.country || {};
    const customers = (planningArea.customers || []).map(c => `${c.code} - ${c.name}`).join(', ');
    const facilities = (planningArea.facilities || []).map(c => `${c.code} - ${c.name}`).join(', ');

    const planningParameters = plan.planningParameters || {};
    const { timezone } = planningParameters;
    const weekStartDay = planningParameters.firstDayOfWeek;
    const { hoursInDayFTE } = planningParameters;

    const deactivatePlan = () =>
      this.props.formik.setFieldValue(
        `plans[${index}].validTo`,
        selectedPlan.validFrom < DateTime.local() ? DateTime.local() : selectedPlan.validFrom,
      );

    const canBeDeactivated =
      this.props.isEdit &&
      (!selectedPlan.validTo ||
        (selectedPlan.validTo > selectedPlan.validFrom && selectedPlan.validTo > DateTime.local()));

    const deactivatePlanButton = canBeDeactivated && (
      <ButtonLimitedWidth id="deactivate" message={messages.deactivatePlan} onClick={deactivatePlan} />
    );

    const planLink = (plan) => {
      const url = `/plan/${plan?.id}`
      return (
        <a href={url} target="_blank">
        {plan?.name}
      </a>
      )
    }

    return (
      <PlanSectionWrapper>
        <GridRow>
          <InlineLabel {...messages.name} />
          <LongTextWrapper>{planLink(plan)}</LongTextWrapper>
          <span />
          {deactivatePlanButton}
        </GridRow>

        <PlanSectionInnerWrapper>
          <GridRow>
            <InlineLabel {...messages.validFrom} />
            <FormikText>
              <Field
                name={`plans[${index}].validFrom`}
                component={FormikDatePicker}
                timePicker={false}
                editable={this.props.isEdit}
                okLabel="OK"
              />
            </FormikText>
            <InlineLabel {...messages.validTo} />
            <FormikText>
              <Field
                name={`plans[${index}].validTo`}
                minDate={selectedPlan.validFrom}
                timePicker={false}
                component={FormikDatePicker}
                clearable
                editable={this.props.isEdit}
                defaultValue={null}
                okLabel="OK"
              />
            </FormikText>
          </GridRow>
        </PlanSectionInnerWrapper>

        <PlanSectionInnerWrapper>
          <GridRow>
            <InlineLabel {...messages.description} />
            <FormikText>{plan.description}</FormikText>

            <InlineLabel {...messages.customers} />
            <LongTextWrapper>{customers}</LongTextWrapper>
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.planningArea} />
            <FormikText>{planningArea.name}</FormikText>

            <InlineLabel {...messages.facilities} />
            <LongTextWrapper>{facilities}</LongTextWrapper>
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.region} />
            <FormikText>{country.regionName}</FormikText>

            <InlineLabel {...messages.weekStartDay} />
            <FormikText>{weekStartDay}</FormikText>
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.country} />
            <FormikText>{country.name}</FormikText>

            <InlineLabel {...messages.workTime} />
            <FormikText>{hoursInDayFTE}</FormikText>
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.sector} />
            <FormikText>{planningArea.sector && planningArea.sector.name}</FormikText>

            <InlineLabel {...messages.timezone} />
            <FormikText>{timezone && timezone.code}</FormikText>
          </GridRow>
        </PlanSectionInnerWrapper>
      </PlanSectionWrapper>
    );
  }

  private planTabHeader = plan => <span key="title">{(plan.plan && plan.plan.name) || 'New plan'}</span>;

  private addNewPlanHandler = formik => () => {
    if (formik.values.plans.every(p => p.plan)) {
      this.props.storePeriodIndexShiftScheduleAction(formik.values.plans.length);
      pushToFormikArray(formik, 'plans', {});
    } else {
      this.props.storePeriodIndexShiftScheduleAction(formik.values.plans.length - 1);
    }
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storePeriodIndexShiftScheduleAction,
    },
    dispatch,
  );
}

const mapStateToProps = createStructuredSelector({
  availablePlans: selectAvailablePlans,
  selectedPlanIndex: selectShiftScheduleIndex,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
  // @ts-ignore
)(PlanTabs);
