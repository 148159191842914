/*
 * ResultMatrixActivitiesTable Messages
 *
 * This contains all the text for the ResultMatrixActivitiesTable component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  activities: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.activities',
    defaultMessage: 'Activities',
  },
  month: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.month',
    defaultMessage: 'Month',
  },
  mhe: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.mhe',
    defaultMessage: 'Material Handling Equipment',
  },
  activity: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  colProductivityRate: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  uom: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.uom',
    defaultMessage: 'UoM',
  },
  uomPerHour: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.uomPerHour',
    defaultMessage: 'UoM/h',
  },
  week: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.week',
    defaultMessage: 'Week',
  },
  colVolume: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colVolume',
    defaultMessage: 'Volume',
  },
  colEffort: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colEffort',
    defaultMessage: 'Effort h',
  },
  colFte: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colFte',
    defaultMessage: 'FTE MD',
  },
  result: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.result',
    defaultMessage: 'Result',
  },
  colSumVolume: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colSumVolume',
    defaultMessage: 'Sum Volume',
  },
  colSumEffort: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colSumEffort',
    defaultMessage: 'Sum Effort h',
  },
  colSumFte: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.colSumFte',
    defaultMessage: 'Sum FTE MD',
  },
  sum: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.sum',
    defaultMessage: 'Sum',
  },
  hWeek: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.hWeek',
    defaultMessage: 'h/Week',
  },
  hDay: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.hDay',
    defaultMessage: 'h/day',
  },
  hWzp: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.hWzp',
    defaultMessage: 'h/wzp',
  },
  hShift: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.hShift',
    defaultMessage: 'h/shift',
  },
  h: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.h',
    defaultMessage: 'h/h',
  },
  mheName: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.mheName',
    defaultMessage: 'MHE Name',
  },
  export: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.export',
    defaultMessage: 'Export',
  },
  mheResult: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.mheResult',
    defaultMessage: 'Total',
  },
  emptyTable: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.emptyTable',
    defaultMessage: 'Please confirm selection to see data.',
  },
  loading: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.loading',
    defaultMessage: 'Loading',
  },
  department: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.department',
    defaultMessage: 'Department',
  },
  customer: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.customer',
    defaultMessage: 'Customer',
  },
  all: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.all',
    defaultMessage: 'All',
  },
  allDepartments: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.allDepartments',
    defaultMessage: 'All Departments',
  },
  demand: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.demand',
    defaultMessage: 'Demand',
  },
  discrepancy: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.discrepancy',
    defaultMessage: 'Discrepancy',
  },
  day: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.day',
    defaultMessage: 'Day',
  },
  hour: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.hour',
    defaultMessage: 'Hour',
  },
  shift: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.shift',
    defaultMessage: 'Shift',
  },
  wzp: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.wzp',
    defaultMessage: 'Wzp',
  },
  laborDemandSum: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.laborDemandSum',
    defaultMessage: 'Labor Demand Sum h',
  },

  type_budget: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.budget',
    defaultMessage: 'Baseline',
  },
  type_planned: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.planned',
    defaultMessage: 'Planned',
  },
  type_actuals: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.actuals',
    defaultMessage: 'Actuals',
  },
  type_forecast: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.forecast',
    defaultMessage: 'Forecast',
  },
  fullValue: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.fullValue',
    defaultMessage: 'Full Value',
  },
  role: {
    id: 'app.components.ResultMatrixMHETableNewDataModel.role',
    defaultMessage: 'Role',
  }
});
