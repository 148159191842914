const beEnv = process.env.BE_ENV || 'fat';

const fallBackProtocol = 'https';
const fallBackHost = `smp-${beEnv}.apps.czchooct002.dhl.com`;
// const fallBackHost = 'smp-uat1.apps.czchooct002.dhl.com';
const fallBackPowerBIProtocol = 'https';
const fallBackPowerBIHost = 'app.powerbi.com';
const fallBackPowerBiURL = `${fallBackPowerBIProtocol}://${fallBackPowerBIHost}/reportEmbed?reportId=c3a812fc-dc32-4ffa-9306-52b14dc4eca3&appId=720f5c25-dcc1-484b-9eb0-ac5dbfe0a99c&autoAuth=true&ctid=cd99fef8-1cd3-4a2a-9bdf-15531181d65e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1nLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`;

const fallBackEnv = {
  basePathBe: beEnv === 'localhost' ? `http://localhost:8080/api` : `${fallBackProtocol}://${fallBackHost}/smp/api`,
  changePasswordPage: `${fallBackProtocol}://${fallBackHost}/um/app/sec/ChangePasswordPage?id={login}&backUrl=${fallBackProtocol}://${fallBackHost}/`,
  ribbonColor: '#43a',
  label: `LH-${beEnv}`,
  shiftFillingDisabled: false,
  powerBIScheduleURL: fallBackPowerBiURL,
  umUrl: 'https://dsc-um-uat.dhl.com/um/',
  azureRedirect: 'http://localhost:3000/',
};

function getActiveEnv() {
  try {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    const injectedEnvironment = INJECTED_ENVIRONMENT || {};
    if (injectedEnvironment && injectedEnvironment.basePathBe && injectedEnvironment.label) {
      return injectedEnvironment;
    }
  } catch (e) {
    console.warn('Environment not injected, defaulting to FAT', e);
  }
  return fallBackEnv;
}

console.log('Target ENV is ', getActiveEnv().label);
export const ACTIVE_ENV = getActiveEnv();
