import DeleteCellRenderer from 'components/DeleteCellRenderer';
import NameWithToolTip from 'components/NameWithToolTip';
import { numericSetter, currencyCellFormatter, percentageCellFormatter } from 'utils/utils';
import isNil from 'lodash/isNil';

export default function createDirectColumnDef(props, messages) {
  const editable = props.edit;
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.category),
      width: 160,
      field: 'labourCategory.name',
      colId: 'labourCategory',
      cellRendererFramework: NameWithToolTip('labourCategory.regionalConfigurationName'),
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.productivity),
      field: 'productivity',
      colId: 'productivity',
      type: 'numericColumn',
      menuTabs: ['filterMenuTab'],
      valueFormatter: percentageCellFormatter,
      valueSetter: numericSetter,
      editable,
    },
    {
      headerName: props.intl.formatMessage(messages.absenteerism),
      field: 'absenteerismRate',
      colId: 'absenteerismRate',
      type: 'numericColumn',
      menuTabs: ['filterMenuTab'],
      valueFormatter: percentageCellFormatter,
      valueSetter: numericSetter,
      editable,
    },
    {
      headerName: props.intl.formatMessage(messages.vacation),
      field: 'vacationRate',
      colId: 'vacationRate',
      type: 'numericColumn',
      valueFormatter: percentageCellFormatter,
      valueSetter: numericSetter,
      menuTabs: ['filterMenuTab'],
      editable,
    },
    {
      headerName: props.intl.formatMessage(messages.costPerHour),
      field: 'cost',
      colId: 'cost',
      type: 'numericColumn',
      valueFormatter: currencyCellFormatter,
      valueSetter: numericSetter,
      menuTabs: ['filterMenuTab'],
      width: 160,
      editable,
    },
  ];
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        disabled: rowData => rowData.used,
        onDelete: (payload, cellProps) => {
          props.openDeleteDialog(
            props.deleteLine,
            {
              payload,
              rowIndex: cellProps.rowIndex,
            },
            {
              categoryName: payload.labourCategory.name,
            },
          );
        },
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
    });
  }
  return columnDefs;
}
