import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { getIn } from 'formik';
import { CategoryWithVariableGetter } from '../ListGrid/utils';
import DateTime from 'luxon/src/datetime.js';
import NameWithToolTip, { UOMWithToolTip, uomFilterParams } from '../NameWithToolTip';
import {
  DOW_TRANS_ACTIVITY_AKA_WEEKLY,
  DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY,
  VOLUME_GRANULARITY_DAILY,
  VOLUME_GRANULARITY_WEEKLY,
} from '../../utils/constants';
import { parseDateAndFormatToLocalString } from '../../utils/dateTime';
import { numberCellFormatter, numericSetter, numericSetterVolumes } from '../../utils/utils';
import FormulaRenderer from 'components/ActivitySettingsDirect/FormulaRenderer';

export const createDeleteColumn = ({ props, messages, onDelete }) => ({
  headerName: props.intl.formatMessage(messages.action),
  field: 'delete',
  colId: 'delete',
  suppressMovable: true,
  cellRendererFramework: DeleteCellRenderer,
  width: 80,
  cellRendererParams: { onDelete },
  sortable: false,
  suppressMenu: true,
  pinned: true,
});

export const createCategoryColumn = ({ props, categoryMessage }) => ({
  headerName: props.intl.formatMessage(categoryMessage),
  valueGetter: CategoryWithVariableGetter,
  cellRendererFramework: NameWithToolTip('volumeCategory.regionalConfigurationName'),
  width: 160,
  field: 'category',
  colId: 'category',
  suppressMovable: true,
  pinned: true,
  menuTabs: ['filterMenuTab'],
});

export const createUomColumn = ({ props, messages }) => ({
  headerName: props.intl.formatMessage(messages.uom),
  field: 'uom',
  colId: 'uom',
  editable: props.edit,
  cellEditor: 'agRichSelectCellEditor',
  cellRendererFramework: UOMWithToolTip,
  valueFormatter: params => params.value && `${params.value.name} / ${params.value.regionalConfigurationName}`,
  cellEditorParams: {
    values: props.uoms,
  },
  suppressMovable: true,
  width: 160,
  pinned: true,
  menuTabs: ['filterMenuTab'],
});

export const createDefaultFormula = ({ props, messages, editable }) => ({
  headerName: props.intl.formatMessage(messages.defaultFormula),
  field: 'defaultFormula',
  colId: 'defaultFormula',
  menuTabs: ['filterMenuTab'],
  width: 160,
  sortable: false,
  editable: editable,
  cellRendererFramework: FormulaRenderer,
  suppressMenu: true,
  suppressMovable: true,
});

export const createStandardVolumeColumnDefs = (props, messages, categoryMessage, planningParametersField, daily) => {
  const editable = props.edit;
  const columnDefs = [];
  columnDefs.push(createCategoryColumn({ props, categoryMessage }));
  columnDefs.push(createUomColumn({ props, messages }));
  props.resultPage !== true && columnDefs.push(createDefaultFormula({ props, messages, editable }));

  const title = props.intl.formatMessage((daily && messages.day) || messages.week);
  const headersKey = (daily && 'dailyHeaders') || 'headers';
  const headers = getIn(props.formik.values, `planningParameters.${planningParametersField}.${headersKey}`);
  for (const header of headers) {
    const valueColumn =  {
      headerName: props.resultPage === true ? parseDateAndFormatToLocalString(header.headerName) :props.intl.formatMessage(messages.value),
      field: header.field,
      colId: header.field,
      width: 120,
      suppressMovable: true,
      cellClass: (editable && 'cell-editable') || '',
      valueSetter: numericSetterVolumes,
      valueFormatter: numberCellFormatter,
      editable,
    };
    const formulaColumn = {
      headerName: props.intl.formatMessage(messages.reverseFormula),
      field: header.formula,
      colId: header.formula,
      width: 150,
      suppressMovable: true,
      cellClass: (editable && 'cell-editable') || '',
      cellRendererFramework: FormulaRenderer,
      editable,
    };
    const columnsToDisplay = [];

    if(props.showValue) columnsToDisplay.push(valueColumn);
    if(props.showFormula && props.resultPage !== true) columnsToDisplay.push(formulaColumn);
    columnDefs.push({
      headerName: props.resultPage === true ? title : `${header.headerName === 'Default' ? '' : title} ${parseDateAndFormatToLocalString(header.headerName)}`,
      children: columnsToDisplay,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
    });
  }
  return columnDefs;
};

export const createStandardActualVolumeColumnDefs = (props, messages, categoryMessage, planningParametersField, daily) => {
  const editable = props.edit;
  const columnDefs = [];
  columnDefs.push(createCategoryColumn({ props, categoryMessage }));
  columnDefs.push(createUomColumn({ props, messages }));

  const title = props.intl.formatMessage((daily && messages.day) || messages.week);
  const headersKey = (daily && 'dailyHeaders') || 'headers';
  const headers = getIn(props.formik.values, `planningParameters.${planningParametersField}.${headersKey}`);
  for (const header of headers) {
    columnDefs.push({
      headerName: title,
      children: [
        {
          headerName: parseDateAndFormatToLocalString(header.headerName),
          field: header.field,
          colId: header.field,
          suppressMovable: true,
          cellClass: (editable && 'cell-editable') || '',
          valueSetter: numericSetterVolumes,
          valueFormatter: numberCellFormatter,
          editable,
        },
      ],
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
    });
  }
  return columnDefs;
};

export const createStandardVolumeColumnDefsVar = (props, messages, categoryMessage, planningParametersField, daily) => {

  const editable = props.edit;
  const columnDefs = [];
  columnDefs.push(createCategoryColumn({ props, categoryMessage }));
  columnDefs.push(createUomColumn({ props, messages }));
  props.resultPage !== true && columnDefs.push(createDefaultFormula({ props, messages, editable }));

  const title = props.intl.formatMessage((daily && messages.day) || messages.week);
  const headersKey = (daily && 'dailyHeaders') || 'headers';
  const headers = getIn(props.formik.values, `planningParameters.${planningParametersField}.${headersKey}`);
  for (const header of headers) {
    const valueColumnVar =  {
      headerName: props.resultPage === true ? parseDateAndFormatToLocalString(header.headerName):props.intl.formatMessage(messages.value),
      field: header.field,
      colId: header.field,
      width: 120,
      suppressMovable: true,
      cellClass: (editable && 'cell-editable') || '',
      valueSetter: numericSetterVolumes,
      valueFormatter: numberCellFormatter,
      editable,
    };
    const formulaColumnVar = {
      headerName: props.intl.formatMessage(messages.reverseFormula),
      field: header.formula,
      colId: header.formula,
      width: 150,
      suppressMovable: true,
      cellClass: (editable && 'cell-editable') || '',
      cellRendererFramework: FormulaRenderer,
      editable,
    };
    const columnsToDisplay = [];

    if(props.showValueVar) columnsToDisplay.push(valueColumnVar);
    if(props.showFormulaVar && props.resultPage !== true) columnsToDisplay.push(formulaColumnVar);
    columnDefs.push({
      headerName: props.resultPage === true ? title : `${header.headerName === 'Default' ? '' : title} ${parseDateAndFormatToLocalString(header.headerName)}`,
      children: columnsToDisplay,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
    });
  }
  return columnDefs;
};

export const isDaily = props =>
  getIn(props, 'formik.values.planningParameters.dayTransformationType', DOW_TRANS_ACTIVITY_AKA_WEEKLY) ===
    DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY &&
  getIn(props, 'formik.values.planningParameters.volumeGranularity', VOLUME_GRANULARITY_WEEKLY) ===
    VOLUME_GRANULARITY_DAILY;
