import * as React from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { injectIntl, InjectedIntl } from 'react-intl';
import styled from 'styled-components';
import messages from './messages';
import { toNumber, numberFormat } from 'utils/utils';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';
import { compose } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import AgTable from 'components/Table';
import { GroupWithToolTip } from 'components/NameWithToolTip';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { camelCase, startCase } from 'lodash';
import { calculateSmartProdActivityTableData } from './utils';

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;

  .cell-emptyAdjustment {
    background-color: lightgray;
    border-right-color: coral;
  }
`;

type Props = {
  intl: InjectedIntl;
  smartProdActivityAdjustments: any;
  planDetails: any;
  response: any;
}

const SmartProdActivityAdjustments: React.FC<Props> = ({ intl, smartProdActivityAdjustments, planDetails, response }) => {

  function calculateTableData(data) {
    const colDefs: (ColDef | ColGroupDef)[] = [
      {
        colId: 'source',
        field: 'source',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.source),
        headerTooltip: intl.formatMessage(messages.source),
        cellRenderer: 'agGroupCellRenderer',
        tooltipValueGetter : params => params.value,
        sortable: true,
        width: 100,
        suppressSizeToFit: true,
        pinned: 'left',
        rowGroup: true,
        hide: true,
        resizable: true
      },
      {
        colId: 'omsName',
        field: 'omsName',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.jobCodeOmsName),
        headerTooltip: intl.formatMessage(messages.jobCodeOmsName),
        tooltipValueGetter : params => params.value,
        cellRenderer: 'agGroupCellRenderer',
        width: 250,
        sortable: true,
        suppressSizeToFit: true,
        pinned: 'left',
        rowGroup: false,
        hide: true,
        resizable: true
      },
      {
        colId: 'activity',
        field: 'activity',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.activity),
        headerTooltip: intl.formatMessage(messages.activity),
        tooltipValueGetter : params => params.value,
        width: 250,
        sortable: true,
        suppressSizeToFit: true,
        pinned: true,
        resizable: true
      },
      {
        colId: 'activityMapping',
        field: 'activityMapping',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.activityMapping),
        headerTooltip: intl.formatMessage(messages.activityMapping),
        tooltipValueGetter : params => params.value,
        width: 250,
        sortable: true,
        suppressSizeToFit: true,
        pinned: true,
        resizable: true
      },
    ];
    const { rowData, days } = data;
    const sortedDays = [...days].sort();
    sortedDays.forEach(day => {
      var d = new Date(day);
      var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
      var dayName = days[d.getDay()];
      const dayOfWeek = startCase(camelCase(dayName.substring(0, 3)));
      colDefs.push({
        headerName: `${dayOfWeek} ${parseDateAndFormatToLocalString(day)}`,
        headerTooltip: `${dayOfWeek} ${parseDateAndFormatToLocalString(day)}`,
        children: [
          {
            sortable: false,
            suppressMenu: true,
            suppressMovable: true,
            resizable: true,
            colId: day,
            headerName: intl.formatMessage(messages.adjsutment),
            headerTooltip: intl.formatMessage(messages.adjsutment),
            width: 90,
            minWidth: 90,
            pinned: false,
            valueGetter: params => {
              const row = params && params.data && params?.data[day];
              return row?.rate;
            },
            cellClass: params => {
              const row = params && params.data && params?.data[day];
              if(params.data === undefined){
                return ''
              }
              if((row === undefined || row.rate === undefined)){
                return 'cell-emptyAdjustment'
              }
            },
            valueFormatter: params =>
              // eslint-disable-next-line no-restricted-globals
              params.value === null || params.value === undefined || params.value === '' || isNaN(params.value)
                ? ''
                : `${numberFormat(toNumber(params.value).toFixed(2))}%`,
          },
        ],
      });
    });
    return { colDefs, rowData: cloneDeep(rowData) };
  }

  const plan =  planDetails.planningArea || planDetails;
  const departments = response?.planningParameters?.departments;
  const activityParamaterList = response.planningParameters?.periods?.map((data) => data.activityParameters).flat();
  const calculatedData = calculateSmartProdActivityTableData(smartProdActivityAdjustments, plan, departments, activityParamaterList);
  const tableData = calculateTableData(calculatedData);
  const ROW_HEIGHT = 35;

  return (
    <div>
      <div>
        <TableWithHeight
          name="SmartProdActivityAdjustment"
          defaultColDef={{
            flex: 1,
          }}
          frameworkComponents={{
            acWithToolTip: GroupWithToolTip(),
            groupFilter: GroupFilter,
          }}
          sortable
          pagination={false}
          applyColumnDefOrder
          columnDefs={tableData.colDefs}
          rowData={tableData.rowData}
          height={150 + ROW_HEIGHT * Math.min(10, tableData.rowData.length)}
          groupDefaultExpanded={1}
          showCollapseButtonsInsideTable={tableData.rowData.length ? true : false}
          autoGroupColumnDef={{
            colId: 'groupping',
            headerName: `${intl.formatMessage(messages.source)} \u2192 ${intl.formatMessage(
              messages.omsCodesTableOMSCode,
            )}`,
            field: 'omsName',
            width: 220,
            suppressSizeToFit: true,
            pinned: true,
            resizable: true,
            cellRenderer: 'agGroupCellRenderer',
            tooltipValueGetter : params => params.value,
            menuTabs: ['filterMenuTab'],
            filter: 'groupFilter',
            filterParams: {
              columns: ['source', 'omsName'],
              applyButton: true,
              clearButton: true,
            },
          }}
        />
      </div>
    </div>
  );
}

export default compose(injectIntl)(SmartProdActivityAdjustments);
