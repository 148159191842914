import React, { useCallback } from 'react';
import { connect as formikConnect, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import IconButton, { SaveButton } from 'components/IconButton';
import { Title } from 'components/PlanningAreaDetailToolbar';
import { ButtonsGroup, ButtonWrap } from 'components/ResultToolBar';
import UserViewToggleButton from 'components/ToggleButton/UserViewToggleButton';
import SmartProdScheduleDialog from './SmartProdScheduleDialog';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';

import { useSelector, useDispatch } from 'react-redux';
import { ApiSmartProdSourceDTO } from 'types/drep-backend.d';
import createUploadModal from '../UploadModal';
import { openUploadModalAction } from '../UploadModal/actions';
import messages from './messages';
import { SmartProdSourceForm } from './types';
import { smartProdSourceName } from "./fetch";

const UploadModal = createUploadModal('smartProdSourcePage');
type Props = {
  fixed: boolean;
  intl: InjectedIntl;
  formik: FormikProps<SmartProdSourceForm>;
  entity: ApiSmartProdSourceDTO;
  isEdit: boolean;
  isNew: boolean;
  editModeChange: (editMode: boolean) => void;
  handleGetSmartProdJobCodes: () => void;
  hasPerm: (permission: string) => boolean;
  user: any;
  history: RouteComponentProps.history;
  openUploadModal: Function;
  handleReloadData: Function;
  handleReloadLatestData: Function;
  hasRunningRun: boolean;
  initialRequestSmartVolumeForecast: boolean;
};

const ToolBar: React.FC<Props> = ({
  intl,
  fixed,
  formik,
  history,
  editModeChange,
  entity,
  handleGetSmartProdJobCodes,
  openUploadModal,
  user,
  hasPerm,
  handleReloadData,
  handleReloadLatestData,
  isEdit,
  hasRunningRun,
  initialRequestSmartVolumeForecast
}) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const [smartProdModal, setSmartProdModal] = React.useState(false);
  const dialogAssociationLabels = {
    smartProdInstanceLabel: entity && entity.smartProdInstance.name,
    WHIDLabel: entity && entity.smartProdWarehouse.smartProdWhId
  };

  const handleSaveData = () => {
    formik.submitForm();
  };
  const handleToggleDisable = async() => { 
    let payload = {
      "id": entity.id,
      "smartProdInstance": entity.smartProdInstance,
      "smartProdWarehouse": entity.smartProdWarehouse ,
      "startDate": entity.startDate,
      "description": entity.description,
      "deleted": !entity.deleted,
      //"plans": change.plans ? change.plans : [],
      //"planningAreas": change.planningAreas ? change.planningAreas : [],
      "omses": entity.omses ? entity.omses : [],
      "scheduleStartDate": entity.scheduleStartDate,
      "schedulePeriod": entity.schedulePeriod ? Number(entity.schedulePeriod):'',
      isRequestSmartVolumeForecast: entity.isRequestSmartVolumeForecast,
    }
    await fetchData(
      withUrl(`/smartProdSource/`)
        .post(payload)
        .andToken(token),
      dispatch,
    );
    handleReloadLatestData();
  };
  const disabled = entity?.deleted == true;
  const scheduleButtonFlag = entity?.startDate;

  const name = smartProdSourceName(entity);
  const nameTitle = name ? ` - ${name}` : '';
  const openSmartProdModal = () => {
    setSmartProdModal(true);
  };

  const openSmartProdModalCallback = () => {
    setSmartProdModal(false);
  };

  const handleChangeData = async (association: ApiSmartProdSourceDTO, change: ApiSmartProdSourceDTO) => {
    let payload = {
      "id": change.id,
      "smartProdInstance": change.smartProdInstance,
      "smartProdWarehouse": change.smartProdWarehouse ,
      "startDate": change.startDate,
      "description": change.description,
      "deleted": change.deleted,
      //"plans": change.plans ? change.plans : [],
      //"planningAreas": change.planningAreas ? change.planningAreas : [],
      "omses": change.omses ? change.omses : [],
      "scheduleStartDate": change.scheduleStartDate,
      "schedulePeriod": change.schedulePeriod ? Number(change.schedulePeriod):'',
      isRequestSmartVolumeForecast: entity.isRequestSmartVolumeForecast,
    }
    await fetchData(
      withUrl(`/smartProdSource/`)
        .post(payload)
        .andToken(token),
      dispatch,
    );
    setSmartProdModal(false);
    handleReloadLatestData();
  };

  return (
    <ButtonWrap fixed={fixed}>
      <IconButton icon="arrow-circle-left" id="back" tooltip={{ ...messages.back }} onClick={() => history.push(PATHS.smartProdSourceListNoReset.replace(':reset', false),{disabled: disabled})} />
      <Title>
        <FormattedMessage {...messages.header} />{nameTitle}
      </Title>
      <Prompt when={formik.dirty} message={intl.formatMessage(messages.confirmDirty)} />

      <ButtonsGroup>
        {!disabled &&<SaveButton disabled={!formik.dirty} onClick={handleSaveData} />}
        {isEdit && !disabled ? (
          <ComponentWithDirtyCheck
            actionHandler={props => {
              if (isEmpty(formik.errors) || !props?.waitForSaveAll) {
                editModeChange(false);
              }
            }}
          >
            {({ onClickHandler }) => (
              <IconButton icon="eye" id="plan-detail-edit" tooltip={messages.display} onClick={onClickHandler} />
            )}
          </ComponentWithDirtyCheck>
        ) : (
          !disabled && <IconButton icon="edit" id="edit" tooltip={{ ...messages.edit }} onClick={() => editModeChange(true)} />
        )
        }
        { !disabled &&
          <IconButton
          icon="upload"
          id="forecast-detail-upload"
          disabled={false}
          tooltip={messages.upload}
          onClick={() => openUploadModal()}
        />
        }
        { !disabled &&
          <IconButton
          id="scheduleSmartVolume"
          label={messages.scheduleSmartVolume}
          tooltip={messages.scheduleSmartVolume}
          onClick={openSmartProdModal}
          disabled={hasRunningRun || !initialRequestSmartVolumeForecast}
        />
        }
        {smartProdModal && 
          <SmartProdScheduleDialog
            associations={entity}
            changeRow={handleChangeData}
            smartProdModal={smartProdModal}
            openSmartProdModalCallback={openSmartProdModalCallback}
            {...dialogAssociationLabels}
          />}
         <IconButton
              id="toggleDisable"
              label={disabled ? messages.enable : messages.disable}
              tooltip={disabled ? messages.enable : messages.disable}
              onClick={handleToggleDisable}
            />
      </ButtonsGroup>
      <UploadModal
        entity="smartProdSource"
        currentId={entity?.id}
        intl={intl}
        user={user}
        isAuditor={hasPerm(PERMISSIONS.VIEW_SMART_PROD_SOURCE)}
        onCloseHandler={handleReloadData}
      />
    </ButtonWrap>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        openUploadModal: openUploadModalAction,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(undefined, mapDispatchToProps);

export default withSecurity()(withScrollWatch(70)(injectIntl(formikConnect(withRouter(withConnect(ToolBar))))));
