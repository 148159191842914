/*
 * ResultBasicInfo Messages
 *
 * This contains all the text for the ResultBasicInfo component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.ResultBasicInfo.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'app.components.ResultBasicInfo.description',
    defaultMessage: 'Description',
  },
  planningArea: {
    id: 'app.components.ResultBasicInfo.planningArea',
    defaultMessage: 'Planning Area',
  },
  region: {
    id: 'app.components.ResultBasicInfo.region',
    defaultMessage: 'Region',
  },
  country: {
    id: 'app.components.ResultBasicInfo.country',
    defaultMessage: 'Country',
  },
  sector: {
    id: 'app.components.ResultBasicInfo.sector',
    defaultMessage: 'Sector',
  },
  customer: {
    id: 'app.components.ResultBasicInfo.customer',
    defaultMessage: 'Customer',
  },
  facility: {
    id: 'app.components.ResultBasicInfo.facility',
    defaultMessage: 'Facility',
  },
  wzp: {
    id: 'app.components.ResultBasicInfo.wzp',
    defaultMessage: 'Work Zone Periods',
  },
  startDay: {
    id: 'app.components.ResultBasicInfo.startDay',
    defaultMessage: 'Week Start Day',
  },
  fteHours: {
    id: 'app.components.ResultBasicInfo.fteHours',
    defaultMessage: 'FTE Working Hours/Day',
  },
  fteDays: {
    id: 'app.components.ResultBasicInfo.fteDays',
    defaultMessage: 'FTE Working Days/Week',
  },
  timeZone: {
    id: 'app.components.ResultBasicInfo.timeZone',
    defaultMessage: 'Time Zone',
  },
  weekStartDay: {
    id: 'app.components.ResultBasicInfo.weekStartDay',
    defaultMessage: 'Week Start Day',
  },
  offDays: {
    id: 'app.components.ResultBasicInfo.offDays',
    defaultMessage: 'Days Off',
  },
  shifts: {
    id: 'app.components.ResultBasicInfo.shifts',
    defaultMessage: 'Shifts',
  },
});
