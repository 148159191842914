import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import { CheckButton } from 'components/ToggleButton';
import { getToken } from 'containers/App/selectors';
import { fetchSmartVolumeRuns, refreshSmartVolumeRuns, fetchSmartVolumeLastRuns } from 'containers/PlanInterfaces/fetch';
import { formatDateTime } from 'utils/dateTime';
import { ApiForecastPerOmsCodeTableDTO, ApiSmartVolumeRunDTO } from 'types/drep-backend.d';

import ForecastPerOMSCodeTable from './ForecastPerOMSCodeTable';
import messages from './messages';
import {
  EntityEntry,
  ForecastConversionRateTableDTOWithId,
  ForecastListEntityEntry,
  ForecastPerOMSCodeColumns,
} from './types';
import { Loading } from './Loading';

const CompactButton = styled(Button)``;
const Buttons = styled.div``;
const Title = styled.div``;
const TableActions = styled.div`
  display: inline-block;
`;

const CustomButton = styled.span`
  font-size: 14px;
`;
export const ButtonsGroup = styled.div`
  display: inline-block;

  > div {
    display: flex;
  }

  ${Title} {
    margin-top: 18px;
    margin-bottom: 12px;
  }

  ${CompactButton} {
    white-space: normal;
    max-width: 180px;
    height: auto;
    font-size: 14px;
  }

  ${Buttons} > * {
    margin-top: 0;
    margin-bottom: 18px;
    margin-right: 10px;
  }
`;

const SmallTable = styled.table`
  display: inline-block;
  margin-left: 120px;

  th,
  td {
    padding: 5px;
    text-align: left;
  }
`;

type SmartProdSourceTableRow = {
  value: number;
  label: string;
  lastRefresh: string;
  smartProdSourceValue: any;
};

type Props = {
  intl: InjectedIntl;
  planningParametersId: number;
  forecastConversionRates: ForecastConversionRateTableDTOWithId[];
  forecastPerOMSCode: ApiForecastPerOmsCodeTableDTO;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  activityForecastList?: ForecastListEntityEntry[];
  handleReloadData: () => void;
  dates: DateTime[];
  hasAdjustments: boolean;
};

const ForecastPerOMSCode: React.FC<Props> = ({
  intl,
  planningParametersId,
  forecastConversionRates,
  forecastPerOMSCode,
  allOmsCodes,
  allSmartProdSources,
  activityForecastList,
  handleReloadData,
  dates,
  hasAdjustments,
}) => {
  const dispatch = useDispatch();
  const token: string = useSelector(getToken);

  const [columnsSwitch, setColumnsSwitch] = useState<ForecastPerOMSCodeColumns>({
    effortSmartVolume: true,
    effortSmartVolumeWithEvent: true,
    effortSmartVolumeWithAdjustment: hasAdjustments ? true: false,
    effortSmartProd: true,
    effortSmartProdWithEvent: true,
    effortSmartProdWithEventAndAdjustment: hasAdjustments ? true: false,
    actualPR: true,
    targetPR: true,
    ratio: true,
    model: false,
  });
  const [hasRunningRuns, setHasRunningRuns] = useState<boolean>(false);
  const [smartVolumeRuns, setSmartVolumeRuns] = useState<ApiSmartVolumeRunDTO[]>(null);
  const [smartProdSourceData, setSmartProdSourceData] = useState<SmartProdSourceTableRow[]>([]);
  const [smartVolumeLoader, setSmartVolumeLoader] = useState(false);
  const loadSmartVolumeRuns = () => {
    fetchSmartVolumeLastRuns(token, dispatch, planningParametersId, runs => {
      setSmartVolumeRuns(runs.sort((a, b) => (a.created > b.created ? -1 : 1)));
    }, setSmartVolumeLoader);
  };

  const handleRefreshSmartProdSources = () => {
    refreshSmartVolumeRuns(token, dispatch, planningParametersId).then(response => {
      if (response.isOk) {
        setHasRunningRuns(true);
        loadSmartVolumeRuns();
      }
    });
  };

  useEffect(() => {
    loadSmartVolumeRuns();
  }, [allSmartProdSources, planningParametersId]);

  useEffect(() => {
    let foundRunningRuns = false;
    if (smartVolumeRuns && allSmartProdSources) {
      setSmartProdSourceData(
        allSmartProdSources.map(smartProdSource => {
          const lastRun = smartVolumeRuns.find(run => run.smartProdSource.id === smartProdSource.value);
          let lastRefresh = '';
          let smartProdSourceValue = '';
          let hasHolidaysValue;
          if(lastRun?.smartProdSource){
            smartProdSourceValue = `${lastRun.smartProdSource?.smartProdInstance?.name} / ${lastRun.smartProdSource?.smartProdWarehouse?.smartProdWhId}`;
            hasHolidaysValue = lastRun.smartProdSource?.hasHolidays;
          };
          if (lastRun?.status === 'FINISHED' || lastRun?.status === 'SUCCESSFUL') {
            lastRefresh = formatDateTime(lastRun?.finished);
          }
          if (lastRun?.status === 'CREATED' || lastRun?.status === 'RUNNING') {
            lastRefresh = intl.formatMessage(messages.omsCodesTableLastRunInProgress);
            foundRunningRuns = true;
          }
          if (lastRun?.status === 'FAILED') {
            lastRefresh = intl.formatMessage(messages.omsCodesTableLastRunFailed);
          }
          return {
            ...smartProdSource,
            lastRefresh,
            smartProdSourceValue,
            hasHolidaysValue
          };
        }),
      );
    }
    if (!foundRunningRuns && hasRunningRuns) {
      handleReloadData();
      toast.info(intl.formatMessage(messages.omsCodesRefreshedToast));
    }
    setHasRunningRuns(foundRunningRuns);
    let timeout;
    if (foundRunningRuns) {
      timeout = setTimeout(loadSmartVolumeRuns, 10000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [allSmartProdSources, smartVolumeRuns]);
  const displayVolumeUsable=(row)=>{
   if(row && row.smartProdSource){
    return row.smartProdSource.isUsable ? 'Yes' : 'No'
   }
   return 'No';
  };

  const displayHolidaysFlag=(row)=>{
    if(row && row.hasHolidaysValue){
     return row.hasHolidaysValue ? 'Yes' : 'No'
    }
    return 'No';
   };

  const displayButton = (field) => {
    if (!hasAdjustments && field === 'effortSmartVolumeWithAdjustment') {
      return true;
    }
    if (!hasAdjustments && field === 'effortSmartProdWithEventAndAdjustment') {
      return true;
    }
    return false;
  } 

  return (
    <div>
      <ButtonsGroup>
        <Title>
          <FormattedMessage {...messages.omsCodesTableColumnDisplayFilter} />
        </Title>
        <Buttons>
          {['effortSmartVolume', 'effortSmartVolumeWithEvent', 'effortSmartVolumeWithAdjustment', 'effortSmartProd', 'effortSmartProdWithEvent', 'effortSmartProdWithEventAndAdjustment', 'actualPR', 'targetPR', 'ratio', 'model'].map(fieldName => (
          <span style={{display: displayButton(fieldName) ? 'none' : null}}>
              <CheckButton
              key={fieldName}
              name={fieldName}
              value={columnsSwitch[fieldName]}
              onChange={({ name, value }) => setColumnsSwitch({ ...columnsSwitch, [name]: value })}
            >
             <CustomButton><FormattedMessage {...messages[`omsCodesTableFieldName_${fieldName}`]} /></CustomButton> 
            </CheckButton>
            </span>
          ))}
        </Buttons>
      </ButtonsGroup>
      {allSmartProdSources && (
        <>
          <SmallTable>
            <thead>
              <tr>
                <th colSpan={3}>
                  <FormattedMessage {...messages.omsCodesTableSmartVolumeInfo} />
                </th>
              </tr>
              <tr>
                <th>
                  <FormattedMessage {...messages.omsCodesTableSmartProdSource} />
                </th>
                <th>
                  <FormattedMessage {...messages.omsCodesTableLastRefresh} />
                </th>
                <th>
                  <FormattedMessage {...messages.omsCodesTableSmartVolumeUsable} />
                </th>
                <th>
                  <FormattedMessage {...messages.omsCodesTableHolidays} />
                </th>
                <th>
                  <FormattedMessage {...messages.omsCodesTableAction} />
                </th>
              </tr>
            </thead>
            {
              smartVolumeLoader && smartVolumeRuns?.length > 0  ? 
              <Loading message = {messages.smartVolumeRefreshLoading}/> : 
              smartVolumeLoader ? 
              <Loading message = {messages.smartVolumeLoading}/> :
            <tbody>
              {smartProdSourceData.map((row, index) => (
                <tr key={row.label}>
                  <td>{row.smartProdSourceValue}</td>
                  <td>{row.lastRefresh}</td>
                  <td>{displayVolumeUsable(row)}</td>
                  <td>{displayHolidaysFlag(row)}</td>
                  {index === 0 && (
                    <td rowSpan={allSmartProdSources.length}>
                      <TableActions>
                        <ButtonWithDirtyCheck
                          actionHandler={handleRefreshSmartProdSources}
                          color="secondary"
                          variant="contained"
                          message={messages.omsCodesTableRefreshSmartProdSources}
                          disabled={hasRunningRuns}
                        />
                      </TableActions>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
}
          </SmallTable>
        </>
      )}
      {activityForecastList && (
        <ForecastPerOMSCodeTable
          forecastConversionRates={forecastConversionRates.filter(row => row.activityForecastId)}
          forecastPerOMSCode={forecastPerOMSCode}
          activityForecastList={activityForecastList}
          displayedColumns={columnsSwitch}
          dates={dates}
          allOmsCodes={allOmsCodes}
          hasAdjustments={hasAdjustments}
        />
      )}
    </div>
  );
};
export default injectIntl(ForecastPerOMSCode);
