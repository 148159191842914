/*
 * PlanListPage Messages
 *
 * This contains all the text for the PlanListPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.PlanListPage.header',
    defaultMessage: 'Plans',
  },
  pageName: {
    id: 'app.containers.PlanListPage.pageName',
    defaultMessage: 'Plan List',
  },
  disabled: {
    id: 'app.containers.PlanListPage.disabled',
    defaultMessage: 'Status',
  },
  updatedBy: {
    id: 'app.containers.PlanListPage.updatedBy',
    defaultMessage: 'Modified By',
  },
  updated: {
    id: 'app.containers.PlanListPage.updated',
    defaultMessage: 'Modified',
  },
  calculated: {
    id: 'app.containers.PlanListPage.calculated',
    defaultMessage: 'Calculated',
  },
  calculatedBy: {
    id: 'app.containers.PlanListPage.calculatedBy',
    defaultMessage: 'Calculated By',
  },
  numOfThisMonthCalcs: {
    id: 'app.containers.PlanListPage.numOfThisMonthCalcs',
    defaultMessage: 'This month # of Calculations',
  },
  numOfLastMonthCalcs: {
    id: 'app.containers.PlanListPage.numOfLastMonthCalcs',
    defaultMessage: 'Last month # of Calculations',
  },
  content: {
    id: 'app.containers.PlanListPage.content',
    defaultMessage: 'Planning Areas',
  },
  add: {
    id: 'app.containers.PlanListPage.add',
    defaultMessage: 'Add',
  },
  removeSelected: {
    id: 'app.containers.PlanListPage.removeSelected',
    defaultMessage: 'Disable selected',
  },
  name: {
    id: 'app.containers.PlanListPage.name',
    defaultMessage: 'Name',
  },
  id: {
    id: 'app.containers.PlanListPage.id',
    defaultMessage: 'Id',
  },
  facilities: {
    id: 'app.containers.PlanListPage.facilities',
    defaultMessage: 'Facilities',
  },
  customers: {
    id: 'app.containers.PlanListPage.customers',
    defaultMessage: 'Customers',
  },
  managementUnits: {
    id: 'app.containers.PlanListPage.managementUnits',
    defaultMessage: 'Management Units',
  },
  created: {
    id: 'app.containers.PlanListPage.created',
    defaultMessage: 'Created',
  },
  validFrom: {
    id: 'app.containers.PlanListPage.validFrom',
    defaultMessage: 'Valid From',
  },
  validTo: {
    id: 'app.containers.PlanListPage.validTo',
    defaultMessage: 'Valid To',
  },
  createdBy: {
    id: 'app.containers.PlanListPage.createdBy',
    defaultMessage: 'Created By',
  },
  action: {
    id: 'app.containers.PlanListPage.action',
    defaultMessage: 'Actions',
  },
  deleteConfirmText: {
    id: 'app.containers.PlanListPage.deleteConfirmText',
    defaultMessage: 'Are you sure? Disabling items: {items}',
  },
  enableConfirmText: {
    id: 'app.containers.PlanListPage.enableConfirmText',
    defaultMessage: 'Are you sure? Enabling items: {items}',
  },
  enableConfirmButton: {
    id: 'app.containers.PlanListPage.enableConfirmButton',
    defaultMessage: 'ENABLE',
  },
  enableTitle: {
    id: 'app.containers.PlanListPage.enableTitle',
    defaultMessage: 'Enable Action',
  },
  disableConfirmButton: {
    id: 'app.containers.PlanListPage.disableConfirmButton',
    defaultMessage: 'DISABLE',
  },
  disableTitle: {
    id: 'app.containers.PlanListPage.disableTitle',
    defaultMessage: 'Disable Action',
  },
  saveTableConfig: {
    id: 'app.containers.PlanListPage.saveTableConfig',
    defaultMessage: 'Save Current To User View',
  },
  defaultView: {
    id: 'app.containers.PlanListPage.defaultView',
    defaultMessage: 'Default View',
  },
  userView: {
    id: 'app.containers.PlanListPage.userView',
    defaultMessage: 'User View',
  },
  deleted: {
    id: 'app.containers.PlanListPage.deleted',
    defaultMessage: 'Status',
  },
  area: {
    id: 'app.containers.PlanListPage.area',
    defaultMessage: 'Planning Area',
  },
  planListPage_Enabled: {
    id: 'app.containers.PlanListPage.planListPage_Enabled',
    defaultMessage: 'Load enabled Plans',
  },
  planListPage_Disabled: {
    id: 'app.containers.PlanListPage.planListPage_Disabled',
    defaultMessage: 'Load disabled Plans',
  }
});
