/*
 * ActivitySettingsDirect Messages
 *
 * This contains all the text for the ActivitySettingsDirect component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ShiftSettings.header',
    defaultMessage: 'Shifts Weekly overview',
  },
  name: {
    id: 'app.components.ShiftSettings.name',
    defaultMessage: 'Name',
  },
  days: {
    id: 'app.components.ShiftSettings.days',
    defaultMessage: 'Days',
  },
  startTime: {
    id: 'app.components.ShiftSettings.startTime',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'app.components.ShiftSettings.endTime',
    defaultMessage: 'End Time',
  },
  action: {
    id: 'app.components.ShiftSettings.action',
    defaultMessage: 'Action',
  },
  detailsButtons: {
    id: 'app.components.ShiftSettings.detailsButtons',
    defaultMessage: 'Details',
  },
  delete: {
    id: 'app.components.ShiftSettings.delete',
    defaultMessage: 'Delete shift',
  },
  dialogDeleteText: {
    id: 'app.components.ShiftSettings.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting shift {name}',
  },
  addButtonLabel: {
    id: 'app.components.ShiftSettings.addButtonLabel',
    defaultMessage: 'Labour Category',
  },
  laborCategory: {
    id: 'app.components.ShiftSettings.laborCategory',
    defaultMessage: 'Labour Category',
  },
  headCount: {
    id: 'app.components.ShiftSettings.headCount',
    defaultMessage: 'Head Count',
  },
  headCountDefault: {
    id: 'app.components.ShiftSettings.headCountDefault',
    defaultMessage: 'Head Count (default)',
  },
  breakTime: {
    id: 'app.components.ShiftSettings.breakTime',
    defaultMessage: 'Break Time (minutes)',
  },
  productivity: {
    id: 'app.components.ShiftSettings.productivity',
    defaultMessage: 'Productivity',
  },
  absenteeismRate: {
    id: 'app.components.ShiftSettings.absenteeismRate',
    defaultMessage: 'Absenteeism',
  },
  vacationRate: {
    id: 'app.components.ShiftSettings.vacationRate',
    defaultMessage: 'Vacation',
  },
  addLine: {
    id: 'app.components.ShiftSettings.addLine',
    defaultMessage: 'Add Line',
  },
  setStartTime: {
    id: 'app.components.ShiftSettings.setStartTime',
    defaultMessage: 'Set Start Time',
  },
  setEndTime: {
    id: 'app.components.ShiftSettings.setEndTime',
    defaultMessage: 'Set End Time',
  },
  department: {
    id: 'app.components.ShiftSettings.department',
    defaultMessage: 'Department',
  },
  labourRole:{
    id: 'app.components.ShiftSettings.labourRole',
    defaultMessage: 'Labour Role',
  },
  role: {
    id: 'app.components.ShiftSettings.role',
    defaultMessage: 'Role',
  }
});
