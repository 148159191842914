import { createSelector } from 'reselect';
import { T_TYPE } from 'utils/constants';
import { getIn } from 'formik';
import { initialState } from './reducer';

/**
 * Direct selector to the planningAreaDetailPage state domain
 */

const selectPlanningAreaDetailPageDomain = state => state.get('planningAreaDetailPage', initialState);

export const selectPlanningAreaFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('planningArea'),
);

export const selectPlanningAreaDetailsLoader = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('planningAreaDetailsLoader'),
);

export const selectPlanningAreaDetailsLoaderFlag = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('planingAreadetailsFlag'),
);

export const selectPlanningAreaOverviewLoaderFlag = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('planningAreaOverviewFlag'),
);

export const selectPlanningAreaIdFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['planningArea', 'id']),
);

export const selectPlanningParametersIdFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['planningArea', 'planningParameters', 'id']),
);

export const selectPlanningParametersFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['planningArea', 'planningParameters']),
);
export const selectOffDaysPA = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['planningArea', 'planningParameters', 'offDays']),
);
export const selectFirstdayPA = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['planningArea', 'planningParameters', 'firstDayOfWeek']),
);

export const selectGuiStateForPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('guiState'),
);

export const selectEditFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['guiState', 'edit']),
);

export const selectPeriodIndexFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.getIn(['guiState', 'periodIndex']),
);

export const selectPeriodIdFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  selectPeriodIndexFromPa,
  (domain, periodIndex) => domain.getIn(['planningArea', 'planningParameters', 'periods', periodIndex, 'id']),
);

export const selectOpenedMhesFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('activityIdsMheOpen').toJS(),
);

export const selectOpenedShiftsFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('activityIdsShiftOpen').toJS(),
);

export const selectOpenedRolesFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('activityIdsRoleOpen').toJS(),
);
export const selectOpenedButtonFlagFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('openedButtonFlag'),
);

export const selectIsShiftFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate =>
    (substate.getIn(['planningArea', 'planningParameters', 'transformationType']) || T_TYPE.WZP) === T_TYPE.SHIFT,
);

export const selectPRPerShiftFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate =>
    (substate.getIn(['planningArea', 'planningParameters', 'productivityRatePerShift'])),
);

export const selectShiftCountFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  selectPeriodIndexFromPa,
  (substate, periodIndex) => {
    const pa = substate.get('planningArea');
    return getIn(pa, ['planningParameters', 'periods', periodIndex, 'shifts'], []).length;
  },
);

export const selectWzpCountFromPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  selectPeriodIndexFromPa,
  (substate, periodIndex) => {
    const pa = substate.get('planningArea');
    const wzp = getIn(pa, ['planningParameters', 'periods', periodIndex, 'workZonePeriods'], []);
    return (wzp && wzp.length) || 0;
  },
);
export const selectPlanSmartCodeMappings = createSelector(
  selectPlanningAreaDetailPageDomain, substate =>
  substate.get('planSmartCodeMappings'),
);

export const selectAreaDepartments = createSelector(
  selectPlanningParametersFromPa,
  params => (params && params.departments) || [],
);

export const selectUOMS = state => state.getIn(['global', 'uoms']);
export const selectActivities = state => state.getIn(['global', 'activities']);

export function get(obj, pathArray) {
  for (const value of pathArray.values()) {
    if (!(value in obj)) return undefined;
    obj = obj[value];
  }
  return obj;
}

export function set(obj, pathArray, value) {
  const lastIndex = pathArray.length - 1;
  pathArray.map((key, index) => {
    if (index === lastIndex) {
      obj[key] = value;
      return;
    }
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  });
}

export function getUomsForActivites(activities, aParams) {
  if (!activities) return;
  // getting all unit measures for al activites
  // removing already used measures for each type of activity
  // only needed for direct ones
  const params = aParams;
  if (!params) return null;
  const departments = {};
  activities.map(a => {
    const matchedParams = params.filter(p => p.activity && p.activity.id === a.id);
    if (!matchedParams.length === 0) return;
    matchedParams.map(param => {
      const department = param.departmentId || 'all';
      const customer = param.customerId || 'all';
      if (a.indirect) {
        set(departments, [department, a.id, customer], true);
      } else {
        set(departments, [department, a.id, customer, param.uom.id], true);
      }
    });
  });
  return (Object.keys(departments).length === 0 && null) || departments;
}

export const uomsForPaActitivies = createSelector(
  selectPlanningAreaDetailPageDomain,
  selectActivities,
  selectPeriodIndexFromPa,
  (substate, activities, periodIndex) => {
    const aParams = substate.getIn(['planningArea', 'planningParameters', 'periods', periodIndex, 'activityParameters']);
    return getUomsForActivites(activities, aParams);
  },
);


export const selectplanRoles = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('planRoles'),
);


export const selectShowValuePa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('showValue'),
);

export const selectShowFormulaPa = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('showFormula'),
);

export const selectShowValuePaVar = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('showValueVar'),
);

export const selectShowFormulaPaVar = createSelector(
  selectPlanningAreaDetailPageDomain,
  substate => substate.get('showFormulaVar'),
);