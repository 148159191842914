/*
 * CdmEditableList Messages
 *
 * This contains all the text for the CdmEditableList component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    id: 'app.components.CdmEditableList.code',
    defaultMessage: 'MDM ID',
  },
  name: {
    id: 'app.components.CdmEditableList.name',
    defaultMessage: 'Name',
  },
  saveFromTo: {
    id: 'app.components.CdmEditableList.save',
    defaultMessage: 'Save',
  },
  add: {
    id: 'app.components.CdmEditableList.add',
    defaultMessage: 'Add',
  },
  facility: {
    id: 'app.components.CdmEditableList.facility',
    defaultMessage: 'Facility',
  },
  managementUnit: {
    id: 'app.components.CdmEditableList.managementUnit',
    defaultMessage: 'Management Unit',
  },
  uniqueValueError: {
    id: `app.components.uniqueValueError`,
    defaultMessage: 'This department already exists',
  },
  emptyValueError: {
    id: `app.components.emptyValueError`,
    defaultMessage: 'Please enter the name',
  },
  specialCharactersError: {
    id: `app.components.specialCharactersError`,
    defaultMessage: 'Department name should not contain these special characters "\\\\" , "," , "_"',
  },
});
