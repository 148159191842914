import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { connect as formikConnect, FormikProps, getIn } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button';
import OverwriteConfirmDialog from 'components/EffortForecast/OverwriteConfirmDialog';
import { CheckButton } from 'components/ToggleButton';
import Select from 'components/StyledSelect';
import { getToken } from 'containers/App/selectors';
import { fetchOMSCodes } from 'containers/ForecastDetailPage/fetch';
import { EntityEntry, ForecastListEntityEntry } from 'containers/ForecastDetailPage/types';
import ModifyMultipleDialog from 'containers/ModifyMultipleDialog';
import {
  ModifyMultipleChangeType,
  ModifyMultipleDataSource,
  ModifyMultipleDialogType,
  ModifyMultipleFormSettings,
} from 'containers/ModifyMultipleDialog/types';
import { PlanDetailData } from 'containers/PlanResultPage/calculation/types';
import { withConfirmDialog } from 'containers/ShiftScheduleRunsPage/withConfirmDialog';
import { formatDateToApiFormat } from 'utils/api';
import { generateDays, parseDate } from 'utils/dateTime';
import { ApiOmsCodeEffortPlanningParametersType } from 'types/drep-backend.d';

import EffortForecastTable from './EffortForecastTable';
import messages from './messages';
import {
  EffortForecastModifySwitch,
  EffortForecastPlanSwitch,
  EffortForecastTableData,
  EffortRatioSwitch,
} from './types';

const CompactButton = styled(Button)``;
export const ButtonsGroup = styled(props => (
  <Box mt={2} mb={1}>
    <Grid container spacing={6} {...props} />
  </Box>
))`
  padding-bottom: 12px;
  padding-left: 4px;
`;

const ButtonsSection = styled(props => (
  <Grid item xs="auto">
    <Grid container spacing={1} {...props} />
  </Grid>
))`
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
  position: relative;
  padding-top: 24px;
  min-width: 200px;

  h6 {
    position: absolute;
    margin-top: 8px;
    margin-left: 8px;
    top: 0;
    left: 0;
  }

  ${CompactButton} {
    white-space: normal;
    height: 36px;
    font-size: 14px;
  }
`;

const modifyMultipleDataSourceOptions: Array<{ label: string; value: ModifyMultipleDataSource }> = [
  {
    label: 'OMS Codes',
    value: ModifyMultipleDataSource.OMS_CODE,
  },
  {
    label: 'Activity',
    value: ModifyMultipleDataSource.ACTIVITY,
  },
];

type Props = {
  formik: FormikProps<PlanDetailData>;
  startDay: string;
  endDay: string;
  edit: boolean;
  planningParametersId: number;
  activityForecastList: ForecastListEntityEntry[];
  openConfirmDialog: (confirmHandler, message) => void;
  planningParameters: any;
};

const EffortForecast: React.FC<Props> = ({
  formik,
  startDay,
  endDay,
  edit,
  planningParametersId,
  activityForecastList,
  openConfirmDialog,
  planningParameters
}) => {
  const dispatch = useDispatch();
  const token: string = useSelector(getToken);

  const [data, setData] = useState<EffortForecastTableData[]>();
  const [dates, setDates] = useState<DateTime[]>([]);
  const [effortRatioSwitch, setEffortRatioSwitch] = useState<EffortRatioSwitch>({
    effort: true,
    ratio: true,
    dataSource: false,
  });
  const [forecastPlanSwitch, setForecastPlanSwitch] = useState<EffortForecastPlanSwitch>('plan');
  const [selectedModifyMultipleDataSource, setSelectedModifyMultipleDataSource] = useState<ModifyMultipleDataSource>(
    ModifyMultipleDataSource.OMS_CODE,
  );
  const [overwriteConfirmDialogVisible, setOverwriteConfirmDialogVisible] = useState<boolean>(false);
  const [modifyMultipleOpen, setModifyMultipleOpen] = useState<boolean>(false);
  const [modifySwitch, setModifySwitch] = useState<EffortForecastModifySwitch>('modifyActivity');
  const [omsCodes, setAllOmsCodes] = useState<EntityEntry[]>([]);
  const defaultForecastSourceValue = getIn(formik.values, 'planningParameters.defaultForecastSource');
  const forecastOptions = [ { value: 'SMART_PROD', label: 'Planner Driven' }, { value: 'SMART_VOLUME', label: 'SmartVolume' }, { value: 'SMART_PROD_WITH_EVENT', label: 'Planner Driven One-Off Event' },{ value: 'SMART_VOLUME_WITH_EVENT', label: 'SmartVolume One-Off Event' }];
  const forecastValue = forecastOptions.filter((f)=>f.value === defaultForecastSourceValue);
  const MultipleActivities = activityForecastList.filter(s =>
    data?.map(d => d.activityForecastId)?.includes(s.value),
  );
  const modifyMultipleActivities = MultipleActivities?.filter(
    (item, index, self) =>
      index === self?.findIndex(t => t?.value === item?.value)
   );
  const modifyMultipleOMSCodes = omsCodes.filter(s => data?.map(d => d.omsId)?.includes(s.value));

  useEffect(() => {
    if (startDay && endDay) {
      setDates(generateDays(parseDate(startDay), parseDate(endDay)));
    }
  }, [startDay, endDay]);

  useEffect(() => {
    fetchOMSCodes(token, dispatch, setAllOmsCodes);
  }, []);

  useEffect(() => {
    /// initializeData();
  }, [dates, startDay, endDay]);

  useEffect(() => {
    setData(formik.values?.effortForecast);
  }, [formik.values?.effortForecast]);

  const handleSetDataFromForecastSource = (val) => {
    data.forEach((d)=>{
      d?.omsDates?.forEach((o, i)=>{
        if(o?.dataSource === formik?.values?.planningParameters?.defaultForecastSource){
          o.effortOverride = null;
          if( d && d.activityDates[i] && d.activityDates[i].effortOverride) d.activityDates[i].effortOverride =null;
          if( d && d.activityDates[i] && d.activityDates[i].ratioOverride)  d.activityDates[i].ratioOverride = null;
          if(o?.dataSource === formik?.values?.planningParameters?.defaultForecastSource){
            o.dataSource = val.value;
          }
        }
      })
    })
    setData(data);
    formik.setFieldValue('planningParameters.defaultForecastSource', val.value)
  }
  const handleGetBackToForecast = () => {
    const update = data.map(dataRow => ({
      ...dataRow,
      omsDates: dataRow.omsDates.map(dateRow => ({
        ...dateRow,
        effortOverride: null,
      })),
      activityDates: dataRow.activityDates.map(dateRow => ({
        ...dateRow,
        effortOverride: null,
        ratioOverride: null,
      })),
    }));
    setData(update);
    formik.setFieldValue('effortForecast', update);
    setOverwriteConfirmDialogVisible(false);
  };

  const handleModifyMultiple = () => {
    setModifyMultipleOpen(true);
  };

  const handleCloseModifyMultiple = () => {
    setModifyMultipleOpen(false);
  };

  const getDatesStoredInFormik = () => {
    const datesSet = new Set<string>();
    formik.values?.effortForecast?.forEach(omsRow => {
      omsRow?.activityDates.forEach(row => {
        datesSet.add(row.effortDate);
      });
      omsRow?.omsDates.forEach(row => {
        datesSet.add(row.effortDate);
      });
    });
    return datesSet;
  };

  const getDatesFromSettingsThatDoNotExistInData = (settings: ModifyMultipleFormSettings) => {
    const datesSet = new Set<string>(
      generateDays(parseDate(settings.startDate), parseDate(settings.endDate)).map(luxonDate =>
        formatDateToApiFormat(luxonDate),
      ),
    );
    const existingDates = getDatesStoredInFormik();
    return [...datesSet].filter(d => !existingDates.has(d));
  };

  const handleMultipleEditValueSubmit = (settings: ModifyMultipleFormSettings) => {
    // add virtual rows, if the start/end dates are out of range
    const datesThatHaveToBeCreated = getDatesFromSettingsThatDoNotExistInData(settings);

    let update = formik.values?.effortForecast.map(dataRow => ({
      ...dataRow,
      omsDates: [
        ...dataRow.omsDates,
        ...datesThatHaveToBeCreated.map(effortDate => ({
          effortDate,
          dataSource: 'SMART_PROD' as ApiOmsCodeEffortPlanningParametersType,
          effortSmartVolume: null,
          effortSmartVolumeWithEvent: null,
          effortSmartProd: null,
          effortSmartProdWithEvent: null,
          effortOverride: null,
          model: null,
        })),
      ],
      activityDates: [
        ...dataRow.activityDates,
        ...datesThatHaveToBeCreated.map(effortDate => ({
          effortDate,
          effortSmartVolume: null,
          effortSmartVolumeWithEvent: null,
          effortSmartProd: null,
          effortSmartProdWithEvent: null,
          effortOverride: null,
          productivityRateActual: null,
          productivityRateTarget: null,
          ratioSmartProd: null,
          ratioOverride: null,
        })),
      ],
    }));

    const getActivitiesForOmsCode = (omsUpdate, omsId) => {
      const activities = [];
      omsUpdate.forEach(omsRow => {
        if (omsRow.omsId === omsId) {
          activities.push(omsRow);
        }
      });
      return activities;
    };

    const getEffortForActivity = (activityDate, dataSource) => {
      if (activityDate.effortOverride !== null) {
        return activityDate.effortOverride;
      }
      if (dataSource === 'SMART_VOLUME') {
        return activityDate.effortSmartVolume;
      }
      if (dataSource === 'SMART_VOLUME_WITH_EVENT'){
        return activityDate.effortSmartVolumeWithEvent;
      }
      if (dataSource === 'SMART_PROD_WITH_EVENT'){
        return activityDate.effortSmartProdWithEvent;
      }
      return activityDate.effortSmartProd;
    };

    update = update.map(dataRow => ({
      ...dataRow,
      omsDates: dataRow.omsDates.map((dateRow, i) => {
        const date = parseDate(dateRow.effortDate);
        if (
          parseDate(settings.startDate).ts <= date.ts &&
          date.ts <= parseDate(settings.endDate).ts &&
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.OMS_CODE &&
          settings.selectedData[dataRow.omsId]
        ) {
          if (settings.changeType === ModifyMultipleChangeType.PERCENTAGE) {
            const percentageUpdate = parseFloat(settings.percentageInput);
            const valueBefore = getEffortForActivity(dateRow, dateRow.dataSource);
            // do not create 0 override, if value was 0 before
            if (!valueBefore) {
              return dateRow;
            }
            return {
              ...dateRow,
              effortOverride: valueBefore + valueBefore * (percentageUpdate / 100.0),
            };
          }
          if (settings.changeType === ModifyMultipleChangeType.VALUE) {
            const valueUpdate = parseFloat(settings.valueInput);
            return {
              ...dateRow,
              effortOverride: valueUpdate,
            };
          }
          if (settings.changeType === ModifyMultipleChangeType.DATA_SOURCE) {
            const activities = getActivitiesForOmsCode(update, dataRow.omsId);
            activities.forEach(activity => {
              const row = activity.activityDates[i];
              row.effortOverride = null;
              row.ratioOverride = null;
            });
            return {
              ...dateRow,
              dataSource: settings.dataTypeChangeEffortForecast,
              effortOverride: null,
            };
          }
        }
        return dateRow;
      }),
      activityDates: dataRow.activityDates.map((dateRow, i) => {
        const date = parseDate(dateRow.effortDate);
        if (
          settings.startDate.ordinal <= date.ordinal &&
          date.ordinal <= settings.endDate.ordinal &&
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.ACTIVITY &&
          settings.selectedData[dataRow.activityForecastId]
        ) {
          if (settings.changeType === ModifyMultipleChangeType.PERCENTAGE) {
            const percentageUpdate = parseFloat(settings.percentageInput);
            const valueBefore = getEffortForActivity(dateRow, dataRow.omsDates[i].dataSource);
            // do not create 0 override, if value was 0 before
            if (!valueBefore) {
              return dateRow;
            }
            return {
              ...dateRow,
              effortOverride: valueBefore + valueBefore * (percentageUpdate / 100.0),
            };
          }
          if (settings.changeType === ModifyMultipleChangeType.VALUE) {
            const valueUpdate = parseFloat(settings.valueInput);
            return {
              ...dateRow,
              effortOverride: valueUpdate,
            };
          }
        }
        return dateRow;
      }),
    }));

    // fix the sums
    update = update.map(dataRow => ({
      ...dataRow,
      omsDates: dataRow.omsDates.map((dateRow, i) => {
        const date = parseDate(dateRow.effortDate);
        if (
          settings.startDate.ordinal <= date.ordinal &&
          date.ordinal <= settings.endDate.ordinal &&
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.ACTIVITY &&
          settings.selectedData[dataRow.activityForecastId]
        ) {
          if (
            settings.changeType === ModifyMultipleChangeType.PERCENTAGE ||
            settings.changeType === ModifyMultipleChangeType.VALUE
          ) {
            const activities = getActivitiesForOmsCode(update, dataRow.omsId);
            let sum = 0;
            activities.forEach(activity => {
              sum += getEffortForActivity(activity.activityDates[i], dateRow.dataSource);
            });
            activities.forEach(activity => {
              const effort = getEffortForActivity(activity.activityDates[i], dateRow.dataSource);
              activity.activityDates[i].ratioOverride = (effort / sum) * 100;
              activity.omsDates[i].effortOverride = sum;
            });
            return {
              ...dateRow,
              effortOverride: sum,
            };
          }
        }
        return dateRow;
      }),
      activityDates: dataRow.activityDates.map((dateRow, i) => {
        const date = parseDate(dateRow.effortDate);
        if (
          settings.startDate.ordinal <= date.ordinal &&
          date.ordinal <= settings.endDate.ordinal &&
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.OMS_CODE &&
          settings.selectedData[dataRow.omsId]
        ) {
          if (
            settings.changeType === ModifyMultipleChangeType.PERCENTAGE ||
            settings.changeType === ModifyMultipleChangeType.VALUE
          ) {
            const activities = getActivitiesForOmsCode(update, dataRow.omsId);
            const totalEffort = getEffortForActivity(dataRow.omsDates[i], dataRow.omsDates[i].dataSource);
            let totalRatio = 0;
            activities.forEach(activity => {
              const ratio =
                activity.activityDates[i].ratioOverride !== null
                  ? activity.activityDates[i].ratioOverride
                  : activity.activityDates[i].ratioSmartProd;
              totalRatio += ratio || 0;
            });
            activities.forEach(activity => {
              const ratio =
                activity.activityDates[i].ratioOverride !== null
                  ? activity.activityDates[i].ratioOverride
                  : activity.activityDates[i].ratioSmartProd;
              if (!totalRatio) {
                activity.activityDates[i].effortOverride = (1.0 / activities.length) * totalEffort;
                activity.activityDates[i].ratioOverride = 100.0 / activities.length;
              } else {
                activity.activityDates[i].effortOverride = (ratio / 100) * totalEffort;
                activity.activityDates[i].ratioOverride = ratio || 0;
              }
            });
            return {
              ...dateRow,
            };
          }
        }
        return dateRow;
      }),
    }));
    setData(update);
    formik.setFieldValue('effortForecast', update);
  };

  return (
    <div>
      <div>
        <ButtonsGroup>
          {edit && (
            <ButtonsSection>
              <h6>Edit</h6>
              <Grid item xs>
                <CompactButton
                  onClick={() => setOverwriteConfirmDialogVisible(true)}
                  color="secondary"
                  variant="contained"
                  disabled={forecastPlanSwitch !== 'plan'}
                >
                  <FormattedMessage {...messages.buttonGetBackToForecast} />
                </CompactButton>
              </Grid>
              <Grid item xs>
                <CompactButton
                  onClick={handleModifyMultiple}
                  color="secondary"
                  variant="contained"
                  disabled={forecastPlanSwitch !== 'plan'}
                >
                  <FormattedMessage {...messages.buttonModifyMultiple} />
                </CompactButton>
              </Grid>
              <Grid item xs>
                <CompactButton
                  onClick={() => setModifySwitch(modifySwitch === 'modifyOmsCode' ? 'modifyActivity' : 'modifyOmsCode')}
                  color="secondary"
                  variant="contained"
                  disabled={forecastPlanSwitch !== 'plan'}
                >
                  <FormattedMessage
                    {...(modifySwitch === 'modifyActivity'
                      ? messages.buttonModifyOMSCode
                      : messages.buttonModifySmartPlanActivity)}
                  />
                </CompactButton>
              </Grid>
            </ButtonsSection>
          )}

          <ButtonsSection>
            <h6>Hide/Unhide Columns</h6>
            <Grid item>
              <CheckButton
                name="effort"
                value={effortRatioSwitch.effort}
                onChange={({ name, value }) => setEffortRatioSwitch({ ...effortRatioSwitch, [name]: value })}
              >
                <FormattedMessage {...messages.buttonEffort} />
              </CheckButton>
            </Grid>
            <Grid item>
              <CheckButton
                name="ratio"
                value={effortRatioSwitch.ratio}
                onChange={({ name, value }) => setEffortRatioSwitch({ ...effortRatioSwitch, [name]: value })}
              >
                <FormattedMessage {...messages.buttonRatio} />
              </CheckButton>
            </Grid>
            <Grid item>
              <CheckButton
                name="dataSource"
                value={effortRatioSwitch.dataSource}
                onChange={({ name, value }) => setEffortRatioSwitch({ ...effortRatioSwitch, [name]: value })}
              >
                <FormattedMessage {...messages.dataSource} />
              </CheckButton>
            </Grid>
          </ButtonsSection>

          <ButtonsSection>
            <h6>Change View</h6>
            <Grid item>
              <CompactButton
                onClick={() => setForecastPlanSwitch(forecastPlanSwitch === 'forecast' ? 'plan' : 'forecast')}
                color="secondary"
                variant="contained"
              >
                <FormattedMessage
                  {...(forecastPlanSwitch === 'forecast' ? messages.buttonPlan : messages.buttonForecast)}
                />
              </CompactButton>
            </Grid>
          </ButtonsSection>
          {
            <ButtonsSection>
              <h6>Default Forecast Source</h6>
              {edit ?
                <Grid style={{ width: 200, paddingBottom: '12px' }} item>
                  <Select
                    id="defaultForecastSource"
                    options={forecastOptions}
                    onChange={val => handleSetDataFromForecastSource(val)}
                    value={forecastOptions.filter((f) => f.value === defaultForecastSourceValue)}
                  />
                </Grid> :
                <span style={{ paddingTop: '5px', paddingBottom: '20px' }}>{forecastValue[0]?.label}</span>
              }
            </ButtonsSection>
          }
        </ButtonsGroup>
      </div>
      <div>
        {forecastPlanSwitch === 'forecast' ? (
          <FormattedMessage {...messages.tableTitleForecast} />
        ) : (
          <FormattedMessage {...messages.tableTitlePlan} />
        )}
      </div>
      <EffortForecastTable
        edit={edit}
        forecastPlanSwitch={forecastPlanSwitch}
        effortRatioSwitch={effortRatioSwitch}
        modifySwitch={modifySwitch}
        effortForecast={data}
        dates={dates}
        allOmsCodes={omsCodes}
        activityForecastList={activityForecastList}
      />
      <ModifyMultipleDialog
        dialogType={ModifyMultipleDialogType.EFFORT_FORECAST}
        open={modifyMultipleOpen}
        closeHandler={handleCloseModifyMultiple}
        startDate={parseDate(startDay)}
        endDate={parseDate(endDay)}
        selectedData={
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.OMS_CODE
            ? modifyMultipleOMSCodes
            : modifyMultipleActivities
        }
        dataSourceLabel={
          selectedModifyMultipleDataSource === ModifyMultipleDataSource.OMS_CODE ? 'OMS Codes' : 'Activities'
        }
        dataSourceOptions={modifyMultipleDataSourceOptions}
        dataSourceSwitch={selectedModifyMultipleDataSource}
        dataSourceSwitchChanged={setSelectedModifyMultipleDataSource}
        editValueSubmit={handleMultipleEditValueSubmit}
      />
      <OverwriteConfirmDialog
        visible={overwriteConfirmDialogVisible}
        onCancel={() => setOverwriteConfirmDialogVisible(false)}
        onConfirm={handleGetBackToForecast}
      />
    </div>
  );
};

export default withConfirmDialog(formikConnect(EffortForecast));
