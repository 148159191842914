// @flow
import { fetchData } from 'utils/reduxApi';
import { withUrl, convertEntityWithPlanningParametersFromApi } from 'utils/api';
import { storeActivitiesByPlanAction } from 'containers/App/actions';
import { storePlanAction } from './actions';

export async function fetchPlan(id: number, token: string, dispatch: Function) {
  const response = await fetchData(
    withUrl(`/plans/${id}`)
      .andTitle('Loading plan')
      .andToken(token),
    dispatch,
  );
  if (response.isOk) {
    const plan = convertEntityWithPlanningParametersFromApi(response.data);
    dispatch(storePlanAction(plan));
    dispatch(storeActivitiesByPlanAction(plan));
  }
}
