import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import ToggleSection from 'components/ToggleSection';
import { ApiMasterPlanDTO, ApiPlanDTO } from 'types/drep-backend.d';

import messages from './messages';
import PlansTable from './PlansTable';
import { makeSelectUser } from '../LoginPage/selectors';
import { formatDate } from 'utils/dateTime';
import { ROLES } from 'utils/security';
import uuid from 'uuid/v4';
import DialogActivityCheck from './DialogActivityCheck';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-right: 30px;
    display: flex;
  }

  padding-bottom: 5px;
`;
const ColorLabelSpan = styled.span`
color:red;
font-size:14px;
`;

const CenteredLabel = styled(Label)`
  line-height: 40px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
  z-index: 1200;
`;

type Props = {
  editMode: boolean;
  formik: FormikProps<{
    masterPlan: ApiMasterPlanDTO;
  }>;
  plans: ApiPlanDTO[];
  fullPlans: ApiPlanDTO[];
  user: any;
  intl: InjectedIntl;
};

const PlansSettings: React.FC<Props> = ({ formik, plans, editMode, user, intl, fullPlans }) => {
  const [open, setOpen] = React.useState(false);
  const userData = user.toJS();
  const isAdmin  = userData.roles.find(r => r.role === ROLES.ADMIN.name);
  const data = formik.values.masterPlan.plans;
  const planIdExists = Number(sessionStorage.getItem('planIdToAddInMasterPlan'));
  data.sort(function (a, b) {
    if(typeof (b.validTo) == 'object' || typeof (a.validTo)=='object'){
      return +new Date(b.validTo).toDateString() - +new Date(a.validTo).toDateString()
    }else{
      return +new Date(b.validTo) - +new Date(a.validTo)
    }
  });
  let resultArray = [];
  for (let i = 0; i < data.length - 1; i++) {
    let diff = (+new Date(formatDate(data[i].validFrom)) - +new Date(formatDate(data[i + 1].validTo)))!= 24 * 60 * 60 * 1000;
    if (diff && (new Date(DateTime.now().toLocaleString()) <= new Date(data[i + 1].validTo))) {
      (data[i].validFrom != data[i + 1].validTo) && resultArray.push({validFrom :`${data[i].validFrom}`, validTo: `${data[i + 1].validTo}`})
    }
  };

  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleAddPlan = () => {
    formik.setFieldValue('masterPlan.plans', [
      { planId: selectedPlan.value, validFrom: DateTime.local(), validTo: DateTime.local(2100, 1, 1), uuid: uuid() },
      ...formik.values.masterPlan.plans,
    ]);
    setSelectedPlan(null);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  }
  useEffect(()=>{
    if(planIdExists){
      formik.setFieldValue('masterPlan.plans', [
        { planId: planIdExists, validFrom: DateTime.local(), validTo: DateTime.local(2100, 1, 1) },
        ...formik.values.masterPlan.plans,
      ]);
    }
    return () =>{
      sessionStorage.removeItem('planIdToAddInMasterPlan');
    }
  },[])

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <ToggleSection message={messages.plans} expanded>
      <Wrapper>
        <div>
          <CenteredLabel {...messages.plan} />
        </div>
        <div>
          <StyledSelect
            options={plans?.filter(p => !p.deleted).map(p => ({ value: p.id, label: p.name }))}
            onChange={option => {
              setSelectedPlan(option);
            }}
            isDisabled={!editMode}
            value={selectedPlan}
          />
        </div>
        {editMode && (
          <div>
            <Button
              name="add"
              type="submit"
              dirty={formik.dirty}
              disabled={!selectedPlan || !editMode}
              onClick={handleOpen}
            >
              <FormattedMessage {...messages.addPlanAssociation} />
            </Button>
          </div>
        )}
      </Wrapper>
      {editMode && resultArray.length > 0 && !isAdmin &&
        <>
          <ColorLabelSpan><FormattedMessage {...messages.validGaps}/></ColorLabelSpan>
          {
            resultArray.map((item, index) => {
              return (
                <ColorLabelSpan>
                  {` ${formatDate(item.validTo)} - ${formatDate(item.validFrom)}`}
                  {index != resultArray.length - 1 && <span>,</span>}
                </ColorLabelSpan>
              )
            })
          }
        </>
      }
      <PlansTable formik={formik} data={data} plans={plans} editable={editMode} fullPlans={fullPlans}/>
      <DialogActivityCheck open={open} closeDialog={handleClose} intl={intl} handleAdd={handleAddPlan} addFlag={true} id={formik?.values?.masterPlan?.id} planId = {selectedPlan}/>
    </ToggleSection>
  );
};

// @ts-ignore
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);
export default injectIntl(withConnect(PlansSettings));
