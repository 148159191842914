import { defineMessages } from 'react-intl';

const scope = 'app.containers.ForecastDetailPage';
const scopeToolbar = 'app.containers.ForecastDetailPage.Toolbar';
const scopePeriodSelection = 'app.containers.ForecastDetailPage.PeriodSelection';
const scopeEvents = 'app.containers.ForecastDetailPage.Events';
const scopeHolidays = 'app.containers.ForecastDetailPage.Holidays';
const scopeOMSCodesAndSmartProdJobs = 'app.containers.ForecastDetailPage.OMSCodesAndSmartProdJobs';
const scopeSmartProdRuns = 'app.containers.ForecastDetailPage.SmartProdRuns';

export default defineMessages({
  // Page
  headerPlan: {
    id: `${scope}.headerPlan`,
    defaultMessage: 'Plan {name} - Forecast',
  },
  headerArea: {
    id: `${scope}.headerArea`,
    defaultMessage: 'Area {name} - Forecast',
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'Forecast',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Basic Information',
  },
  periodSelection: {
    id: `${scope}.periodSelectionSection`,
    defaultMessage: 'Period Selection',
  },
  activityAssignment: {
    id: `${scope}.activityAssignmentSection`,
    defaultMessage: 'Mapping OMS Codes Activities and SmartProd Jobs',
  },
  activityAssignmentError: {
    id: `${scope}.activityAssignmentError`,
    defaultMessage: 'Not all job codes are mapped!',
  },
  forecastPerOMSCode: {
    id: `${scope}.forecastPerOMSCode`,
    defaultMessage: 'Forecast per OMS Code',
  },
  adjustmentsMapping: {
    id: `${scope}.adjustmentsMapping`,
    defaultMessage: 'Adjustments Mapping',
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage: 'Warnings',
  },

  // Toolbar
  back: {
    id: `${scopeToolbar}.back`,
    defaultMessage: 'Back',
  },
  edit: {
    id: `${scopeToolbar}.edit`,
    defaultMessage: 'Edit',
  },
  display: {
    id: `${scopeToolbar}.display`,
    defaultMessage: 'Display',
  },
  upload: {
    id: `${scopeToolbar}.upload`,
    defaultMessage: 'Upload',
  },
  getSmartProdJobCodes: {
    id: `${scopeToolbar}.getSmartProdJobCodes`,
    defaultMessage: 'Get SmartProd Job Codes',
  },
  interfaces: {
    id: `${scopeToolbar}.interfaces`,
    defaultMessage: 'Interfaces',
  },
  jobCodesRefreshedToast: {
    id: `${scopeToolbar}.jobCodesRefreshedToast`,
    defaultMessage: 'Job Codes Updated',
  },

  // PeriodSelection
  startDate: {
    id: `${scopePeriodSelection}.startDate`,
    defaultMessage: 'Start Date',
  },
  setstartDate: {
    id: `${scopePeriodSelection}.setstartDate`,
    defaultMessage: 'Set Start Date',
  },
  endDate: {
    id: `${scopePeriodSelection}.endDate`,
    defaultMessage: 'End Date',
  },
  setendDate: {
    id: `${scopePeriodSelection}.setendDate`,
    defaultMessage: 'Set End Date',
  },
  confirmDirty: {
    id: `${scopePeriodSelection}.confirmDirty`,
    defaultMessage: 'You have unsaved changes. Do you want to leave anyway?',
  },
  startEndDatesRangeError: {
    id: `${scopePeriodSelection}.startEndDatesRangeError`,
    defaultMessage: 'Maximum range for date interval is 3 months',
  },

  // Events
  eventsTitle: {
    id: `${scopeEvents}.title`,
    defaultMessage: 'Events',
  },
  newEventFormName: {
    id: `${scopeEvents}.newEventFormName`,
    defaultMessage: 'Name',
  },
  newEventFormCategory: {
    id: `${scopeEvents}.newEventFormCategory`,
    defaultMessage: 'Category',
  },
  newEventFormSmartProd: {
    id: `${scopeEvents}.newEventFormSmartProd`,
    defaultMessage: 'SmartProd',
  },
  copyEventButton: {
    id: `${scopeEvents}.copyEventButton`,
    defaultMessage: 'Copy',
  },
  deleteEventButton: {
    id: `${scopeEvents}.deleteEventButton`,
    defaultMessage: 'Delete',
  },
  eventsTableName: {
    id: `${scopeEvents}.eventsTableName`,
    defaultMessage: 'Name',
  },
  eventsTableDescription: {
    id: `${scopeEvents}.eventsTableDescription`,
    defaultMessage: 'Description',
  },
  eventsTableSmartProd: {
    id: `${scopeEvents}.eventsTableSmartProd`,
    defaultMessage: 'SmartProd',
  },
  eventsTableCategory: {
    id: `${scopeEvents}.eventsTableCategory`,
    defaultMessage: 'Category',
  },
  eventsTableSubCategory: {
    id: `${scopeEvents}.eventsTableSubCategory`,
    defaultMessage: 'Subcategory',
  },
  eventsTableOrigin: {
    id: `${scopeEvents}.eventsTableOrigin`,
    defaultMessage: 'Origin',
  },
  eventsTableType: {
    id: `${scopeEvents}.eventsTableType`,
    defaultMessage: 'Type',
  },
  eventsTableTypeOneOff: {
    id: `${scopeEvents}.eventsTableTypeOneOff`,
    defaultMessage: 'One-off',
  },
  eventsTableTypeRecurring: {
    id: `${scopeEvents}.eventsTableTypeRecurring`,
    defaultMessage: 'Recurring',
  },
  eventsTableValidationErrorEventName: {
    id: `${scopeEvents}.eventsTableValidationErrorEventName`,
    defaultMessage: "Event '{event}': {error}",
  },
  eventsTableImpact: {
    id: `${scopeEvents}.eventsTableImpact`,
    defaultMessage: 'Impact',
  },
  eventsTableImpactValidation: {
    id: `${scopeEvents}.eventsTableImpactValidation`,
    defaultMessage: 'Value should be a number between -100 and +1000',
  },
  eventsTableSelectedDaysValidation: {
    id: `${scopeEvents}.eventsTableSelectedDaysValidation`,
    defaultMessage: 'Select at least one day of week',
  },
  eventsTableSelectedOMSCodeValidation: {
    id: `${scopeEvents}.eventsTableSelectedOMSCodeValidation`,
    defaultMessage: 'Select at least one OMS code',
  },
  eventsTableSelectedSmartProdSourceValidation: {
    id: `${scopeEvents}.eventsTableSelectedSmartProdSourceValidation`,
    defaultMessage: 'Select SmartProd',
  },
  eventsTableStartDate: {
    id: `${scopeEvents}.eventsTableStartDate`,
    defaultMessage: 'Start Date',
  },
  eventsTableEndDate: {
    id: `${scopeEvents}.eventsTableEndDate`,
    defaultMessage: 'End Date',
  },
  eventsTableActions: {
    id: `${scopeEvents}.eventsTableActions`,
    defaultMessage: 'Actions',
  },
  eventsTableDaysOfWeek: {
    id: `${scopeEvents}.eventsTableDaysOfWeek`,
    defaultMessage: 'Days of Week',
  },
  eventsTableOMSCodes: {
    id: `${scopeEvents}.eventsTableOMSCodes`,
    defaultMessage: 'OMS Codes',
  },
  eventsTableCreated: {
    id: `${scopeEvents}.eventsTableCreated`,
    defaultMessage: 'Created',
  },
  eventsTableCreatedBy: {
    id: `${scopeEvents}.eventsTableCreatedBy`,
    defaultMessage: 'Created by',
  },
  eventsTableConsideredButton: {
    id: `${scopeEvents}.eventsTableConsideredButton`,
    defaultMessage: 'Considered',
  },
  eventsTableNotConsideredButton: {
    id: `${scopeEvents}.eventsTableNotConsideredButton`,
    defaultMessage: 'Not Considered',
  },
  eventsTableAddAllOMSCodes: {
    id: `${scopeEvents}.eventsTableAddAllOMSCodes`,
    defaultMessage: 'Add All OMS Codes',
  },
  eventsTableRemoveAllOMSCodes: {
    id: `${scopeEvents}.eventsTableRemoveAllOMSCodes`,
    defaultMessage: 'Unselect All OMS Codes',
  },

  // holidays
  holidaysTitle: {
    id: `${scopeHolidays}.holidaysTitle`,
    defaultMessage: 'Holidays',
  },
  holidaysTableName: {
    id: `${scopeHolidays}.holidaysTableName`,
    defaultMessage: 'Name',
  },
  holidaysTableLocalName: {
    id: `${scopeHolidays}.holidaysTableLocalName`,
    defaultMessage: 'Local Name',
  },
  holidaysTableCategory: {
    id: `${scopeHolidays}.holidaysTableCategory`,
    defaultMessage: 'Category',
  },
  holidaysTableStartDate: {
    id: `${scopeHolidays}.holidaysTableStartDate`,
    defaultMessage: 'Start Date',
  },
  holidaysTableEndDate: {
    id: `${scopeHolidays}.holidaysTableEndDate`,
    defaultMessage: 'End Date',
  },
  holidaysTableCountry: {
    id: `${scopeHolidays}.holidaysTableCountry`,
    defaultMessage: 'Country',
  },
  holidaysTableDistrict: {
    id: `${scopeHolidays}.holidaysTableDistrict`,
    defaultMessage: 'Counties',
  },
  holidaysTableHolidayType: {
    id: `${scopeHolidays}.holidaysTableHolidayType`,
    defaultMessage: 'Holiday Type',
  },
  holidaysTableSmartProd: {
    id: `${scopeHolidays}.holidaysTableSmartProd`,
    defaultMessage: 'SmartProd',
  },
  holidaysTableOMSCodes: {
    id: `${scopeHolidays}.holidaysTableOMSCodes`,
    defaultMessage: 'OMS Codes',
  },
  assignedHolidaysTitle: {
    id: `${scopeHolidays}.assignedHolidaysTitle`,
    defaultMessage: 'Assigned Holidays',
  },
  notAssignedHolidaysTitle: {
    id: `${scopeHolidays}.notAssignedHolidaysTitle`,
    defaultMessage: 'Not assigned Holidays',
  },
  holidaysTableErrorHolidayName: {
    id: `${scopeEvents}.holidaysTableErrorHolidayName`,
    defaultMessage: "Holiday '{holiday}': {error}",
  },

  // scope OMS Codes table
  filterToSelectedOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.filterToSelectedOMSCode`,
    defaultMessage: 'Filter',
  },
  clearFilterToSelectedOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.clearFilterToSelectedOMSCode`,
    defaultMessage: 'Clear Filter',
  },
  filterToSelectedOMSCodeTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.filterToSelectedOMSCodeTooltip`,
    defaultMessage: 'Filter to Selected OMSCode',
  },
  assignToSelectedSmartProdJobCodes: {
    id: `${scopeOMSCodesAndSmartProdJobs}.assignToSelectedSmartProdJobCodes`,
    defaultMessage: 'Assign',
  },
  assignToSelectedSmartProdJobCodesTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.assignToSelectedSmartProdJobCodesTooltip`,
    defaultMessage: 'Assign to Selected SmartProd Job Codes',
  },
  copyMapping: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMapping`,
    defaultMessage: 'Copy Mapping',
  },
  copyMappingDialogHeader: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogHeader`,
    defaultMessage: 'Copy Mapping',
  },
  copyMappingButtonTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingButtonTooltip`,
    defaultMessage:
      'You can copy mapping from another plan. Mapping will be copied only for SmartPlan activities that exist in this plan and the plan you copy from.',
  },
  copyMappingDialogSave: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogSave`,
    defaultMessage: 'Copy Selected',
  },
  copyMappingDialogCancel: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogCancel`,
    defaultMessage: 'Cancel',
  },
  copyMappingDialogOverride: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogOverride`,
    defaultMessage: 'Override',
  },
  copyMappingDialogType: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogType`,
    defaultMessage: 'Type',
  },
  copyMappingDialogTypePlan: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogTypePlan`,
    defaultMessage: 'Plan',
  },
  copyMappingDialogTypePlanningArea: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogTypePlanningArea`,
    defaultMessage: 'Planning Area',
  },
  copyMappingDialogName: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogName`,
    defaultMessage: 'Name',
  },
  copyMappingDialogCustomer: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogCustomer`,
    defaultMessage: 'Customers',
  },
  copyMappingDialogFacility: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogFacility`,
    defaultMessage: 'Facilities',
  },
  copyMappingDialogSmartProdSources: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogSmartProdSources`,
    defaultMessage: 'SmartProd Sources',
  },
  labelOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.labelOMSCode`,
    defaultMessage: 'OMS Code: {omsCode}',
  },
  labelActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.labelActivity`,
    defaultMessage: 'Activity',
  },
  mappingOMSCodesLabelWaring: {
    id: `${scopeOMSCodesAndSmartProdJobs}.mappingOMSCodesLabelWaring`,
    defaultMessage: 'Mapping is done from SmartCode, manual mapping is not possible!'
  },
  omsCodesTableOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableOMSCode`,
    defaultMessage: 'OMS Code',
  },
  omsCodesTableSmartProdSource: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartProdSource`,
    defaultMessage: 'SmartProd Source',
  },
  omsCodesTableSmartPlanActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartPlanActivity`,
    defaultMessage: 'SmartPlan Activity (UoM)',
  },
  omsCodesTableSmartPlanActivityShort: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartPlanActivityShort`,
    defaultMessage: 'SmartPlan Activity',
  },
  omsCodesTableSmartProdJobCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartProdJobCode`,
    defaultMessage: 'SmartProd Job Code (UoM)',
  },
  omsCodesTableSmartProdJobCodeWithCustomer: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartProdJobCodeWithCustomer`,
    defaultMessage: 'SmartProd Job Code (Customer / UoM)',
  },
  omsCodesTableSmartConversionRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartConversionRate`,
    defaultMessage: 'Conversion Rate',
  },
  omsCodesTableActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableActivity`,
    defaultMessage: 'Activity',
  },
  omsCodesTableJob: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableJob`,
    defaultMessage: 'Job',
  },
  omsCodesTableUnassign: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableUnassign`,
    defaultMessage: 'Unassign Current Activity',
  },
  omsCodesTableDefaultConversionRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableDefaultConversionRate`,
    defaultMessage: 'Default Conversion Rate',
  },
  invalidConversionRateErrorActivityDate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.invalidConversionRateErrorActivityDate`,
    defaultMessage: 'Invalid conversion rate activity value "{value}" for: {jobCode} / {date}',
  },
  invalidConversionRateErrorJobDate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.invalidConversionRateErrorJobDate`,
    defaultMessage: 'Invalid conversion rate job value "{value}" for: {jobCode} / {date}',
  },

  // forecast per OMS code
  forecastLoading:{
    id: `${scopeOMSCodesAndSmartProdJobs}.loading`,
    defaultMessage: 'Please wait, while we fetch the Forecast per OMS Code data',
  },
  smartVolumeLoading:{
    id: `${scopeOMSCodesAndSmartProdJobs}.smartVolumeLoading`,
    defaultMessage: 'Please wait, while we fetch the SmartVolume information',
  },
  omsLoading:{
    id: `${scopeOMSCodesAndSmartProdJobs}.omsLoading`,
    defaultMessage: 'Please wait, while we fetch the Mapping OMS Codes Activities and SmartProd Jobs data',
  },
  smartProdLoading:{
    id: `${scopeOMSCodesAndSmartProdJobs}.smartProdLoading`,
    defaultMessage: 'Please wait, while we fetch the SmartProd Runs data',
  },
  omsCodesTableEffort: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffort`,
    defaultMessage: 'Effort',
  },
  smartVolumeRefreshLoading: {
    id: `${scopeOMSCodesAndSmartProdJobs}.smartVolumeRefreshLoading`,
    defaultMessage: 'Please wait, while we refresh the SmartVolume information',
  },
  omsCodesTableEffortSmartVolume: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartVolume`,
    defaultMessage: 'SmartVolume Effort',
  },
  omsCodesTableEffortSmartVolumeEvent: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartVolumeEvent`,
    defaultMessage: 'SmartVolume One-off Event',
  },
  omsCodesTableeffortSmartVolumeWithAdjustment: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableeffortSmartVolumeWithAdjustment`,
    defaultMessage: 'SmartVolume Effort with Adjustment',
  },
  omsCodesTableEffortSmartProd: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartProd`,
    defaultMessage: 'Planner Driven Effort',
  },
  omsCodesTableEffortSmartProdEvent: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartProdEvent`,
    defaultMessage: 'Planner Driven One-off Event',
  },
  omsCodesTableeffortSmartProdWithEventAndAdjustment: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableeffortSmartProdWithEventAndAdjustment`,
    defaultMessage: 'Planner Driven Effort with Adjustment',
  },
  omsCodesTableActualProductivityRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableActualProductivityRate`,
    defaultMessage: 'Actual Productivity Rate',
  },
  omsCodesTableTargetProductivityRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableTargetProductivityRate`,
    defaultMessage: 'Target Productivity Rate',
  },
  omsCodesTableRatio: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableRatio`,
    defaultMessage: 'Ratio',
  },
  omsCodesTableColumnDisplayFilter: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableColumnDisplayFilter`,
    defaultMessage: 'Column Display Filter',
  },
  omsCodesTableSmartVolumeInfo: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartVolumeInfo`,
    defaultMessage: 'SmartVolume Info',
  },
  omsCodesTableLastRefresh: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRefresh`,
    defaultMessage: 'Last Refresh',
  },
  omsCodesTableLastRunInProgress: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRunInProgress`,
    defaultMessage: 'In Progress',
  },
  omsCodesTableSmartVolumeUsable: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartVolumeUsable`,
    defaultMessage: 'SmartVolume Usable',
  },
  omsCodesTableHolidays: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableHolidays`,
    defaultMessage: 'Holidays',
  },
  omsCodesTableLastRunFailed: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRunFailed`,
    defaultMessage: 'Failed',
  },
  omsCodesRefreshedToast: {
    id: `${scopeToolbar}.omsCodesRefreshedToast`,
    defaultMessage: 'Refreshed',
  },
  omsCodesTableAction: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableAction`,
    defaultMessage: 'Action',
  },
  omsCodesTableFieldName_effortSmartVolume: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartVolume`,
    defaultMessage: 'SmartVolume Effort',
  },
  omsCodesTableFieldName_effortSmartVolumeWithEvent: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartVolumeOneOff`,
    defaultMessage: 'SmartVolume One-Off Event',
  },
  omsCodesTableFieldName_effortSmartVolumeWithAdjustment: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartVolumeWithAdjustment`,
    defaultMessage: 'SV Effort Adjustment',
  },
  omsCodesTableFieldName_effortSmartProd: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartProd`,
    defaultMessage: 'Planner Driven Effort',
  },
  omsCodesTableFieldName_effortSmartProdWithEvent: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartProdOneOff`,
    defaultMessage: 'Planner Driven One-Off Event',
  },
  omsCodesTableFieldName_effortSmartProdWithEventAndAdjustment: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartProdWithEventAndAdjustment`,
    defaultMessage: 'PD Effort Adjustment',
  },
  omsCodesTableFieldName_actualPR: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_actualPR`,
    defaultMessage: 'Actual PR',
  },
  omsCodesTableFieldName_targetPR: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_targetPR`,
    defaultMessage: 'Target PR',
  },
  omsCodesTableFieldName_ratio: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_ratio`,
    defaultMessage: 'Ratio',
  },
  omsCodesTableFieldName_model: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_model`,
    defaultMessage: 'Model',
  },
  omsCodesTableRefreshSmartProdSources: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableRefreshSmartProdSources`,
    defaultMessage: 'Refresh All',
  },
  omsCodesTableModelTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableModelTooltip`,
    defaultMessage: 'Model: {model}',
  },

  // SmartProd runs table
  smartProdRuns: {
    id: `${scopeSmartProdRuns}.smartProdRuns`,
    defaultMessage: 'SmartProd Runs',
  },
  dataToRequestTitle: {
    id: `${scopeSmartProdRuns}.dataToRequestTitle`,
    defaultMessage: 'Data to request',
  },
  requestStatusTitle: {
    id: `${scopeSmartProdRuns}.requestStatusTitle`,
    defaultMessage: 'Request status',
  },
  smartProdRunsName: {
    id: `${scopeSmartProdRuns}.smartProdRunsName`,
    defaultMessage: 'Name',
  },
  smartProdRunsSartDate: {
    id: `${scopeSmartProdRuns}.smartProdRunsSartDate`,
    defaultMessage: 'Start Date',
  },
  smartProdRunsEndDate: {
    id: `${scopeSmartProdRuns}.smartProdRunsEndDate`,
    defaultMessage: 'End Date',
  },
  smartProdRunsDataSourcePeriod: {
    id: `${scopeSmartProdRuns}.smartProdRunsDataSourcePeriod`,
    defaultMessage: 'Data Source Period',
  },
  smartProdRunsForecastPeriod: {
    id: `${scopeSmartProdRuns}.smartProdRunsForecastPeriod`,
    defaultMessage: 'Forecast Period',
  },
  smartProdRunsPlaceholderYear: {
    id: `${scopeSmartProdRuns}.smartProdRunsPlaceholderYear`,
    defaultMessage: 'Year',
  },
  smartProdRunsPlaceholderMonths: {
    id: `${scopeSmartProdRuns}.smartProdRunsPlaceholderMonths`,
    defaultMessage: 'Months',
  },
  smartProdRunsPlaceholderWeeks: {
    id: `${scopeSmartProdRuns}.smartProdRunsPlaceholderWeeks`,
    defaultMessage: 'Weeks',
  },
  smartProdRunsPlaceholderDayOfWeek: {
    id: `${scopeSmartProdRuns}.smartProdRunsPlaceholderDayOfWeek`,
    defaultMessage: 'Day of Week',
  },
  smartProdRunsPlaceholderDays: {
    id: `${scopeSmartProdRuns}.smartProdRunsPlaceholderDays`,
    defaultMessage: 'Days',
  },
  smartProdRunsCreated: {
    id: `${scopeSmartProdRuns}.smartProdRunsCreated`,
    defaultMessage: 'Created',
  },
  smartProdRunsCreatedBy: {
    id: `${scopeSmartProdRuns}.smartProdRunsCreatedBy`,
    defaultMessage: 'Created by',
  },
  smartProdRunsCalculated: {
    id: `${scopeSmartProdRuns}.smartProdRunsCalculated`,
    defaultMessage: 'Calculated',
  },
  smartProdRunsCalculatedBy: {
    id: `${scopeSmartProdRuns}.smartProdRunsCalculatedBy`,
    defaultMessage: 'Calculated by',
  },
  smartProdRunsStatus: {
    id: `${scopeSmartProdRuns}.smartProdRunsStatus`,
    defaultMessage: 'Status',
  },
  matchDayOfWeek: {
    id: `${scopeSmartProdRuns}.matchDayOfWeek`,
    defaultMessage: 'Match Day of Week',
  },
  jobCodesRatio: {
    id: `${scopeSmartProdRuns}.jobCodesRatio`,
    defaultMessage: 'SmartProd Job Codes Ratio in OMS Code (%)',
  },
  actualProductivityRate: {
    id: `${scopeSmartProdRuns}.actualProductivityRate`,
    defaultMessage: 'Actual Productivity Rate',
  },
  targetProductivityRate: {
    id: `${scopeSmartProdRuns}.targetProductivityRate`,
    defaultMessage: 'Target Productivity Rate',
  },
  planerDrivenForecast: {
    id: `${scopeSmartProdRuns}.planerDrivenForecast`,
    defaultMessage: 'Planer Driven Forecast',
  },
  adjustmentMapping: {
    id: `${scopeSmartProdRuns}.adjustmentMapping`,
    defaultMessage: 'Adjustment Mapping',
  },
  smartProdRunsOMSCodes: {
    id: `${scopeSmartProdRuns}.smartProdRunsOMSCodes`,
    defaultMessage: 'OMS Codes',
  },
  smartProdRunsNewName: {
    id: `${scopeSmartProdRuns}.smartProdRunsNewName`,
    defaultMessage: 'Name',
  },
  smartProdRunsSmartProd: {
    id: `${scopeSmartProdRuns}.smartProdRunsSmartProd`,
    defaultMessage: 'SmartProd',
  },
  smartProdRunsAddButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsAddButton`,
    defaultMessage: 'Add',
  },
  smartProdRunsRequestButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsRequestButton`,
    defaultMessage: 'Request',
  },
  smartProdRunsCopyRunSettingsButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsCopyRunSettingsButton`,
    defaultMessage: 'Copy Run Settings',
  },
  smartProdRunsValidateSmartProdRunEntry: {
    id: `${scopeSmartProdRuns}.smartProdRunsValidateSmartProdRunEntry`,
    defaultMessage: 'At least one of the values bellow should be selected',
  },
  smartProdRunsErrorSmartProdRunName: {
    id: `${scopeEvents}.smartProdRunsErrorSmartProdRunName`,
    defaultMessage: "SmartProd Run '{smartProdRun}': {error}",
  },
  smartProdRunType: {
    id: `${scopeSmartProdRuns}.smartProdRunType`,
    defaultMessage: 'Type',
  },
  smartProdRunTypeRequest: {
    id: `${scopeSmartProdRuns}.smartProdRunTypeRequest`,
    defaultMessage: 'Request',
  },
  smartProdRunTypeSchedule: {
    id: `${scopeSmartProdRuns}.smartProdRunTypeSchedule`,
    defaultMessage: 'Schedule',
  },
  smartProdRunsScheduledRuns: {
    id: `${scopeSmartProdRuns}.smartProdRunsScheduledRuns`,
    defaultMessage: 'Scheduled Run',
  },
  smartProdRunsScheduleStartDate: {
    id: `${scopeSmartProdRuns}.smartProdRunsScheduleStartDate`,
    defaultMessage: 'Schedule Start Date',
  },
  smartProdRunsScheduleStartTime: {
    id: `${scopeSmartProdRuns}.smartProdRunsScheduleStartTime`,
    defaultMessage: 'Schedule Start Time',
  },
  smartProdRunsSchedulePeriod: {
    id: `${scopeSmartProdRuns}.smartProdRunsSchedulePeriod`,
    defaultMessage: 'Schedule Period (days)',
  },
  sourcePeriodLastWeek: {
    id: `${scopeSmartProdRuns}.sourcePeriodLastWeek`,
    defaultMessage: 'Last week',
  },
  sourcePeriodLast4Weeks: {
    id: `${scopeSmartProdRuns}.sourcePeriodLast4Weeks`,
    defaultMessage: 'Last 4 weeks',
  },
  sourcePeriodLastMonth: {
    id: `${scopeSmartProdRuns}.sourcePeriodLastMonth`,
    defaultMessage: 'Last month',
  },
  sourcePeriodLast3Months: {
    id: `${scopeSmartProdRuns}.sourcePeriodLast3Months`,
    defaultMessage: 'Last 3 months',
  },
  sourcePeriodSameMonthLastYear: {
    id: `${scopeSmartProdRuns}.sourcePeriodSameMonthLastYear`,
    defaultMessage: 'Same month last year',
  },
  sourcePeriodLastYear: {
    id: `${scopeSmartProdRuns}.sourcePeriodLastYear`,
    defaultMessage: 'Last year',
  },
  sourcePeriodCustom: {
    id: `${scopeSmartProdRuns}.sourcePeriodCustom`,
    defaultMessage: 'Custom',
  },
  sourcePeriodDateRange: {
    id: `${scopeSmartProdRuns}.sourcePeriodDateRange`,
    defaultMessage: 'Date range',
  },
  forecastPeriodNextWeek: {
    id: `${scopeSmartProdRuns}.forecastPeriodNextWeek`,
    defaultMessage: 'Next week',
  },
  forecastPeriodNext4Weeks: {
    id: `${scopeSmartProdRuns}.forecastPeriodNext4Weeks`,
    defaultMessage: 'Next 4 weeks',
  },
  forecastPeriodNextMonth: {
    id: `${scopeSmartProdRuns}.forecastPeriodNextMonth`,
    defaultMessage: 'Next month',
  },
  forecastPeriodNext3Months: {
    id: `${scopeSmartProdRuns}.forecastPeriodNext3Months`,
    defaultMessage: 'Next 3 months',
  },
  forecastPeriodCustom: {
    id: `${scopeSmartProdRuns}.forecastPeriodCustom`,
    defaultMessage: 'Custom',
  },
  smartProdRunsLastRun: {
    id: `${scopeSmartProdRuns}.smartProdRunsLastRun`,
    defaultMessage: 'Last Run',
  },
  smartProdRunsScheduleButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsScheduleButton`,
    defaultMessage: 'Schedule',
  },
  smartProdRunsUnScheduleButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsUnScheduleButton`,
    defaultMessage: 'Unschedule',
  },
  smartProdRunsDeleteButton: {
    id: `${scopeSmartProdRuns}.smartProdRunsDeleteButton`,
    defaultMessage: 'Delete',
  },
  smartProdRunsSchedulingHeader: {
    id: `${scopeSmartProdRuns}.smartProdRunsSchedulingHeader`,
    defaultMessage: 'Set run scheduling',
  },
  smartProdRunsStart: {
    id: `${scopeSmartProdRuns}.smartProdRunsStart`,
    defaultMessage: 'Start',
  },
  smartProdRunsEnd: {
    id: `${scopeSmartProdRuns}.smartProdRunsEnd`,
    defaultMessage: 'End',
  },
  smartProdRunsDays: {
    id: `${scopeSmartProdRuns}.smartProdRunsDays`,
    defaultMessage: 'Days',
  },
  smartProdRunsToday: {
    id: `${scopeSmartProdRuns}.smartProdRunsToday`,
    defaultMessage: 'Today',
  },
  source: {
    id: `${scopeSmartProdRuns}.source`,
    defaultMessage: 'Source',
  },
  jobCodeOmsName: {
    id: `${scopeSmartProdRuns}.jobCodeOmsName`,
    defaultMessage: 'OMS Code',
  },
  jobCodeName: {
    id: `${scopeSmartProdRuns}.jobCodeName`,
    defaultMessage: 'Smart Prod Source Job',
  },
  jobCodeAdjustmentName: {
    id: `${scopeSmartProdRuns}.jobCodeAdjustmentName`,
    defaultMessage: 'Adjustment Mapping Job',
  },
  adjsutment: {
    id: `${scopeSmartProdRuns}.adjsutment`,
    defaultMessage: 'Adjustment',
  },
  remaining: {
    id: `${scopeSmartProdRuns}.remaining`,
    defaultMessage: 'Remaining',
  },
  smartProdJobAdjustmentTable: {
    id: `${scopeSmartProdRuns}.smartProdJobAdjustmentTable`,
    defaultMessage: 'SmartProd Jobs Adjustments',
  },
  smartPlanActivityAdjustmentTable: {
    id: `${scopeSmartProdRuns}.smartPlanActivityAdjustmentTable`,
    defaultMessage: 'SmartPlan Activity Adjustments recalculation based on mapping',
  },
  direct: {
    id: `${scopeSmartProdRuns}.direct`,
    defaultMessage: 'Adjusted',
  },
  inDirect: {
    id: `${scopeSmartProdRuns}.inDirect`,
    defaultMessage: 'Adjusting',
  },
  activity: {
    id: `${scopeSmartProdRuns}.activity`,
    defaultMessage: 'Activity',
  },
  activityMapping: {
    id: `${scopeSmartProdRuns}.activityMapping`,
    defaultMessage: 'Adjustment Mapping Activity',
  },
  jobCodeAdjustmentsEnabled:{
    id: `${scopeSmartProdRuns}.jobCodeAdjustmentsEnabled`,
    defaultMessage: 'Adjustment Mapping',
  },
  timeTransformationTableRun:{
    id: `${scopeSmartProdRuns}.timeTransformationTableRun`,
    defaultMessage: 'Time Transformation Table Run',
  },
  TimeTransformationTableRunYes: {
    id: `${scopeSmartProdRuns}.TimeTransformationTableRunYes`,
    defaultMessage: 'Yes',
  },
  TimeTransformationTableRunNo: {
    id: `${scopeSmartProdRuns}.TimeTransformationTableRunNo`,
    defaultMessage: 'No',
  },
  tabs:{
    id: `${scopeSmartProdRuns}.tabs`,
    defaultMessage: 'Tabs',
  },
  applyToTabs:{
    id: `${scopeSmartProdRuns}.applyToTabs`,
    defaultMessage: 'Apply to Tab(s)',
  },
  shiftMapping:{
    id: `${scopeSmartProdRuns}.shiftMapping`,
    defaultMessage: 'Shift Mapping',
  },
  shiftFromSmartPlan: {
    id: `${scopeSmartProdRuns}.shiftFromSmartPlan`,
    defaultMessage: 'Shift from SmartPlan',
  }, 
  startTime: {
    id: `${scopeSmartProdRuns}.startTime`,
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: `${scopeSmartProdRuns}.endTime`,
    defaultMessage: 'End Time',
  },
  breakTime: {
    id: `${scopeSmartProdRuns}.breakTime`,
    defaultMessage: 'Break Time(minutes)',
  },
  shiftFromSmartProd: {
    id: `${scopeSmartProdRuns}.shiftFromSmartProd`,
    defaultMessage: 'Shift from SmartProd',
  },
  setStartTime: {
    id: `${scopeSmartProdRuns}.setStartTime`,
    defaultMessage: 'SET START TIME',
  },
  setEndTime: {
    id: `${scopeSmartProdRuns}.setEndTime`,
    defaultMessage: 'SET END TIME',
  },
  split:{
    id: `${scopeSmartProdRuns}.split`,
    defaultMessage: '%Split of SmartProd Shift',
  },
  action: {
    id: `${scopeSmartProdRuns}.action`,
    defaultMessage: 'Action',
  },
  duplicate: {
    id: `${scopeSmartProdRuns}.duplicate`,
    defaultMessage: 'Duplicate row',
  },
  delete: {
    id: `${scopeSmartProdRuns}.delete`,
    defaultMessage: 'Delete row',
  },
  eventsTableAddAllTabs:{
    id: `${scopeSmartProdRuns}.eventsTableAddAllTabs`,
    defaultMessage: 'Add All Tabs',
  },
  forecastInformationMessage: {
    id: `${scopeSmartProdRuns}.forecastInformationMessage`,
    defaultMessage: 'Please select Tab(s) and click on Confirm Seletion to view Shift Mapping table',
  },
  confirmSelection: {
    id: `${scopeSmartProdRuns}.confirmSelection`,
    defaultMessage: 'Confirm Selection',
  }
});
