/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ShiftAddLine from 'components/ShiftAddLine';
import ShiftSettings from 'containers/ShiftSettings';

/* eslint-disable react/prefer-stateless-function */
class ShiftDefinition extends React.PureComponent {
  render() {
    const { values, formik } = this.props;
    return (
      <div>
        <ShiftAddLine {...{ values }} />
        <ShiftSettings tableKey="shift-definition" labourCategory={false} formik={formik} />
      </div>
    );
  }
}

ShiftDefinition.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default ShiftDefinition;
