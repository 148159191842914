import React, { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getToken } from 'containers/App/selectors';
import { Section } from 'containers/PlanResultPage';
import { timeToApiFormat } from 'utils/commonDetailSaga';
import { ApiPlanDTO, ApiPlanningAreaDTO, ApiSmartProdSourceDTO, ApiSmartVolumeRunDTO } from 'types/drep-backend.d';

import { fetchEntityRelation, fetchSmartProdInstances, fetchSmartVolumeRuns, fetchWarehouses } from './fetch';
import messages from './messages';
import SmartVolumeAddAssociationForm from './SmartVolumeAddAssociationForm';
import SmartVolumeAssociationTable from './SmartVolumeAssociationTable';
import SmartVolumeRunsTable from './SmartVolumeRunsTable';
import { EntityEntry, ExtendedSmartProdSourceDTO, FacilityWarehouseEntityRelations } from './types';

const Subtitle = styled.p`
  margin: 12px 20px 12px 0;
`;

type Props = {
  dirty: boolean;
  plan: ApiPlanDTO & ApiPlanningAreaDTO;
  storePlanDetails: (update: Record<string, unknown>) => void;
  fetchDetails: () => void;
  handleScheduleVolumeUpdate:Function;
};

const SmartVolume: React.FC<Props> = ({ dirty, plan, fetchDetails, storePlanDetails, handleScheduleVolumeUpdate }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ApiSmartVolumeRunDTO[]>(null);
  const [allSmartProdInstances, setSmartProdInstances] = useState<EntityEntry[]>([]);
  const [allWHIDs, setWHIDs] = useState<EntityEntry[]>([]);
  const [entityRelations, setEntityRelations] = useState<FacilityWarehouseEntityRelations>({});

  const token: string = useSelector(getToken);

  const planningParameters = plan?.planningParameters;
  const associations: ExtendedSmartProdSourceDTO[] = (planningParameters?.smartProdSourceList || []).map(sp => {
    const smartProdSourceDate = sp.scheduleStartDate ? DateTime.fromISO(sp.scheduleStartDate) : null;
    const res: ExtendedSmartProdSourceDTO = {
      ...sp,
      scheduleTime: smartProdSourceDate
        ? timeToApiFormat(
            Duration.fromObject({ hours: smartProdSourceDate.hour, minutes: smartProdSourceDate.minute, seconds: 0 }),
          )
        : null,
      smartProdSourceDate: sp.scheduleStartDate,
    };
    return res;
  });

  const facilities = plan.planningArea?.facilities || plan.facilities || [];
  const addEntryFacilities = facilities.map(facility => ({
    value: facility.id,
    label: `${facility.code} : ${facility.name}`,
    code: facility.code,
  }));
  const allFacilities = addEntryFacilities;

  useEffect(() => {
    fetchSmartProdInstances(token, dispatch, setSmartProdInstances);
    fetchWarehouses(token, dispatch, setWHIDs);
    fetchEntityRelation(token, dispatch, setEntityRelations);
    fetchSmartVolumeRuns(token, dispatch, planningParameters.id, setData);
  }, []);

  const dataProps = {
    allFacilities,
    allWHIDs,
    allSmartProdInstances,
  };

  return (
    <div>
      <Subtitle>
        <FormattedMessage {...messages.smartProdAssociationSubtitle} />
      </Subtitle>
      <SmartVolumeAddAssociationForm
        dirty={dirty}
        plan={plan}
        {...dataProps}
        addEntryFacilities={addEntryFacilities}
        entityRelations={entityRelations}
        fetchDetails={fetchDetails}
      />
      <SmartVolumeAssociationTable
        dirty={dirty}
        plan={plan}
        associations={associations}
        {...dataProps}
        storePlanDetails={storePlanDetails}
        handleScheduleVolumeUpdate={handleScheduleVolumeUpdate}
      />
      {/* <Section message={messages.smartVolumeRunsSubtitle} expanded>
        <SmartVolumeRunsTable data={data} {...dataProps} />
      </Section> */}
    </div>
  );
};

export default SmartVolume;
