import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { getIn, connect as formikConnect } from 'formik';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';

import NameWithToolTip from 'components/NameWithToolTip';
import IconButton from '../../components/IconButton';
import messages from './messages';
import DeleteCellRenderer from '../../components/DeleteCellRenderer';
import { numberCellFormatter, toNumber, percentageCellFormatter } from '../../utils/utils';
import { TABLE_DEFAULTS } from '../App/constants';
import TableControlled from '../TableControlled';
import AddLineSection from './AddLineSection';

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${props => props.theme.color.grey4};
`;

const WrapInner = styled.div`
  width: ${props => props.width}px;;
  max-width: 100%;
  padding: 7px;
  display: flex;
  flex-wrap: wrap;
  ${IconButton} {
    max-height: 28px;
  }
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.height}px;
  min-width: 320px;
  align-items: center;
  > * {
    height: 100%;
  }
`;

function createColumnDef(props, mes, dep, rowData, roles) {
  const { editable } = props.data;
  const departmentComparator = (a, b) => {
    if(a === undefined) return 1;
    if(b === undefined) return -1;
    const nameA = a && a.name && a.name.toUpperCase();
    const nameB = b && b.name && b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const handleDepartmentValue = (params) => {
    const selectedDep = params && params.value && params.value.name;
    const departmentAvailable = dep.find(d => d.name == selectedDep);
    return departmentAvailable && departmentAvailable.name;
  };
  const handleRoleValue = (params) => {
    return params && params.value && params.value.name;
  }
  const handleSelectEditorValue = (params, props) => {
    if(props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT' && props.viewSettings.checkboxRole){
      const currentLabouryCategory = params.data.labourCategory;
      const currentRoleId = params.data.role;
      const isLCWithRoleAndWithoutDep = params.data.labourCategory && !params.data.department && params.data.role;
      if(isLCWithRoleAndWithoutDep){
        const currentRoleLabourCategories = rowData.filter((rd) =>((rd.department) &&((rd.role && rd.role.id) == (currentRoleId && currentRoleId.id))) && (rd.labourCategory.id == currentLabouryCategory.id));
        const usedDepartmentIds = currentRoleLabourCategories && currentRoleLabourCategories.map(uc =>(uc.department && uc.department.id));
        const dept = dep && dep.filter(d => !usedDepartmentIds.includes(d.id));
        return dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }));
      }
      const currentRoleLabourCategories = rowData.filter((rd) => (rd.department) && ((rd.role && rd.role.id) == (currentRoleId && currentRoleId.id)) && (rd.labourCategory.id == currentLabouryCategory.id));
      if((currentRoleLabourCategories && currentRoleLabourCategories.length) === (props.roles && props.roles.results && props.roles.results.length)){
        return [];
      }
      const selectedLabourCategories = rowData.filter((rd) => rd.role && (rd.labourCategory.id == currentLabouryCategory.id) && rd.department && rd.role);
      const usedDepartmentIds = selectedLabourCategories && selectedLabourCategories.map(uc => (uc.role && uc.role.id) && (uc.department && uc.department.id));
      if (usedDepartmentIds === null || usedDepartmentIds === undefined) {
        return dep && dep.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }))
      } else {
        const counts = {};

        for (const num of usedDepartmentIds) {
          counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        const depOptions = [];
        if (Object.keys(counts).length === 0) {
          return dep && dep.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }));
        } else {
          const allDeps = dep && dep.map((dep) => dep.id);
          allDeps && allDeps.forEach((dep) => {
            if (!counts[dep]) {
              counts[dep] = 0;
            }
          })
          for (const depId in counts) {
            if (counts[depId] === 0) {
              const dept = dep && dep.filter(d => (d.id == depId));
              const departments = dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }));
              depOptions.push(...departments);
            } else if (counts[depId] !== (props.roles && props.roles.results && props.roles.results.length)) {
              const dept = dep && dep.filter(d => (d.id == depId));
              const departments = dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }));
              depOptions.push(...departments);
            }
          }
          let result = depOptions.filter(
            (person, index) => index === depOptions.findIndex(
              other => person.id === other.id
            ));  
          const filteredResult = result && result.filter((r)=> r.id !== (params.data.department && params.data.department.id));
          return filteredResult;
        }
      }
    }
    const currentLabouryCategory = params.data.labourCategory;
    const selectedLabourCategories = rowData.filter((rd) => (rd.labourCategory.id == currentLabouryCategory.id) && rd.department);
    const currentDepartments = selectedLabourCategories.map((lc) => lc.department && lc.department.id);
    if (currentDepartments.length == dep.length) {
      return [];
    } else {
      return dep.filter(d => !currentDepartments.includes(d.id))
    }
  };

  const handleSelectRoleEditorValue = (params, props) => {
    if (props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT' && props.viewSettings.checkboxRole) {
      const currentLabouryCategory = params.data && params.data.labourCategory;
      const selectedLabourCategories = rowData.filter((rd) => rd.role && (rd.labourCategory.id == currentLabouryCategory.id));
      const filterDep = selectedLabourCategories && selectedLabourCategories.filter((select) => {
        return ((select && select.department && select.department.id) === (params && params.data && params.data.department && params.data.department.id))
      })
      if (filterDep && filterDep.length !== (props.roles && props.roles.results && props.roles.results.length)) {
        const usedroleIds = filterDep && filterDep.map(uc => (uc.role && uc.role.id));
        const rolesAvailable = usedroleIds && props.roles && props.roles.results && props.roles.results.filter(d => !usedroleIds.includes(d.id));
        return rolesAvailable && rolesAvailable.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }))
      }
    }
    const currentLabouryCategory = params.data.labourCategory;
    const selectedLabourCategories = rowData.filter((rd) => rd.role && (rd.labourCategory.id == currentLabouryCategory.id));
    const currentDepartments = selectedLabourCategories && selectedLabourCategories.map((lc) => lc.role && lc.role.id);
    return roles.filter(d => !currentDepartments.includes(d.id))
  };

  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.laborCategory),
      field: 'labourCategory.name',
      colId: 'laborCategory',
      cellRendererFramework: NameWithToolTip('labourCategory.regionalConfigurationName'),
      width: 140,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.headCountDefault),
      field: 'headCount',
      colId: 'headCount',
      type: 'numericColumn',
      width: 140,
      editable,
      valueFormatter: numberCellFormatter,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.breakTime),
      field: 'breakTime',
      colId: 'breakTime',
      width: 160,
      editable,
      valueFormatter: numberCellFormatter,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.productivity),
      field: 'productivity',
      colId: 'productivity',
      width: 110,
      editable,
      valueFormatter: percentageCellFormatter,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.absenteeismRate),
      field: 'absenteeismRate',
      colId: 'absenteeismRate',
      width: 110,
      editable,
      valueFormatter: percentageCellFormatter,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.vacationRate),
      field: 'vacationRate',
      colId: 'vacationRate',
      width: 110,
      editable,
      valueFormatter: percentageCellFormatter,
      menuTabs: ['filterMenuTab'],
    },
  ];
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: (row, params) => {
          props.deleteRowWithIndex(params.rowIndex);
        },
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
    });
  }
  if (props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT') {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.department),
      field: 'department',
      colId: 'department',
      width: 130,
      sortable: true,
      menuTabs: ['filterMenuTab'],
      filter: true,
      filterParams: {
        valueGetter : params => params?.data?.department?.name,
        applyButton: true,
      },
      comparator: departmentComparator,
      cellRendererFramework: NameWithToolTip(),
      valueFormatter: handleDepartmentValue,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => ({
        component: 'agRichSelect',
        values: handleSelectEditorValue(params, props),
        cellRenderer: p => p && p.value && p.value.name,
      }),
      singleClickEdit: true,
      editable: editable,
    });
  }
  if (props.viewSettings.checkboxRole) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.role),
      field: 'role',
      colId: 'role',
      width: 130,
      sortable: true,
      menuTabs: ['filterMenuTab'],
      comparator: departmentComparator,
      filter: true,
      filterParams: {
        valueGetter : params => params?.data?.role?.name,
        applyButton: true,
      },
      cellRendererFramework: NameWithToolTip(),
      valueFormatter: handleRoleValue,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => ({
        component: 'agRichSelect',
        values: handleSelectRoleEditorValue(params, props),
        cellRenderer: p => p && p.value && p.value.name,
      }),
      singleClickEdit: true,
      editable: editable,
    });
  }
  return columnDefs;
}

class LabourCategoryCellRenderer extends Component {
  componentDidMount() {
    // to allow for inner scrolling
    ReactDOM.findDOMNode(this).addEventListener(
      // eslint-disable-line
      'mousewheel',
      event => {
        event.stopPropagation();
      },
      false,
    );
    // to restrict user from staying at same section after scrolling SMP-3891
    document.addEventListener("scroll", function () {
      document.activeElement.blur();
    });
  }

  shouldComponentUpdate(nextProps) {
    //will be removed with final changes
    //console.log(this.props.viewSettings.checkboxRole, nextProps.viewSettings.checkboxRole)
    if (!isEqual(this.props.viewSettings.checkboxRole !== nextProps.viewSettings.checkboxRole)) {
      return true;
    }
    return false;
  };

  getFormikDataPath = () =>
    `planningParameters.periods.${this.props.periodIndex}.shifts.${this.props.data.shiftIndex
    }.labourCategoryTransitions`;

  getData = () => getIn(this.props.formik.values, this.getFormikDataPath()) || [];

  getFormikLabourCategoriesPath = () => `planningParameters.periods.${this.props.periodIndex}.labourCategoryParameters`;

  getLaborCategoryParameters = () => getIn(this.props.formik.values, this.getFormikLabourCategoriesPath()) || [];

  render() {
    const jsonValues = JSON.stringify(this.getData());
    const rowData = JSON.parse(jsonValues);
    // when assigned directly agGrid changes formik data during edit even if
    // doc says it never does: https://www.ag-grid.com/javascript-grid-client-side-model/#state-1-row-data
    // this also changes initial values so data is not found dirty and Save button stays gray (defect 342254)
    const usedCategoryIds = rowData.map(row => row.labourCategory.id);
    const departments = this.props.formik.values.planningParameters.departments;
    const labourAvailabilityType = this.props.formik.values.planningParameters.labourAvailabilityType;
    const roles = (this.props.roles && this.props.roles.results && this.props.roles.results.map((role) => (
      { id: role.id, label: role.name, value: role.name, name: role.name }
    ))) || [];
    const labourCategoriesRawData = this.getLaborCategoryParameters().map(lcp => lcp);
    const labourCategories = labourCategoriesRawData.filter(cat => !usedCategoryIds.includes(cat.labourCategory.id));
    const labourCategoriesDep = []; // Below section will be moved to function with final changes
    const labourCategoriesRole = [];
    const labourCategoriesDepRole = [];
    const tempRowData = [...rowData]
    labourCategoriesRawData.forEach((lab) => {
      let count = 0;
      let rCount = 0;
      let rdCount = 0;
      let rdWrCount = 0;
      let rdrCount = 0;
      let rWdr = 0;
      tempRowData.forEach(rd => {
        if ((rd.labourCategory.id == lab.labourCategory.id) && rd.department) {
          count++;
        }
        if ((rd.labourCategory.id == lab.labourCategory.id) && rd.department && !rd.role) {
          rdWrCount++;
        }
        if ((rd.labourCategory.id == lab.labourCategory.id) && !rd.department && rd.role) {
          rWdr++;
        }
        if ((rd.labourCategory.id == lab.labourCategory.id) && !rd.department && !rd.role) {
          rdrCount++;
        }
        if ((rd.labourCategory.id == lab.labourCategory.id) && rd.role) {
          rCount++;
        }
        if((rd.labourCategory.id == lab.labourCategory.id) && rd.department && rd.role){
          rdCount++;
        }
      })
      if (departments.length != count) {
        labourCategoriesDep.push(lab);
      }
      if (departments.length == count) {
        const emptyDep = tempRowData && tempRowData.filter((lc)=> (lc.labourCategory.id == lab.labourCategory.id) && !lc.department);
        if(emptyDep.length === 0){
          labourCategoriesDep.push(lab);
        }
      }
      if (roles.length == rCount) {
        const emptyDep = tempRowData && tempRowData.filter((lc)=> (lc.labourCategory.id == lab.labourCategory.id) && !lc.role);
        if(emptyDep.length === 0){
          labourCategoriesRole.push(lab);
        }
      }
      if (roles.length != rCount) {
        labourCategoriesRole.push(lab);
      }
      if((departments.length * roles.length ) !== rdCount){
        labourCategoriesDepRole.push(lab);
      }
      if((departments.length !== rdWrCount) || rdrCount === 0 || (roles.length !== rWdr)){
        const allReadyExists = labourCategoriesDepRole && labourCategoriesDepRole.find((l) => ((l && l.labourCategory && l.labourCategory.id) === (lab && lab.labourCategory && lab.labourCategory.id)));
        if(isEmpty(allReadyExists)){
          labourCategoriesDepRole.push(lab);
        }
      }
    })
    const labCategory = (labourAvailabilityType == 'DEPARTMENT' && !this.props.viewSettings.checkboxRole) ? labourCategoriesDep : (labourAvailabilityType == 'DEPARTMENT' && this.props.viewSettings.checkboxRole) ? labourCategoriesDepRole : (labourAvailabilityType == 'SHIFT_ONLY' && this.props.viewSettings.checkboxRole) ? labourCategoriesRole : labourCategories;
    return (
      <Wrap className={this.props.className}>
        <WrapInner width={(labourAvailabilityType == 'DEPARTMENT' && this.props.viewSettings.checkboxRole) ? 1350 : 1050}>
          {this.props.data.editable && (
            <AddLineSection
              labourAvailabilityType={labourAvailabilityType}
              roleChecked={this.props.viewSettings.checkboxRole}
              labourCategories={labCategory}
              roles={roles}
              departments={departments}
              rowData={rowData}
              addLineHandler={(category, departments =[], roles= []) => {
                const currentData = this.getData();
                const newData = [...currentData, ...this.createNewRow(category, departments, roles)];
                this.props.formik.setFieldValue(this.getFormikDataPath(), newData);
                this.props.api.resetRowHeights();
              }}
            />
          )}
          <TableWrap height={((rowData.length || 1) + 1) * 35 + 50}>
            <TableControlled
              key={`${this.props.data.editable}_${this.props.viewSettings.checkboxRole}`}
              defaultConfig={
                this.props.data.editable
                  ? TABLE_DEFAULTS.shiftDetailLabourCategoryEditTableConfig
                  : TABLE_DEFAULTS.shiftDetailLabourCategoryTableConfig
              }
              messages={messages}
              name={this.props.data.editable ? 'shiftDetailLabourCategoryEdit' : 'shiftDetailLabourCategory'}
              columnDefs={createColumnDef({ ...this.props, deleteRowWithIndex: this.deleteRowWithIndex }, messages, departments, rowData, roles)}
              rowData={rowData}
              rowHeight={35}
              pagination={false}
              onFilterChanged={() => this.handleFilterChanged()}
              //getRowNodeId={data => data.labourCategory.id}
              //deltaRowDataMode
              onCellValueChanged={params => {
                const convertedParams = params.newValue ? { ...params, newValue: toNumber(params.newValue) } : params;
                // updateData(convertedParams);
                this.onCellValueChanged(convertedParams, labourCategoriesRawData);
              }}
            />
          </TableWrap>
        </WrapInner>
      </Wrap>
    );
  }

  handleFilterChanged(){
    if(document.getElementsByClassName('ag-menu') && document.getElementsByClassName('ag-menu')[0] && document.getElementsByClassName('ag-menu')[0].style) document.getElementsByClassName('ag-menu')[0].style.display = 'none'
  }

  onCellValueChanged(params, labourCategoriesRawData) {
    let newValue = params.newValue;
    let parentLabourCategory = labourCategoriesRawData && labourCategoriesRawData.filter((lab) => lab.labourCategory.id == params.data.labourCategory.id);
    if (params.newValue === '') {
      if (params.colDef.field == "headCount" || params.colDef.field == "breakTime") {
        newValue = 0;
      }
      if (params.colDef.field == "absenteeismRate") {
        newValue = parentLabourCategory && parentLabourCategory[0] && parentLabourCategory[0].absenteerismRate;
      }
      if (params.colDef.field == "productivity") {
        newValue = parentLabourCategory && parentLabourCategory[0] && parentLabourCategory[0].productivity;
      }
      if (params.colDef.field == "vacationRate") {
        newValue = parentLabourCategory && parentLabourCategory[0] && parentLabourCategory[0].vacationRate;
      }
    }
    if (params.newValue !== params.oldValue) {
      const path = `${this.getFormikDataPath()}.${params.rowIndex}.${params.colDef.field}`;
      if (params.oldValue === newValue) {
        this.props.formik.setFieldValue(path, params.newValue);
      }
      this.props.formik.setFieldValue(path, newValue);
    }
    return true;
  }

  deleteRowWithIndex = deleteIndex => {
    const newData = [...this.getData()];
    newData.splice(deleteIndex, 1);
    this.props.formik.setFieldValue(this.getFormikDataPath(), newData);
  };

  createNewRow(category, departments, roles) {
    if (this.props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT' && !this.props.viewSettings.checkboxRole) {
      if (departments.length == 0) {
        return [{
          labourCategory: category.labourCategory,
          absenteeismRate: category.absenteerismRate,
          productivity: category.productivity,
          vacationRate: category.vacationRate,
          headCount: 0,
          breakTime: 0,
        }]
      } else {
        const newData = departments.map((dep) => {
          return {
            labourCategory: category.labourCategory,
            department: { name: dep.value, id: dep.id },
            absenteeismRate: category.absenteerismRate,
            productivity: category.productivity,
            vacationRate: category.vacationRate,
            headCount: 0,
            breakTime: 0,
          }
        })
        return newData;
      }
    } else if (this.props.formik.values.planningParameters.labourAvailabilityType == 'SHIFT_ONLY' && this.props.viewSettings.checkboxRole) {
      if(roles.length === 0){
        return [{
          labourCategory: category.labourCategory,
          absenteeismRate: category.absenteerismRate,
          productivity: category.productivity,
          vacationRate: category.vacationRate,
          headCount: 0,
          breakTime: 0,
        }]
      }else {
        const newData = roles.map((role) => {
          return {
            labourCategory: category.labourCategory,
            role: { id: role.id, name: role.name },
            absenteeismRate: category.absenteerismRate,
            productivity: category.productivity,
            vacationRate: category.vacationRate,
            headCount: 0,
            breakTime: 0,
          }
        })
        return newData;
      }
    } else if (this.props.formik.values.planningParameters.labourAvailabilityType == 'SHIFT_ONLY') {
      const newDataShift = [
        {
          labourCategory: category.labourCategory,
          headCount: 0,
          breakTime: 0,
          absenteeismRate: category.absenteerismRate,
          productivity: category.productivity,
          vacationRate: category.vacationRate,
        }
      ]
      return newDataShift;
    } else if (this.props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT' && this.props.viewSettings.checkboxRole) {
      if ((isEmpty(departments)) && ((roles && roles.length === 0) || roles === null)) {
        return [{
          labourCategory: category.labourCategory,
          headCount: 0,
          breakTime: 0,
          absenteeismRate: category.absenteerismRate,
          productivity: category.productivity,
          vacationRate: category.vacationRate,
        }]
      } else if ((isEmpty(departments)) && (roles && roles.length > 0)) {
        const newData = roles.map((role) => {
          return {
            labourCategory: category.labourCategory,
            role: { id: role.id, name: role.name },
            absenteeismRate: category.absenteerismRate,
            productivity: category.productivity,
            vacationRate: category.vacationRate,
            headCount: 0,
            breakTime: 0,
          }
        })
        return newData;
      }  else if ((departments) && ((roles && roles.length === 0) || roles === null)) {
          return [{
            labourCategory: category.labourCategory,
            department: { name: departments.value, id: departments.id },
            absenteeismRate: category.absenteerismRate,
            productivity: category.productivity,
            vacationRate: category.vacationRate,
            headCount: 0,
            breakTime: 0,
          }]
      }
      else {
          const newData = roles.map((role) => {
            return {
              labourCategory: category.labourCategory,
              department: { name: departments.value, id: departments.id },
              role: { id: role.id, name: role.name },
              absenteeismRate: category.absenteerismRate,
              productivity: category.productivity,
              vacationRate: category.vacationRate,
              headCount: 0,
              breakTime: 0,
            }
          })
        return newData;
      }

    }
  }
}

LabourCategoryCellRenderer.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
  reactContainer: PropTypes.object,
  className: PropTypes.string,
  token: PropTypes.string,
  formik: PropTypes.object,
  periodIndex: PropTypes.number,
  isIndirect: PropTypes.bool,
  api: PropTypes.object, // parent grid api
};

const mapStateToProps = createStructuredSelector({
  viewSettings: selectViewModeStoredData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//export default styled(injectIntl(formikConnect(LabourCategoryCellRenderer)))``;

export default compose(
  injectIntl,
  withConnect,
  formikConnect,
)(LabourCategoryCellRenderer);
