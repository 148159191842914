/*
 * ResultToolBar Messages
 *
 * This contains all the text for the ResultToolBar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'app.components.ResultToolBar.back',
    defaultMessage: 'Back',
  },
  copy: {
    id: 'app.components.ResultToolBar.copy',
    defaultMessage: 'Copy',
  },
  csv: {
    id: 'app.components.ResultToolBar.csv',
    defaultMessage: 'CSV',
  },
  history: {
    id: 'app.components.ResultToolBar.history',
    defaultMessage: 'History',
  },
  display: {
    id: 'app.components.ResultToolBar.display',
    defaultMessage: 'Display',
  },
  edit: {
    id: 'app.components.ResultToolBar.edit',
    defaultMessage: 'Edit',
  },
  graphs: {
    id: 'app.components.ResultToolBar.graphs',
    defaultMessage: 'Graphs',
  },
  upload: {
    id: 'app.components.ResultToolBar.upload',
    defaultMessage: 'Upload',
  },
  calculationStats: {
    id: 'app.components.ResultToolBar.calculationStats',
    defaultMessage: 'Calculation Statistics',
  },
});
