import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColGroupDef } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { InjectedIntl } from 'react-intl';
import styled from 'styled-components';

import { ProductivityRateForecastPlanSwitch } from 'components/ProductivityRate/types';
import AgTable from 'components/Table';
import { TableWrap } from 'containers/PlanResultPage/styled';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';
import { LOG_PROD_RATE } from 'utils/logger';
import { numberFormat, toNumber } from 'utils/utils';
import { LookupsType } from 'types/planningParametersTypes';

import messages from './messages';

export interface ProductivityRateTableProps {
  isEdit: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  planningParameters: any;
  lookups: LookupsType;
  intl: InjectedIntl;
  forecastPlanSwitch: ProductivityRateForecastPlanSwitch;

  setFieldValue(field: string, value: any);

  granularity: string;
  dayTransformationType: string;
}

const Table = styled(AgTable)`
  .cell-prod-override {
    background-color: rgba(223, 100, 100, 0.44);
  }

  .override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }
`;

function calculateTableData(props: ProductivityRateTableProps) {
  const { forecastPlanSwitch } = props;
  LOG_PROD_RATE.d('Calculating table data', props);
  const colDefs: ColGroupDef[] = [
    {
      children: [
        {
          colId: 'activity',
          field: 'activity',
          headerName: props.intl.formatMessage(messages.activityKey),
          headerTooltip: props.intl.formatMessage(messages.activityKey),
          width: 320,
          minWidth: 320,
          pinned: true,
        },
        {
          colId: 'uom',
          field: 'uom',
          headerName: props.intl.formatMessage(messages.uom),
          headerTooltip: props.intl.formatMessage(messages.uom),
          width: 130,
          minWidth: 100,
          pinned: true,
        },
        {
          colId: 'wzpName',
          field: 'wzpName',
          headerName: props.intl.formatMessage(messages.shift),
          headerTooltip: props.intl.formatMessage(messages.shift),
          width: 120,
          minWidth: 120,
          pinned: true,
        },
      ],
    },
  ];
  const { rowData, days } = props.planningParameters.activityForecastTable;
  const sortedDays = [...days].sort();
  const isWeekGranularity = props.granularity.toUpperCase() === 'WEEK';
  const weekOrDay = props.intl.formatMessage(isWeekGranularity ? messages.week : messages.day);
  sortedDays.forEach(day => {
    colDefs.push({
      headerName: weekOrDay,
      children: [
        {
          sortable: false,
          suppressMenu: true,
          suppressMovable: true,
          colId: day,
          headerName: parseDateAndFormatToLocalString(day),
          headerTooltip: parseDateAndFormatToLocalString(day),
          width: 60,
          minWidth: 60,
          pinned: false,
          editable: params => {
            const readOnlyFlag = params.data[day] === undefined;
            return props.isEdit && forecastPlanSwitch === 'plan' && !readOnlyFlag;
          },
          valueGetter: params => {
            const row = params.data[day];
            if (forecastPlanSwitch === 'plan') {
              if (row?.overrideHours !== null && row?.overrideHours !== undefined) {
                return row?.overrideHours;
              }
            }
            if (row?.dataSource === 'DAY_ACTUAL' || row?.dataSource === 'DAY_DEFAULT') {
              return row?.actualHours;
            }
            if(row?.dataSource === 'DAY_TARGET' && (row?.targetHours === undefined || row?.targetHours === null)){
              return row?.actualHours;
            }
            return row?.targetHours;
          },
          tooltipValueGetter: params => {
            const row = params.data[day];
            if(row === undefined){
              return null;
            }
            if (forecastPlanSwitch === 'plan') {
              if (row?.overrideHours !== null && row?.overrideHours !== undefined) {
                return 'Overridden value';
              }
            }
            if(props.dayTransformationType === 'ACTIVITY'){
              return 'Default value';
            }
            if (isWeekGranularity) {
              return 'Default value';
            }
            if (row?.dataSource === 'DAY_ACTUAL') {
              return 'Actual';
            }
            if (row?.dataSource === 'DAY_DEFAULT') {
              return 'Default value';
            }
            return 'Target';
          },
          valueFormatter: params =>
            // eslint-disable-next-line no-restricted-globals
            params.value === null || params.value === '' || isNaN(params.value)
              ? ''
              : `${numberFormat(toNumber(params.value)?.toFixed(2))}`,
          valueSetter: params => {
            const current = params.data[params.colDef.colId];
            const newValue = toNumber(params.newValue);
            if (Number.isNaN(newValue)) {
              return false;
            }
            if ((current && current.actualHours === newValue) || params.newValue === '') {
              // eslint-disable-next-line no-param-reassign
              params.data[params.colDef.colId] = { ...current, overrideHours: undefined };
            } else {
              // eslint-disable-next-line no-param-reassign
              params.data[params.colDef.colId] = { ...current, overrideHours: newValue };
            }
            // without redraw agGrid won't call cellClass function :-(
            params.api.redrawRows({ rowNodes: [params.node] });
            return true;
          },
          cellClass: params => {
            const cssClasses = [];
            if (props.isEdit && forecastPlanSwitch === 'plan') {
              cssClasses.push('cell-editable');
            }
            const row = params.data[day];
            if (row?.dataSource !== 'DAY_ACTUAL' && !isWeekGranularity) {
              cssClasses.push('cell-data-source-not-default');
            }
            if (
              params.data[day]?.overrideHours !== null &&
              params.data[day]?.overrideHours !== undefined &&
              forecastPlanSwitch === 'plan'
            ) {
              cssClasses.push('override-state-ovrd');
            }
            return cssClasses.join(' ');
          },
        },
      ],
    });
  });
  return { colDefs, rowData: cloneDeep(rowData.filter(row => !row.indirect && !row.ForecastOutOfPlanDateInterval)) };
}

export const ProductivityRateTable = (props: ProductivityRateTableProps) => {
  const [gridApi, setGridApi] = useState(null);
  let pasteFlag = false;
  const onGridReady = params => {
    setGridApi(params);
    //params.api.sizeColumnsToFit();
  };

  const tableData = calculateTableData(props);

  useEffect(() => {
    if (gridApi) {
      gridApi.api.setColumnDefs([]);
      gridApi.api.setRowData(tableData.rowData);
      gridApi.api.setColumnDefs(tableData.colDefs);
      //gridApi.api.sizeColumnsToFit();
      gridApi.api.refreshCells({ force: true });
    }
  }, [
    gridApi,
    props.forecastPlanSwitch,
    props.granularity,
    props.planningParameters.startDay,
    props.planningParameters.endDay,
    props.isEdit
  ]);

  const onCellValueChanged = params => {
    if (!pasteFlag) {
      LOG_PROD_RATE.d('Field value change with params', params);
      const cellData = params.data[params.colDef.colId];
      const formikPath = `planningParameters.activityForecastTable.rowData.${params.data.index}.${params.colDef.colId}`;
      props.setFieldValue(formikPath, cellData);
      LOG_PROD_RATE.d(`Formik path ${formikPath} set to`, cellData);
    }
  };

  const onPaste = data => {
    pasteFlag = false;
    const dataToLoad = [];
    data.api.forEachNode(node => {
      console.log(node,"node")
      if (!node.group && node.data) {
        dataToLoad.push(node.data);
      }
    });
    const formikPath = `planningParameters.activityForecastTable.rowData`;
    props.setFieldValue(formikPath, dataToLoad);
    LOG_PROD_RATE.d('After paste new data is', data);
  };

  return (
    <TableWrap key={props.granularity}>
      <Table
        messages={messages}
        applyColumnDefOrder
        pagination
        paginationPageSize={50}
        columnDefs={tableData.colDefs}
        rowData={tableData.rowData}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        onPaste={onPaste}
        onPasteStart={() => pasteFlag = true}
        onPasteEnd={onPaste}
      />
    </TableWrap>
  );
};
