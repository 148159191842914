import { listWithIdToMap } from 'utils/api/calculations';
import { parseDate } from 'utils/dateTime';
import { DateTime } from 'luxon';

export function calculateSmartProdJobTableData(data) {
  const rowData = [];
  let minDay = null;
  let maxDay = null;
  const days = new Set<string>();
  data.forEach((item) => {
    item.jobeCodeAdjustmentsList.forEach((jobCode) => {
      const row = {
        jobCodeAdjustmentId: jobCode.jobCodeAdjustmentId,
        jobCodeAdjustmentName: `${jobCode.jobCodeAdjustmentName} (${jobCode?.customer})`,
        jobCodeAdjustmentOmsName: jobCode.jobCodeAdjustmentOmsName,
        jobCodeId: jobCode.jobCodeId,
        jobCodeName: `${jobCode.jobCodeName} (${jobCode?.customer})`,
        jobCodeOmsName: jobCode.jobCodeOmsName,
        source: jobCode.source,
        customer: jobCode.customer,
      }
      jobCode?.dates.forEach(dayItem => {
        if (dayItem.date < minDay || !minDay) {
          minDay = dayItem.date;
        }
        if (dayItem.date > maxDay || !maxDay) {
          maxDay = dayItem.date;
        }
        if (!days.has(dayItem.date)) {
          days.add(dayItem.date);
        }
        row[dayItem.date] = {
          adjustment: dayItem.adjustment
        };
      });
      rowData.push(row);
    })
  })
  return { rowData: rowData.map((row, index) => ({ ...row, index })), minDay, maxDay, days };
}

export function calculateIndirectData(data) {
  data.map((item) => {
    item.jobeCodeAdjustmentsList = calculateRemainingData(item.jobeCodeAdjustmentsList),
      item.source = item.source
  })
  return data;
}

export function calculateIndirectActivityData(data) {
  data && data.sources && data.sources.map((item) => {
    item.rows = calculateRemainingActivityData(item.rows),
      item.source = item.source
  })
  return data;
}

function calculateRemainingData(dataRem) {
  var rowDataIndirect = dataRem.reduce((final, data) => {
    let isAlready = final.find((value) => {
      return value.jobCodeAdjustmentId == data.jobCodeAdjustmentId;
    });
    if (!isAlready) {
      final.push(data);
    } else {
      var index = final.indexOf(isAlready);
      const initialData = final[index] && final[index].dates;
      const columnsData = [...initialData];
      columnsData.forEach((cd, index) => {
        const dataToAdd = data.dates.filter(c => c.date == cd.date);
        if (columnsData[index].adjustment >= 0 || dataToAdd[0].adjustment >= 0) {
          columnsData[index].adjustment = parseFloat(columnsData[index].adjustment || 0) + parseFloat(dataToAdd[0].adjustment || 0);
        }
      })
      final[index].dates = [...columnsData]
    }
    return final;
  }, [])
  return rowDataIndirect
}

function calculateRemainingActivityData(dataRem) {
  var rowDataIndirect = dataRem.reduce((final, data) => {
    let isAlready = final.find((value) => {
      return value.activityForecastFromLogicalKey == data.activityForecastFromLogicalKey;
    });
    if (!isAlready) {
      final.push(data);
    } else {
      var index = final.indexOf(isAlready);
      const initialData = final[index] && final[index].columns;
      const columnsData = [...initialData];
      columnsData.forEach((cd, index) => {
        const dataToAdd = data.columns.filter(c => c.date == cd.date);
        if (columnsData[index]?.rate >= 0 || dataToAdd[0]?.rate >= 0) {
          columnsData[index].rate = parseFloat(columnsData[index]?.rate || 0) + parseFloat(dataToAdd[0]?.rate || 0);
        }
      })
      final[index].columns = [...columnsData]
    }
    return final;
  }, [])
  return rowDataIndirect
}


export function calculateSmartProdActivityTableData(data, plan, departments, activityParamaterList) {
  const pa = plan.planningArea || plan;
  const lookups = {
    activities: {},
    departments: listWithIdToMap(departments),
    customers: listWithIdToMap(pa.customers),
    uoms: {},
    facilities: listWithIdToMap(pa.facilities),
  };

  const rowData = [];
  let minDay = null;
  let maxDay = null;
  const days = new Set<string>();
  data && data.sources && data.sources.forEach((data) => {
    data.rows.forEach((item) => {
      const activityFrom = item.activityForecastToLogicalKey;
      const activityTo = item.activityForecastFromLogicalKey;
      const department = (activityFrom.split('_')[1] ? lookups.departments[activityFrom.split('_')[1]] : null) || { name: activityFrom.split('_')[1], facilityId: null };
      const customer = (activityFrom.split('_')[2] ? lookups.customers[activityFrom.split('_')[2]] : null)?.name || activityFrom.split('_')[2];
      const activityFromData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityFrom?.split('_')[0]);
      const activityToData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityTo?.split('_')[0]);
      const activityDirectName = `${customer}/${department?.name}/${activityFromData?.activity?.name} (${activityFromData?.activity?.variableName})`;
      const departmentMapping = (activityFrom.split('_')[1] ? lookups.departments[activityTo.split('_')[1]] : null) || { name: activityTo.split('_')[1], facilityId: null };
      const customerMapping = (activityFrom.split('_')[2] ? lookups.customers[activityTo.split('_')[2]] : null)?.name || activityTo.split('_')[2];
      const omsDirect = `${activityFromData?.activity?.omsCode}-${activityFromData?.activity?.omsName}`
      const activityIndirectName = `${customerMapping}/${departmentMapping?.name}/${activityToData?.activity?.name} (${activityToData?.activity?.variableName})`;
      const row = {
        activity: activityDirectName,
        activityMapping: activityIndirectName,
        omsName: omsDirect,
        source: data.source,
      }
      item?.columns.forEach(dayItem => {
        if (dayItem.date < minDay || !minDay) {
          minDay = dayItem.date;
        }
        if (dayItem.date > maxDay || !maxDay) {
          maxDay = dayItem.date;
        }
        if (!days.has(dayItem.date)) {
          days.add(dayItem.date);
        }
        row[dayItem.date] = {
          rate: dayItem.rate
        };
      });
      rowData.push(row);
    })

  }
  )
  return { rowData: rowData.map((row, index) => ({ ...row, index })), minDay, maxDay, days };
}

export function calculateSmartProdIndirectActivityTableData(data, plan, departments, activityParamaterList) {
  const pa = plan.planningArea || plan;
  const lookups = {
    activities: {},
    departments: listWithIdToMap(departments),
    customers: listWithIdToMap(pa.customers),
    uoms: {},
    facilities: listWithIdToMap(pa.facilities),
  };

  const rowData = [];
  let minDay = null;
  let maxDay = null;
  const days = new Set<string>();
  data && data.sources && data.sources.forEach((data) => {
    data.rows.forEach((item) => {
      const activityFrom = item.activityForecastToLogicalKey;
      const activityTo = item.activityForecastFromLogicalKey;
      const department = (activityFrom.split('_')[1] ? lookups.departments[activityTo.split('_')[1]] : null) || { name: activityFrom.split('_')[1], facilityId: null };
      const customer = (activityFrom.split('_')[2] ? lookups.customers[activityTo.split('_')[2]] : null)?.name || activityFrom.split('_')[2];
      const activityToData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityTo?.split('_')[0]);
      const omsInDirect = `${activityToData?.activity?.omsCode}-${activityToData?.activity?.omsName}`
      const activityIndirectName = `${customer}/${department?.name}/${activityToData?.activity?.name} (${activityToData?.activity?.variableName})`;
      const row = {
        activityMapping: activityIndirectName,
        activityIndirectName: activityIndirectName,
        omsInDirect: omsInDirect,
        source: data.source,
      }
      item?.columns.forEach(dayItem => {
        if (dayItem.date < minDay || !minDay) {
          minDay = dayItem.date;
        }
        if (dayItem.date > maxDay || !maxDay) {
          maxDay = dayItem.date;
        }
        if (!days.has(dayItem.date)) {
          days.add(dayItem.date);
        }
        row[dayItem.date] = {
          rate: dayItem.rate
        };
      });
      rowData.push(row);
    })

  }
  )
  return { rowData: rowData.map((row, index) => ({ ...row, index })), minDay, maxDay, days };
}


const timeCellFormatter = value => {
  const val = value;
  if (!val) return;
  const parsed = typeof val === 'string' ? parseDate(val) : val;
  return (parsed && parsed.toLocaleString(DateTime.TIME_24_SIMPLE)) || null;
};

export const constructShifts = (planDetails, periods) => {
  const workingHoursData = planDetails?.planningParameters?.periods?.filter((period)=> periods?.includes(period.id));
  const wzpShifts = workingHoursData?.map((w) => w.workZonePeriods);
  var flattened = [].concat.apply([], wzpShifts);
  var shifts = flattened && flattened.reduce((unique, o) => {
    if (!unique.some(obj => obj.name === o.name)) {
      unique.push(o);
    }
    return unique;
  }, []);
  shifts.forEach((shift) => {
      shift.label = shift.name,
      shift.value = shift.id
  })
  return shifts;
}