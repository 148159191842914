/*
 * ActualVolume Messages
 *
 * This contains all the text for the ActualVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  testLabel: {
    id: 'app.components.MHEAssistant.testLabel',
    defaultMessage: 'TODO',
  },
  cancelButton: {
    id: 'app.components.MHEAssistant.cancelButton',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'app.components.MHEAssistant.saveButton',
    defaultMessage: 'Confirm',
  },
  headerMHEAssistant: {
    id: 'app.components.MHEAssistant.headerMHEAssistant',
    defaultMessage: 'MHE Assistant - Activity workload redistribution helper',
  },
  granularity: {
    id: 'app.components.MHEAssistant.granularity',
    defaultMessage: 'Granularity',
  },
  redistributeFromStartDate: {
    id: 'app.components.MHEAssistant.redistributeFromStartDate',
    defaultMessage: 'Redistribute workload from start date',
  },
  redistributeShortFall: {
    id: 'app.components.MHEAssistant.redistributeShortFall',
    defaultMessage: 'Redistribute workload from MHE shortfall',
  },
  redistributeFooterText: {
    id: 'app.components.MHEAssistant.redistributeFooterText',
    defaultMessage: 'If still leftovers it will stay unchanged in the granularity where the leftovers were before.',
  },
  headerRedistribute: {
    id: 'app.components.MHEAssistant.headerRedistribute',
    defaultMessage: 'Activity Effort Redistribution',
  },
  TextRedistribute: {
    id: 'app.components.MHEAssistant.TextRedistribute',
    defaultMessage: 'Redistribute workload from activity',
  },
  to: {
    id: 'app.components.MHEAssistant.to',
    defaultMessage: 'to',
  },
  selectHeaderRedistribute: {
    id: 'app.components.MHEAssistant.selectHeaderRedistribute',
    defaultMessage: 'Select MHE to Redistribute',
  },
  headerProductivityRate: {
    id: 'app.components.MHEAssistant.headerProductivityRate',
    defaultMessage: 'Modify Multiple Plan Values for Productivity Rate Forecast',
  },
  startDate: {
    id: 'app.components.MHEAssistant.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.MHEAssistant.endDate',
    defaultMessage: 'End Date',
  },
  sameActivity: {
    id: 'app.components.MHEAssistant.sameActivity',
    defaultMessage: 'Same Activity',
  },
  differentActivity: {
    id: 'app.components.MHEAssistant.differentActivity',
    defaultMessage: 'Different Activity',
  },
  use: {
    id: 'app.components.MHEAssistant.use',
    defaultMessage: 'Use',
  },
  plannerDriven: {
    id: 'app.components.MHEAssistant.plannerDriven',
    defaultMessage: 'Planner Driven',
  },
  smartVolume: {
    id: 'app.components.MHEAssistant.smartVolume',
    defaultMessage: 'Smart Volume',
  },
  plannerDrivenOneOff: {
    id: 'app.components.MHEAssistant.plannerDrivenOneOff',
    defaultMessage: 'Planner Driven One-Off Event',
  },
  smartVolumeOneOff: {
    id: 'app.components.MHEAssistant.smartVolumeOneOff',
    defaultMessage: 'Smart Volume One-Off Event',
  },
  actual: {
    id: 'app.components.MHEAssistant.actual',
    defaultMessage: 'Actual',
  },
  target: {
    id: 'app.components.MHEAssistant.target',
    defaultMessage: 'Target',
  },
  omsCodeLevel: {
    id: 'app.components.MHEAssistant.omsCodeLevel',
    defaultMessage: 'OMS Codes Level',
  },
  activityLevel: {
    id: 'app.components.MHEAssistant.activityLevel',
    defaultMessage: 'Activity Level',
  },

  settingsTitleOMSEffortUpdate: {
    id: 'app.components.MHEAssistant.settingsTitleOMSEffortUpdate',
    defaultMessage: 'OMS Effort Update (Activities updated based on Ratio)',
  },

  settingsTitleActivityEffortUpdate: {
    id: 'app.components.MHEAssistant.settingsTitleActivityEffortUpdate',
    defaultMessage: 'Activity effort Update (OMS Effort recalculated based on changed activities)',
  },

  settingsTitleProductivityRateUpdate: {
    id: 'app.components.MHEAssistant.settingsTitleProductivityRateUpdate',
    defaultMessage: 'Activity Productivity Rate Update',
  },
  changeTypeOMSCodeValueEffortOMSCode: {
    id: 'app.components.MHEAssistant.changeTypeOMSCodeValueEffortOMSCode',
    defaultMessage: 'change all selected OMS effort for the period to this value (Activities recalculated per Ratio)',
  },
  changeTypeOMSCodeValueEffortActivity: {
    id: 'app.components.MHEAssistant.changeTypeOMSCodeValueEffortActivity',
    defaultMessage:
      'change all selected Activities effort for the period to this value. (Ratio recalculated accordingly)',
  },
  changeTypeOMSCodeValueProductityRate: {
    id: 'app.components.MHEAssistant.changeTypeOMSCodeValueProductityRate',
    defaultMessage: 'change all selected Activities Productivity Rate for the period to this value.',
  },
  changeTypeDataSourceEffortForecast: {
    id: 'app.components.MHEAssistant.changeTypeDataSourceEffortForecast',
    defaultMessage: 'change all selected OMS effort to',
  },
  changeTypeDataSourceProductivityRate: {
    id: 'app.components.MHEAssistant.changeTypeDataSourceProductivityRate',
    defaultMessage: 'change all selected Productivity rate to',
  },
  changeTypePercentageOfValue: {
    id: 'app.components.MHEAssistant.changeTypePercentageOfValue',
    defaultMessage: '% change of value',
  },
  changeTypePercentageOfValueActivity: {
    id: 'app.components.MHEAssistant.changeTypePercentageOfValueActivity',
    defaultMessage: '% change of value. (Ratio recalculated accordingly)',
  },
  startEndDatesRangeError: {
    id: 'app.components.MHEAssistant.startEndDatesRangeError',
    defaultMessage: 'Maximum range for date interval is 3 months',
  },
  startEndDateOrder: {
    id: `app.components.MHEAssistant.startEndDateOrder`,
    defaultMessage: 'End date should be after start date',
  },
  negativeProductivityRateNotAllowedError: {
    id: 'app.components.MHEAssistant.negativeProductivityRateNotAllowedError',
    defaultMessage: 'Negative productivity rate is not allowed',
  },
  percentageIsMandatoryError: {
    id: 'app.components.MHEAssistant.percentageIsMandatoryError',
    defaultMessage: 'Percentage is mandatory',
  },
  valueIsMandatoryError: {
    id: 'app.components.MHEAssistant.valueIsMandatoryError',
    defaultMessage: 'Value is mandatory',
  },
  enterANumberError: {
    id: 'app.components.MHEAssistant.enterANumberError',
    defaultMessage: 'Enter a number',
  },
  enterAPositiveNumberError: {
    id: 'app.components.MHEAssistant.enterAPositiveNumberError',
    defaultMessage: 'Enter a positive number',
  },
  selectAtLeastOneItemError: {
    id: 'app.components.MHEAssistant.selectAtLeastOneItemError',
    defaultMessage: 'Select at least 1 item',
  },
});
