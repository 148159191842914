import * as React from 'react';
import { Component } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { matchPath, Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import BasePage from 'components/BasePage';
import { DetailPageSectionsWrapper } from 'components/DetailPageShared';
import { withDeleteDialog } from 'components/Dialog';
import NamedSection from 'components/NamedSection';
import { PATHS } from 'containers/App/constants';
import { ScheduleRunRenderer } from 'containers/ShiftScheduleRunDetailPage/ScheduleRunRenderer';
import {
  selectPullingChanges,
  selectShiftSchedule,
  selectShiftScheduleName,
  selectShiftScheduleRuns,
  selectErrorDescriptions,
} from 'containers/ShiftScheduleRunsPage/selectors';
import { ShiftScheduleRun } from 'containers/ShiftScheduleRunsPage/types';
import { formatDateToApiFormat } from 'utils/api';
import injectSaga from 'utils/injectSaga';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import { ParameterSet } from '../ShiftScheduleDetailPage/types';
import {
  addScheduleRun,
  cancelScheduleRun,
  copyShiftScheduleRun,
  deleteScheduleRun,
  loadShiftSchedule,
  loadShiftScheduleRuns,
  pullShiftScheduleRunChanges,
  recalculateShiftScheduleRuns,
  saveScheduleRuns,
  sendScheduleRun,
  loadErrorDescription,
} from '../ShiftScheduleRunsPage/actions';
import saga from '../ShiftScheduleRunsPage/saga';
import messages from './messages';
import ShiftScheduleRunDetailPageToolbar from './ShiftScheduleRunDetailPageToolbar';
import { ShiftScheduleRunDetailForm } from './types';
import { withConfirmDialog } from './withConfirmDialog';

const FullWidthSection = styled(NamedSection)`
  min-width: 1170px;
  margin-top: 10px;
`;

interface ShiftScheduleRunDetailPageProps {
  shiftSchedule: ApiScheduleDTO;
  shiftScheduleName: string;
  shiftScheduleRuns: ShiftScheduleRun[];
  history: any;
  location: any;
  intl: any;
  dispatch: any;
  user: any;
  match: any;
  openConfirmDialog: (any1, any2) => void;
  openDeleteDialog: (any1, any2, any3) => void;
  sendScheduleRun: (payload) => void;
  copyShiftScheduleRun: (payload) => void;
  cancelScheduleRun: (payload) => void;
  recalculateShiftScheduleRuns: () => void;
  deleteScheduleRun: () => void;
  hasPerm(perm: string): () => boolean;
  addScheduleRun(run: ShiftScheduleRun): () => void;
  loadShiftSchedule(action: any): () => void;
  loadShiftScheduleRuns(scheduleId: number): () => void;
  pullShiftScheduleRunChanges(payload: any): () => void;
  save(values: any): () => void;
  loadErrorDescription(): () => void,
  errorDescriptions: any,
}

export interface ShiftScheduleRunsState {
  endDate: DateTime;
  granularity: any;
  name: string;
  nameError: boolean;
  parameterSet: ParameterSet;
  parameterSetError: boolean;
  sendDirectly: boolean;
  startDate: DateTime;
}

class ShiftScheduleRunDetailPage extends Component<ShiftScheduleRunDetailPageProps, ShiftScheduleRunsState> {
  private formik = null;

  private pullingJob = null;

  public getScheduleId() {
    return Number(this.props.match.params.scheduleId);
  }

  public getRunId() {
    return Number(this.props.match.params.id);
  }

  public componentDidMount() {
    const scheduleId = this.getScheduleId();
    this.props.loadShiftSchedule(scheduleId);
    this.props.loadShiftScheduleRuns(scheduleId);
    this.props.loadErrorDescription();

    this.pullingJob = setInterval(() => {
      const runId = this.getRunId();
      const shiftScheduleRun = this.props.shiftScheduleRuns.find(s => s.id === runId);
      if (shiftScheduleRun) {
        if (shiftScheduleRun.status.shouldPullChanges()) {
          this.props.pullShiftScheduleRunChanges({
            runIds: [runId],
            scheduleId,
          });
        }
      }
    }, 5000);
  }

  public componentWillUnmount() {
    clearInterval(this.pullingJob);
  }

  public render() {
    const runId = this.getRunId();

    if (!Array.isArray(this.props.shiftScheduleRuns)) {
      return (
        <BasePage labelMessage={messages.header} labelValues={{ name: '' }}>
          Loading...
        </BasePage>
      );
    }

    const isCopyPath = !!matchPath(this.props.location.pathname, {
      path: PATHS.shiftScheduleRunDetailCopy,
      exact: true,
    });

    const doSubmit = (values, actions) => {
      const { shiftScheduleRun } = values;
      const originalScheduleData = this.props.shiftScheduleRuns.find(s => s.id === runId);

      const updateScheduleData: Partial<ShiftScheduleRun & { parametersValues: Object[] }> = {
        id: shiftScheduleRun.id,
      };
      Object.keys(shiftScheduleRun).forEach(key => {
        if (!isEqual(shiftScheduleRun[key], originalScheduleData[key]) || isCopyPath) {
          updateScheduleData[key] = shiftScheduleRun[key];
        }
      });
      if (updateScheduleData.startDate) {
        updateScheduleData.startDate = formatDateToApiFormat(shiftScheduleRun.startDate);
      }
      if (updateScheduleData.endDate) {
        updateScheduleData.endDate = formatDateToApiFormat(shiftScheduleRun.endDate);
      }
      if (updateScheduleData.groups || isCopyPath) {
        let parametersValues = [];
        shiftScheduleRun.groups.forEach(group => {
          parametersValues = parametersValues.concat(group.parametersValues);
        });
        updateScheduleData.parametersValues = parametersValues;
      }

      if (isCopyPath) {
        this.props.copyShiftScheduleRun({
          shiftScheduleRun: updateScheduleData,
          scheduleId: this.getScheduleId(),
        });
      } else {
        this.props.save({
          runs: [updateScheduleData],
          scheduleId: this.getScheduleId(),
        });
      }
      actions.setSubmitting(false);
    };

    const shiftScheduleRun = this.props.shiftScheduleRuns.find(s => s.id === runId);
    const initial = {
      shiftScheduleName: this.props.shiftScheduleName,
      shiftScheduleRun: shiftScheduleRun
        ? {
            ...shiftScheduleRun,
            name: isCopyPath ? `Copy - ${shiftScheduleRun?.name}` : shiftScheduleRun?.name,
          }
        : null,
    };

    return (
      <BasePage
        labelMessage={isCopyPath ? messages.copyHeader : messages.header}
        labelValues={{ name: shiftScheduleRun?.name }}
        noMaxWidth
      >
        <Formik
          key={`${isCopyPath}`}
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          initialValues={initial}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  private renderForm = (formik: FormikProps<ShiftScheduleRunDetailForm>) => {
    this.formik = formik;
    const scheduleId = this.getScheduleId();
    const { shiftScheduleRun } = formik.values;
    const isCopyPath = !!matchPath(this.props.location.pathname, {
      path: PATHS.shiftScheduleRunDetailCopy,
      exact: true,
    });

    const {
      intl,
      dispatch,
      intl: { formatMessage },
      user,
      history,
      match,
    } = this.props;

    return (
      <Form>
        <ShiftScheduleRunDetailPageToolbar
          formik={formik}
          match={match}
          history={history}
          sendScheduleRun={this.props.sendScheduleRun}
          shiftScheduleRuns={this.props.shiftScheduleRuns}
          copyShiftScheduleRun={this.props.copyShiftScheduleRun}
          cancelScheduleRun={this.props.cancelScheduleRun}
          openConfirmDialog={this.props.openConfirmDialog}
        />
        <Prompt when={formik.dirty && !isCopyPath} message={formatMessage(messages.confirmDirty)} />
        <DetailPageSectionsWrapper>
          <FullWidthSection noMaxWidth>
            {formik.values.shiftScheduleRun && (
              <ScheduleRunRenderer
                scheduleId={scheduleId}
                shiftSchedule={this.props.shiftSchedule}
                activitiesNotInKronosExist
                shiftScheduleRuns={this.props.shiftScheduleRuns}
                deleteScheduleRun={this.props.deleteScheduleRun}
                dispatch={dispatch}
                isCopyPath={isCopyPath}
                isEditable={shiftScheduleRun?.status?.isEditable() || isCopyPath}
                formik={formik}
                intl={intl}
                openDeleteDialog={this.props.openDeleteDialog}
                openConfirmDialog={this.props.openConfirmDialog}
                recalculateShiftScheduleRuns={this.props.recalculateShiftScheduleRuns}
                hasPerm={this.props.hasPerm}
                user={user}
                loadErrorDescription={this.props.loadErrorDescription}
                errorDescriptions={this.props.errorDescriptions}
              />
            )}
          </FullWidthSection>
        </DetailPageSectionsWrapper>
      </Form>
    );
  };
}

const mapStateToProps = createStructuredSelector({
  pullingChanges: selectPullingChanges,
  shiftSchedule: selectShiftSchedule,
  shiftScheduleName: selectShiftScheduleName,
  shiftScheduleRuns: selectShiftScheduleRuns,
  errorDescriptions: selectErrorDescriptions,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        cancelScheduleRun,
        addScheduleRun,
        deleteScheduleRun,
        recalculateShiftScheduleRuns,
        sendScheduleRun,
        copyShiftScheduleRun,
        loadShiftSchedule,
        loadShiftScheduleRuns,
        pullShiftScheduleRunChanges,
        save: saveScheduleRuns,
        loadErrorDescription,
      },
      dispatch,
    ),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'shiftScheduleRunDetailPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  withRouter,
  withConfirmDialog,
  withDeleteDialog,
  // @ts-ignore
)(ShiftScheduleRunDetailPage);
