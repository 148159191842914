/**
 *
 * UploadModal
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import delay from 'lodash/delay';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { loadFromStorageById, saveToStorageById } from 'containers/PlanResultPage/utils';
import injectSaga from 'utils/injectSaga';

import Button from '../../components/Button';
import ItemSelectable from '../../components/Menu/ItemSelectable';
import ToggleSection from '../../components/ToggleSection';
import { makeSelectRunningApiCalls } from '../App/selectors';
import {
  closeUploadModalAction,
  collapseExportAction,
  collapseHistoryAction,
  downloadFromHistoryAction,
  expandExportAction,
  expandHistoryAction,
  exportFileAction,
  fetchUploadsAction,
  uploadFileAction,
} from './actions';
import { EXPORT_SECTIONS } from './constants';
import messages from './messages';
import NewUpload from './NewUpload';
import saga from './saga';
import {
  isRunningSelector,
  makeSelectExportExpanded,
  makeSelectUploadInfo,
  makeSelectUploadModalVisible,
  makeSelectUploadsExpanded,
  selectUploadsByPlan,
  selectUploadsByUser,
} from './selectors';
import {
  ButtonStyledForExport,
  ButtonsWrap,
  Content,
  ExportSectionsStyledDiv,
  ExportSectionWrap,
  Info,
  Mask,
  NewWrap,
  TopWrap,
  Wrap,
} from './styled';
import Tabs from './Tabs';
import UploadsList from './UploadsList';

class UploadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const exportSectionsDefinition = props.exportSections || EXPORT_SECTIONS[props.entity];
    const exportSections = [];
    this.delayedFunc = null;
    exportSectionsDefinition &&
      exportSectionsDefinition.forEach(section => {
        if (section.default) {
          exportSections.push(section.code);
        }
      });
    this.state = {
      files: [],
      uploadStarted: false,
      reloadAfterClose: false,
      exportSections,
      historyTab: 0,
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ files: acceptedFiles });
  };

  fetchData = () => {
    this.props.fetchUploadsAction(this.props.entity);
    this.delayedFunc = null;
  };

  componentDidUpdate(prevProps) {
    const { entity, uploadRunning, rowDataByPlan, rowDataByUser, isApiRunning, visible } = this.props;
    if (prevProps.visible === false && this.props.visible === true && this.state.files.length > 0) {
      this.setState({ files: [], uploadStarted: false, reloadAfterClose: false });
    }

    if (prevProps.visible === false && this.props.visible === true) {
      this.setState({ reloadAfterClose: false });
    }

    if (!isApiRunning && visible && visible !== prevProps.visible) {
      this.props.fetchUploadsAction(entity);
    }

    if (prevProps.rowDataByPlan !== rowDataByPlan || prevProps.rowDataByUser !== rowDataByUser) {
      this.setState({
        rowDataByPlan: (rowDataByPlan && rowDataByPlan.toJS()) || false,
        rowDataByUser: (rowDataByUser && rowDataByUser.toJS()) || false,
      });
    }

    if (uploadRunning && !isApiRunning && !this.delayedFunc) {
      this.delayedFunc = delay(this.fetchData, 5000);
      this.setState({ uploadStarted: false });
    }
  }

  onModelUpdated = key => params => {
    const {
      user: {
        user: { login },
      },
      currentId,
    } = this.props;
    const model = {
      filter: params.api.getFilterModel(),
      sort: params.api.getSortModel(),
    };
    saveToStorageById(login, currentId, model, key);
  };

  onGridReady = key => params => {
    const {
      user: {
        user: { login },
      },
      currentId,
    } = this.props;
    const model = loadFromStorageById(login, currentId, key);
    if (model) {
      params.api.setFilterModel(model.filter);
      params.api.setSortModel(model.sort);
    }
  };

  handleClose = () => {
    const { reloadAfterClose } = this.state;
    const { entity } = this.props;
    this.props.onClose(reloadAfterClose, entity);
    if (this.props.onCloseHandler) {
      this.props.onCloseHandler(reloadAfterClose, entity);
    }
  };

  render() {
    const {
      uploadRunning = false,
      intl: { formatMessage },
      isAuditor,
      entity,
    } = this.props;
    const { historyTab, rowDataByPlan, rowDataByUser, uploadStarted } = this.state;
    if (!this.props.visible) {
      return null;
    }
    let rowData = false;
    if (rowDataByPlan && rowDataByUser) {
      rowData = (historyTab === 0 && rowDataByPlan) || rowDataByUser;
    }

    const disabledButton =
      !rowData ||
      !this.state.files ||
      this.state.files.length === 0 ||
      this.props.uploadInfo.get('uploading') ||
      uploadRunning;

    let subtitle = '';
    if (uploadStarted) {
      subtitle = <Info>{formatMessage(messages.uploadStarted)}</Info>;
    } else if (uploadRunning) {
      subtitle = <Info>{formatMessage(messages.uploadIsRunning)}</Info>;
    }

    return (
      <Wrap>
        <Mask onClick={this.handleClose} />
        <Content>
          <ToggleSection message={messages.newUpload} subtitle={subtitle} expanded>
            <TopWrap>
              <NewWrap>
                <NewUpload
                  onDrop={this.onDrop}
                  fileName={this.state.files && this.state.files.length > 0 ? this.state.files[0].name : null}
                  disabled={uploadRunning || !rowData}
                />
              </NewWrap>
              <ButtonsWrap>
                <Button
                  disabled={disabledButton}
                  onClick={() => {
                    this.props.uploadFileAction(entity, this.props.currentId, this.state.files);
                    this.setState({ files: [], uploadStarted: true, reloadAfterClose: true });
                  }}
                >
                  <FormattedMessage {...messages.upload} />
                </Button>
                <Button onClick={this.handleClose}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </ButtonsWrap>
            </TopWrap>
          </ToggleSection>
          <ToggleSection
            message={messages.exportCurrent}
            expanded={this.props.exportExpanded}
            onToggle={this.onToggleExport}
          >
            <ExportSectionWrap>
              {this.renderExportSections()}
              <ButtonStyledForExport
                onClick={() => {
                  this.props.exportFileAction(entity, this.props.currentId, this.state.exportSections);
                }}
              >
                <FormattedMessage {...messages.doExport} />
              </ButtonStyledForExport>
            </ExportSectionWrap>
          </ToggleSection>
          <ToggleSection message={messages.list} expanded={this.props.uploadsExpanded} onToggle={this.onToggleHistory}>
            {(isAuditor && (
              <>
                <Tabs
                  tabs={[{ label: messages[`${entity}UploadsList`] }, { label: messages.listByUser }]}
                  value={historyTab}
                  onChange={(event, value) => {
                    if (value === historyTab) return;
                    this.setState({ historyTab: value });
                  }}
                />
                {(historyTab === 0 && (
                  <UploadsList
                    tableKey="uploadByPlan"
                    {...this.props}
                    rowData={rowDataByPlan}
                    onFilterChanged={this.onModelUpdated('uploadByPlan')}
                    onSortChanged={this.onModelUpdated('uploadByPlan')}
                    onGridReady={this.onGridReady('uploadByPlan')}
                  />
                )) || (
                  <UploadsList
                    tableKey="uploadByUser"
                    {...this.props}
                    onGridReady={this.onGridReady('uploadByUser')}
                    rowData={rowDataByUser}
                    onFilterChanged={this.onModelUpdated('uploadByUser')}
                    onSortChanged={this.onModelUpdated('uploadByUser')}
                  />
                )}
              </>
            )) || (
              <UploadsList
                tableKey="uploadByUser"
                {...this.props}
                onGridReady={this.onGridReady('uploadByUser')}
                rowData={rowDataByUser}
                onFilterChanged={this.onModelUpdated('uploadByUser')}
                onSortChanged={this.onModelUpdated('uploadByUser')}
              />
            )}
          </ToggleSection>
        </Content>
      </Wrap>
    );
  }

  renderExportSections = () => {
    const exportSections = this.props.exportSections || EXPORT_SECTIONS[this.props.entity];
    if (exportSections) {
      return (
        <ExportSectionsStyledDiv>
          {exportSections.map(section => (
            <React.Fragment key={section.code}>
              <ItemSelectable
                isSelected={this.state.exportSections.includes(section.code)}
                disabled={exportSections.length === 1}
                onClick={() => {
                  if (this.state.exportSections.includes(section.code)) {
                    this.setState(state => ({
                      exportSections: state.exportSections.filter(i => i !== section.code),
                    }));
                  } else {
                    this.setState(state => ({ exportSections: [...state.exportSections, section.code] }));
                  }
                }}
              />
              <div>
                {messages[section.message] && this.props.intl
                  ? this.props.intl.formatMessage(messages[section.message])
                  : section.message}
              </div>
            </React.Fragment>
          ))}
        </ExportSectionsStyledDiv>
      );
    }
    return null;
  };

  onToggleHistory = () => {
    if (this.props.uploadsExpanded) {
      this.props.collapseHistoryAction();
    } else {
      this.props.fetchUploadsAction(this.props.entity);
      this.props.expandHistoryAction();
    }
  };

  onToggleExport = () => {
    if (this.props.exportExpanded) {
      this.props.collapseExportAction();
    } else {
      this.props.expandExportAction();
    }
  };
}

UploadModal.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  uploadsExpanded: PropTypes.bool,
  exportExpanded: PropTypes.bool,
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClose: PropTypes.func,
  onCloseHandler: PropTypes.func,
  expandHistoryAction: PropTypes.func,
  collapseHistoryAction: PropTypes.func,
  expandExportAction: PropTypes.func,
  collapseExportAction: PropTypes.func,
  fetchUploadsAction: PropTypes.func,
  entity: PropTypes.string,
  uploadInfo: PropTypes.object,
  uploadFileAction: PropTypes.func,
  exportFileAction: PropTypes.func,
  downloadFromHistoryAction: PropTypes.func,
  currentId: PropTypes.number,
  exportSections: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  visible: makeSelectUploadModalVisible(),
  rowDataByUser: selectUploadsByUser,
  rowDataByPlan: selectUploadsByPlan,
  uploadsExpanded: makeSelectUploadsExpanded(),
  exportExpanded: makeSelectExportExpanded(),
  uploadInfo: makeSelectUploadInfo(),
  uploadRunning: isRunningSelector,
  isApiRunning: makeSelectRunningApiCalls(),
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        onClose: closeUploadModalAction,
        expandHistoryAction,
        collapseHistoryAction,
        expandExportAction,
        collapseExportAction,
        fetchUploadsAction,
        uploadFileAction,
        exportFileAction,
        downloadFromHistoryAction,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = sagaKey => injectSaga({ key: `uploadModal${sagaKey}`, saga });

export default sagaKey => compose(withConnect, withSaga(sagaKey))(UploadModal);
