/*
 * PlanDetailBasicSettings Messages
 *
 * This contains all the text for the PlanDetailBasicSettings component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  offDays: {
    id: 'app.containers.PlanDetailBasicSettings.offDays',
    defaultMessage: 'Days Off',
  },
  wtDay: {
    id: 'app.containers.PlanDetailBasicSettings.wtDay',
    defaultMessage: 'FTE Working Hours/Day',
  },
  wtWeek: {
    id: 'app.containers.PlanDetailBasicSettings.wtWeek',
    defaultMessage: 'FTE Working Days/Week',
  },
  timeZone: {
    id: 'app.containers.PlanDetailBasicSettings.timeZone',
    defaultMessage: 'Time Zone',
  },
  calculateType: {
    id: 'app.containers.PlanDetailBasicSettings.calculateType',
    defaultMessage: 'Calculate',
  },
  FTE:{
    id: 'app.containers.PlanDetailBasicSettings.FTE',
    defaultMessage: 'FTE',
  },
  Staff:{
    id: 'app.containers.PlanDetailBasicSettings.Staff',
    defaultMessage: 'Staff',
  },
  transformationType: {
    id: 'app.containers.PlanDetailBasicSettings.transformationType',
    defaultMessage: 'Transformation Type',
  },
  useAdvancedShiftDistribution: {
    id: 'app.containers.PlanDetailBasicSettings.useAdvancedShiftDistribution',
    defaultMessage: 'Use Advanced Shift Distribution',
  },
  productivityRatePerShift: {
    id: 'app.containers.PlanDetailBasicSettings.productivityRatePerShift',
    defaultMessage: 'Productivity Rate per Shift',
  },
  activityAdjustment: {
    id: 'app.containers.PlanDetailBasicSettings.activityAdjustment',
    defaultMessage: 'Activity Adjustments',
  },
  activityTransformation: {
    id: 'app.containers.PlanDetailBasicSettings.activityTransformation',
    defaultMessage: 'Activity Transformation',
  },
  activityTransformationHelp: {
    id: 'app.containers.PlanDetailBasicSettings.activityTransformationHelp',
    defaultMessage: `<strong>Activity Transformation</strong>
    <br /><br />
    <p>Weekly – Transformation From Volume category to Activity done on Weekly granularity </p>
    <p>Daily – Transformation From Volume category to Activity done on Daily granularity </p>`,
  },
  VOLUME_CATEGORY: {
    id: 'app.containers.PlanDetailBasicSettings.VOLUME_CATEGORY',
    defaultMessage: 'Daily',
  },
  ACTIVITY: {
    id: 'app.containers.PlanDetailBasicSettings.ACTIVITY',
    defaultMessage: 'Weekly',
  },
  SHIFT_ONLY: {
    id: 'app.containers.PlanDetailBasicSettings.SHIFT_ONLY',
    defaultMessage: 'Shift only',
  },
  DEPARTMENT: {
    id: 'app.containers.PlanDetailBasicSettings.DEPARTMENT',
    defaultMessage: 'Department',
  },
  LABOUR_ROLE: {
    id: 'app.containers.PlanDetailBasicSettings.LABOUR_ROLE',
    defaultMessage: 'Labour Role',
  },
  WZP: {
    id: 'app.containers.PlanDetailBasicSettings.WZP',
    defaultMessage: 'Day to WZP',
  },
  HOUR: {
    id: 'app.containers.PlanDetailBasicSettings.HOUR',
    defaultMessage: 'Day to Hour',
  },
  SHIFT: {
    id: 'app.containers.PlanDetailBasicSettings.SHIFT',
    defaultMessage: 'Day to SHIFT',
  },
  currency: {
    id: 'app.containers.PlanDetailBasicSettings.currency',
    defaultMessage: 'Currency',
  },
  labourAvailabilityType: {
    id: 'app.containers.PlanDetailBasicSettings.labourAvailabilityType',
    defaultMessage: 'Labour Availability',
  },
  useWZP: {
    id: 'app.components.PlanDetailBasicSettings.useWZP',
    defaultMessage: 'WZP',
  },
  useShift: {
    id: 'app.components.PlanDetailBasicSettings.useShift',
    defaultMessage: 'Shift',
  },
  useWZPTooltip: {
    id: 'app.components.PlanDetailBasicSettings.useWZPTooltip',
    defaultMessage: 'Use WZP Transformation Type',
  },
  useShiftTooltip: {
    id: 'app.components.PlanDetailBasicSettings.useShiftTooltip',
    defaultMessage: 'Use Shift Transformation Type',
  },
  warning: {
    id: 'app.components.PlanDetailBasicSettings.warning',
    defaultMessage: 'Warning',
  },
  toShiftsWarning: {
    id: 'app.components.PlanDetailBasicSettings.toShiftsWarning',
    defaultMessage:
      'Change Transformation type to "Day to Shift" mode will delete Work Zone Periods settings including associated Transformation Tables and MHE Availabilities!',
  },
  warningSuggestion: {
    id: 'app.components.PlanDetailBasicSettings.warningSuggestion',
    defaultMessage:
      'It is suggested to create a copy of your {item} and change the transformation type there, to keep old settings backup, as this action is irreversible.',
  },
  fromShiftsWarning: {
    id: 'app.components.PlanDetailBasicSettings.fromShiftsWarning',
    defaultMessage:
      'Change Transformation type from "Day to Shift" mode will delete Shifts settings including associated Transformation Tables and MHE Availabilities!',
  },
  weeklyTransformationTypeSelectedWarning: {
    id: 'app.components.PlanDetailBasicSettings.weeklyTransformationTypeSelectedWarning',
    defaultMessage: 'If "Weekly" is selected than Effort based forecast (including SmartVolume) will not be available.',
  },
  changeAnyway: {
    id: 'app.components.PlanDetailBasicSettings.changeAnyway',
    defaultMessage: 'Change Anyway',
  },
  copyPlan: {
    id: 'app.components.PlanDetailBasicSettings.copyPlan',
    defaultMessage: 'Copy {item} and Change',
  },
  cancel: {
    id: 'app.components.PlanDetailBasicSettings.cancel',
    defaultMessage: 'Cancel',
  },
  plan: {
    id: 'app.components.PlanDetailBasicSettings.plan',
    defaultMessage: 'Plan',
  },
  planningArea: {
    id: 'app.components.PlanDetailBasicSettings.planningArea',
    defaultMessage: 'Planning Area',
  },
});
