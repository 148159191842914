/*
 * ActualVolume Messages
 *
 * This contains all the text for the ActualVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonForecast: {
    id: 'app.components.EffortForecast.buttonForecast',
    defaultMessage: 'Forecast',
  },
  buttonPlan: {
    id: 'app.components.EffortForecast.buttonPlan',
    defaultMessage: 'Plan',
  },
  tableTitleForecast: {
    id: 'app.components.EffortForecast.tableTitleForecast',
    defaultMessage: 'Forecast (Readonly)',
  },
  tableTitlePlan: {
    id: 'app.components.EffortForecast.tableTitlePlan',
    defaultMessage: 'Plan',
  },
  buttonGetBackToForecast: {
    id: 'app.components.EffortForecast.restoreForecast',
    defaultMessage: 'Restore Forecast',
  },
  buttonModifyMultiple: {
    id: 'app.components.EffortForecast.buttonModifyMultiple',
    defaultMessage: 'Modify Multiple',
  },
  buttonModifyOMSCode: {
    id: 'app.components.EffortForecast.buttonModifyOMSCode',
    defaultMessage: 'Modify OMS Code',
  },
  buttonModifySmartPlanActivity: {
    id: 'app.components.EffortForecast.buttonModifySmartPlanActivity',
    defaultMessage: 'Modify SmartPlan Activity',
  },
  buttonEffort: {
    id: 'app.components.EffortForecast.buttonEffort',
    defaultMessage: 'Effort',
  },
  buttonRatio: {
    id: 'app.components.EffortForecast.buttonRatio',
    defaultMessage: 'Ratio',
  },
  dataSource: {
    id: 'app.components.EffortForecast.forecastSource',
    defaultMessage: 'Forecast Source',
  },
  tableOMSCode: {
    id: 'app.components.EffortForecast.tableOMSCode',
    defaultMessage: 'OMSCode',
  },
  tableFacilityCustomerDepartmentActivity: {
    id: 'app.components.EffortForecast.tableFacilityCustomerDepartmentActivity',
    defaultMessage: 'Facility/Customer/Department/Activity',
  },
  tableUnitOfMeasure: {
    id: 'app.components.EffortForecast.tableUnitOfMeasure',
    defaultMessage: 'Unit of Measure',
  },
  tableDay: {
    id: 'app.components.EffortForecast.tableDay',
    defaultMessage: '{ day }',
  },
  backToForecastConfirmDialogMessage: {
    id: 'app.components.EffortForecast.backToForecastConfirmDialogMessage',
    defaultMessage: 'Do you want to delete all overridden values?',
  },
  overwriteConfirmDialogHeader: {
    id: 'app.components.EffortForecast.overwriteConfirmDialogHeader',
    defaultMessage: 'Do you want to delete all overridden values?',
  },
  overwriteConfirmDialogConfirm: {
    id: 'app.components.EffortForecast.overwriteConfirmDialogConfirm',
    defaultMessage: 'OK',
  },
  overwriteConfirmDialogCancel: {
    id: 'app.components.EffortForecast.overwriteConfirmDialogCancel',
    defaultMessage: 'Cancel',
  },
  defaultType: {
    id: 'app.components.EffortForecast.defaultType',
    defaultMessage: 'Default Forecast Source',
  },
});
