/* eslint-disable no-restricted-syntax,no-plusplus */
import jscookie from 'js-cookie';
import { DateTime, Duration } from 'luxon';
import { toast } from 'react-toastify';
import { call, put, select } from 'redux-saga/effects';
import uniqid from 'uniqid';

import { calculateProductivityRate } from 'components/ProductivityRate/prodRateCaclulations';
import { apiCallEndedAction, apiCallStartedAction, storeBeVersionAction } from 'containers/App/actions';
import { storeTokenAction, storeUserAction } from 'containers/LoginPage/actions';
import { COOKIE_CAS_ST } from 'containers/LoginPage/constants';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { parseDates, parseTimes } from 'utils/dateTime';

import { ApiOptions } from './api/ApiOptions';
import {
  calculateActuals,
  listWithIdToMap,
  recalculateLabourCategoryParameters,
  recalculateMheAvailabilities,
  recalculateVolumeCategoryDayTransitions,
  recalculateVolumeCategoryParameters,
  reorganizeActivityParameters,
} from './api/calculations';
import calculateWorkingHours from './api/workingHoursCalc';
import { fetchAccessToken } from './azure';
import { ACTIVE_ENV } from './activeEnv';


// For release update following line - set target environment and release version

export function extractReadableMessage(err) {
  let result = '';
  if (err.error && typeof err.error === 'string') {
    result = `${err.error}:`;
  }
  if (err.message) {
    result += err.message;
  }
  if (!result) result = 'Error';
  return result;
}

function convertPlanningParametersFromApi(planningParameters, lookups) {
  const result = parseDates(planningParameters, ['startDay', 'endDay', 'budgetLastUpdated']);
  for (const volumesField of ['actualsVolumeCategoryParameters', 'volumeCategoryParameters']) {
    if (planningParameters[volumesField]) {
      result[volumesField] = recalculateVolumeCategoryParameters(planningParameters[volumesField]);
    }
  }
  if (planningParameters.periods) {
    for (let i = 0; i < planningParameters.periods.length; i++) {
      const period = parseDates(planningParameters.periods[i], ['startDay', 'endDay']);
      period.index = i;
      period.adjustments = [];
      if (period.workZonePeriods) {
        period.workZonePeriods = period.workZonePeriods.map(wzp => parseTimes(wzp, ['startTime', 'endTime']));
      }
      if (period.mheAvailabilities) {
        period.mheAvailabilities = recalculateMheAvailabilities(period);
      }
      if (period.activityParameters) {
        period.apCalculated = reorganizeActivityParameters(period, planningParameters, lookups);
      }
      if (period.labourCategoryParameters) {
        period.labourCategoryParameters = recalculateLabourCategoryParameters(period);
      }
      if (period.shifts) {
        period.shifts = period.shifts.map((shift, index) => ({
          ...parseTimes(shift, ['startTime', 'endTime']),
          index,
        }));
      }
      if (period.volumeCategoryDayTransitions) {
        period.volumeCategoryDayTransitions = recalculateVolumeCategoryDayTransitions(
          period.volumeCategoryDayTransitions,
          planningParameters.volumeCategoryParameters,
        );
      }
      result.periods[i] = period;
    }
  }
  result.cellEditing = false;
  result.firstHourOfDay = Duration.fromObject({
    hours: planningParameters.firstHourOfDay ? Number(planningParameters.firstHourOfDay) : 0,
    minutes: Number(0),
    seconds: Number(0),
  });
  result.workingHours = calculateWorkingHours(planningParameters);
  if (planningParameters.activityForecastList) {
    result.activityForecastTable = calculateProductivityRate(planningParameters, lookups);
    result.activityForecastList = undefined;
  } else {
    result.activityForecastTable = {
      rowData: [],
      days: new Set(),
    };
  }
  return result;
}

function convertAuditFields(entity) {
  const result = { ...entity };
  if (result.audit) {
    if (result.audit.created) {
      result.audit.created = DateTime.fromISO(result.audit.created);
    }
    if (result.audit.updated) {
      result.audit.updated = DateTime.fromISO(result.audit.updated);
    }
  }
  return result;
}

function formatCalculatedBy(lastCalculatedBy) {
  const { firstName, lastName } = lastCalculatedBy;
  // eslint-disable-next-line no-nested-ternary
  return firstName ? (lastName ? `${firstName} ${lastName}` : firstName) : lastName || undefined;
}

export function convertCalculationStatisticsFromApi(calculationStatistics) {
  return calculationStatistics.map(item => {
    const { calculated, calculatedBy, startDay, endDay, granularity } = item;
    return {
      calculatedBy: calculatedBy && formatCalculatedBy(calculatedBy),
      calculated: calculated && DateTime.fromISO(calculated),
      startDate: startDay && DateTime.fromFormat(startDay, 'yyyy-MM-dd'),
      endDate: endDay && DateTime.fromFormat(endDay, 'yyyy-MM-dd'),
      granularity,
    };
  });
}

export function convertEntityWithPlanningParametersFromApi(plan) {
  const result = nullsToEmptyStringsFor(plan, ['description']);
  const pa = plan.planningArea || plan;
  const departmentValues = plan.planningParameters && plan.planningParameters.departments;
  const lookups = {
    activities: {},
    departments: listWithIdToMap(departmentValues),
    customers: listWithIdToMap(pa.customers),
    uoms: {},
    facilities: listWithIdToMap(pa.facilities),
  };
  if (result.planningParameters) {
    result.planningParameters = convertPlanningParametersFromApi(result.planningParameters, lookups);
    result.actuals = calculateActuals(plan.planningParameters.volumeCategoryParameters);
  }
  result.lookups = lookups;
  return convertAuditFields(result);
}

export function convertEffortForecastToEditableObject(effortForecast) {
  const editable = {};
}

function nullsToEmptyStringsFor(entity, fields) {
  const result = { ...entity };
  fields.forEach(f => {
    if (entity[f] === null) {
      result[f] = '';
    }
  });
  return result;
}

export function formatDateToApiFormat(dateTime) {
  return dateTime && dateTime.toISODate ? dateTime.toISODate() : dateTime;
}

export function withUrl(url) {
  return new ApiOptions(url);
}

export function apiError(message) {
  console.log(`API error: ${message}`);
  return { isOk: false, isForward: false, message };
}

export function* extractServiceTicket(action, tgc) {
  const { basePathCas } = ACTIVE_ENV;
  const url = `${basePathCas}/v1/ticketService`;
  const formBody1 = `ticketGrantingTicketId=${encodeURIComponent(tgc)}&service=drep`;
  const result = yield call(() =>
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody1,
    }),
  );
  return result;
}

export function loadCookie(cookieName) {
  return jscookie.get(cookieName);
}

export function* storeST(st) {
  storeTokenToCookie(st, COOKIE_CAS_ST);
  yield put(storeTokenAction(st));
}

export function* loadUser(token) {
  const response = yield call(api, withUrl('/user').andToken(token));
  if (response.isOk) {
    if (response.data.changePasswordAtNextLogin === true) {
      // yield put(logoutAction()); // clean store and forward -- can't logout, ticket needs to be used by UM
      // redirect to UM
      window.location = ACTIVE_ENV.changePasswordPage.replace('{login}', response.data.login);
      return { ...response, isForward: true };
    }
    yield put(storeUserAction(response.data));
    const verResponse = yield call(api, withUrl('/version').andToken(token).asRawResponse());
    const backendVersion = verResponse.ok ? yield verResponse.text() : '?';
    yield put(storeBeVersionAction(backendVersion));
  }
  return response;
}

export function storeTokenToCookie(token, cookieName) {
  jscookie.set(cookieName, token, { path: '/', secure: process.env.NODE_ENV === 'production' });
}

class TokenExpiredError extends Error {
  constructor(message, response) {
    super(message);
    this.name = 'TokenExpiredError';
    this.response = response;
  }
}

export const checkAndHandleTokenExpired = (status, responseJson) => {
  if (status === 403) {
    if (responseJson && responseJson.message && responseJson.message.includes('JWT expired')) {
      console.log('Forbidden, Azure token expired, reloading the page', responseJson);
      toast.error('Azure token expired, reloading the page');
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 1000);
      return true;
    } else {
      console.log('Forbidden, but no Azure token expired info in message, treating as regular error', responseJson);
    }
  }
  return false;
};

export function* api(options, firstGo = true) {
  const fullUrl = ACTIVE_ENV.basePathBe + options.url;
  if (options.logCalls === true) {
    console.log(`Calling API ${fullUrl} using method ${options.method} and options`, options);
  }
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const fetchOptions = { method: options.method };
    if (options.addToken) {
      const storeToken = yield select(makeSelectToken());
      const token = options.token || (yield fetchAccessToken()); // storeToken || loadTokenFromCookie();
      if (!token) {
        // token is requested but was not found in store. forward to login page
        console.log('Token not found, should never happen for Azure, returning error');
        // Azure: should never happen, msa provider will always have token
        // yield put(logoutAction()); // clean store and forward
        return { isOk: false, isForward: true };
      }
      // fetchOptions.headers = { ticket: token };
      fetchOptions.headers = { Authorization: `Bearer ${token}` };
      if (!storeToken) {
        yield put(storeTokenAction(token));
      }
    }
    if (options.body) {
      fetchOptions.body = options.body;
      if (options.contentType) {
        fetchOptions.headers = { ...fetchOptions.headers, 'Content-Type': options.contentType };
      }
    }
    const result = yield call(() =>
      fetch(fullUrl, fetchOptions).then(response => {
        if (options.isFileDownload) {
          return response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = options.isFileDownload;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); // afterwards we remove the element again
            return {};
          });
        }
        if (response.status >= 200 && response.status < 300) {
          return options.rawResponse ? response : { data: response.json(), status: response.status };
        }
        if (response.status === 403) {
          throw new TokenExpiredError('Forbidden, Azure token probably expired', response);
        }

        throw response;
      }),
    );
    if (options.rawResponse) {
      return result;
    }
    if (result.data) {
      const resolved = yield result.data; // resolve the promise
      result.data = resolved;
    }
    if (options.logCalls === true) {
      console.log(`API ${fullUrl} response status ${result.status} with data`, result.data);
    }
    return { ...result, isOk: true, isForward: false };
  } catch (err) {
    if (err.status === 401) {
      // should not happen on Azure
      // yield put(logoutAction()); // clean store and forward
      return { ...err, isOk: false, isForward: true };
    }
    const errorResponse = (err instanceof TokenExpiredError) ? err.response : err;
    let errorBody = null;
    if (errorResponse.json) {
      errorBody = yield errorResponse.json();
    }
    if (err instanceof TokenExpiredError) {
      if (checkAndHandleTokenExpired(403, errorBody)) {
        return { ...err, isOk: false, isForward: true };
      }
    }
    if (errorBody) {
      // Otherwise let callsite to deal with the error and don't show the default message
      if (!errorBody.errorType) {
        try {
          toast.error(extractReadableMessage(errorBody));
        } catch (e) {
          console.log(`Catched in api: ${e}`);
        }
      }
      return { ...errorResponse, isOk: false, isForward: false, error: errorResponse, ...errorBody };
    }
    if (errorResponse.message) {
      toast.error(errorResponse.message);
    }
    console.error('Fetch error', errorResponse);
    return { ...errorResponse, isOk: false, isForward: false };
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

export function loadTokenFromCookie() {
  return jscookie.get(COOKIE_CAS_ST);
}
