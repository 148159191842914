import { omitKey } from 'utils/utils';
import {
  ApiActivityParametersCalculationResultDTO,
  ApiEffortSummaryByWeekDTO,
  ApiLabourCategoryAvailabilityCalculationResultDTO,
  ApiMHEAvailabilityCalculationResultDTO,
  ApiMheCalculationDTO,
  ApiOverallEffortSummaryDTO,
} from 'types/drep-backend.d';

import { getWzpAvgColId } from './calculateColDef';
import { columnName, getNextLevel, getPrevLevel } from './calculateCommon';
import {
  CELL_TYPE,
  CURSORS,
  DATASET,
  Filter,
  GRANULARITY,
  GranulartiyTree,
  LEVEL,
  Props,
  TablesDataResult,
  UNIT,
  Value,
} from './types';

export function defined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function getValue(value: number): number | string {
  return defined(value) ? value || 0 : '-';
}

const getSumKeysForDataset = (dataset: DATASET) => {
  let eKey = null;
  let fteKey = null;
  let effortAdjustmentKey = null;
  switch (dataset) {
    case DATASET.budget: {
      eKey = budgetEColName;
      fteKey = budgetFTEColName;
      effortAdjustmentKey = budgetEffortAdjustmentColName;
      break;
    }
    case DATASET.planned: {
      eKey = plannedEColName;
      fteKey = plannedFTEColName;
      effortAdjustmentKey = plannedEffortAdjustmentColName;
      break;
    }
    case DATASET.forecast: {
      eKey = forecastEColName;
      fteKey = forecastFTEColName;
      effortAdjustmentKey = forecastEffortAdjustmentColName;
      break;
    }
    default:
      break;
  }
  return [eKey, fteKey, effortAdjustmentKey];
};

export function labourDiscrepancyRow(
  sum: ApiEffortSummaryByWeekDTO[],
  totals: ApiOverallEffortSummaryDTO,
  filter: Filter,
  props: Props,
  dataset: DATASET,
) {
  const { messages } = props;
  const ldr = periodsToData(
    {
      isLabor: true,
      isSumRow: true,
      level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
      periods: sum,
    },
    dataset,
    filter,
  );
  // labor name
  ldr.activity = messages.labourDiscrepancy;
  ldr.groupKey = messages.labourDiscrepancy;
  ldr.isLabor = false;
  ldr.isSumRow = true;

  // totals
  const [eKey, fteKey, effortAdjustmentKey] = getSumKeysForDataset(dataset);
  ldr[eKey] = totals.calculatedHoursEffort;
  ldr[fteKey] = totals.calculatedFte;
  ldr[effortAdjustmentKey] = totals.calculatedHoursEffort;
  return ldr;
}

export function labourSumsToRow(
  sum: ApiEffortSummaryByWeekDTO[],
  totals: ApiOverallEffortSummaryDTO,
  filter: Filter,
  props: Props,
  dataset: DATASET,
) {
  const { messages } = props;

  // periods
  const ls = periodsToData(
    {
      isLabor: true,
      isSumRow: true,
      level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
      periods: sum,
    },
    dataset,
    filter,
  );
  // labor name
  ls.activity = messages.labourAvailabilitySum;
  ls.isLabor = true;
  ls.isSumRow = true;
  // totals
  const [eKey, fteKey, effortAdjustmentKey] = getSumKeysForDataset(dataset);
  ls[eKey] = totals.calculatedHoursEffort;
  ls[fteKey] = totals.calculatedFte;
  ls[effortAdjustmentKey] = totals.calculatedHoursEffort;
  return ls;
}

function getPrefixByDataset(dataset: DATASET) {
  switch (dataset) {
    case DATASET.planned:
      return 'overridden';
    case DATASET.budget:
      return 'overridden';
    case DATASET.actuals:
      return 'actuals';
    case DATASET.original:
    case DATASET.forecast:
      return 'overridden';
  }
}

function valueKeyByUnit(unit: UNIT) {
  switch (unit) {
    case UNIT.v:
      return 'Volume';
    case UNIT.e:
      return 'HoursEffort';
    case UNIT.fte:
      return 'Fte';
    case UNIT.heads:
      return 'Heads';
    case UNIT.headsOpt:
      return 'HeadsOpt';
    case UNIT.pr:
      return 'ProductivityRate';
    case UNIT.nos:
      return 'NumberOfStaff';
    case UNIT.effAdju:
      return 'HoursEffort';
    default:
      return undefined;
  }
}

function getKey(dataSet: DATASET, unit: UNIT, cellType?: CELL_TYPE) {
  return `${getPrefixByDataset(dataSet)}${valueKeyByUnit(unit)}${cellType === CELL_TYPE.OVERRIDE_STATE ? 'Src' : ''}`;
}

function getKeySource(dataSet: DATASET, unit: UNIT, cellTypeSource?: CELL_TYPE) {
  return `${getPrefixByDataset(dataSet)}${valueKeyByUnit(unit)}${cellTypeSource === CELL_TYPE.OVERRIDE_STATE_SOURCE ? 'SrcSource' : ''}`;
}

function setValue(
  value: Value,
  dataSet: DATASET,
  unit: UNIT,
  granulartiyTree: GranulartiyTree,
  result: any,
  byHour: boolean,
) {
  const val = value[getKey(dataSet, unit)];
  if (!defined(val)) {
    return;
  }
  result[columnName(dataSet, unit, granulartiyTree, byHour)] = getValue(val);
  if(unit === UNIT.effAdju){
  const effortValue = value['adjustedHoursEffort'];
  const effortAdjustment = defined(effortValue) ? effortValue : 0; 
  result[columnName(dataSet, unit, granulartiyTree, byHour)] = effortAdjustment;
  }
  const overridenVal = value[getKey(dataSet, unit, CELL_TYPE.OVERRIDE_STATE)];
  const overridenValSource = value[getKeySource(dataSet, unit, CELL_TYPE.OVERRIDE_STATE_SOURCE)];
  result[
    columnName(
      dataSet,
      unit,
      {
        ...granulartiyTree,
        cellType: CELL_TYPE.OVERRIDE_STATE,
      },
      byHour,
    )
  ] = overridenVal;

  result[
    columnName(
      dataSet,
      unit,
      {
        ...granulartiyTree,
        cellType: CELL_TYPE.OVERRIDE_STATE_SOURCE,
      },
      byHour,
    )
  ] = overridenValSource;
}

function setVarianceValue(target: Value, result: any, granulartiyTree: GranulartiyTree, byHour: boolean) {
  let variance =
    ((target[getKey(DATASET.actuals, UNIT.pr)] - target[getKey(DATASET.planned, UNIT.pr)]) /
      target[getKey(DATASET.planned, UNIT.pr)]) *
    100;
  if (variance >= -0.0001 && variance <= 0.0001) {
    variance = 0;
  }
  if(isNaN(variance)){
    return;
  }
  result[columnName(DATASET.actuals, UNIT.variance, granulartiyTree, byHour)] = variance;
}

function setDifferenceAdjustment(target: Value, result: any, granulartiyTree: GranulartiyTree, byHour: boolean, dataSet) {
  if(dataSet === "planned"){
  let variance =
    ((target['adjustedHoursEffort'] - target[getKey(DATASET.planned, UNIT.e)]) /
      target[getKey(DATASET.planned, UNIT.e)]) *
    100;
    if(!target[getKey(DATASET.planned, UNIT.e)]){
      variance = 100;
    }  
    if(!target['adjustedHoursEffort']){
      variance = -100;
    }
  if(!target[getKey(DATASET.planned, UNIT.e)]&& !target['adjustedHoursEffort']){
    variance = 0;
  }  
  if(isNaN(variance)){
    variance = 0;
  }  
  if (variance >= -0.0001 && variance <= 0.0001) {
    variance = 0;
  }
  if(target[getKey(DATASET.planned, UNIT.e)] === 0 && target['adjustedHoursEffort'] === 0){
    variance = 0;
  }  
  if(target['adjustedHoursEffort'] === 0 && target[getKey(DATASET.planned, UNIT.e)] === undefined){
  return;
  }else{
    result[columnName(DATASET.planned, UNIT.diffAdju, granulartiyTree, byHour)] = variance;
  }
}
if(dataSet === "budget"){
  let variance =
    ((target['adjustedHoursEffort'] - target[getKey(DATASET.planned, UNIT.e)]) /
      target[getKey(DATASET.planned, UNIT.e)]) *
    100;
    if(!target[getKey(DATASET.planned, UNIT.e)]){
      variance = 100;
    }  
    if(!target['adjustedHoursEffort']){
      variance = -100;
    }
  if(!target[getKey(DATASET.planned, UNIT.e)]&& !target['adjustedHoursEffort']){
    variance = 0;
  }  
  if(isNaN(variance)){
    variance = 0;
  }  
  if (variance >= -0.0001 && variance <= 0.0001) {
    variance = 0;
  }
  if(target[getKey(DATASET.planned, UNIT.e)] === 0 && target['adjustedHoursEffort'] === 0){
    variance = 0;
  }  
  if(target['adjustedHoursEffort'] === 0 && target[getKey(DATASET.planned, UNIT.e)] === undefined){
    return;
  }else{
    result[columnName(DATASET.budget, UNIT.diffAdju, granulartiyTree, byHour)] = variance;
  }
}
if(dataSet === "forecast"){
  let variance =
    ((target['adjustedHoursEffort'] - target[getKey(DATASET.planned, UNIT.e)]) /
      target[getKey(DATASET.planned, UNIT.e)]) *
    100;
    if(!target[getKey(DATASET.planned, UNIT.e)]){
      variance = 100;
    }  
    if(!target['adjustedHoursEffort']){
      variance = -100;
    }
  if(!target[getKey(DATASET.planned, UNIT.e)]&& !target['adjustedHoursEffort']){
    variance = 0;
  }  
  if(isNaN(variance)){
    variance = 0;
  }  
  if (variance >= -0.0001 && variance <= 0.0001) {
    variance = 0;
  }
  if(target[getKey(DATASET.planned, UNIT.e)] === 0 && target['adjustedHoursEffort'] === 0){
    variance = 0;
  }  
  result[columnName(DATASET.forecast, UNIT.diffAdju, granulartiyTree, byHour)] = variance;
}
}

function mergeVals(prevVal, value) {
  if (prevVal === null && value === null) {
    return null;
  }

  if (prevVal === undefined && value === undefined) {
    return undefined;
  }

  if (typeof prevVal === 'number' && typeof value === 'number') {
    return prevVal + value;
  }

  if (typeof prevVal === 'string' && typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number' || (value && !prevVal)) {
    return value;
  }

  if (typeof prevVal === 'number' || (prevVal && !value)) {
    return prevVal;
  }

  if (typeof value === 'object' && typeof prevVal === 'object') {
    if (!value) {
      return prevVal;
    }

    if (!prevVal) {
      return value;
    }

    if (Array.isArray(value)) {
      return [...new Set(value.concat(prevVal))];
    }

    const keys = new Set(Object.keys(value).concat(Object.keys(prevVal)));
    const newVal = {};
    keys.forEach((k: string) => {
      const v = value[k];
      const prevV = prevVal[k];
      newVal[k] = mergeVals(prevV, v);
    });
    return newVal;
  }
  return prevVal + value;
}

function periodsToData(granulartiyTree: GranulartiyTree, dataSet: DATASET, filter: Filter, mhes?: any): any {
  const result: any = {};
  if (granulartiyTree.periods !== undefined && granulartiyTree.periods.length === 0) {
    return result;
  }
  const cursor = CURSORS[granulartiyTree.level];
  const subarray = granulartiyTree[cursor.array];
  if (subarray && subarray.length > 0) {
    const nextLevel = getNextLevel(granulartiyTree.level);
    const nextCursor = CURSORS[nextLevel];

    // @ts-ignore
    const parseDeeper = (res: object) => (item: any) => {
      const periodCols = periodsToData(
        {
          ...granulartiyTree,
          [cursor.single]: item,
          level: nextLevel,
          [nextCursor.array]: item[nextCursor.array],
        },
        dataSet,
        filter,
        mhes,
      );
      for (const [key, value] of Object.entries(periodCols)) {
        if (key in res) {
          const prevVal = res[key];
          if (typeof prevVal === 'object') {
            res[key] = mergeVals(prevVal, value);
          }
        } else {
          res[key] = value;
        }
      }
    };

    if (granulartiyTree.level === LEVEL.WZP && filter.byHour && !granulartiyTree.isLabor && !granulartiyTree.isMheAva) {
      subarray.map((wzp: any) => {
        const id = wzp.workZonePeriodName;
        const wzpRow = (result[id] = { wzp: omitKey(wzp, ['hours', 'mhes']) });
        if (mhes) {
          for (const key in mhes) {
            const mhrow = mhes[key];
            if (!('wzps' in mhrow)) {
              mhrow.wzps = {};
            }
            mhrow.currentWzp = id;
            if (!(mhrow.currentWzp in mhrow.wzps)) {
              mhrow.wzps[id] = { shift: wzp.workZonePeriodName || wzp.name, shiftId: id };
            }
          }
        }
        parseDeeper(wzpRow)(wzp);
      });
    } else {
      const parser = parseDeeper(result);
      subarray.forEach(parser);
    }
  } else {
    const prevLevel = getPrevLevel(granulartiyTree.level);
    const prevcursor = CURSORS[prevLevel];
    const target: Value = granulartiyTree[prevcursor.single];

    const addHeads = !granulartiyTree.isSumRow && !granulartiyTree.isLabor && filter.granularity !== GRANULARITY.MONTH;
    if (granulartiyTree.isLabor || (granulartiyTree.isMheAva && target)) {
      const valOriginalE = target.calculatedHoursEffort;
      const valOriginalFte = target.calculatedFte;
      const valOriginalHeads = target.calculatedHeads;
      const valOriginalNumberOfStaff = target.calculatedNumberOfStaff;

      result[columnName(dataSet, UNIT.e, granulartiyTree, filter.byHour)] = valOriginalE;
      result[columnName(dataSet, UNIT.fte, granulartiyTree, filter.byHour)] = valOriginalFte;
      result[columnName(dataSet, UNIT.effAdju, granulartiyTree, filter.byHour)] = valOriginalE;

      if (addHeads) {
        result[columnName(dataSet, UNIT.heads, granulartiyTree, filter.byHour)] = getValue(valOriginalHeads);
        result[columnName(dataSet, UNIT.headsOpt, granulartiyTree, filter.byHour)] = getValue(target.calculatedHeadsOpt);
      }
      result[columnName(dataSet, UNIT.nos, granulartiyTree, filter.byHour)] = valOriginalNumberOfStaff;
    } else {
      if (target) {
        setValue(target, dataSet, UNIT.e, granulartiyTree, result, filter.byHour);
        setValue(target, dataSet, UNIT.fte, granulartiyTree, result, filter.byHour);
        if (addHeads) {
          setValue(target, dataSet, UNIT.heads, granulartiyTree, result, filter.byHour);
          setValue(target, dataSet, UNIT.headsOpt, granulartiyTree, result, filter.byHour);
        }

        if (!granulartiyTree.indirect && !granulartiyTree.isSumRow) {
          setValue(target, dataSet, UNIT.pr, granulartiyTree, result, filter.byHour);
          setValue(target, dataSet, UNIT.v, granulartiyTree, result, filter.byHour);
        }
          setValue(target, dataSet, UNIT.effAdju, granulartiyTree, result, filter.byHour);
          setDifferenceAdjustment(target, result, granulartiyTree, filter.byHour, dataSet);
        setValue(target, dataSet, UNIT.nos, granulartiyTree, result, filter.byHour);
        if (dataSet === DATASET.planned) {
          setValue(target, DATASET.actuals, UNIT.e, granulartiyTree, result, filter.byHour);
          setValue(target, DATASET.actuals, UNIT.fte, granulartiyTree, result, filter.byHour);
          if (addHeads) {
            setValue(target, DATASET.actuals, UNIT.heads, granulartiyTree, result, filter.byHour);
            setValue(target, DATASET.actuals, UNIT.headsOpt, granulartiyTree, result, filter.byHour);
          }
          if (!granulartiyTree.indirect && !granulartiyTree.isSumRow) {
            setValue(target, DATASET.actuals, UNIT.pr, granulartiyTree, result, filter.byHour);
            setValue(target, DATASET.actuals, UNIT.v, granulartiyTree, result, filter.byHour);
            setVarianceValue(target, result, granulartiyTree, filter.byHour);
          }
          setValue(target, DATASET.actuals, UNIT.effAdju, granulartiyTree, result, filter.byHour);
          setDifferenceAdjustment(target, result, granulartiyTree, filter.byHour, dataSet);
          setValue(target, DATASET.actuals, UNIT.nos, granulartiyTree, result, filter.byHour);
          setValue(target, DATASET.original, UNIT.e, granulartiyTree, result, filter.byHour);
          setValue(target, DATASET.original, UNIT.fte, granulartiyTree, result, filter.byHour);
          if (addHeads) {
            setValue(target, DATASET.original, UNIT.heads, granulartiyTree, result, filter.byHour);
            setValue(target, DATASET.original, UNIT.headsOpt, granulartiyTree, result, filter.byHour);
          }
          if (!granulartiyTree.indirect && !granulartiyTree.isSumRow) {
            setValue(target, DATASET.original, UNIT.pr, granulartiyTree, result, filter.byHour);
            setValue(target, DATASET.original, UNIT.v, granulartiyTree, result, filter.byHour);
          }
          setValue(target, DATASET.original, UNIT.effAdju, granulartiyTree, result, filter.byHour);
          setDifferenceAdjustment(target, result, granulartiyTree, filter.byHour, dataSet);
          setValue(target, DATASET.original, UNIT.nos, granulartiyTree, result, filter.byHour);
        }
      }
    }
  }
  return result;
}

function countDecimals(value: number): number {
  if (!value) {
    return 0;
  }
  if (Math.floor(value.valueOf()) === value.valueOf()) {
    return 0;
  }
  const decimals = value.toString().split('.')[1];
  return (decimals && decimals.length) || 0;
}

function addFormatter(formatters: Object, field: any, value: number): void {
  const dCount = countDecimals(value);
  if (!(field in formatters) || dCount > formatters[field]) {
    formatters[field] = dCount;
  }
}

export function addFormatters(formatters: Object, vals: Object) {
  for (const [key, value] of Object.entries(vals)) {
    if (typeof value === 'string') {
      continue;
    }

    addFormatter(formatters, key, value);
  }
}

const plannedEColName = columnName(DATASET.planned, UNIT.e, { isSum: true });
const originalEColName = columnName(DATASET.original, UNIT.e, { isSum: true });
const actualsEColName = columnName(DATASET.actuals, UNIT.e, { isSum: true });
const budgetEColName = columnName(DATASET.budget, UNIT.e, { isSum: true });
const forecastEColName = columnName(DATASET.forecast, UNIT.e, { isSum: true });

const plannedEffortAdjustmentColName = columnName(DATASET.planned, UNIT.effAdju, { isSum: true });
const plannedDifferenceAdjustmentColName = columnName(DATASET.planned, UNIT.diffAdju, { isSum: true });
const budgetEffortAdjustmentColName = columnName(DATASET.budget, UNIT.effAdju, { isSum: true });
const budgetDifferenceAdjustmentColName = columnName(DATASET.budget, UNIT.diffAdju, { isSum: true });
const forecastEffortAdjustmentColName = columnName(DATASET.forecast, UNIT.effAdju, { isSum: true });
const forecastDifferenceAdjustmentColName = columnName(DATASET.forecast, UNIT.diffAdju, { isSum: true });

const plannedFTEColName = columnName(DATASET.planned, UNIT.fte, { isSum: true });
const budgetFTEColName = columnName(DATASET.budget, UNIT.fte, { isSum: true });
const actualsFTEColName = columnName(DATASET.actuals, UNIT.fte, { isSum: true });
const originalFTEColName = columnName(DATASET.original, UNIT.fte, { isSum: true });
const forecastFTEColName = columnName(DATASET.forecast, UNIT.fte, { isSum: true });

const plannedVColName = columnName(DATASET.planned, UNIT.v, { isSum: true });
const budgetVColName = columnName(DATASET.budget, UNIT.v, { isSum: true });
const actualsVColName = columnName(DATASET.actuals, UNIT.v, { isSum: true });
const originalVColName = columnName(DATASET.original, UNIT.v, { isSum: true });
const forecastVColName = columnName(DATASET.forecast, UNIT.v, { isSum: true });

function addActivityDetails(row, activity, activityName, period, isShift, messages) {
  const pActivity = activity.activity;

  row.activity = `${activityName} || ${pActivity.regionalConfigurationName}`;
  row.activityId = pActivity.id;
  row.regionalConfigurationName = pActivity.regionalConfigurationName;
  row.department = activity.department ? activity.department.name : messages.allDepartments;
  row.departmentId = activity.department ? activity.department.id : null;
  row.customer = activity.customer;
  row.customerId = activity.customer ? activity.customer.id : null;
  row.isIndirect = activity.indirect;
  row.uom = (activity.uom && activity.uom.name) || `h/${messages[period]}`;
  row.uomId = activity.uom ? activity.uom.id : undefined;

  if (isShift) {
    const shift = (row.wzp && (row.wzp.name || row.wzp.workZonePeriodName)) || undefined;
    row.shift = shift;
    row.shiftId = row.wzp ? row.wzp.id : undefined;
  }
}

function calculateDifferenceAdjustment(planned){
  if(!planned){
    return;
  }
  if(planned.adjustedHoursEffort === 0 && planned.overriddenHoursEffort === undefined){
    return;
  }
  if(!planned.adjustedHoursEffort && !planned.overriddenHoursEffort){
    return 0;
  }
  if(!planned?.adjustedHoursEffort){
    return -100
  }
  if(!planned.overriddenHoursEffort){
    return 100;
  }
  let differenceInAdjustment = ((planned.adjustedHoursEffort - planned.overriddenHoursEffort) / planned.overriddenHoursEffort) * 100;
  if(isNaN(differenceInAdjustment)){
    differenceInAdjustment = 0;
  }
  if (differenceInAdjustment >= -0.0001 && differenceInAdjustment <= 0.0001) {
    differenceInAdjustment = 0;
  }
  return differenceInAdjustment;
}

function calculateAdjustment(budget){
  if(!budget){
    return;
  }
  if(budget.adjustedHoursEffort === 0 && budget.overriddenHoursEffort === undefined){
    return;
  }
  return budget.adjustedHoursEffort;
}

function addRowTotals(row, planned, budget, forecast, numberFormatter) {
  if (!row || !planned) {
    return;
  }

  row[plannedEColName] = planned.overriddenHoursEffort;
  row[plannedFTEColName] = planned.overriddenFte;
  row[plannedEffortAdjustmentColName] = calculateAdjustment(planned);
  row[plannedDifferenceAdjustmentColName] = calculateDifferenceAdjustment(planned)
  row[actualsEColName] = planned.actualsHoursEffort;
  row[actualsFTEColName] = planned.actualsFte;
  row[originalEColName] = planned.calculatedHoursEffort;
  row[originalFTEColName] = planned.calculatedFte;

  row[budgetEColName] = budget && budget.overriddenHoursEffort;
  row[budgetFTEColName] = budget && budget.overriddenFte;
  row[budgetEffortAdjustmentColName] = calculateAdjustment(budget);
  row[budgetDifferenceAdjustmentColName] = calculateDifferenceAdjustment(budget);

  row[forecastEColName] = forecast && forecast.overriddenHoursEffort;
  row[forecastFTEColName] = forecast && forecast.overriddenFte;
  row[forecastEffortAdjustmentColName] = calculateAdjustment(forecast);
  row[forecastDifferenceAdjustmentColName] = calculateDifferenceAdjustment(forecast);

  if (!planned.indirect) {
    const overriddenV = planned.overriddenVolume;
    addFormatter(numberFormatter, plannedVColName, overriddenV);
    row[plannedVColName] = overriddenV;

    const overriddenBV = budget && budget.overriddenVolume;
    addFormatter(numberFormatter, budgetVColName, overriddenBV);
    row[budgetVColName] = overriddenBV;

    const actualsV = planned.actualsVolume;
    addFormatter(numberFormatter, actualsVColName, actualsV);
    row[actualsVColName] = actualsV;

    const calculatedV = planned.calculatedVolume;
    addFormatter(numberFormatter, originalVColName, calculatedV);
    row[originalVColName] = calculatedV;

    const forecastV = forecast && forecast.overriddenVolume;
    addFormatter(numberFormatter, forecastVColName, forecastV);
    row[forecastVColName] = forecast && forecastV;
  }
}


export function activityToRow(
  plannedActivity: ApiActivityParametersCalculationResultDTO,
  budgetActivity: ApiActivityParametersCalculationResultDTO,
  forecastActivity: ApiActivityParametersCalculationResultDTO,
  filter: Filter,
  numberFormatter: Object,
  mheNumberFormatter: Object,
  props: Props,
): TablesDataResult {
  const mhesRows = {}; // not used any more, mhes are calculated separately
  const period = filter.granularity.toLowerCase();
  const { messages } = props;

  const activityName = `${plannedActivity.activity.name}${` (${(plannedActivity.uom && plannedActivity.uom.name) || `h/${messages[period]}`
    })`}`;


  const activityPlannedData = periodsToData(
    {
      indirect: plannedActivity.indirect,
      isSum: false,
      level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
      periods: plannedActivity.periods,
    },
    DATASET.planned,
    filter,
    mhesRows,
  );

  const activityBudgetData =
    (budgetActivity &&
      periodsToData(
        {
          indirect: plannedActivity.indirect,
          isSum: false,
          level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
          periods: budgetActivity.periods,
        },
        DATASET.budget,
        filter,
        mhesRows,
      )) ||
    {};

  const activityForecastData =
    (forecastActivity &&
      periodsToData(
        {
          indirect: forecastActivity.indirect,
          isSum: false,
          level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
          periods: forecastActivity.periods,
        },
        DATASET.forecast,
        filter,
        mhesRows,
      )) ||
    {};

  let activityData;

  // if isShift periodsToData return dictionary shift id => row
  if (filter.byHour) {
    activityData = [];
    Object.keys(activityPlannedData)
      .filter((k: string) => activityPlannedData[k] && activityPlannedData[k].wzp)
      .map((k: string) => {
        const planned = activityPlannedData[k];
        const budget = activityBudgetData && activityBudgetData[k];
        const forecast = activityForecastData && activityForecastData[k];
        const row = { ...planned, ...budget, ...forecast };
        addActivityDetails(row, plannedActivity, activityName, period, filter.isShift, messages);
        addRowTotals(row, planned.wzp, budget && budget.wzp, forecast && forecast.wzp, numberFormatter);
        addFormatters(numberFormatter, row);
        activityData.push(row);
      });

    return {
      activity: {
        data: activityData,
      },
      mhe: { data: Object.values(mhesRows) },
    };
  }
  activityData = { ...activityPlannedData, ...activityBudgetData, ...activityForecastData };
  addActivityDetails(activityData, plannedActivity, activityName, period, filter.isShift, messages);
  addFormatters(numberFormatter, activityData);
  addRowTotals(activityData, plannedActivity, budgetActivity, forecastActivity, numberFormatter);
  if (filter.showNumberOfStaff) {
    if (plannedActivity && plannedActivity.wzpNumberOfStaffTotal) {
      plannedActivity.wzpNumberOfStaffTotal.forEach(avgPerWzp => {
        activityData[getWzpAvgColId(avgPerWzp.wzpName, DATASET.planned)] = avgPerWzp.calculatedNumberOfStaff;
      });
    }
    if (budgetActivity && budgetActivity.wzpNumberOfStaffTotal) {
      budgetActivity.wzpNumberOfStaffTotal.forEach(avgPerWzp => {
        activityData[getWzpAvgColId(avgPerWzp.wzpName, DATASET.budget)] = avgPerWzp.calculatedNumberOfStaff;
      });
    }
    if (forecastActivity && forecastActivity.wzpNumberOfStaffTotal) {
      forecastActivity.wzpNumberOfStaffTotal.forEach(avgPerWzp => {
        activityData[getWzpAvgColId(avgPerWzp.wzpName, DATASET.forecast)] = avgPerWzp.calculatedNumberOfStaff;
      });
    }
  }

  return {
    activity: {
      data: activityData,
    },
    mhe: { data: Object.values(mhesRows) },
  };
}


export function labourCategoryToRow(
  category: ApiLabourCategoryAvailabilityCalculationResultDTO,
  filter: Filter,
  dataset: DATASET = DATASET.budget,
) {
  const row = periodsToData(
    {
      isLabor: true,
      isSum: false,
      level: LEVEL.WEEK,
      periods: category.periods,
    },
    dataset,
    filter,
  );
  
  category?.periods?.forEach((item)=>{
    function columnNames(dataset, unit) {
      const result = [];
      if (item) {
        result.push(`w${item?.startDay}`);
      }
      result.push(`${dataset}_${unit}`);
      return result.join('_');
    }
    row[columnNames(dataset, UNIT.e)] = item?.calculatedHoursEffort;
    row[columnNames(dataset, UNIT.fte)] = item?.calculatedFte;
    row[columnNames(dataset, UNIT.nos)] = item?.calculatedNumberOfStaff;
  })
  row.activity = category.labourCategory.name;
  row.id = category.labourCategory.id;
  // totals
  const [eKey, fteKey] = getSumKeysForDataset(dataset);
  row[eKey] = category.calculatedHoursEffort;
  row[fteKey] = category.calculatedFte;
  row.isSumRow = true;
  // periods
  return row;
}

export function labourCategoryToRowDept(
  category,
  filter: Filter,
  dataset: DATASET = DATASET.planned,
  period?: any,
  item?: any,
  day?: any,
  wzp?: any,
  hour?: any,
) {
  const data = {}
  function columnName(dataset, unit, filter) {
    const result = [];
    if (item) {
      result.push(`w${item.startDay}`);
    }
    if (day) {
      result.push(`d${day.day}`);
    }
    if (wzp && !filter) {
      result.push(`wzp${wzp.workZonePeriodId}`);
    }
    if (hour) {
      result.push(`h${hour.hourOfDay}`);
    }
    result.push(`${dataset}_${unit}`);
    return result.join('_');
  }
  data[columnName(dataset, UNIT.e, filter.byHour)] = category.calculatedHoursEffort,
    data[columnName(dataset, UNIT.fte, filter.byHour)] = category.calculatedFte,
    data[columnName(dataset, UNIT.nos, filter.byHour)] = category.calculatedNumberOfStaff,
    data[columnName(dataset, UNIT.effAdju, filter.byHour)] = category.calculatedHoursEffort,
    //@ts-ignore
    data.activity = (category?.department?.name && category?.role?.name) ? `${period?.labourCategory?.name} (${category?.department?.name}/${category?.role?.name})` : (!category?.department?.name && category?.role?.name) ? `${period?.labourCategory?.name} (${category?.role?.name})` : category?.department?.name ? `${period?.labourCategory?.name} (${category?.department?.name})` : period?.labourCategory?.name;
  //@ts-ignore
  data.id = (category?.department?.id && category?.role?.id) ? `${period?.labourCategory?.id}_${category?.department?.id}_${category?.role?.id}` : (!category?.department?.id && category?.role?.id) ? `${period?.labourCategory?.id}_${category?.role?.id}` : (category?.department?.id && !category?.role?.id) ? `${period?.labourCategory?.id}_${category?.department?.id}`:  period?.labourCategory?.id;
  let eValue = [];
  let e, fte = 0;
  if (category?.department?.id && category?.role?.id) {
    eValue = period?.availabilityCalculatedValuesForDepartmentsAndRoles?.filter((a) => (a?.department?.id == category?.department?.id) && (a?.role?.id == category?.role?.id))
  }else if(!category?.department?.id && category?.role?.id){
    eValue = period?.availabilityCalculatedValuesForDepartmentsAndRoles?.filter((a) => (a?.role?.id == category?.role?.id))
  }else if(category?.department?.id && !category?.role?.id){
    eValue = period?.availabilityCalculatedValuesForDepartmentsAndRoles?.filter((a) => (a?.department?.id == category?.department?.id))
  } else {
    const eData = period?.availabilityCalculatedValuesForDepartmentsAndRoles?.filter((a)=> !a?.department);
    e = eData[0] ? eData[0].calculatedHoursEffort : 0;
    fte = eData[0] ? eData[0].calculatedFte : 0;
  }
  const [eKey, fteKey, effortAdjustmentKey] = getSumKeysForDataset(dataset);
  data[eKey] = (category?.department?.id || category?.role?.id) ? eValue[0]?.calculatedHoursEffort : e;
  data[fteKey] = (category?.department?.id || category?.role?.id) ? eValue[0]?.calculatedFte : fte;
  data[effortAdjustmentKey] = (category?.department?.id || category?.role?.id) ? eValue[0]?.calculatedHoursEffort : e
  //@ts-ignore
  data.isSumRow = true;
  return data;
}
