/*
 *
 * PlanningAreaDetailPage constants
 *
 */

export const LOAD_PA = 'app/PlanningAreaDetailPage/LOAD_PA';
export const LOAD_PA_DETAILS = 'app/PlanningAreaDetailPage/LOAD_PA_DETAILS';
export const LOAD_PA_OVERVIEW_DATA = 'app/PlanningAreaDetailPage/LOAD_PA_OVERVIEW_DATA';
export const STORE_PA = 'app/PlanningAreaDetailPage/STORE_PA';
export const SUBMIT_ERROR = 'app/PlanningAreaDetailPage/SUBMIT_ERROR';
export const STORE_PA_GUI_STATE_ITEM = 'app/PlanningAreaDetailPage/STORE_PA_GUI_STATE_ITEM';
export const SAVE_PA_BASIC_SETTINGS = 'app/PlanningAreaDetailPage/SAVE_PA_BASIC_SETTINGS';
export const SAVE_PA_PLANNING_PARAMETERS = 'app/PlanningAreaDetailPage/SAVE_PA_PLANNING_PARAMETERS';
export const ADD_BUDGET_VOLUME_LINE = 'app/PlanningAreaDetailPage/ADD_BUDGET_VOLUME_LINE';
export const DELETE_BUDGET_VOLUME = 'app/PlanningAreaDetailPage/DELETE_BUDGET_VOLUME';
export const MODIFY_PA_ACTIVITY_LINE = 'app/PlanningAreaDetailPage/MODIFY_PA_ACTIVITY_LINE';
export const DELETE_PA_ACTIVITY_LINES = 'app/PlanningAreaDetailPage/DELETE_PA_ACTIVITY_LINES';
export const ADD_PA_MHE_OPEN = 'app/PlanningAreaDetailPage/ADD_PA_MHE_OPEN';
export const ADD_PA_SHIFT_OPEN = 'app/PlanningAreaDetailPage/ADD_PA_SHIFT_OPEN';
export const ADD_PA_ACTIVITY_LINE = 'app/PlanningAreaDetailPage/ADD_PA_ACTIVITY_LINE';
export const COPY_PA = 'app/PlanningAreaDetailPage/COPY_PA';
export const NAVIGATE_TO_PLANS = 'app/PlanningAreaDetailPage/NAVIGATE_TO_PLANS';
export const CREATE_PLAN = 'app/PlanningAreaDetailPage/CREATE_PLAN';
export const START_PA_COPY = 'app/PlanningAreaDetailPage/START_PA_COPY';
export const NAVIGATE_PA_COPY = 'app/PlanningAreaDetailPage/NAVIGATE_PA_COPY';
export const CLEAR_AREA_MHE = 'app/PlanningAreaDetailPage/CLEAR_AREA_MHE';
export const SAVE_PA = 'app/PlanningAreaDetailPage/SAVE_PA';
export const SAVE_PA_DONE = 'app/PlanningAreaDetailPage/SAVE_PA_DONE';
export const SAVE_MASTER_PLAN_CONFLICT = 'app/PlanningAreaDetailPage/SAVE_MASTER_PLAN_CONFLICT';
export const CLEAR_MASTER_PLAN_CONFLICT = 'app/PlanningAreaDetailPage/CLEAR_MASTER_PLAN_CONFLICT';
export const ADD_PA_WZP = 'app/PlanningAreaDetailPage/ADD_PA_WZP';
export const DELETE_PA_WZP = 'app/PlanningAreaDetailPage/DELETE_PA_WZP';
export const SWITCH_TO_DISPLAY_PA = 'app/PlanningAreaDetailPage/SWITCH_TO_DISPLAY_PA';
export const TOGGLE_SECTION_PA = 'app/PlanningAreaDetailPage/TOGGLE_SECTION_PA';
export const STORE_PA_PERIOD_INDEX = 'app/PlanningAreaDetailPage/STORE_PA_PERIOD_INDEX';
export const ADD_PERIOD_PA = 'app/PlanningAreaDetailPage/ADD_PERIOD_PA';
export const DELETE_PERIOD_PA = 'app/PlanningAreaDetailPage/DELETE_PERIOD_PA';
export const COPY_PERIOD_PA = 'app/PlanningAreaDetailPage/COPY_PERIOD_PA';
export const SAVE_LABOUR_OVERVIEW = 'app/PlanningAreaDetailPage/SAVE_LABOUR_OVERVIEW';
export const SAVE_MHE_OVERVIEW  = 'app/PlanningAreaDetailPage/SAVE_MHE_OVERVIEW';
export const ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA = 'app/PlanDetailPage/ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA';
export const DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA =
  'app/PlanDetailPage/DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA';
export const ADD_SHIFT_SETTINGS_LINE_PA = 'app/PlanDetailPage/ADD_SHIFT_SETTINGS_LINE_PA';
export const DELETE_SHIFT_SETTINGS_LINE_PA = 'app/PlanDetailPage/DELETE_SHIFT_SETTINGS_LINE_PA';
export const PLANNINGAREA_DETAILS_LOADER = 'app/PlanningAreaDetailPage/PLANNINGAREA_DETAILS_LOADER';
export const PLANNINGAREA_DETAILS_LOADER_FLAG = 'app/PlanningAreaDetailPage/PLANNINGAREA_DETAILS_LOADER_FLAG';
export const PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG = 'app/PlanningAreaDetailPage/PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG';
export const RESET_PLANNING_AREA = "app/PlanningAreaDetailPage/RESET_PLANNING_AREA";
export const ADD_ADJUSTMENT_LINE = 'app/PlanningAreaDetailPage/ADD_ADJUSTMENT_LINE';
export const DELETE_ADJUSTMENT_LINE = 'app/PlanningAreaDetailPage/DELETE_ADJUSTMENT_LINE';
export const SAVE_ADJUSTMENT_OVERVIEW = 'app/PlanningAreaDetailPage/SAVE_ADJUSTMENT_OVERVIEW';
export const SAVE_ROLES = 'app/PlanningAreaDetailPage/SAVE_ROLES';
export const ADD_PA_ROLE_OPEN = 'app/PlanningAreaDetailPage/ADD_PA_ROLE_OPEN';
export const SAVE_PLANINGAREA_SMART_CODE_MAPPINGS = 'app/PlanningAreaDetailPage/SAVE_PLANINGAREA_SMART_CODE_MAPPINGS';
export const TOGGLE_VOLUME_VALUE = 'app/PlanningAreaDetailPage/TOGGLE_VOLUME_VALUE';
export const TOGGLE_VOLUME_FORMULA = 'app/PlanningAreaDetailPage/TOGGLE_VOLUME_FORMULA';
export const TOGGLE_VOLUME_VALUE_VAR = 'app/PlanningAreaDetailPage/TOGGLE_VOLUME_VALUE_VAR';
export const TOGGLE_VOLUME_FORMULA_VAR = 'app/PlanningAreaDetailPage/TOGGLE_VOLUME_FORMULA_VAR';
