import React from "react";
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { getIn } from 'formik';
import messages from './messages';
import Table from 'components/Table';
import { Wraptable } from 'components/ResultMatrixActivitiesTable/styled';
import { DATASET, GRANULARITY, UNIT } from 'containers/PlanResultPage/calculation/types';
import cloneDeep from 'lodash/cloneDeep';
import { dateFormat, numberFormat } from 'utils/utils';
import { useSelector } from 'react-redux';
import { getVolumeRawData } from 'containers/PlanResultPage/selectors';

function getDataSourcePath(granularity: string, weekIndex: number, dayIndex: number, wzpIndex: number = null, hourOfDay: number = null): string {
    let result = weekIndex !== undefined ? `weeklyVolumes.${weekIndex}` : 'missingWeek'; // dummy property
    if (granularity !== GRANULARITY.WEEK && granularity !== GRANULARITY.MONTH) {
        result = dayIndex !== undefined ? `${result}.dayVolumeDTOS.${dayIndex}` : 'missingDay'; // dummy property
        if (granularity !== GRANULARITY.DAY) {
            result = wzpIndex !== undefined ? `${result}.wzpVolumeDTOList.${wzpIndex}` : 'missingWzp'; // dummy property
            if (granularity !== GRANULARITY.WZP) {
                result = hourOfDay !== undefined ? `${result}.hourVolumeDTOList.${hourOfDay}` : 'missingHour'; // dummy property
            }
        }
    }
    return result;
}

const parseDateAndFormat = value => {
    const day = new Date(`${value}T00:00:00`);
    return dateFormat(day);
};

const VolumeCategoryForWZPHour = (props) => {
    const rawData = useSelector(getVolumeRawData);
    const getColDefs = () => {
        const columns: (ColGroupDef | ColDef)[] = [
            {
                headerName: 'Volume Category',
                width: 150,
                pinned: true,
                valueGetter: params => `${params?.data?.volumeCategory?.name} (${params?.data?.volumeCategory?.variableName})`
            },
            {
                headerName: 'Unit of Meausre',
                width: 150,
                pinned: true,
                valueGetter: params => params?.data?.uom?.name
            }
        ];
        const commonFieldAttributes = (
            dataset: DATASET,
            weekIndex?: number,
            dayIndex?: number,
            wzpIndex?: number,
            hour?: number,
        ) => {
            return ({
                width: 65,
                sortable: true,
                suppressMenu: true,
                valueFormatter: params => {
                    if (!params.value || params.value === 0) {
                        return params.value;
                    }
                    const val = Number(params.value).toFixed(2);
                    return numberFormat(val, { minimumFractionDigits: 2 });
                },
                valueGetter: params => {
                    const dsForGetter = dataset === DATASET.budget ? 'baseline' : dataset;
                    let dataSource;
                    let dataSources = []; // for isShift when hours can overlap
                    if (granularity === GRANULARITY.HOUR) {
                        const wzpDataSourcePath = getDataSourcePath(GRANULARITY.WZP, weekIndex, dayIndex, wzpIndex)
                        const wzpDataSource = getIn(params.data, wzpDataSourcePath, { hours: [] });
                        dataSource = wzpDataSource.hourVolumeDTOList.find(
                            it => it.hour === hour,
                        ) || {};
                        if (props.isShift) {
                            const daysDataSource = getIn(params.data, getDataSourcePath(GRANULARITY.DAY, weekIndex, dayIndex));
                            // shifts can overlap so we need sum hour entries for all shifts
                            dataSources = daysDataSource?.wzps
                                .flatMap(it => it.hours || [])
                                .filter(it => it.hour === hour) || [];
                        }
                    } else {
                        const dataSourcePath = getDataSourcePath(granularity, weekIndex, dayIndex, wzpIndex, hour);
                        dataSource = getIn(params.data, dataSourcePath);
                    }
                    if (!dataSource) {
                        // for some activities wee may not arrive so datasource would be undefined SMP-2780
                        // this happens for more periods if MHE is defined only in some period
                        return '';
                    }
                    let available = dataSource.volume;
                    if (props.isShift) {
                        switch (dataSources.length) {
                            case 0:
                                available = 0;
                                break;
                            case 1:
                                available = dataSource.volume || 0;
                                break;
                            default: {
                                // go through overlapping shifts
                                let availableForTheHourWithoutMaintenance;
                                let maintenanceForTheHour = 0;
                                dataSources.forEach(ds => {
                                    const availableForShift = (ds.data?.available || {})[dsForGetter] || 0;
                                    const maintenanceForShift = (ds.data?.maintenance || {})[dsForGetter] || 0;
                                    const availableForTheHourAndShiftWithoutMaintenance = availableForShift + maintenanceForShift;
                                    maintenanceForTheHour += maintenanceForShift;
                                    if (availableForTheHourWithoutMaintenance === undefined) {
                                        availableForTheHourWithoutMaintenance = availableForTheHourAndShiftWithoutMaintenance;
                                    } else if (availableForTheHourWithoutMaintenance !== availableForTheHourAndShiftWithoutMaintenance) {
                                        if (availableForTheHourAndShiftWithoutMaintenance > availableForTheHourWithoutMaintenance) {
                                            availableForTheHourWithoutMaintenance = availableForTheHourAndShiftWithoutMaintenance;
                                        }
                                    }
                                });
                                available = availableForTheHourWithoutMaintenance - maintenanceForTheHour;
                            }
                        }
                    }
                    return dataSource?.volume || '0';
                },
                tooltipValueGetter: params => `${props.intl.formatMessage(messages.fullValue)}: ${params.value}`,
            })
        };
        const {
            granularity,
            data,
            intl,
        } = props;
        const isShiftTransformationType = props.plan.planningParameters.transformationType === 'SHIFT';

        const regularChildrenOfDay = (day, weekIndex: number, dayIndex: number) => {

            if (granularity === GRANULARITY.WZP || granularity === GRANULARITY.HOUR) {

                const emptyWzp = {
                    wzpName: '',
                    data: {
                        actuals: null,
                        baseline: null,
                        planned: null,
                        forecast: null,
                    },
                    hours: null,
                };
                const wzps = day.wzpVolumeDTOList?.length ? day.wzpVolumeDTOList : [emptyWzp];
                return wzps.map((wzp, wzpIndex) => {
                    return wzp.hourVolumeDTOList.length === 0 ? {
                        headerName: wzp.wzpName,
                        headerTooltip: wzp.wzpName,
                        ...commonFieldAttributes(undefined, weekIndex, dayIndex, wzpIndex),
                    } : {
                        headerName: wzp.wzpName,
                        headerTooltip: wzp.wzpName,
                        children: wzp?.hourVolumeDTOList?.length
                            ? wzp?.hourVolumeDTOList.map((hour) => (
                                {
                                    headerName: `${hour.hour} h`,
                                    headerTooltip: `${hour.hour} h`,
                                    ...commonFieldAttributes(undefined, weekIndex, dayIndex, wzpIndex, hour.hour)
                                }))
                            : [],
                    }
                }
                )
            }
            return [
                {
                    headerName: intl.formatMessage(messages.hDay),
                    headerTooltip: intl.formatMessage(messages.hDay),
                    ...commonFieldAttributes(undefined, weekIndex, dayIndex),
                },
            ]
        };

        const shiftChildrenOfDay = (day, weekIndex: number, dayIndex: number) => {
            if (!day.wzpVolumeDTOList?.length) {
                return [
                    {
                        headerName: intl.formatMessage(messages.hDay),
                        headerTooltip: intl.formatMessage(messages.hDay),
                        ...commonFieldAttributes(undefined, weekIndex, dayIndex),
                    },
                ];
            }
            const hourColumns = [...new Set(day.wzpVolumeDTOList?.flatMap(shift => shift.hourVolumeDTOList).map(it => it.hour))];
            return hourColumns.map(hour => ({
                headerName: `${hour} h`,
                headerTooltip: `${hour} h`,
                children: [
                    {
                        headerName: intl.formatMessage(messages.h),
                        headerTooltip: intl.formatMessage(messages.h),
                        //@ts-ignore
                        ...commonFieldAttributes(undefined, weekIndex, dayIndex, 0, hour), // wzpIndex can be 0
                        // because there will be always only 1 wzp in the row when isShift == true (see moveShiftsToRows)
                    },
                ],
            }));
        };

        let colDefs = cloneDeep(columns);
        let byActivity = [];
        //@ts-ignore
        rawData?.forEach((raw) => {
            raw?.weeklyVolumes?.forEach(week => {
                const doesPeriodAlreadyExists = byActivity?.some((ps) => (ps.startDay === week.startDay));
                if (week.startDay && !doesPeriodAlreadyExists) {
                    byActivity.push(week);
                }
            })
        })
        if (byActivity?.length) {

            colDefs.push(
                ...byActivity.map((week, weekIndex) => ({
                    headerName: `${granularity === 'MONTH' ? props.intl.formatMessage(messages.month) : props.intl.formatMessage(messages.week)
                        } ${parseDateAndFormat(week.startDay)}`,
                    headerTooltip: `${granularity === 'MONTH' ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
                        } ${parseDateAndFormat(week.startDay)}`,
                    children: week.dayVolumeDTOS?.length
                        ? week.dayVolumeDTOS.map((day, dayIndex) => ({
                            headerName: `Day ${parseDateAndFormat(day.day,)}`,
                            headerTooltip: `Day ${parseDateAndFormat(day.day)}`,
                            children: props.result.isShift
                                ? shiftChildrenOfDay(day, weekIndex, dayIndex)
                                : regularChildrenOfDay(day, weekIndex, dayIndex),
                        }))
                        : [
                            {
                                headerName: `h/${granularity === GRANULARITY.MONTH ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
                                    }`,
                                headerTooltip: `h/${granularity === 'MONTH' ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
                                    }`,
                                ...commonFieldAttributes(undefined, weekIndex),
                            },
                        ],
                })),
            );
        }
        return colDefs;
    };

    const onGridReady = params => {
        params.columnApi.autoSizeAllColumns();
    };

    const colDefs = getColDefs();
    const lineHeight = 30;
    const rowLineHeight = 28;
    let headerHeight = 4 * lineHeight;
    if (props.granularity === 'WZP') headerHeight = 3 * lineHeight + 10;
    if (props.granularity === 'HOUR') headerHeight = 4 * lineHeight;
    //@ts-ignore
    const dataLength = rawData?.length ? rawData?.length : 30;
    const bodyHeight = dataLength * rowLineHeight + lineHeight;
    let aproxHeight = headerHeight + bodyHeight;
    return (
        <Wraptable aproxHeight={aproxHeight}>
            <Table
                name="volumeCategoryWZPHourTable"
                //key={`-${props.granularity}-${colDefs.length}`}
                showCOG={false}
                pagination={false}
                filter
                columnDefs={(colDefs && colDefs) || []}
                rowData={rawData}
                onGridReady={onGridReady}
            />
        </Wraptable>
    )
}

export default VolumeCategoryForWZPHour;