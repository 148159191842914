/**
 *
 * ActionColumn
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CrystalBallIcon from 'images/assets/magic-ball.png';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImgWrapper = styled.img`
  height: 16.5px;
  width: 17px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
  align-items: center;
  background-color: ${props => props.theme.color.white};
  transition ${props => props.theme.transitions.shadow};
  color: ${props => props.disabled && props.theme.color.grey4};
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: none;
    background-color: ${props => !props.disabled && props.theme.color.yellow};
  }
`;

function canEditDetail(props) {
  return props.onDetail && props.data.editable;
}

function canEditCopy(props) {
  return props.onCopy && props.data.editable && !props.data.deleted;
}

function ActionColumn(props) {
  const copyDisabled = props.data.planningArea && props.data.planningArea.deleted;
  const hasSmartProdJobs = props.data.planningParameters && props.data.planningParameters.hasSmartProdJobs;
  return (
    <IconsWrapper>
      {canEditDetail(props) && (
        <ItemWrapper onClick={() => props.onDetail(props.data.id, true)}>
          <FontAwesomeIcon icon="edit" size="lg" />
          <FormattedMessage {...messages.details} />
        </ItemWrapper>
      )}
       {props.onGraph && (
        <ItemWrapper onClick={() => props.onGraph(props.data.id)}>
          <FontAwesomeIcon icon="edit" size="lg" />
          <FormattedMessage {...messages.graph} />
        </ItemWrapper>
      )}
      {props.onResult && (
        <ItemWrapper onClick={() => props.onResult(props.data.id)}>
          <FontAwesomeIcon icon="vials" size="lg" />
          <FormattedMessage {...messages.result} />
        </ItemWrapper>
      )}
      {props.onForecast && hasSmartProdJobs && (
        <ItemWrapper onClick={() => props.onForecast(props.data.id)}>
          <ImgWrapper src={CrystalBallIcon} height='16.5px' width='17px' />
          <FormattedMessage {...messages.forecast} />
        </ItemWrapper>
      )}
      {canEditCopy(props) && (
        <ItemWrapper
          onClick={() => {
            if (copyDisabled) {
              return;
            }
            props.onCopy(props.data.id);
          }}
          disabled={copyDisabled}
          title={copyDisabled && props.intl.formatMessage(messages.paDisabled)}
        >
          <FontAwesomeIcon icon="copy" size="lg" color={copyDisabled && props.theme.color.grey4} />
          <FormattedMessage {...messages.copy} />
        </ItemWrapper>
      )}
    </IconsWrapper>
  );
}

ActionColumn.propTypes = {
  onDetail: PropTypes.func,
  onResult: PropTypes.func,
  onCopy: PropTypes.func,
  data: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
};

export default compose(
  injectIntl,
  withTheme,
)(ActionColumn);
