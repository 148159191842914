import { createSelector } from 'reselect';
import { selectUOMS, getUomsForActivites, selectActivities } from 'containers/PlanningAreaDetailPage/selectors';
import { T_TYPE } from 'utils/constants';
import get from 'lodash/get';
import { initialState } from './reducer';

/**
 * Direct selector to the planDetailPage state domain
 */

const selectPlanDetailPageDomain = state => state.get('planDetailPage', initialState);
const selectPeriodIndex = state => state.getIn(['guiState', 'periodIndex'], initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PlanDetailPage
 */

export const selectPlan = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('plan'),
);

export const selectPlanId = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['plan', 'id']),
);

export const selectPlanningParameters = createSelector(
  selectPlan,
  plan => plan && plan.planningParameters,
);

export const selectPlanningParametersId = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['plan', 'planningParameters', 'id']),
);

export const selectPlanningAreaFromPlan = createSelector(
  selectPlan,
  plan => plan && plan.planningArea,
);

export const selectOffDays = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['plan', 'planningParameters', 'offDays']),
);
export const selectFirstday = createSelector(selectPlanDetailPageDomain,
substate => substate && substate.getIn(['plan', 'planningParameters', 'firstDayOfWeek']));

export const selectGuiState = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('guiState'),
);
export const selectEditFromplan = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.getIn(['guiState', 'edit']),
);

export const selectPeriodIndexFromPlan = createSelector(
  selectPlanDetailPageDomain,
  selectPeriodIndex,
);

export const selectPeriodIdFromPlan = createSelector(
  selectPlanningParameters,
  selectPeriodIndexFromPlan,
  (pp, periodIndex) => pp && pp.periods && pp.periods[periodIndex] && pp.periods[periodIndex].id,
);

export const makeSelectLoadingFromPlan = () =>
  createSelector(
    selectPlanDetailPageDomain,
    substate => substate.getIn(['guiState', 'loading']),
  );

export const makeSelectOpenedMhesFromPlan = () =>
  createSelector(
    selectPlanDetailPageDomain,
    substate => substate.get('activityIdsMheOpen').toJS(),
  );

  export const makeSelectOpenedShiftsFromPlan = () =>
  createSelector(
    selectPlanDetailPageDomain,
    substate => substate.get('activityIdsShiftOpen').toJS(),
  );

  export const makeSelectOpenedRolesFromPlan = () =>
  createSelector(
    selectPlanDetailPageDomain,
    substate => substate.get('activityIdsRoleOpen').toJS(),
  );

  export const selectPRPerShiftFromPlan = createSelector(
    selectPlanDetailPageDomain,
    substate =>
      (substate.getIn(['plan', 'planningParameters', 'productivityRatePerShift'])),
  );

export const makeSelectShiftCountFromPlan = () =>
  createSelector(
    selectPlanningParameters,
    selectPeriodIndexFromPlan,
    (pp, periodIndex) => {
      const shifts = pp && get(pp, `periods[${periodIndex}].shifts`);
      return (shifts && shifts.length) || 0;
    },
  );

export const makeSelectIsShiftFromPlan = () =>
  createSelector(
    selectPlanningParameters,
    pp => ((pp && pp.transformationType) || T_TYPE.WZP) === T_TYPE.SHIFT,
  );

export const selectWzpCountFromPlan = createSelector(
  selectPlanningParameters,
  selectPeriodIndexFromPlan,
  (pp, periodIndex) => {
    const wzp = pp && get(pp, `periods[${periodIndex}].workZonePeriods`);
    return (wzp && wzp.length) || 0;
  },
);

export const selectPlanDepartments = createSelector(
  selectPlanningParameters,
  params => (params && params.departments) || [],
);

export const uomsForActitivies = createSelector(
  selectPlanningParameters,
  selectActivities,
  selectPeriodIndexFromPlan,
  (pp, activities, periodIndex) => {
    const aParams = pp && get(pp, `periods[${periodIndex}].activityParameters`);
    return getUomsForActivites(activities, aParams);
  },
);

export const selectPlanDetailMasterPlan = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planDetailMasterPlans'),
);

export const selectPlanDetailSchedule = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planDetailSchedules'),
);

export const selectPlanDetailSmartRuns = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planDetailSmartRuns'),
);

export const selectPlanDetailSmartRunsLoader = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planWarnings'),
);

export const selectPlanDetailsLoader = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('plandetailsWarning'),
);

export const selectPlanDetailsLoaderFlag = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('plandetailsFlag'),
);

export const selectPlanOverviewLoaderFlag = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planOverviewFlag'),
);

export const selectMasterPlanDialog = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.get('masterplanDialog'),
);

export const selectScheduleDialog = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.get('scheduleDialog'),
);

export const selectMasterPlanFromPlan = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.get('planDetailsforMP'),
);

export const selectOpenedButtonFlag = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('openedButtonFlag'),
);

export const selectplanRationWarningFlag = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planRationWarningFlag'),
);

export const selectplanRoles = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planRoles'),
);

export const selectPlanSmartCodeMappings = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('planSmartCodeMappings'),
);

export const selectShowValue = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('showValue'),
);

export const selectShowFormula = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('showFormula'),
);

export const selectShowValueVar = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('showValueVar'),
);

export const selectShowFormulaVar = createSelector(
  selectPlanDetailPageDomain,
  substate => substate.get('showFormulaVar'),
);