// @flow

import React from 'react';
import NamedSection from 'components/NamedSection';
import { PlanDetailBasicInfo } from 'components/PlanDetailBasicInfo';
import { PlanDetailBasicSettings } from 'components/PlanDetailBasicSettings';
import { EditableList } from 'components/CdmEditableList';
import WorkingHours from 'components/WorkingHours';
import { FacilityItem } from 'components/CdmEditableList/FacilityItem';
import { DetailPageSectionsWrapper } from 'components/DetailPageShared';
import { injectIntl } from 'react-intl';
import { getIn } from 'formik';
import { T_TYPE } from 'utils/constants';
import DepartmentItem from 'components/CdmEditableList/DepartmentItem';
import messages from './messages';

type Props = {
  editMode: boolean,
  formik?: Object,
};

class BasicSettings extends React.PureComponent<Props> {
  render() {
    const {
      formik = {},
      intl: { formatMessage },
    } = this.props;

    const tTypeValue = formik.values && getIn(formik.values, 'planningParameters.transformationType', T_TYPE.WZP);
    const isShift = tTypeValue === T_TYPE.SHIFT;

    return (
      <DetailPageSectionsWrapper>
        <NamedSection message={messages.basicInfo}>
          <PlanDetailBasicInfo formik={formik} />
        </NamedSection>
        <NamedSection message={messages.basicSettings}>
          <PlanDetailBasicSettings />
        </NamedSection>
        <NamedSection message={isShift ? messages.shifts : messages.workingHours}>
          <WorkingHours values={formik.values} formatMessage={formatMessage} />
        </NamedSection>
        <NamedSection message={messages.facilities}>
          <EditableList field="planningArea.facilities" isEdit={false} editComponent={FacilityItem} />
        </NamedSection>
        <NamedSection message={messages.customers}>
          <EditableList field="planningArea.customers" isEdit={false} />
        </NamedSection>
        <NamedSection message={messages.department}>
          <EditableList
            field="planningParameters.departments"
            isEdit={false}
            editComponent={DepartmentItem}
            delegateNewItemRender
          />
        </NamedSection>
      </DetailPageSectionsWrapper>
    );
  }
}

export default injectIntl(BasicSettings);
