/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import 'react-toastify/dist/ReactToastify.css';

import React, { Fragment } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBookOpen,
  faBookReader,
  faBoxes,
  faBroadcastTower,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClock,
  faCog,
  faCogs,
  faComments,
  faCommentSlash,
  faCopy,
  faDatabase,
  faDownload,
  faEdit,
  faExchangeAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faFileCsv,
  faFileExport,
  faFileUpload,
  faHistory,
  faHome,
  faInfo,
  faInfoCircle,
  faKey,
  faKeyboard,
  faLock,
  faMinusCircle,
  faObjectGroup,
  faPaperPlane,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faSignOutAlt,
  faSquare,
  faStar,
  faSync,
  faTable,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faUnlockAlt,
  faUpload,
  faUserClock,
  faUserCog,
  faUserEdit,
  faUsersCog,
  faUserTie,
  faVials,
  faYinYang,
} from '@fortawesome/free-solid-svg-icons';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import CreateMasterPlanPage from 'containers/CreateMasterPlanPage';
import ForecastDetailPage from 'containers/ForecastDetailPage';
import MasterPlanDetailPage from 'containers/MasterPlanDetailPage';
import PlanInterfaces, { PlanAreaInterfaces } from 'containers/PlanInterfaces';

import EnvRibbon from '../../components/EnvRibbon';
import CreateCopyPlanPaPage from '../CreateCopyPlanPaPage/Loadable';
import CreateShiftSchedulePage from '../CreateShiftSchedulePage/Loadable';
import CreateSmartProdPage from '../CreateSmartProdPage/index.tsx';
import HistoryPage from '../HistoryPage/Loadable';
import HomePage from '../HomePage/Loadable';
import Init from '../Init';
import LoginPage from '../LoginPage/Loadable';
import MasterPlanListPage from '../MasterPlanListPage';
import NotFoundPage from '../NotFoundPage/Loadable';
import PlanComputationStatisticsPage from '../PlanCalculationStatistics';
import PlanDetailPage from '../PlanDetailPage';
import PlanGraphs from '../PlanGraphs';
import PlanListPage from '../PlanListPage';
import PlanningAreaDetailPage from '../PlanningAreaDetailPage';
import PlanningAreasListPage from '../PlanningAreasListPage/Loadable';
import PlanResultPage from '../PlanResultPage';
import ProgressBar from '../ProgressBar';
import ShiftScheduleActivityDetailPage from '../ShiftScheduleActivityDetailPage';
import ShiftScheduleDetailPage from '../ShiftScheduleDetailPage';
import ShiftScheduleInterfacesPage from '../ShiftScheduleInterfacesPage';
import ShiftScheduleListPage from '../ShiftScheduleListPage';
import ShiftScheduleRunDetailPage from '../ShiftScheduleRunDetailPage';
import ShiftScheduleRunsPage from '../ShiftScheduleRunsPage';
import ShiftScheduleRunsPowerBIPage from '../ShiftScheduleRunsPowerBIPage';
import ShiftScheduleStaffDetailPage from '../ShiftScheduleStaffDetailPage';
import SmartProdSourceDetailPage from '../SmartProdSourceDetailPage';
import SmartProdSourceListPage from '../SmartProdSourceListPage';
import { PATHS } from './constants';
import ErrorBoundary from './ErrorBoundary';

library.add(
  faDownload,
  faQuestionCircle,
  faBookOpen,
  faBookReader,
  faSearch,
  faUserCog,
  faStar,
  faCogs,
  faSync,
  faHome,
  faBuilding,
  faChartLine,
  faInfoCircle,
  faInfo,
  faVials,
  faArrowCircleLeft,
  faSave,
  faCopy,
  faPlusCircle,
  faHistory,
  faArrowCircleRight,
  faEdit,
  faCheck,
  faSquare,
  faCheckSquare,
  faCheckCircle,
  faTrash,
  faKeyboard,
  faArrowLeft,
  faMinusCircle,
  faYinYang,
  faArrowRight,
  faClock,
  faTable,
  faTimes,
  faTimesCircle,
  faUserTie,
  faLock,
  faArrowDown,
  faArrowUp,
  faFileExport,
  faSignOutAlt,
  faKey,
  faUnlockAlt,
  faCog,
  faUsersCog,
  faDatabase,
  faTrashAlt,
  faAngleUp,
  faAngleDown,
  faComments,
  faCommentSlash,
  faUpload,
  faBroadcastTower,
  faExternalLinkAlt,
  faBoxes,
  faFileCsv,
  faPaperPlane,
  faEye,
  faUserEdit,
  faCalculator,
  faToggleOn,
  faToggleOff,
  faCalendarAlt,
  faExchangeAlt,
  faExclamationCircle,
  faFileUpload,
  faObjectGroup,
  faUserClock,
);

export default function App() {
  return (
    <ErrorBoundary>
      <EnvRibbon />
      <Switch>
        <Route exact path={PATHS.root} component={HomePage} />
        <Route exact path={PATHS.mainScreen} component={HomePage} />
        <Route exact path={PATHS.planList} component={PlanListPage} />
        <Route exact path={PATHS.planListNoReset} component={PlanListPage} />
        <Route exact path={PATHS.planDetail} component={PlanDetailPage} />
        <Route exact path={PATHS.planResult} component={PlanResultPage} />
        <Route exact path={PATHS.planInterfaces} component={PlanInterfaces} />
        <Route exact path={PATHS.planGraphs} component={PlanGraphs} />
        <Route exact path={PATHS.planComputationStatistics} component={PlanComputationStatisticsPage} />
        <Route exact path={PATHS.planningAreaInterfaces} component={PlanAreaInterfaces} />
        <Route exact path={PATHS.planDetailId} component={PlanDetailPage} />
        <Route exact path={PATHS.planningAreasList} component={PlanningAreasListPage} />
        <Route exact path={PATHS.planningAreasListNoReset} component={PlanningAreasListPage} />
        <Route exact path={PATHS.planningAreaDetail} component={PlanningAreaDetailPage} />
        <Route exact path={PATHS.masterPlanList} component={MasterPlanListPage} />
        <Route exact path={PATHS.masterPlanListNoReset} component={MasterPlanListPage} />
        <Route exact path={PATHS.masterPlanDetail} component={MasterPlanDetailPage} />
        <Route exact path={PATHS.login} component={LoginPage} />
        <Route exact path={PATHS.history} component={HistoryPage} />
        <Route exact path={PATHS.forecast} component={ForecastDetailPage} />

        <Route exact path={PATHS.makeShiftSchedule} component={CreateShiftSchedulePage} />
        <Route exact path={PATHS.shiftScheduleDetailId} component={ShiftScheduleDetailPage} />
        <Route exact path={PATHS.shiftScheduleRuns} component={ShiftScheduleRunsPage} />
        <Route exact path={PATHS.shiftScheduleRunsPowerBi} component={ShiftScheduleRunsPowerBIPage} />
        <Route exact path={PATHS.shiftScheduleList} component={ShiftScheduleListPage} />
        <Route exact path={PATHS.shiftScheduleListNoReset} component={ShiftScheduleListPage} />
        <Route exact path={PATHS.smartShiftJobScheduleDetailId} component={ShiftScheduleActivityDetailPage} />
        <Route exact path={PATHS.shiftScheduleStaffDetailId} component={ShiftScheduleStaffDetailPage} />
        <Route exact path={PATHS.shiftScheduleInterfaces} component={ShiftScheduleInterfacesPage} />
        <Route exact path={PATHS.shiftScheduleRunDetailId} component={ShiftScheduleRunDetailPage} />
        <Route exact path={PATHS.shiftScheduleRunDetailCopy} component={ShiftScheduleRunDetailPage} />
        <Route exact path={PATHS.makeSmartProdSource} component={CreateSmartProdPage}/>
        <Route exact path={PATHS.makeMasterPlan} component={CreateMasterPlanPage} />
        <Route exact path={PATHS.make} component={CreateCopyPlanPaPage} />
        <Route exact path={PATHS.makeCopy} component={CreateCopyPlanPaPage} />

        <Route exact path={PATHS.smartProdSourceList} component={SmartProdSourceListPage} />
        <Route exact path={PATHS.smartProdSourceListNoReset} component={SmartProdSourceListPage} />
        <Route exact path={PATHS.smartProdSourceDetail} component={SmartProdSourceDetailPage} />

        <Route component={NotFoundPage} />
      </Switch>
      <ToastContainer />
      <ProgressBar />
      <Init />
    </ErrorBoundary>
  );
}
