/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import LabourAvailabilityCategoryTable from '../LabourAvailabilityCategoryTable';
import LabourAvailabilityCategoryAddLine from '../LabourAvailabilityCategoryAddLine';

/* eslint-disable react/prefer-stateless-function */
class PlanDetailLabourAvailability extends React.PureComponent {
  render() {
    return (
      <div>
        <FormattedMessage {...messages.header} />
        <LabourAvailabilityCategoryAddLine values={this.props.formik.values} />
        <LabourAvailabilityCategoryTable formik={this.props.formik} />
      </div>
    );
  }
}

PlanDetailLabourAvailability.propTypes = {
  formik: PropTypes.object,
};

export default PlanDetailLabourAvailability;
