export function groupByDepartmentProps(formatMessage, messages, valueGetter) {
  function departmentValueGetter(params) {
    return (params.data && params.data.department) || formatMessage(messages.allDepartments);
  }

  return {
    // Grouping
    groupDefaultExpanded: -1,
    autoGroupColumnDef: {
      filterValueGetter: valueGetter || departmentValueGetter,
      menuTabs: ['filterMenuTab'],
      headerName: formatMessage(messages.department), // custom header name for group
      pinned: 'left', // force pinned left. Does not work in columnDef
      cellRendererParams: {
        suppressCount: true, // remove number in Group Column
      },
    },
  };
}
